import useNow from "@cosine/composables/useNow";
import { differenceInCalendarDays, isSameDay, isSameMonth } from "date-fns";
import { computed, Ref } from "vue";

export default function (date: Ref<Date>, monthDate: Ref<Date>) {
  const {
    now,
  } = useNow();

  const isDateCurrentMonth = computed(() => isSameMonth(date.value, monthDate.value));
  const differenceInDays = computed(() => differenceInCalendarDays(date.value, now.value));
  const isDatePastOrPresent = computed(() => differenceInDays.value <= 0);
  const isDateFuture = computed(() => differenceInDays.value > 0);
  const isToday = computed(() => isSameDay(now.value, date.value));

  return {
    isDateCurrentMonth,
    isDatePastOrPresent,
    isDateFuture,
    isToday,
  };
}
