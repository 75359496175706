import googleTagManager from "@analytics/google-tag-manager";
import * as _amplitude from "@amplitude/analytics-browser";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import Analytics from "analytics";
import { defineStore } from "pinia";
import { Capacitor } from "@capacitor/core";

export default defineStore("AnalyticsStore", () => {
  const {
    isDevelopment,
  } = useRuntimeEnvironment();

  const plugins = [];

  const gtmId = import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID;
  if (gtmId) {
    plugins.push(googleTagManager({
      containerId: gtmId,
      debug: isDevelopment,
    }));
  }

  const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
  const amplitude = amplitudeApiKey ? _amplitude : null;
  amplitude?.init(import.meta.env.VITE_AMPLITUDE_API_KEY, {
    logLevel: isDevelopment ? amplitude.Types.LogLevel.Debug : amplitude.Types.LogLevel.Error,
    autocapture: {
      elementInteractions: {
        dataAttributePrefix: "data-analytics-",
      },
    },
  });

  const analytics = Analytics({
    plugins,
  });

  function identify (userId: string) {
    const platform = Capacitor.isNativePlatform() ? "mobile_native" : "web";

    analytics.identify(userId, {
      app_platform: platform,
    });

    if (amplitude) {
      amplitude.setUserId(userId);

      const identifyEvent = new amplitude.Identify();
      identifyEvent.set("app_platform", platform);
      amplitude.identify(identifyEvent);
    }
  };

  function page () {
    analytics.page();
    amplitude?.track("Page View");
  };

  function track (eventName: string, data: {[key: string]: any }) {
    analytics.track(eventName, data);
    amplitude?.track(eventName, data);
  };

  return {
    identify,
    page,
    track,
  };
});
