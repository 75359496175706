export enum FlagKey {
  GlobalBanner = "GlobalBanner",
  EnableProfileSettingsUI = "EnableProfileSettingsUI",
  EnableUpgradeCTAs = "EnableUpgradeCTAs",
  HasAccessToRoutines = "HasAccessToRoutines",
  HasAccessToInvesting = "HasAccessToInvesting",
  EnableChatUI = "EnableChatUI",
  EnableForYouTimeline = "EnableForYouTimeline",
  SpendingCalendarDetails = "SpendingCalendarDetails",
  EnableYourInvestments = "EnableYourInvestments",
  EnableAppleAuth = "EnableAppleAuth",
  EnableFacebookAuth = "EnableFacebookAuth",
}
