<template>
  <InfiniteScroll
    class="TimelineListView"
    scrollViewport="Mobile"
    :pagination="{
      pageIndex: pagination.Page,
      pageCount: pagination.TotalPages,
    }"
    includeViewportPadding="bottom"
    ref="scrollContainerRef"
    @scrollEnd="$emit('scrollEnd')"
  >
    <SyncIndicator
      :isSyncing="isSyncingFinances"
      itemName="timeline"
      :lastUpdatedDate="financesSyncDate"
      :includeBorder="false"
      v-bind="{scrollElementRef}"
    />

    <EmailVerificationReminderView />

    <GroupedList>
      <slot name="carousel" />

      <LabeledList
        v-for="list in lists"
        :key="list.title"
        :title="list.title"
      >
        <TimelineEntryView
          v-for="entry in list.items"
          :key="entry.IdReference"
          :modelValue="entry"
        />
      </LabeledList>
    </GroupedList>
  </InfiniteScroll>
</template>

<script lang="ts" setup>
import GroupedList from "@cosine/components/GroupedList.vue";
import InfiniteScroll from "@cosine/components/InfiniteScroll.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import SyncIndicator from "@cosine/components/SyncIndicator.vue";
import groupByDate from "@cosine/lib/utils/collection/groupByDate";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { IClientTimelineEntry, IPagination } from "@cosine/types/api-models";
import TimelineEntryView from "@cosine/views/TimelineEntryView.vue";
import { storeToRefs } from "pinia";
import { computed, toRefs, useTemplateRef } from "vue";
import EmailVerificationReminderView from "./EmailVerificationReminderView.vue";

defineEmits<{
  scrollEnd: [],
}>();

const props = defineProps<{
  entries: Array<IClientTimelineEntry>,
  pagination: IPagination,
}>();

const scrollContainerRef = useTemplateRef("scrollContainerRef");

const {
  entries,
} = toRefs(props);

const financialStore = useFinancialStore();
const {
  isSyncingFinances,
  financesSyncDate,
} = storeToRefs(financialStore);

const lists = computed(() => {
  return groupByDate(entries.value, (entry) => new Date(entry.DateForDisplay || new Date));
});

const scrollElementRef = computed(() => scrollContainerRef.value?.scrollRef);
</script>
