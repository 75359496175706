<template>
  <Modal
    v-bind="{isVisible}"
  >
    <slot />
  </Modal>
</template>

<script lang="ts" setup>
import Modal from "@cosine/components/Modal.vue";
import { RouteName } from "@cosine/routes";
import { computed, toRefs } from "vue";
import { useRoute } from "vue-router";

const props = defineProps<{
  routeNames: Array<RouteName>,
}>();
const route = useRoute();
const {
  routeNames,
} = toRefs(props);
const isVisible = computed(() => routeNames.value.includes(route.name as RouteName));
</script>
