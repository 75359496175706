<template>
  <TransactionRecapPlaceholderEntry
    v-if="isPlaceholder"
    v-bind="{ isSyncing }"
  />
  <FinancialTimelineEntry
    v-else
    :label="label"
    v-bind="{
      title,
      titleCallouts,
      isSyncing,
    }"
    :lastUpdatedTimestamp="payload?.SnapshotDate"
  >
    <TransactionRecapMetricGrid
      v-if="payload"
      :modelValue="payload"
    />
  </FinancialTimelineEntry>
</template>

<script lang="ts" setup>
import FinancialTimelineEntry from "@cosine/components/FinancialTimelineEntry.vue";
import TransactionRecapPlaceholderEntry from "@cosine/components/TransactionRecapPlaceholderEntry.vue";
import useFinancialTimelineEntry from "@cosine/composables/useFinancialTimelineEntry";
import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { IClientTimelineEntry, ITransactionRecapSnapshot } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { parseISO, format, differenceInCalendarDays } from "date-fns";
import TransactionRecapMetricGrid from "@cosine/components/TransactionRecapMetricGrid.vue";
import useNow from "@cosine/composables/useNow";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";

const modelValue = defineModel<IClientTimelineEntry>({
  required: true,
});

const {
  payload,
  snapshot,
} = useFinancialTimelineEntry(modelValue, FinancialEntryPayloadTypeName.TransactionRecapPayload);

const {
  accounts,
} = storeToRefs(useFinancialStore());

const isWaitingForSnapshot = computed(() => {
  return accounts.value.length > 0 && totalTransactions.value === null && totalSpent.value === null;
});

const isSyncing = computed(() => isWaitingForSnapshot.value);

const isPlaceholder = computed(() => {
  return accounts.value.length === 0 || (totalTransactions.value === null && totalSpent.value === null);
});

const transactionSnapshot = computed(() => {
  return snapshot.value as ITransactionRecapSnapshot | undefined;
});

const date = computed(() => parseISO(payload.value?.FromDateInclusive));
const {
  now,
} = useNow();

const label = computed(() => `Spend recap: ${format(date.value, DateFormat.monthNameWithOrdinal)}`);

const totalTransactions = computed(() => transactionSnapshot.value?.TotalTransactions ?? null);
const totalSpent = computed(() => transactionSnapshot.value?.TotalSpent ?? null);

const formattedAmount = computed(() => formatAmount(totalSpent.value?.Amount));

const pluralizedNoun = computed(() => pluralize(totalTransactions.value || 0, "transaction", "transactions", {
  excludeCount: true,
}));

const title = computed(() => {
  const dayCount = differenceInCalendarDays(now.value, date.value);
  const hasNoTransactions = totalTransactions.value === 0;

  if (dayCount === 1) {
    if (hasNoTransactions) return "No transactions yesterday";
    return `Yesterday, you made ${totalTransactions.value} ${pluralizedNoun.value} totaling ${formattedAmount.value}`;
  }

  if (dayCount < 7) {
    if (hasNoTransactions) return `No transactions on ${format(date.value, DateFormat.weekdayName)}`;
    return `On ${format(date.value, DateFormat.weekdayName)}, you made ${totalTransactions.value} ${pluralizedNoun.value} totaling ${formattedAmount.value}`;
  }

  if (hasNoTransactions) return "No transactions on this day";

  return `You made ${totalTransactions.value} ${pluralizedNoun.value} totaling ${formattedAmount.value}`;
});

const titleCallouts = computed(() => {
  if (!totalTransactions.value || formattedAmount.value === null) return [];

  return [`${totalTransactions.value} ${pluralizedNoun.value}`, formattedAmount.value];
});

</script>
