<template>
  <section
    v-if="isUserActive"
    class="AppLayout"
    ref="elRef"
  >
    <div class="AppLayout__container">
      <div class="AppLayout__header">
        <AppHeaderView />
        <AppNavView />
        <LineSpinner
          class="AppLayout__lineSpinner"
          :isVisible="isLoading"
        />
      </div>

      <div class="AppLayout__contentContainer">
        <div class="AppLayout__content">
          <RouterView />
        </div>
      </div>
    </div>
  </section>

  <Teleport :to="PortalId.Body">
    <template v-if="isUserActive">
      <ThreadRouteView />
      <TransactionRouteView />
      <ConnectAccountsRouteView />
      <InvestingConnectRouteView v-if="isFlagReady && investmentsFlag" />
      <UpgradeSheetModalView />
    </template>

    <AlertView />
  </Teleport>
</template>

<script lang="ts" setup>
import LineSpinner from "@cosine/components/LineSpinner.vue";
import useCurrentUserFetch from "@cosine/composables/useCurrentUserFetch";
import useSignedIn from "@cosine/composables/useSignedIn";
import useViewportWidthWithoutScrollbar from "@cosine/composables/useViewportWidthWithoutScrollbar";
import useAppStore from "@cosine/stores/useAppStore";
import useAuthStore from "@cosine/stores/useAuthStore";
import { UserStatus } from "@cosine/types/api-models";
import AlertView from "@cosine/views/AlertView.vue";
import AppHeaderView from "@cosine/views/AppHeaderView.vue";
import AppNavView from "@cosine/views/AppNavView.vue";
import ConnectAccountsRouteView from "@cosine/views/ConnectAccountsRouteView.vue";
import ThreadRouteView from "@cosine/views/ThreadRouteView.vue";
import UpgradeSheetModalView from "@cosine/views/UpgradeSheetModalView.vue";
import { storeToRefs } from "pinia";
import { computed, provide, useTemplateRef } from "vue";
import { PortalId } from "@cosine/components/Portal.types";
import { appLayoutScrollRefInjectionKey } from "@cosine/layouts/AppLayout.types";
import InvestingConnectRouteView from "@cosine/views/InvestingConnectRouteView.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useIntroRedirect from "@cosine/composables/useIntroRedirect";
import TransactionRouteView from "@cosine/views/TransactionRouteView.vue";
import useMobileKeyboardViewport from "@cosine/composables/useMobileKeyboardViewport";

useCurrentUserFetch();
useSignedIn();
useIntroRedirect();
useMobileKeyboardViewport();

const {
  currentUser,
} = storeToRefs(useAuthStore());
const {
  isLoading,
} = storeToRefs(useAppStore());

const {
  isFlagReady, flag: investmentsFlag,
} = useFlag(FlagKey.EnableYourInvestments, false);

const elRef = useTemplateRef<HTMLElement>("elRef");
useViewportWidthWithoutScrollbar(elRef);
provide(appLayoutScrollRefInjectionKey, elRef);

const isUserActive = computed(() => currentUser.value?.Status === UserStatus.Active);
</script>

<style lang="scss" scoped>
.AppLayout {
  height: 100%;

  @media (min-width: 600px) {
    overflow-y: scroll;
  }
}

.AppLayout__container {
  position: relative;
  z-index: 0;

  display: grid;
  grid: min-content auto / auto;
  max-width: var(--layoutMaxWidth);
  min-height: 100%;
  margin: 0 auto;

  outline: 1px solid var(--themeColorBorder);
  background-color: var(--themeColorBackgroundSurface);
}

.AppLayout__header {
  position: sticky;
  top: 0;
  z-index: var(--zIndexHeader);

  background-color: var(--themeColorBackgroundSurface);
}

.AppLayout__lineSpinner {
  position: absolute;
  inset-inline: 0px;
  bottom: -1px;
}

.AppLayout__contentContainer {
  position: relative;
}

.AppLayout__content {
  height: 100%;

  @media (max-width: 599px) {
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
}
</style>
