import { Hsluv } from "hsluv";

export default function (startColor: string, endColor: string, ratio: number) {
  const [
    sr,
    sg,
    sb,
  ] = colorToRgb(startColor);

  const [
    tr,
    tg,
    tb,
  ] = colorToRgb(endColor);

  const mixedColor = new Hsluv();
  mixedColor.rgb_r = sr + (tr - sr) * ratio;
  mixedColor.rgb_g = sg + (tg - sg) * ratio;
  mixedColor.rgb_b = sb + (tb - sb) * ratio;
  mixedColor.rgbToHex();

  return mixedColor.hex;
}

function colorToRgb (color: string) {
  const hsluv = new Hsluv();

  if (color.startsWith("rgb")) {
    const [
      r,
      g,
      b,
    ] = color
      .replace(/^rgb\(|\)$/, "")
      .split(",")
      .map((num) => parseInt(num, 10) / 255);

    hsluv.rgb_r = r;
    hsluv.rgb_g = g;
    hsluv.rgb_b = b;
  } else {
    hsluv.hex = color;
    hsluv.hexToRgb();
  }

  return [
    hsluv.rgb_r,
    hsluv.rgb_g,
    hsluv.rgb_b,
  ];
}
