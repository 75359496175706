<template>
  <Teleport
    v-if="portalRef"
    :to="portalSelector"
    defer
  >
    <Transition>
      <slot />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { computed, inject, ref, Ref } from "vue";

const portalRef = inject<Ref<HTMLElement>>("portalRef", ref(document.body));
const portalSelector = computed(() => {
  if (portalRef.value.id) return `#${portalRef.value.id}`;
  if (portalRef.value === document.body) return "body";

  return null;
});
</script>
