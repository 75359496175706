<template>
  <Form
    class="ThreadForm"
    :class="{
      [`ThreadForm--isEmpty`]: !message
    }"
    v-bind="{isDisabled}"
    @submit="handleSubmit"
  >
    <UIText
      class="ThreadForm__disclaimer"
      size="2XSmall"
      weight="Medium"
    >
      <Icon
        class="ThreadForm__disclaimerIcon"
        name="Info"
      />
      Chat is currently in beta. Double-check responses.
    </UIText>
    <div class="ThreadForm__inputContainer">
      <UIText
        size="Small"
        tag="p"
        class="ThreadForm__inputPlaceholder"
      >
        {{ message || "Ask a question…" }}
      </UIText>
      <UIText
        ref="inputRef"
        :value="message"
        tag="input"
        size="Small"
        class="ThreadForm__input"
        placeholder="Ask a question…"
        required
        @focus="$emit('focus')"
        @input="message = $event.target.value"
      />
    </div>
    <IconFormButton
      class="ThreadForm__button"
      type="submit"
      iconName="ArrowUp"
    />
  </Form>
</template>

<script lang="ts" setup>
import Form from "@cosine/components/Form.vue";
import Icon from "@cosine/components/Icon.vue";
import IconFormButton from "@cosine/components/IconFormButton.vue";
import UIText from "@cosine/components/UIText.vue";
import { ref, useTemplateRef } from "vue";

const emit = defineEmits<{
  focus: [],
  submit: [message:string],
}>();
withDefaults(defineProps<{
  isDisabled?: boolean,
}>(), {
  isDisabled: false,
});
const inputRef = useTemplateRef("inputRef");
const message = ref("");

defineExpose({
  inputRef,
});

function handleSubmit () {
  if (!message.value.trim()) { return; }

  emit("submit", message.value);
  message.value = "";
}
</script>

<style lang="scss" scoped>
.ThreadForm {
  padding: 16px;

  [data-js-mobile-keyboard-state="closed"] & {
    padding-bottom: calc(16px + var(--safeAreaInsetBottom));
  }

  &:not(:focus-within) {
    @media (display-mode: standalone) {
      padding-bottom: calc(var(--viewportPaddingBottom) + 24px);
    }
  }

  // TODO: find a better way to control layout within Form
  :deep(fieldset) {
    display: grid;
    grid: min-content auto / auto min-content;
    gap: 16px 0;
  }
}

.ThreadForm__disclaimer {
  display: flex;
  grid-column: 1 / -1;
  gap: 4px;
  align-items: center;

  color: var(--themeColorTextSubdued);
}

.ThreadForm__disclaimerIcon {
  --iconColor: currentColor;

  width: 14px;
  height: 14px;
}

.ThreadForm__inputContainer {
  position: relative;

  display: flex;
  align-items: center;

  border: solid 1px var(--colorSwissGrey100);
  background-color: var(--colorWhite);

  flex: 1 1 auto;

  &:focus-within {
    border-color: var(--colorSwissGrey200);
  }

  :deep(fieldset:disabled) & {
    background: none;
  }
}

.ThreadForm__inputPlaceholder {
  position: absolute;
  inset: 0;

  display: flex;
  align-items: center;
  padding: 16px;
  overflow: hidden;

  white-space: nowrap;
  pointer-events: none;

  .ThreadForm--isEmpty & {
    color: var(--colorSwissAlphaBlack400);
  }

  [data-js-mobile-keyboard-state="open"] & {
    opacity: 0;
  }

  @media (min-width: 600px) {
    display: none;
  }
}

.ThreadForm__input {
  width: 100%;
  padding: 16px;

  border: none;
  outline: none;
  background: none;

  @media (max-width: 599px) {
    // This prevents mobile browsers from pushing the viewport off the screen when the keyboard opens
    [data-js-mobile-keyboard-state="closed"] & {
      opacity: 0;
    }
  }
}

.ThreadForm__button {
  --buttonColor: var(--colorSwissBlack);

  width: 56px;
  height: 51.5px;
}
</style>
