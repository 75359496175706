<template>
  <section class="InvestingAccountListView">
    <SectionHeader
      title="Linked brokerage accounts"
      :subtitle="formattedAccountCount"
    />

    <FinancialAccountItem
      v-for="account in activeInvestmentAccounts"
      :key="account.EraAccountId"
      :modelValue="account"
      includeSyncStatus
      @click="openModal({name: RouteName.investingAccount, params: {id: account.EraAccountId}})"
    />
  </section>
</template>

<script lang="ts" setup>
import FinancialAccountItem from "@cosine/components/FinancialAccountItem.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { RouteName } from "@cosine/routes";
import useInvestingStore from "@cosine/stores/useInvestingStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const {
  activeInvestmentAccounts,
} = storeToRefs(useInvestingStore());

const {
  openModal,
} = useModalRouter();

const formattedAccountCount = computed(() => pluralize(activeInvestmentAccounts.value.length, "account", "accounts"));
</script>
