<template>
  <Transition>
    <section
      v-if="articleModalRoute && combinedEntry"
      class="ArticleModalView"
      :class="{
        [`ArticleModalView--isBlurred`]: isThreadModalOpen,
        [`ArticleModalView--isChatEnabled`]: isFlagReady && isChatEnabled,
      }"
    >
      <div
        class="ArticleModalView__background"
        @click="handleClose"
      />
      <div class="ArticleModalView__window">
        <ScrollContainer
          class="ArticleModalView__scrollContainer"
          includeScrollbar
          includeViewportPadding="bottom"
        >
          <EntryArticleView
            :modelValue="combinedEntry"
          />
        </ScrollContainer>
        <IconButton
          class="ArticleModalView__backButton"
          iconName="ArrowLeft"
          @click="handleClose"
        />
        <LineSpinner
          class="ArticleModalView__lineSpinner"
          :isVisible="isLoading"
        />
      </div>
    </section>
  </Transition>
</template>

<script lang="ts" setup>
import IconButton from "@cosine/components/IconButton.vue";
import LineSpinner from "@cosine/components/LineSpinner.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { IClientTimelineEntry } from "@cosine/types/api-models";
import EntryArticleView from "@cosine/views/EntryArticleView.vue";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

const {
  closeActiveModal,
} = useModalRouter();
const {
  modalRoute: articleModalRoute,
  isModalOpen: isArticleModalOpen,
} = useModalRoute(RouteName.articlesView);
const {
  isModalOpen: isThreadModalOpen,
} = useModalRoute(RouteName.threadsView);
const timelineStore = useTimelineStore();
const {
  fetchEntry,
} = timelineStore;
const {
  entries,
} = storeToRefs(timelineStore);
const {
  handleRequest,
  isRequesting: isLoading,
} = useRequest();
const {
  isFlagReady,
  flag:isChatEnabled,
} = useFlag(FlagKey.EnableChatUI, true);

const fullStaticEntry = ref<IClientTimelineEntry | null>(null);
const timelineEntry = computed(() => {
  if (!articleModalRoute.value) return null;

  return entries.value.find((entry) => entry.IdReference === articleModalRoute.value?.params.id);
});

const combinedEntry = computed((): IClientTimelineEntry | null => {
  if (!timelineEntry.value) return null;

  return {
    ...timelineEntry.value,
    BodyAsMarkdown: fullStaticEntry.value?.BodyAsMarkdown || timelineEntry.value.BodyAsMarkdown,
  };
});

watch(isArticleModalOpen, () => {
  if (isArticleModalOpen.value && articleModalRoute.value) {
    fetchArticle(articleModalRoute.value.params.id.toString());
  }
}, {
  immediate: true,
});

async function fetchArticle (id: string) {
  fullStaticEntry.value = null;
  const [, fetchedEntry] = await handleRequest(fetchEntry(id));

  if (fetchedEntry) {
    fullStaticEntry.value = fetchedEntry;
  }
}

function handleClose () {
  closeActiveModal();
}
</script>

<style lang="scss" scoped>
.ArticleModalView {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  display: grid;
  justify-items: flex-end;
  width: 100%;

  transition: none 600ms;

  @media (max-width: 599px) {
    top: var(--appLogoBottom);
  }
}

.ArticleModalView__background {
  position: absolute;
  inset: 0;
  z-index: -1;

  background: var(--colorSwissAlphaWhite800);
  backdrop-filter: blur(var(--backgroundBlur));

  transition: opacity var(--mobileThemeTransition);
  cursor: pointer;

  .ArticleModalView.v-enter-from &,
  .ArticleModalView.v-leave-to & {
    opacity: 0;
  }
}

.ArticleModalView__window {
  --mobilePaddingBottom: 0px;
  position: relative;

  display: grid;
  width: 100%;
  height: 100%;
  max-width: var(--layoutMaxWidth);

  outline: 1px solid var(--colorSwissGrey100);
  background-color: var(--colorSwissGrey50);

  transition: 600ms cubic-bezier(0.22, 1, 0.36, 1);
  transition-property: opacity, transform;

  .ArticleModalView--isBlurred & {
    @media (min-width: 600px) {
      transform: translateX(-64px);
    }
  }

  .ArticleModalView.v-enter-from &,
  .ArticleModalView.v-leave-to & {
    transform: translateX(110%);

    @media (min-width: 600px) {
      transform: translateX(64px);
      opacity: 0;
    }
  }
}

.ArticleModalView__lineSpinner {
  position: relative;
  top: -2px;
}

.ArticleModalView__scrollContainer {
  --mobilePaddingBottom: 0px;

  position: absolute;
  inset: 0;

  .ArticleModalView--isChatEnabled & {
    bottom: calc(var(--threadInputHeight) + var(--mobilePaddingBottom));
  }

  @media (display-mode: standalone) {
    .EraRoot[data-js-mobile-keyboard-state="closed"] & {
      --mobilePaddingBottom: calc(8px + var(--viewportPaddingBottom));
    }
  }
}

.ArticleModalView__backButton {
  position: absolute;
  top: 32px;
  left: 0;

  @media (max-width: 599px) {
    &.IconButton {
      border-left: none;
    }
  }

  @media (min-width: 600px) {
    transform: translateX(-100%);
  }
}
</style>
