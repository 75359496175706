import currency from "currency.js";

export default function (value: string | null): number | null {
  if (value === null || typeof value === "string" && value.trim() === "") return null;

  const unformattedValue = currency(value, {
    precision: 10,
  }).divide(100).value;

  return !isNaN(unformattedValue) ? unformattedValue : null;
}
