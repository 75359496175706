import { OS } from "@cosine/lib/utils/system/detectOS.types";

export function detectOS (): OS {
  if (/android/i.test(navigator.userAgent)) {
    return OS.Android;
  } else if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
    return OS.iOS;
  }

  return OS.Other;
}
