<template>
  <header
    class="AppHeader"
    @click="handleClick"
  >
    <div class="AppHeader__container">
      <h1 class="AppHeader__logo">
        <Logo />
        <slot name="badge" />
      </h1>

      <div
        class="AppHeader__buttonContainer"
      >
        <Transition>
          <AppHeaderLogoButton
            v-if="!isThreadOpen && isChatEnabled"
            class="AppHeader__logoButton"
            v-bind="{ connectionState }"
            :isAnimating="isChatAnimating"
            @click.stop="$emit('click:logoIcon')"
          />

          <button
            v-else-if="isThreadOpen"
            class="AppHeader__closeButton"
            @click.stop="$emit('close')"
          >
            <Icon
              name="X"
            />
          </button>
        </Transition>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import AppHeaderLogoButton from "@cosine/components/AppHeaderLogoButton.vue";
import Icon from "@cosine/components/Icon.vue";
import Logo from "@cosine/components/Logo.vue";
import useTapToScrollToTop from "@cosine/composables/useTapToScrollToTop";
import { HubConnectionState } from "@microsoft/signalr";

defineEmits<{
  "click:logoIcon": [],
  close: [],
}>();

withDefaults(defineProps<{
  connectionState?: HubConnectionState,
  isChatAnimating?: boolean,
  isThreadOpen?: boolean,
  isChatEnabled?: boolean,
}>(), {
  connectionState: HubConnectionState.Connected,
  isChatAnimating: false,
  isThreadOpen: false,
  isChatEnabled: true,
});

const {
  scrollToTop,
} = useTapToScrollToTop();

function handleClick () {
  if (window.innerWidth < 600) {
    scrollToTop();
  }
}
</script>

<style lang="scss" scoped>
.AppHeader {
  display: grid;
  align-items: flex-end;
  height: var(--appLogoHeaderHeight);
  padding: 0 6px 0 16px;
}

.AppHeader__container {
  display: grid;
  grid: auto / auto 40px;
  justify-content: space-between;
  align-items: center;
}

.AppHeader__logo {
  display: flex;
  gap: 8px;
  align-items: center;
}

.AppHeader__buttonContainer {
  position: relative;
  width: 40px;
  height: 40px;
}

.AppHeader__closeButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppHeader__logoButton,
.AppHeader__closeButton {
  position: absolute;

  width: 40px;
  aspect-ratio: 1;

  transition: 350ms var(--easeOutCubic);

  &.v-enter-from,
  &.v-leave-to {
    transform: scale(0);
    opacity: 0;
  }
}
</style>
