<template>
  <MonthlyCashflowPlaceholderEntry
    v-if="isPlaceholder"
    v-bind="{ isSyncing }"
  />
  <FinancialTimelineEntry
    v-else
    :label="label"
    v-bind="{
      title,
      titleCallouts,
      isSyncing,
    }"
    :lastUpdatedTimestamp="payload?.SnapshotDate"
  >
    <MonthlyCashflowChart
      v-if="payload?.Snapshot && (income || expenditure)"
      :id="payload?.SnapshotDate"
      :modelValue="payload"
      :isSegmented="shouldSegmentSpendingChart"
      v-bind="{
        incomeLabel: incomeChartLabel,
        spendingLabel: spendingChartLabel,
      }"
    />
  </FinancialTimelineEntry>
</template>

<script lang="ts" setup>
import FinancialTimelineEntry from "@cosine/components/FinancialTimelineEntry.vue";
import useFinancialTimelineEntry from "@cosine/composables/useFinancialTimelineEntry";
import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { IClientTimelineEntry, IMonthlyCashflowOverviewSnapshot } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import MonthlyCashflowPlaceholderEntry from "@cosine/components/MonthlyCashflowPlaceholderEntry.vue";
import MonthlyCashflowChart from "@cosine/components/MonthlyCashflowChart.vue";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import { parseISO, format, differenceInMonths } from "date-fns";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import useNow from "@cosine/composables/useNow";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";

const modelValue = defineModel<IClientTimelineEntry>({
  required: true,
});

// > 500% difference
const SPENDING_TO_INCOME_RATIO_THRESHOLD = 5;
// Don't segment the chart unless it's going to be wide enough
const SEGMENT_SPENDING_CHART_THRESHOLD = 0.5;

const {
  payload,
  snapshot,
} = useFinancialTimelineEntry(modelValue, FinancialEntryPayloadTypeName.MonthlyCashflowOverviewPayload);

const {
  accounts,
} = storeToRefs(useFinancialStore());

const isWaitingForSnapshot = computed(() => {
  return accounts.value.length > 0 && income.value === null && expenditure.value === null;
});

const isSyncing = computed(() => isWaitingForSnapshot.value);

const monthlyCashflowSnapshot = computed(() => {
  return snapshot.value as IMonthlyCashflowOverviewSnapshot | undefined;
});

const isPlaceholder = computed(() => {
  return accounts.value.length === 0 || (income.value === null && expenditure.value === null);
});

const income = computed(() => monthlyCashflowSnapshot.value?.Income?.Amount ?? null);
const expenditure = computed(() => monthlyCashflowSnapshot.value?.Expenditure?.Amount ?? null);
const spendRatio = computed(() => {
  if (!income.value || !expenditure.value) return null;

  return expenditure.value / income.value;
});

const {
  now,
} = useNow();
const date = computed(() => parseISO(payload.value?.FromDateInclusive));

const label = computed(() => `${format(date.value, DateFormat.monthName)} cashflow`);

const isPastMonth = computed(() => differenceInMonths(date.value, now.value) < 0);

const title = computed(() => {
  const month = format(date.value, DateFormat.monthName);

  if (!income.value && !expenditure.value) {
    return isPastMonth.value ? `You didn’t spend or receive any money in ${month}` : "You haven’t spent or received any money yet this month";
  }

  if (expenditure.value === 0) {
    return isPastMonth.value ? `You spent nothing in ${month}` : "You’ve spent nothing this month";
  }

  if (income.value === 0 || spendRatio.value === null || spendRatio.value > SPENDING_TO_INCOME_RATIO_THRESHOLD) {
    return isPastMonth.value ? `You spent way more than your ${month} income` : `You’ve spent way more than your ${month} income`;
  }

  return `${isPastMonth.value ? "You" : "You’ve"} spent ${formatPercent(spendRatio.value, {
    decimalPlaceCount: 2,
    minDecimalPlaceCount: 0,
  })} of your monthly income`;
});

const titleCallouts = computed(() => {
  if (!spendRatio.value && income.value) return [];

  if (income.value === 0
    || (spendRatio.value && spendRatio.value > SPENDING_TO_INCOME_RATIO_THRESHOLD)) {
    return [`${isPastMonth.value ? "You" : "You’ve"} spent way more`];
  };

  return [
    `spent ${formatPercent(spendRatio.value, {
      decimalPlaceCount: 2,
      minDecimalPlaceCount: 0,
    })}`,
  ];
});

const incomeChartLabel = computed(() => `You made ${formatAmount(income.value)} in ${format(date.value, DateFormat.monthName)}`);
const spendingChartLabel = computed(() => `You spent ${formatAmount(expenditure.value)} in ${format(date.value, DateFormat.monthName)}`);

const shouldSegmentSpendingChart = computed(() => {
  return spendRatio.value ? spendRatio.value > SEGMENT_SPENDING_CHART_THRESHOLD : undefined;
});
</script>
