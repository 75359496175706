<template>
  <SheetModal
    class="InvestingConnectModalView"
    size="Fill"
    theme="White"
    :canClose="isConnectModalOpen"
    @close="closeActiveModal()"
  >
    <div class="InvestingConnectModalView__content">
      <Transition>
        <VendorSecurityScreen
          v-if="isConnectModalOpen && viewState === 'Security'"
          tagTitle="Connect brokerage"
          title="Era uses Snaptrade to securely connect your accounts"
          :features="[
            {
              iconName: 'Lock',
              title: 'Top notch security',
              body: 'SnapTrade is SOC-2 certified for the collection, processing, maintenance, and protection of data.',
            },
            {
              iconName: 'VisibilityOff',
              title: 'Your data belongs to you',
              body: 'SnapTrade doesn’t touch your data without your permission, and the only time it does so is when you authenticate your brokerage.',
            },
          ]"
          @click:continue="viewState = 'Connect'"
        >
          <template #logo>
            <path
              d="M205.998 56L230.596 70V98L205.998 112L181.4 98V70L205.998 56Z"
              fill="currentColor"
            />
            <path
              d="M214.898 88.9887C214.898 94.5187 210.548 98.9987 205.188 98.9987V78.9688C210.548 78.9688 214.898 83.4487 214.898 88.9787V88.9887Z"
              fill="white"
            />
            <path
              d="M204.71 71.27V86.75C201.43 85.83 199 82.71 199 79.01C199 75.31 201.42 72.19 204.71 71.27ZM206.71 69C201.35 69 197 73.48 197 79.01C197 84.54 201.35 89.02 206.71 89.02V69Z"
              fill="white"
            />
          </template>
        </VendorSecurityScreen>

        <SelectBrokerageView v-else-if="isConnectModalOpen" />
        <ConnectBrokerageView v-else-if="isConnectBrokerageModalOpen" />
      </Transition>
    </div>
  </SheetModal>
</template>

<script lang="ts" setup>
import SheetModal from "@cosine/components/SheetModal.vue";
import VendorSecurityScreen from "@cosine/components/VendorSecurityScreen.vue";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import ConnectBrokerageView from "@cosine/views/ConnectBrokerageView.vue";
import SelectBrokerageView from "@cosine/views/SelectBrokerageView.vue";
import { ref } from "vue";

const {
  closeActiveModal,
} = useModalRouter();
const {
  isModalOpen: isConnectModalOpen,
} = useModalRoute(RouteName.investingConnect);
const {
  isModalOpen: isConnectBrokerageModalOpen,
} = useModalRoute(RouteName.investingConnectBrokerage);

const viewState = ref<"Security" | "Connect">("Security");
</script>

<style lang="scss" scoped>
.InvestingConnectModalView__content {
  isolation: isolate;
}
</style>
