<template>
  <div class="SpendingCalendar">
    <div class="SpendingCalendar__header">
      <UIText
        v-for="day in days"
        :key="day.title"
        class="SpendingCalendar__day"
        tag="abbr"
        size="2XSmall"
        :title="day.title"
      >
        {{ day.abbreviation }}
      </UIText>
    </div>

    <div class="SpendingCalendar__body">
      <SpendingCalendarDate
        v-for="date in calendarWithoutFutureSpending.dates"
        :key="date.date.toISOString()"
        :modelValue="date"
        v-bind="{monthDate}"
        :isClickable="isDateClickable(date.date)"
        @click="isDateClickable(date.date) ? handleDateClick(date.date) : undefined"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ISpendingCalendarDate } from "@cosine/components/SpendingCalendarDate.types";
import SpendingCalendarDate from "@cosine/components/SpendingCalendarDate.vue";
import UIText from "@cosine/components/UIText.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useModalRouter from "@cosine/composables/useModalRouter";
import useNow from "@cosine/composables/useNow";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";
import { RouteName } from "@cosine/routes";
import useSpendingCalendarStore from "@cosine/stores/useSpendingCalendarStore";
import { IDailySpending, IAggregatedSpendingSnapshot } from "@cosine/types/api-models";
import { eachDayOfInterval, startOfWeek, endOfWeek, format, startOfMonth, endOfMonth, isSameDay, parseISO, addDays, isSameMonth } from "date-fns";
import { computed, toRefs } from "vue";

const modelValue = defineModel<IAggregatedSpendingSnapshot>({
  required: true,
});

const props = defineProps<{
  monthDate: Date,
}>();

const {
  monthDate,
} = toRefs(props);

const {
  openModal,
} = useModalRouter();

const {
  updateSpendingCalendarDates,
  updateSelectedDate,
} = useSpendingCalendarStore();

const {
  flag: detailsFlag,
} = useFlag(FlagKey.SpendingCalendarDetails, false);

const {
  now,
} = useNow();

const days = eachDayOfInterval({
  start: startOfWeek(monthDate.value),
  end: endOfWeek(monthDate.value),
}).map((date) => {
  const title = format(date, DateFormat.weekdayName);

  return {
    title,
    abbreviation: title.substring(0, /^S|T/.test(title) ? 2 : 1), // TODO: rethink this when we localize beyond English
  };
});

const datesWithoutFutureSpending = computed((): Array<IDailySpending> => {
  // NOTE: we filter out future spending because MX returns future transactions
  // that interfere with our min/max logic and styling
  const tomorrow = addDays(now.value, 1)
    .toISOString()
    .substring(0, 10);

  return modelValue.value?.DailySpending?.filter((dailySpending) => {
    return dailySpending.Date < tomorrow;
  }) || [];
});

const amountRange = computed(() => {
  if (datesWithoutFutureSpending.value.length === 0) return [0, 0];

  return datesWithoutFutureSpending.value.reduce((acc, dailySpending, i, arr) => {
    const _minAmount = Math.min(acc[0], dailySpending.Total.Amount);
    const _maxAmount = Math.max(acc[1], dailySpending.Total.Amount);

    if (i === arr.length - 1 && _minAmount === _maxAmount) {
      return [0, _maxAmount];
    }

    return [_minAmount, _maxAmount];
  }, [Number.MAX_SAFE_INTEGER, 0]);
});

const dateRange = computed(() => {
  return eachDayOfInterval({
    start: startOfWeek(startOfMonth(monthDate.value)),
    end: endOfWeek(endOfMonth(monthDate.value)),
  });
});

const calendarWithAllSpending = computed(() => getSpendingCalendarForDates(modelValue.value.DailySpending || []));
const calendarWithoutFutureSpending = computed(() => getSpendingCalendarForDates(datesWithoutFutureSpending.value));

function getSpendingCalendarForDates (dates: Array<IDailySpending>) {
  const [minAmount, maxAmount] = amountRange.value;

  return {
    dates: dateRange.value.reduce<Array<ISpendingCalendarDate>>((acc, date) => {
      const amount = dates.find((dailySpending) => {
        return isSameDay(parseISO(dailySpending.Date), date);
      })?.Total.Amount || 0;

      acc.push({
        date,
        amount,
        spendingRatio: maxAmount ? (amount - minAmount) / (maxAmount - minAmount) : 0,
      });

      return acc;
    }, []),
  };
}

function isDateClickable (date: Date) {
  return detailsFlag && isSameMonth(monthDate.value, date);
}

function handleDateClick (date: Date) {
  updateSpendingCalendarDates(calendarWithAllSpending.value.dates);
  updateSelectedDate(date);

  openModal({
    name: RouteName.spendingCalendarDate,
    params: {
      year: date.getFullYear(),
      month: format(date, "MM"),
      day: format(date, "dd"),
    },
  });
}
</script>

<style lang="scss" scoped>
.SpendingCalendar {
  display: grid;
  grid: auto / repeat(7, 1fr);
  margin: 0 8px;
}

.SpendingCalendar__header,
.SpendingCalendar__body {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: subgrid;
}

.SpendingCalendar__day {
  color: var(--colorSwissAlphaBlack600);
  text-align: center;
}

.SpendingCalendar__body {
  margin: 8px 0;
}
</style>
