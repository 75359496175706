import currency from "currency.js";

// TODO: support other currencies
export default function unformatAmount (value: string | null): number | null {
  if (value === null || typeof value === "string" && value.trim() === "") return null;

  const unformattedValue = currency(value).multiply(100).value;

  return !isNaN(unformattedValue) ? unformattedValue : null;
}
