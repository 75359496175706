<template>
  <FormInput
    :value="modelValue"
    v-bind="{
      disabled,
      required,
      placeholder,
      name,
      readonly,
      type,
      isRounded,
    }"
    @input="handleInput"
  />
</template>

<script lang="ts" setup>
import FormInput from "@cosine/components/FormInput.vue";

const emit = defineEmits<{
  "update:modelValue": [string],
}>();

withDefaults(defineProps<{
  disabled?: boolean,
  modelValue?: string,
  name?: string,
  placeholder?: string,
  required?: boolean,
  readonly?: boolean,
  type?: "text" | "password" | "email" | "number",
  isRounded?: boolean,
}>(), {
  required: false,
  type: "text",
  isRounded: false,
});

function handleInput (event: Event) {
  const target = event.target as HTMLInputElement;

  emit("update:modelValue", target.value);
}
</script>
