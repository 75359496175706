<template>
  <div class="InvestmentMixHistoricalChartView">
    <InvestmentMixHistoricalChart
      v-if="historicalTimeSeriesRange && historicalTimeSeriesRange.Series.length > 0"
      :modelValue="historicalTimeSeriesRange"
    />

    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </div>
</template>

<script lang="ts" setup>
import InvestmentMixHistoricalChart from "@cosine/components/InvestmentMixHistoricalChart.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useNow from "@cosine/composables/useNow";
import useRequest from "@cosine/composables/useRequest";
import getMostRecentMarketDateRange from "@cosine/lib/utils/date/getMostRecentMarketDateRange";
import useInvestmentMixStore from "@cosine/stores/useInvestmentMixStore";
import { AggregateSpan, IBlueprintHistoricalSummary, IBlueprintHistoricalValue, IBlueprintModel, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { ref, watch } from "vue";

const modelValue = defineModel<IBlueprintModel>({
  required: true,
});

const {
  now,
} = useNow();

const investmentMixStore = useInvestmentMixStore();
const {
  fetchInvestmentMixHistoricalData,
} = investmentMixStore;

const {
  isRequesting,
  handleRequest,
} = useRequest();

const historicalTimeSeriesRange = ref<ITimeSeriesRangeOf<IBlueprintHistoricalSummary, IBlueprintHistoricalValue>>();

watch(modelValue, () => {
  if (!modelValue.value) return;

  fetchHistoricalData(modelValue.value);
}, {
  immediate: true,
});

async function fetchHistoricalData (mix: IBlueprintModel) {
  const {
    marketOpenDate,
    marketCloseDate,
  } = getMostRecentMarketDateRange(now.value);

  const [_err, res] = await handleRequest(fetchInvestmentMixHistoricalData({
    mixIds: [mix.BlueprintId],
    from: marketOpenDate.toISOString(),
    to: marketCloseDate.toISOString(),
    aggregateSpan: AggregateSpan.FiveMinutes,
  }));

  if (res) {
    historicalTimeSeriesRange.value = res;
  }
}
</script>

<style lang="scss" scoped>
.InvestmentMixHistoricalChartView {
  position: relative;
}
</style>
