<template>
  <fieldset
    class="FormFieldset"
  >
    <slot />
  </fieldset>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.FormFieldset {
  display: grid;
  gap: 24px 0px;
  padding: 24px var(--layoutMargin);
  outline: 1px solid var(--colorSwissGrey100);
}
</style>
