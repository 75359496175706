<template>
  <FormButton
    class="ConnectAccountsButtonView"
    variant="Primary"
    @click="modalRouter.openModal({name: RouteName.accountsConnect})"
  >
    Link accounts
  </FormButton>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";

const modalRouter = useModalRouter();
</script>
