import useModalStore from "@cosine/stores/useModalStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { ref } from "vue";

export default function useEditModal<T> (modalName: ModalName) {
  const {
    openModal, closeModal,
  } = useModalStore();
  const editingItem = ref<T>();

  function startEditingItem (item?: T) {
    editingItem.value = item;
    openModal(modalName);
  }

  function finishEditingItem () {
    editingItem.value = undefined;
    closeModal(modalName);
  }

  return {
    startEditingItem,
    finishEditingItem,
    editingItem,
  };
}
