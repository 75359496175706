import AppLayout from "@cosine/layouts/AppLayout.vue";
import Accounts from "@cosine/pages/accounts.vue";
import Authenticate from "@cosine/pages/authenticate.vue";
import Budgets from "@cosine/pages/budgets.vue";
import Cashflow from "@cosine/pages/cashflow.vue";
import Investing from "@cosine/pages/investing.vue";
import Login from "@cosine/pages/login.vue";
import Routines from "@cosine/pages/routines.vue";
import Settings from "@cosine/pages/settings.vue";
import Timeline from "@cosine/pages/timeline.vue";
import Transactions from "@cosine/pages/transactions.vue";
import Transfers from "@cosine/pages/transfers.vue";
import BudgetListView from "@cosine/views/BudgetListView.vue";
import InvestingExploreView from "@cosine/views/InvestingExploreView.vue";
import InvestingListView from "@cosine/views/InvestingListView.vue";
import InvestingTradingWaitlistView from "@cosine/views/InvestingTradingWaitlistView.vue";
import RoutineExploreView from "@cosine/views/RoutineExploreView.vue";
import RoutineListView from "@cosine/views/RoutineListView.vue";
import TimelineBookmarkView from "@cosine/views/TimelineBookmarkView.vue";
import TimelineChatView from "@cosine/views/TimelineChatView.vue";
import TimelineForYouView from "@cosine/views/TimelineForYouView.vue";
import TimelineNewsView from "@cosine/views/TimelineNewsView.vue";
import { RouteLocation, RouteRecordRaw } from "vue-router";
import SettingsProfile from "@cosine/pages/settingsProfile.vue";

export enum RouteName {
  accounts = "accounts",
  accountsView = "accountsView",
  accountsConnect = "accountsConnect",
  accountsMemberReconnect = "accountsMemberReconnect",
  accountsReconnect = "accountsReconnect",
  accountsVerify = "accountsVerify",
  articlesView = "articlesView",
  authenticate = "authenticate",
  backstageComponentExample = "backstageComponentExample",
  budgets = "budgets",
  budgetsMonthly = "budgetsMonthly",
  budgetsWeekly = "budgetsWeekly",
  budgetsYearly = "budgetsYearly",
  budgetsDefinitionsView = "budgetsDefinitionsView",
  cashflow = "cashflow",
  checkout = "checkout",
  devtools = "devtools",
  index = "index",
  intro = "intro",
  investing = "investing",
  investingInvestments = "investingInvestments",
  investingExplore = "investingExplore",
  investingTrading = "investingTrading",
  investingConnect = "investingConnect",
  investingConnectBrokerage = "investingConnectBrokerage",
  investingReconnectAccount = "investingReconnectAccount",
  investingAccount = "investingAccount",
  investingMix = "investingMix",
  investingMixSubscribe = "investingMixSubscribe",
  login = "login",
  signup = "signup",
  markets = "markets",
  onboarding = "onboarding",
  routines = "routines",
  routinesYours = "routinesYours",
  routinesExplore = "routinesExplore",
  routinesView = "routinesView",
  routinesSettings = "routinesSettings",
  routinesNew = "routinesNew",
  settings = "settings",
  settingsProfile = "settingsProfile",
  spendingCalendarDate = "spendingCalendarDate",
  threadsNew = "threadsNew",
  threadsView = "threadsView",
  timeline = "timeline",
  timelineAll = "timelineAll",
  timelineForYou = "timelineForYou",
  timelineNews = "timelineNews",
  timelineChats = "timelineChats",
  timelineBookmarks = "timelineBookmarks",
  timelineProfilesView = "timelineProfilesView",
  transactions = "transactions",
  transactionsView = "transactionsView",
  transfers = "transfers",
  transfersNew = "transfersNew",
  transfersEdit = "transfersEdit",
  transfersReview = "transfersReview",
  transfersView = "transfersView",
  transfersDemo = "transfersDemo",
  waitlist = "waitlist",
}

// TODO: refactor to remove this and any reference to it
// in favor of using RouteName and only referencing these paths
// in the routes array
export enum RoutePath {
  accounts = "/accounts",
  authenticate = "/authenticate",
  budgets = "/budgets",
  budgetsMonthly = "/budgets/monthly",
  budgetsWeekly = "/budgets/weekly",
  budgetsYearly = "/budgets/yearly",
  devtools = "/devtools",
  index = "/",
  intro = "/intro",
  login = "/login",
  signup = "/signup",
  markets = "/markets",
  routines = "/routines",
  settings = "/settings",
  transfers = "/transfers",
  transactions = "/transactions",
  transfersDemo = "/demo/transfers",
  waitlist = "/waitlist",
}

export const UngatedRoutePaths: Array<RoutePath> = [
  RoutePath.login,
  RoutePath.signup,
  RoutePath.authenticate,
  RoutePath.waitlist,
];

function redirectAsModal (backgroundRouteName: RouteName) {
  return (to: RouteLocation) => ({
    name: backgroundRouteName,
    hash: `#${to.path}`,
    params: {},
  });
}

export const routes: Array<RouteRecordRaw> = [
  {
    name: RouteName.login,
    path: "/login",
    component: Login,
  },
  {
    name: RouteName.signup,
    path: "/signup",
    component: Login,
  },
  {
    name: RouteName.authenticate,
    path: "/authenticate",
    component: Authenticate,
  },
  {
    name: RouteName.waitlist,
    path: "/waitlist",
    component: () => import("@cosine/pages/waitlist.vue"),
  },
  {
    name: RouteName.intro,
    path: "/intro",
    component: () => import("@cosine/pages/intro.vue"),
  },
  {
    name: RouteName.onboarding,
    path: "/onboarding",
    component: () => import("@cosine/pages/onboarding.vue"),
  },
  {
    name: RouteName.checkout,
    path: "/checkout",
    component: () => import("@cosine/pages/checkout.vue"),
  },
  {
    name: RouteName.devtools,
    path: "/devtools",
    component: () => import("@cosine/pages/devtools.vue"),
    meta: {
      isEmployeeRequired: true,
      isAllowedInDevelopment: true,
    },
  },
  {
    name: "smsDemo",
    path: "/demo/sms",
    component: () => import("@cosine/pages/demo/sms.vue"),
    meta: {
      isEmployeeRequired: true,
      isAllowedInDevelopment: true,
    },
  },
  {
    name: RouteName.transfersDemo,
    path: "/demo/transfers",
    component: () => import("@cosine/pages/demo/transfers.vue"),
    meta: {
      isEmployeeRequired: true,
    },
  },
  {
    path: "",
    component: AppLayout,
    children: [
      {
        path: "",
        name: RouteName.index,
        redirect: {
          name: RouteName.timelineForYou,
        },
      },
      {
        name: RouteName.settings,
        path: "/settings",
        component: Settings,
        children: [
          {
            name: RouteName.settingsProfile,
            path: "/settings",
            component: SettingsProfile,
          },
          {
            name: RouteName.accounts,
            path: "/accounts",
            component: Accounts,
            children: [
              {
                name: RouteName.accountsConnect,
                path: "/accounts/connect",
                redirect: redirectAsModal(RouteName.accounts),
              },
              {
                name: RouteName.accountsReconnect,
                path: "/accounts/:id/reconnect",
                redirect: redirectAsModal(RouteName.accounts),
              },
              {
                name: RouteName.accountsMemberReconnect,
                path: "/accounts/members/:memberGuid/reconnect",
                redirect: redirectAsModal(RouteName.accounts),
              },
              {
                name: RouteName.accountsVerify,
                path: "/accounts/verify",
                redirect: redirectAsModal(RouteName.accounts),
              },
              {
                name: RouteName.accountsView,
                path: "/accounts/:id",
                redirect: redirectAsModal(RouteName.accounts),
              },
            ],
          },
        ],
      },
      {
        name: RouteName.timeline,
        path: "/timeline",
        redirect: {
          name: RouteName.timelineForYou,
        },
        component: Timeline,
        children: [
          {
            name: RouteName.timelineAll,
            path: "/timeline/all",
            redirect: {
              name: RouteName.timelineForYou,
            },
          },
          {
            name: RouteName.timelineForYou,
            path: "/timeline/for-you",
            component: TimelineForYouView,
          },
          {
            name: RouteName.timelineNews,
            path: "/timeline/news",
            component: TimelineNewsView,
          },
          {
            name: RouteName.timelineChats,
            path: "/timeline/chats",
            component: TimelineChatView,
          },
          {
            name: RouteName.timelineBookmarks,
            path: "/timeline/bookmarks",
            component: TimelineBookmarkView,
          },
          {
            name: RouteName.timelineProfilesView,
            path: "/timeline/profiles/:id",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.articlesView,
            path: "/articles/:id",
            redirect: redirectAsModal(RouteName.timelineForYou),
          },
        ],
      },
      {
        name: RouteName.threadsNew,
        path: "/threads/new",
        redirect: redirectAsModal(RouteName.timelineForYou),
      },
      {
        name: RouteName.threadsView,
        path: "/threads/:id",
        redirect: redirectAsModal(RouteName.timelineForYou),
      },
      {
        name: RouteName.routines,
        path: "/routines",
        redirect: {
          name: RouteName.routinesYours,
        },
        component: Routines,
        children: [
          {
            name: RouteName.routinesYours,
            path: "/routines/list",
            component: RoutineListView,
            children: [
              {
                name: RouteName.routinesView,
                path: "/routines/:id",
                component: {
                  template: "",
                },
              },
              {
                name: RouteName.routinesSettings,
                path: "/routines/:id/settings",
                component: {
                  template: "",
                },
              },
            ],
          },
          {
            name: RouteName.routinesExplore,
            path: "/routines/explore",
            component: RoutineExploreView,
            children: [
              {
                name: RouteName.routinesNew,
                path: "/routines/:slug/new",
                component: {
                  template: "",
                },
              },
            ],
          },
        ],
      },
      {
        name: RouteName.transfers,
        path: "/transfers",
        component: Transfers,
        children: [
          {
            name: RouteName.transfersNew,
            path: "/transfers/new",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.transfersView,
            path: "/transfers/:id",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.transfersEdit,
            path: "/transfers/:id/edit",
            component: {
              template: "",
            },
          },
          {
            name: RouteName.transfersReview,
            path: "/transfers/:id/review",
            component: {
              template: "",
            },
          },
        ],
      },
      {
        name: RouteName.cashflow,
        path: "/budgets",
        redirect: {
          name: RouteName.budgetsWeekly,
        },
        component: Cashflow,
        children: [
          {
            name: RouteName.budgets,
            path: "/budgets/weekly",
            component: Budgets,
            children: [
              {
                name: RouteName.budgetsWeekly,
                path: "/budgets/weekly",
                component: BudgetListView,
              },
              {
                name: RouteName.budgetsMonthly,
                path: "/budgets/monthly",
                component: BudgetListView,
              },
              {
                name: RouteName.budgetsYearly,
                path: "/budgets/yearly",
                component: BudgetListView,
              },
              {
                name: RouteName.budgetsDefinitionsView,
                path: "/budgets/definitions/:id",
                redirect: redirectAsModal(RouteName.budgetsWeekly),
              },
            ],
          },
          {
            name: RouteName.transactions,
            path: "/transactions",
            component: Transactions,
            children: [
              {
                name: RouteName.transactionsView,
                path: "/transactions/:id",
                redirect: redirectAsModal(RouteName.transactions),
              },
            ],
          },
        ],
      },
      {
        name: RouteName.investing,
        path: "/investing",
        component: Investing,
        redirect: {
          name: RouteName.investingExplore,
        },
        children: [
          {
            name: RouteName.investingInvestments,
            path: "/investing/list",
            component: InvestingListView,
          },
          {
            name: RouteName.investingExplore,
            path: "/investing/explore",
            component: InvestingExploreView,
          },
          {
            name: RouteName.investingTrading,
            path: "/investing/trading",
            component: InvestingTradingWaitlistView,
          },
          {
            name: RouteName.investingConnect,
            path: "/investing/connect",
            redirect: redirectAsModal(RouteName.investingInvestments),
          },
          {
            name: RouteName.investingConnectBrokerage,
            path: "/investing/connect/:slug",
            redirect: redirectAsModal(RouteName.investingInvestments),
          },
          {
            name: RouteName.investingReconnectAccount,
            path: "/investing/accounts/:id/reconnect",
            redirect: redirectAsModal(RouteName.investingInvestments),
          },
          {
            name: RouteName.investingAccount,
            path: "/investing/accounts/:id",
            redirect: redirectAsModal(RouteName.investingInvestments),
          },
          {
            name: RouteName.investingMix,
            path: "/investing/mixes/:id",
            redirect: redirectAsModal(RouteName.investingExplore),
          },
          {
            name: RouteName.investingMixSubscribe,
            path: "/investing/mixes/:id/subscribe",
            redirect: redirectAsModal(RouteName.investingExplore),
          },
        ],
      },
      {
        name: RouteName.spendingCalendarDate,
        path: "/spending/:year/:month/:day",
        redirect: redirectAsModal(RouteName.timelineForYou),
      },
    ],
  },
  ...(import.meta.env.DEV
    ? [
      {
        path: "/backstage",
        component: () => import("@cosine/layouts/BackstageLayout.vue"),
        children: [
          {
            name: RouteName.backstageComponentExample,
            path: "/backstage/component/:name",
            component: () => import("@cosine/components/backstage/ComponentExamples.vue"),
          },
        ],
      },
    ]
    : []),
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@cosine/pages/404.vue"),
  },
];
