import { RouteName } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { ListName } from "@cosine/stores/useListStore.types";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { useRoute, useRouter } from "vue-router";

export default function () {
  const {
    currentUser,
  } = storeToRefs(useAuthStore());
  const router = useRouter();
  const route = useRoute();

  watch(currentUser, () => {
    if (!currentUser.value) return;

    if (!currentUser.value.ListSubscriptions.includes(ListName.HideIntro2024Q1)) {
      router.push({
        name: RouteName.intro,
        query: {
          redirect: route.query.redirect,
        },
      });
    }
  }, {
    immediate: true,
  });
}
