<template>
  <EmailVerificationReminder
    v-if="isReminderVisible"
    :email="userEmail"
    @click:resend="handleResendMagicLinkEmail"
  />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import EmailVerificationReminder from "@cosine/components/EmailVerificationReminder.vue";
import useAuthStore from "@cosine/stores/useAuthStore";
import useStytchStore from "@cosine/stores/useStytchStore";
import useRequest from "@cosine/composables/useRequest";
import useAuthConfig from "@cosine/composables/useAuthConfig";

const authStore = useAuthStore();

const {
  sendMagicLink,
} = useStytchStore();

const {
  handleRequest,
} = useRequest();

const {
  authenticateUrl,
} = useAuthConfig();

const userEmail = computed(() => authStore.currentUser?.Email);

const isReminderVisible = computed(() => {
  return authStore.currentUser && !authStore.isCurrentUserVerified;
});

async function handleResendMagicLinkEmail () {
  if (!authStore.currentUser || !userEmail.value) return;

  handleRequest(sendMagicLink(userEmail.value, authenticateUrl.value), {
    showAlerts: true,
    successMessage: "Verification email sent! Be sure to check your spam folder.",
  });
}
</script>
