<template>
  <section
    class="LoginPage"
    :data-is-email-sent="isEmailSent"
  >
    <a
      class="LoginPage__logo"
      :href="wwwBaseUrl"
    >
      <svg
        width="80"
        height="48"
        viewBox="0 0 80 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="80"
          height="48.0031"
          fill="var(--colorSwissBlack)"
        />
        <path
          d="M50.4981 31.5031C47.5536 31.5031 45.4223 29.7364 45.4223 27.2966C45.4223 24.7447 47.2732 23.1743 50.4981 22.7536L53.4146 22.361C54.1999 22.2489 54.5925 21.8563 54.5925 21.2113C54.5925 20.1456 53.5549 19.2482 51.8442 19.2482C50.2458 19.2482 49.0399 20.0895 48.9277 21.5758H45.5065C45.843 18.463 48.1986 16.5 51.8442 16.5C55.6861 16.5 58.1259 18.6874 58.1259 22.1367V27.2686C58.1259 28.0818 58.4344 28.4464 59.0233 28.4464C59.2196 28.4464 59.5 28.3903 59.5 28.3903V31.2788C59.5 31.2788 58.6587 31.447 57.8174 31.447C56.3311 31.447 55.2374 30.746 55.0692 29.3718C54.4242 30.6618 52.6575 31.5031 50.4981 31.5031ZM51.0871 28.811C53.3586 28.811 54.6766 27.1845 54.6766 24.9971V24.5764L51.0871 25.1654C49.6568 25.3897 48.9558 26.0627 48.9558 27.1284C48.9558 28.1099 49.7971 28.811 51.0871 28.811Z"
          fill="var(--colorWhite)"
        />
        <path
          d="M45.3329 19.9493C45.0244 19.8932 44.5197 19.7811 43.9308 19.7811C41.8836 19.7811 39.9767 21.5478 39.9767 25.9225V31.2788H36.4432V16.7243H39.9767V19.3885C40.6217 17.7339 42.5847 16.5 44.3514 16.5C44.8562 16.5 45.1647 16.5561 45.3329 16.5841V19.9493Z"
          fill="var(--colorWhite)"
        />
        <path
          d="M20.5 23.9875C20.5 20.2017 22.8276 16.5 27.5949 16.5C32.3623 16.5 34.6618 20.0895 34.6618 23.5949C34.6618 24.1838 34.6057 24.8849 34.6057 24.8849H23.9774C24.2858 27.2125 25.7441 28.5305 27.8754 28.5305C29.5019 28.5305 30.7358 27.8014 31.0723 26.5114H34.5777C34.101 29.6803 31.3808 31.5031 27.7912 31.5031C22.9958 31.5031 20.5 27.8014 20.5 23.9875ZM24.0895 22.2208H30.8479C30.7077 20.4821 29.586 19.1921 27.5949 19.1921C25.7721 19.1921 24.5382 20.2858 24.0895 22.2208Z"
          fill="var(--colorWhite)"
        />
      </svg>
    </a>

    <div class="LoginPage__container">
      <figure class="LoginPage__figure">
        <div class="LoginPage__figureContent">
          <ShapeIcon
            class="LoginPage__hexagonIcon"
            name="Hexagon"
          />

          <div class="LoginPage__screenContainer">
            <Transition>
              <LoginCarousel
                v-if="!isEmailSent"
                class="LoginPage__carousel"
              />
              <LoginEmailSentScreen
                v-else-if="email"
                class="LoginPage__emailSentScreen"
                v-bind="{email}"
              />
            </Transition>
          </div>
        </div>
      </figure>

      <div class="LoginPage__formContainer">
        <div class="LoginPage__formScreen">
          <section
            v-if="isFlagReady"
            class="LoginPage__form"
            data-testid="form"
          >
            <template v-if="!isInAppBrowser">
              <UIText
                v-if="route.name === RouteName.signup"
                class="LoginPage__formLabel"
                weight="Medium"
                size="Small"
              >
                Create your free account
              </UIText>

              <OAuthButtonView
                provider="Google"
                data-testid="googleButton"
              />
              <OAuthButtonView
                v-if="isAppleAuthEnabled"
                provider="Apple"
                data-testid="appleButton"
              />
              <OAuthButtonView
                v-if="isFacebookAuthEnabled"
                provider="Facebook"
                data-testid="facebookButton"
              />

              <div class="LoginPage__formSeparator">
                <UIText
                  weight="Medium"
                  size="XSmall"
                  class="LoginPage__formSeparatorLabel"
                >
                  or
                </UIText>

                <hr class="LoginPage__formSeparatorRule">
              </div>
            </template>

            <EmailFormView
              @send="handleEmailSent"
            />

            <UIText
              weight="Medium"
              size="Small"
              class="LoginPage__loginOrSignupLink"
            >
              <template
                v-if="route.name === RouteName.login"
              >
                Need an account?
                <RouterLink :to="{ name: RouteName.signup, query: route.query }">
                  Sign up for free
                </RouterLink>
              </template>

              <template
                v-else
              >
                Already have an account?
                <RouterLink :to="{ name: RouteName.login, query: route.query }">
                  Sign in
                </RouterLink>
              </template>
            </UIText>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import { RouteName } from "@cosine/routes";
import EmailFormView from "@cosine/views/auth/EmailFormView.vue";
import { useSeoMeta } from "@unhead/vue";
import { useRoute, RouterLink, useRouter } from "vue-router";
import { computed, ref } from "vue";
import LoginCarousel from "@cosine/components/LoginCarousel.vue";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import LoginEmailSentScreen from "@cosine/components/LoginEmailSentScreen.vue";
import ShapeIcon from "@cosine/components/ShapeIcon.vue";
import OAuthButtonView from "@cosine/views/auth/OAuthButtonView.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";

const router = useRouter();
const route = useRoute();

const {
  isFlagReady,
  flag: isAppleAuthEnabled,
} = useFlag(FlagKey.EnableAppleAuth, false);

const {
  flag: isFacebookAuthEnabled,
} = useFlag(FlagKey.EnableFacebookAuth, false);

const routeQueryKeys = Object.keys(route.query);
if (routeQueryKeys.length > 0 && !routeQueryKeys.some((key) => key === "redirect")) {
  router.replace({
    ...route,
    query: {
      ...route.query,
      redirect: encodeURIComponent(router.resolve({
        name: RouteName.index,
        query: route.query,
      }).fullPath),
    },
  });
}

const title = computed(() => route.name === RouteName.login ? "Login" : "Sign up");
useSeoMeta({
  title,
});

const {
  wwwBaseUrl,
} = useRuntimeEnvironment();

const isEmailSent = ref(false);
const email = ref<string>();
const isInAppBrowser = /LinkedIn|Instagram/i.test(navigator.userAgent);

function handleEmailSent (e: { email: string }) {
  isEmailSent.value = true;
  email.value = e.email;
}
</script>

<style lang="scss" scoped>
.LoginPage {
  display: grid;
  justify-items: center;
  height: 100%;
  overflow-y: auto;
}

.LoginPage__logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @media (max-width: 899px) {
    display: none;
  }
}

.LoginPage__container {
  display: grid;
  grid: min-content auto / auto;
  justify-items: center;
  width: 100%;

  background-color: var(--themeColorBackgroundApp);

  @media (min-width: 900px) {
    grid: auto / repeat(2, 1fr);
  }
}

.LoginPage__figure {
  display: grid;
  place-items: center;
  width: 100%;
}

.LoginPage__figureContent {
  position: relative;
  width: 100%;

  @media (min-width: 900px) {
    padding-bottom: 96px;
  }
}

.LoginPage__hexagonIcon {
  position: relative;
  z-index: 1;

  width: 29px;
  height: 32px;
  margin: calc(32px + var(--safeAreaInsetTop)) auto 32px;
}

.LoginPage__screenContainer {
  position: relative;
}

.LoginPage__carousel {
  transition: 500ms var(--easeOutQuad);
  transition-property: opacity, transform;

  &.v-leave-to {
    transform: translateY(24px);
    opacity: 0;
  }
}

.LoginPage__emailSentScreen {
  width: 100%;
  transition: 500ms var(--easeOutQuad);
  transition-property: opacity, transform;

  &.v-enter-active {
    position: absolute;
    inset: 0;
  }

  &.v-enter-from {
    transform: translateY(-24px);
    opacity: 0;
  }
}

.LoginPage__carousel,
.LoginPage__emailSentScreen {
  @media (min-width: 900px) {
    height: 260px;
  }
}

.LoginPage__formContainer {
  display: grid;
  width: 100%;
  overflow: hidden;
}

.LoginPage__formScreen {
  display: grid;
  justify-items: center;
  width: 100%;

  background-color: var(--themeColorBackgroundSurface);

  @media (max-width: 899px) {
    transition: transform 355ms var(--easeInCubic);

    .LoginPage[data-is-email-sent="true"] & {
      transform: translateY(100%);
    }
  }
}

.LoginPage__form {
  display: grid;
  gap: 10px;
  align-content: start;
  width: 100%;
  max-width: 420px;
  padding: 32px 24px;

  @media (min-width: 900px) {
    align-self: center;
  }
}

.LoginPage__formLabel {
  margin-bottom: 8px;
}

.LoginPage__formSeparator {
  display: grid;
  place-items: center;
  margin-block: 8px;
}

.LoginPage__formSeparatorLabel {
  position: relative;
  z-index: 1;

  grid-column: 1 / 2;
  grid-row: 1 / 2;
  padding-inline: 8px;

  background: var(--colorSwissGrey50);
  color: var(--colorSwissAlphaBlack400);
}

.LoginPage__formSeparatorRule {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;

  border: none;

  border-top: 1px solid var(--colorSwissGrey100);
}

.LoginPage__loginOrSignupLink {
  margin: 16px auto;

  @media (display-mode: standalone) {
    margin-bottom: 40px;
  }
}
</style>
