export enum MetricSentiment {
  positive = "positive",
  neutral = "neutral",
  negative = "negative",
}

export interface Metric {
  label: string,
  value: string,
  trend?: {
    value: string,
    sentiment: MetricSentiment,
  },
}
