import { TimelineEntrySources } from "@cosine/types/api-models";

export const timelineNewsSources = Object.freeze([TimelineEntrySources.System_NewsItem, TimelineEntrySources.System_PeriodicBrief]);
export const timelineFromEraSources = Object.freeze([
  TimelineEntrySources.AccountConnectionStatus,
  TimelineEntrySources.Announcement,
  TimelineEntrySources.System,
]);

export const timelineForYouSources = Object.freeze(Object.values(TimelineEntrySources).filter((source) => {
  return !timelineNewsSources.includes(source)
    && !timelineFromEraSources.includes(source);
}));
