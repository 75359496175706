<template>
  <ScrollContainer
    class="AccountsPage"
    viewport="Mobile"
    includeViewportPadding="bottom"
    ref="scrollContainerRef"
  >
    <SyncIndicator
      v-if="accounts.length > 0"
      :isSyncing="isSyncingAccounts"
      :lastUpdatedDate="accountsSyncDate"
      itemName="accounts"
      v-bind="{scrollElementRef}"
    />

    <EmailVerificationReminderView />

    <section class="AccountsPage__cardList">
      <FinancialAccountsView />
      <ImportMintTransactionsView />
      <FeatureCard
        title="Payroll"
        badge="Coming soon"
        body="Connect your employer’s payroll processor and get up-to-date payroll breakdowns, withholdings assistance, and tax estimates."
        color="Yellow"
      />
      <FeatureCard
        title="Brokerages"
        badge="Coming soon"
        body="Allow Era to trade, rebalance, and manage your portfolios by simply describing what you want or following your favorite mixes."
        color="Blue"
      />
    </section>
  </ScrollContainer>

  <Teleport :to="PortalId.Body">
    <Transition>
      <AccountSheetModalView
        v-if="accountModalRoute"
        :accountId="accountModalRoute.params.id.toString()"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import FeatureCard from "@cosine/components/FeatureCard.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import useModalRoute from "@cosine/composables/useModalRoute";
import { RouteName } from "@cosine/routes";
import AccountSheetModalView from "@cosine/views/AccountSheetModalView.vue";
import FinancialAccountsView from "@cosine/views/FinancialAccountsView.vue";
import ImportMintTransactionsView from "@cosine/views/ImportMintTransactionsView.vue";
import { useSeoMeta } from "@unhead/vue";
import { PortalId } from "@cosine/components/Portal.types";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { storeToRefs } from "pinia";
import SyncIndicator from "@cosine/components/SyncIndicator.vue";
import { computed, useTemplateRef } from "vue";
import EmailVerificationReminderView from "@cosine/views/EmailVerificationReminderView.vue";

const scrollContainerRef = useTemplateRef("scrollContainerRef");

useSeoMeta({
  title: "Accounts",
});

const {
  modalRoute: accountModalRoute,
} = useModalRoute(RouteName.accountsView);

const financialStore = useFinancialStore();
const {
  accounts,
  isSyncingAccounts,
  accountsSyncDate,
} = storeToRefs(financialStore);

const scrollElementRef = computed(() => scrollContainerRef.value?.trackEl);
</script>

<style lang="scss" scoped>
.AccountsPage {
  position: relative;

  height: 100%;

  background-color: var(--themeColorBackgroundSurface);

  isolation: isolate;
}

.AccountsPage__cardList {
  display: grid;
  gap: 1px;
}
</style>
