import { Alert } from "@cosine/stores/useAlertStore.types";
import { defineStore } from "pinia";
import { v4 as uuid } from "uuid";
import { ref } from "vue";

export default defineStore("AlertStore", () => {
  const alerts = ref<Array<Alert>>([]);

  function cancelAlertTimeout (alert: Alert) {
    clearTimeout(alert.timeoutId);
  }

  function showSuccess (message?: string): Alert {
    return showAlert({
      status: "Positive",
      title: "Success",
      body: message || "Your changes have been saved.",
    });
  }

  function showError (message?: string): Alert {
    return showAlert({
      status: "Negative",
      title: "Error",
      body: message || "Something went wrong. Try again.",
    });
  }

  function showAlert (_alert: Omit<Alert, "id" | "timeoutId">) {
    const alert: Alert = {
      ..._alert,
      id: uuid(),
      timeoutId: setTimeout(() => hideAlert(alert), 2000),
    };

    alerts.value.push(alert);

    return alert;
  }

  function hideAlert (alert: Alert) {
    cancelAlertTimeout(alert);
    const index = alerts.value.findIndex((_alert) => _alert.id === alert.id);

    if (index !== -1) {
      alerts.value.splice(index, 1);
    }
  }

  function hideAllErrorAlerts () {
    alerts.value = alerts.value.filter((alert) => alert.status !== "Negative");
  }

  return {
    alerts,

    showSuccess,
    showError,
    hideAlert,
    hideAllErrorAlerts,
    cancelAlertTimeout,
  };
});
