<template>
  <div
    class="SpendingCalendarDate"
    :class="{
      'SpendingCalendarDate--isCurrentMonth': isDateCurrentMonth,
      'SpendingCalendarDate--isPastOrPresent': isDatePastOrPresent,
      'SpendingCalendarDate--isFuture': isDateFuture,
      'SpendingCalendarDate--isZero': isZero,
    }"
    :style="`--spendingRatio: ${modelValue.spendingRatio}`"
    v-bind="isClickable ? {role: 'button', tabindex: 0} : {}"
  >
    <UIText
      class="SpendingCalendarDate__date"
      tag="time"
      size="2XSmall"
      weight="Medium"
      :datetime="modelValue.date.toISOString()"
    >
      {{ label }}
    </UIText>
    <UIText
      class="SpendingCalendarDate__amount"
      tag="strong"
      size="2XSmall"
      weight="Medium"
    >
      {{ formattedAmount }}
    </UIText>
  </div>
</template>

<script lang="ts" setup>
import { ISpendingCalendarDate } from "@cosine/components/SpendingCalendarDate.types";
import UIText from "@cosine/components/UIText.vue";
import useSpendingCalendarDate from "@cosine/composables/useSpendingCalendarDate";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { computed, toRefs } from "vue";

const modelValue = defineModel<ISpendingCalendarDate>({
  required: true,
});

const props = defineProps<{
  monthDate: Date,
  isClickable?: boolean,
}>();

const date = computed(() => modelValue.value.date);

const {
  monthDate,
} = toRefs(props);

const label = computed(() => modelValue.value.date.getDate());
const formattedAmount = computed(() => formatAmount(modelValue.value.amount, {
  excludeCents: true,
}));

const {
  isDateCurrentMonth,
  isDatePastOrPresent,
  isDateFuture,
} = useSpendingCalendarDate(date, monthDate);

const isZero = computed(() => modelValue.value.amount === 0);
</script>

<style lang="scss" scoped>
.SpendingCalendarDate {
  position: relative;
  z-index: 0;

  display: grid;
  place-content: center;
  place-items: center;
  aspect-ratio: 1;
  margin: 8px;
  padding: 8px;
  overflow: hidden;

  text-align: center;

  &[role="button"] {
    cursor: pointer;
  }

  &:not(.SpendingCalendarDate--isCurrentMonth) {
    border: 1px solid var(--colorSwissAlphaBlack100);
    color: var(--colorSwissAlphaBlack400);
  }

  &.SpendingCalendarDate--isFuture {
    color: var(--colorSwissAlphaBlack400);
  }

  &.SpendingCalendarDate--isCurrentMonth.SpendingCalendarDate--isFuture {
    background-color: var(--colorSwissAlphaBlack50);
  }

  &.SpendingCalendarDate--isCurrentMonth.SpendingCalendarDate--isZero:not(.SpendingCalendarDate--isFuture) {
    border: 1px solid var(--colorSwissAlphaBlack100);
  }

  &.SpendingCalendarDate--isCurrentMonth.SpendingCalendarDate--isPastOrPresent::before {
    position: absolute;
    inset: 0;
    z-index: -1;

    background-color: var(--colorSwissPurple300);
    opacity: calc(0.1 + var(--spendingRatio) * 0.9);

    content: "";
  }
}

.SpendingCalendarDate__date {
  @media (min-width: 600px) {
    position: absolute;
    top: 0;
    left: 0;

    align-content: center;
    aspect-ratio: 1;
    padding-inline: 8px;

    font: var(--fontSaans3XSmall);
    text-align: center;
  }
}

.SpendingCalendarDate__amount {
  position: absolute;
  bottom: 12px;
  text-align: center;

  .SpendingCalendarDate:is(:not(.SpendingCalendarDate--isCurrentMonth), .SpendingCalendarDate--isFuture) & {
    display: none;
  }

  @media (max-width: 599px) {
    display: none;
  }
}
</style>
