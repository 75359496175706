<template>
  <FeatureCard
    iconName="Bank"
    title="Get started"
    body="Connect your checking accounts, credit cards, and 401Ks for Era to analyze your spending, provide balance monitoring, and personalize news alerts to your financial picture."
  >
    <FormButton
      @click="modalRouter.openModal({ name: RouteName.accountsConnect })"
    >
      Link accounts
    </FormButton>
  </FeatureCard>
</template>

<script lang="ts" setup>
import FeatureCard from "@cosine/components/FeatureCard.vue";
import FormButton from "@cosine/components/FormButton.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";

const modalRouter = useModalRouter();
</script>
