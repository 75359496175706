export const CapacitorPlatform = {
  ios: "ios",
  android: "android",
} as const;

export interface CapacitorPlatformConfig {
  app_id: string,
  scheme?: string,
  flavor?: string,
  sha256_cert_fingerprint?: string,
}

export interface CapacitorAppConfig {
  server?: string,
  [CapacitorPlatform.ios]: CapacitorPlatformConfig,
  [CapacitorPlatform.android]: CapacitorPlatformConfig,
}

export interface CapacitorConfig {
  team_id: string,
  development: CapacitorAppConfig,
  "pre-prod": CapacitorAppConfig,
  prod: CapacitorAppConfig,
  [key: string]: CapacitorAppConfig | string,
}
