import useLogger from "@shared/composables/useLogger";
import { ref } from "vue";

export default function () {
  const logger = useLogger();

  const isFontLoaded = ref(false);

  document.fonts.ready.then(() => {
    isFontLoaded.value = true;
  }).catch(() => {
    logger.error("Fonts failed to load");
  });

  return {
    isFontLoaded,
  };
}
