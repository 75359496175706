export enum SubscriptionPriceKey {
  EssentialsMonthly = "essentials-monthly",
  EssentialsYearly = "essentials-yearly",
  PlusMonthlyQ1Jan = "plus-monthly-2025-q1-jan",
  PlusYearlyQ1Jan = "plus-yearly-2025-q1-jan",
}

export enum SubscriptionPlanKey {
  FreeTrial = "free-trial",
  EssentialsSubscription = "essentials-subscription",
}

export enum SubscriptionExpirationUrgency {
  urgent = "urgent",
  critical = "critical",
}
