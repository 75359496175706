import { TimeZone } from "@cosine/lib/constants/timezones";
import { TZDate } from "@date-fns/tz";

export default function (date: Date) {
  const marketOpenDate = new TZDate(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    9,
    30,
    0,
    0,
    TimeZone["America/New_York"],
  );
  const marketCloseDate = new TZDate(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    16,
    0,
    0,
    0,
    TimeZone["America/New_York"],
  );

  return {
    marketOpenDate,
    marketCloseDate,
  };
}
