import { computed, Ref, unref } from "vue";
import { RouteLocationRaw, RouteQueryAndHash, useRouter } from "vue-router";

export default function (route: Ref<RouteLocationRaw> | RouteLocationRaw) {
  const router = useRouter();

  const resolvedRoute = computed(() => {
    return router.resolve(unref(route));
  });

  const modalRoute = computed((): RouteQueryAndHash => {
    return {
      hash: `#${resolvedRoute.value.fullPath}`,
    };
  });

  return {
    modalRoute,
  };
}
