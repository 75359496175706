<template>
  <section class="MembershipView">
    <div
      v-if="!canUpgrade"
      class="MembershipView__membership"
    >
      <SectionHeader
        title="Membership"
      >
        <template #button>
          <IconButton
            class="MembershipView__editButton"
            iconName="Pencil"
            :isDisabled="isRequesting"
            @click="handleEditClick"
          />
        </template>
      </SectionHeader>

      <DescriptionList>
        <DescriptionListItem
          class="MembershipView__planListItem"
          label="Plan"
        >
          <SubscriptionStatusBadge
            class="MembershipView__planBadge"
            v-bind="{
              subscriptionName,
            }"
            :modelValue="activeSubscription"
          />
        </DescriptionListItem>
      </DescriptionList>
    </div>

    <EmailVerificationReminderView v-if="canUpgrade && !isCurrentUserVerified" />
    <PromptPane
      v-else-if="canUpgrade"
      class="MembershipView__prompt"
      iconName="ArrowUpHexagon"
      iconColor="Teal"
      backgroundColor="Teal"
      title="Upgrade to your wealth era"
      body="With Plus, you get access to our most advanced AI features like unlimited chat and account connections, full financial visibility, auto-budgeting, investment assistance, and more."
    >
      <template #button>
        <FormButton
          class="MembershipView__upgradeButton"
          :disabled="isRequesting"
          @click="handleUpgradeClick"
        >
          Upgrade to Plus
        </FormButton>
      </template>
    </PromptPane>
  </section>
</template>

<script lang="ts" setup>
import DescriptionList from "@cosine/components/DescriptionList.vue";
import DescriptionListItem from "@cosine/components/DescriptionListItem.vue";
import FormButton from "@cosine/components/FormButton.vue";
import IconButton from "@cosine/components/IconButton.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import SubscriptionStatusBadge from "@cosine/components/SubscriptionStatusBadge.vue";
import useRequest from "@cosine/composables/useRequest";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import { storeToRefs } from "pinia";
import EmailVerificationReminderView from "./EmailVerificationReminderView.vue";
import useAuthStore from "@cosine/stores/useAuthStore";

const subscriptionStore = useSubscriptionStore();
const {
  subscriptionName,
  activeSubscription,
  canUpgrade,
} = storeToRefs(subscriptionStore);
const {
  redirectToCheckout,
  redirectToCustomerPortal,
} = subscriptionStore;
const {
  handleRequest,
  isRequesting,
} = useRequest();

const {
  isCurrentUserVerified,
} = storeToRefs(useAuthStore());

function handleUpgradeClick () {
  handleRequest(redirectToCheckout());
}

function handleEditClick () {
  handleRequest(redirectToCustomerPortal());
}
</script>

<style lang="scss" scoped>
.MembershipView__planBadge {
  cursor: default;
}
</style>
