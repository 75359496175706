<template>
  <div
    class="InlineInput"
  >
    <UIText
      class="InlineInput__placeholder"
      size="3XLarge"
      weight="Medium"
    >
      {{ modelValue || placeholder }}
    </UIText>
    <UIText
      ref="inputRef"
      class="InlineInput__input"
      tag="input"
      size="3XLarge"
      weight="Medium"
      inputmode="decimal"
      :value="formattedValue"
      :spellcheck="false"
      autocomplete="off"
      v-bind="{placeholder}"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      required
    />
  </div>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import { onMounted, ref, toRefs, watch } from "vue";

const emit = defineEmits<{
  "update:modelValue": [string | null],
  format: [],
}>();

const props = withDefaults(defineProps<{
  modelValue: string | null,
  placeholder: string,
  autofocus?: boolean,
}>(), {
  autofocus: false,
});
const {
  modelValue,
  autofocus,
} = toRefs(props);

const inputRef = ref<typeof UIText>();
const formattedValue = ref<string | null>(modelValue.value);
const isFocused = ref(false);

onMounted(() => {
  emit("format");

  if (inputRef.value && autofocus.value) {
    inputRef.value.$el.focus();
  }
});

watch(modelValue, () => {
  if (isFocused.value) return;
  updateFormattedValue();
});

function updateFormattedValue () {
  formattedValue.value = modelValue.value;
  emit("format");
}

function handleInput () {
  const inputEl: HTMLInputElement | undefined = inputRef.value?.$el;
  if (!inputEl) return;

  if (inputEl.value !== modelValue.value) {
    emit("update:modelValue", inputEl.value);
  }
}

function handleFocus () {
  isFocused.value = true;
}

function handleBlur () {
  isFocused.value = false;
  updateFormattedValue();
}
</script>

<style lang="scss" scoped>
.InlineInput {
  --padding: 0 var(--layoutMargin);

  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: center;
}

.InlineInput__placeholder {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  padding: var(--padding);
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;

  [data-js-mobile-keyboard-state="open"] & {
    opacity: 0;
  }

  @media (min-width: 600px) {
    display: none;
  }
}

.InlineInput__input {
  width: 100%;
  padding: var(--padding);
  border: none;
  background: none;
  outline: none;

  @media (max-width: 599px) {
    // This prevents mobile browsers from pushing the viewport off the screen when the keyboard opens
    [data-js-mobile-keyboard-state="closed"] & {
      opacity: 0;
    }
  }
}
</style>
