import useApiClient from "@cosine/composables/useApiClient";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import { ClientConstants, IClientTimelineEntry, Constants, TimelineEntryVisibilities, ITimelineEntryRequest, IPaginatedList, IApiResponse, IDynamicAttachmentPayload } from "@cosine/types/api-models";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("TimelineStore", () => {
  const {
    signalRClient,
  } = useConnectionStore();
  const {
    apiClient,
  } = useApiClient();

  const entryMap = ref(new Map<string, IClientTimelineEntry>());
  const entries = computed((): Array<IClientTimelineEntry> => Array.from(entryMap.value.values()));

  function connectTimeline () {
    signalRClient.connection.on(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
  }

  function disconnectTimeline () {
    signalRClient.connection.off(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
  }

  async function fetchEntry (id: string): Promise<IClientTimelineEntry> {
    const response = await signalRClient.invokeWithReconnect<IClientTimelineEntry>(Constants.GetTimelineEntry, id);

    upsertEntry(response);

    return response;
  }

  async function fetchEntries (params: ITimelineEntryRequest) {
    const response = await signalRClient.invokeWithReconnect<IPaginatedList<IClientTimelineEntry>>(Constants.FindTimelineEntries, params);

    response.Items.forEach(upsertEntry);

    return response;
  }

  async function bookmarkEntry (id: string, shouldBookmark: boolean): Promise<IClientTimelineEntry> {
    const methodName = shouldBookmark ? Constants.PinTimelineEntry : Constants.UnpinTimelineEntry;
    const updatedEntry = await signalRClient.invokeWithReconnect<IClientTimelineEntry>(methodName, id);
    upsertEntry(updatedEntry);
    return updatedEntry;
  }

  async function fetchDynamicEntryPayloads (entry: IClientTimelineEntry) {
    const response = await apiClient.value.get<IApiResponse<Array<IDynamicAttachmentPayload>>>(`/timeline/${entry.IdReference}/dynamic-payload`);

    return response.data.Result || [];
  }

  // private

  function upsertEntry (entry: IClientTimelineEntry) {
    if (!entry.IdReference) return;

    const existingEntry = entryMap.value.get(entry.IdReference);

    if (existingEntry && (entry.DateDeleted || entry.Visibility !== TimelineEntryVisibilities.Visible)) {
      removeEntry(existingEntry);
    } else if (existingEntry) {
      updateExistingEntry(existingEntry, entry);
    } else if (!existingEntry) {
      addEntry(entry);
    }
  }

  function addEntry (entry: IClientTimelineEntry) {
    entryMap.value.set(entry.IdReference!, entry);
  }

  function removeEntry (entry: IClientTimelineEntry) {
    entryMap.value.delete(entry.IdReference!);
  }

  function updateExistingEntry (existingEntry: IClientTimelineEntry, entry: IClientTimelineEntry) {
    const overrides: Pick<IClientTimelineEntry, "ImageUrls" | "BodyAsMarkdown"> = {
      ImageUrls: entry.ImageUrls.length === 0 && existingEntry.ImageUrls.length > 0
        ? existingEntry.ImageUrls
        : entry.ImageUrls,
      BodyAsMarkdown: existingEntry.BodyAsMarkdown || entry.BodyAsMarkdown,
    };

    entryMap.value.set(entry.IdReference!, Object.assign(existingEntry, entry, overrides));
  }

  function handleTimelineEntryFromServer (entry: IClientTimelineEntry) {
    upsertEntry(entry);
  }

  return {
    connectTimeline,
    disconnectTimeline,
    fetchEntry,
    fetchEntries,
    bookmarkEntry,
    fetchDynamicEntryPayloads,
    entries,
    entryMap,
  };
});
