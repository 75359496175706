<template>
  <FinancialTimelineEntry
    label="Real-time budget tracker"
    title="Summarize your budgets compared to your income"
    :titleCallouts="['budgets']"
    v-bind="{ isSyncing }"
  >
    <div class="MonthlyCashflowPlaceholderEntry__chartContainer">
      <MonthlyCashflowChart
        :modelValue="payload"
      />
    </div>

    <template #cta>
      <ConnectAccountsButtonView :data-amp-track="AnalyticsTrackId['MonthlyCashflowTimelineEntry.connectAccountsButton']" />
    </template>
  </FinancialTimelineEntry>
</template>

<script lang="ts" setup>
import FinancialTimelineEntry from "@cosine/components/FinancialTimelineEntry.vue";
import MonthlyCashflowChart from "@cosine/components/MonthlyCashflowChart.vue";
import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import { AnalyticsTrackId } from "@cosine/stores/useAnalyticsStore.types";
import { Currency, IAttachmentSnapshot, MonthlyCashflowOverviewSnapshotStatus } from "@cosine/types/api-models";
import ConnectAccountsButtonView from "@cosine/views/ConnectAccountsButtonView.vue";
import { ref } from "vue";

defineProps<{
  isSyncing?: boolean,
}>();

const payload = ref({
  Snapshot: {
    TypeName: FinancialEntryPayloadTypeName.MonthlyCashflowOverviewPayload,
    Status: MonthlyCashflowOverviewSnapshotStatus.Success,
    Income: {
      Amount: 4400_53,
      Currency: Currency.USD,
    },
    Expenditure: {
      Amount: 3870_03,
      Currency: Currency.USD,
    },
    NeedsSpending: {
      Amount: 1625_41,
      Currency: Currency.USD,
    },
    WantsSpending: {
      Amount: 2244_62,
      Currency: Currency.USD,
    },
  } as unknown as IAttachmentSnapshot,
  SnapshotDate: "2024-11-07T18:58:07.9092024Z",
  FromDateInclusive: "2024-11-01T06:00:00Z",
  SnapshotGracePeriodSeconds: 0,
  SnapshotIntervalSeconds: 3600,
  ToDateExclusive: "2024-12-01T05:59:59Z",
  TypeName: "MonthlyCashflowOverviewPayload",
});
</script>

<style lang="scss" scoped>
.MonthlyCashflowPlaceholderEntry__chartContainer {
  padding-bottom: 88px;
}
</style>
