<template>
  <section class="TimelineView">
    <Teleport
      :to="PortalId.AppSubNav"
      defer
    >
      <TabNavView
        class="TimelineView__tabNav"
        :items="tabNavItems"
        isSubNav
      />
    </Teleport>

    <slot />
  </section>

  <Teleport :to="PortalId.Body">
    <ArticleModalView />
    <ProfileModalView />

    <Transition>
      <SpendingCalendarModalView
        v-if="spendingDateModalRoute"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import useModalRoute from "@cosine/composables/useModalRoute";
import { RouteName } from "@cosine/routes";
import ArticleModalView from "@cosine/views/ArticleModalView.vue";
import ProfileModalView from "@cosine/views/ProfileModalView.vue";
import SpendingCalendarModalView from "@cosine/views/SpendingCalendarModalView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { computed } from "vue";
import { PortalId } from "@cosine/components/Portal.types";

const {
  modalRoute: spendingDateModalRoute,
} = useModalRoute(RouteName.spendingCalendarDate);

const tabNavItems = computed((): Array<TabNavItem> => {
  return [
    {
      label: "For you",
      to: {
        name: RouteName.timelineForYou,
      },
    },
    {
      label: "News",
      to: {
        name: RouteName.timelineNews,
      },
    },
    {
      label: "Chats",
      to: {
        name: RouteName.timelineChats,
      },
    },
    {
      label: "Bookmarks",
      to: {
        name: RouteName.timelineBookmarks,
      },
    },
  ];
});
</script>

<style lang="scss" scoped>
.TimelineView {
  display: grid;
  height: 100%;
}
</style>
