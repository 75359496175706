<template>
  <section class="BudgetListView">
    <div class="BudgetListView__periodField">
      <BudgetPeriodField
        v-model="period"
        @update:modelValue="handlePeriodFieldChange"
      />
    </div>

    <BudgetCard
      v-for="budget in budgetInstancesForPeriod"
      :key="budget.EraId"
      :modelValue="budget"
      @click="handleBudgetCardClick(budget)"
    />
  </section>
</template>

<script lang="ts" setup>
import BudgetCard from "@cosine/components/BudgetCard.vue";
import BudgetPeriodField from "@cosine/components/BudgetPeriodField.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import { BudgetPeriod, IBudgetInstanceModel } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

// TODO: test

const route = useRoute();
const router = useRouter();
const {
  openModal,
} = useModalRouter();

const budgetStore = useBudgetStore();
const {
  budgetDefinitions,
  budgetInstances,
} = storeToRefs(budgetStore);

const period = ref(getInitialPeriod());

const budgetInstancesForPeriod = computed(() => {
  return budgetInstances.value.filter((budgetInstance) => {
    return budgetInstance.Period === period.value;
  });
});

function getInitialPeriod () {
  switch (route.name) {
    case RouteName.budgetsWeekly:
      return BudgetPeriod.Weekly;
    case RouteName.budgetsMonthly:
      return BudgetPeriod.Monthly;
  }

  return BudgetPeriod.Yearly;
}

function getRouteNameForPeriod (period: BudgetPeriod) {
  switch (period) {
    case BudgetPeriod.Weekly:
      return RouteName.budgetsWeekly;
    case BudgetPeriod.Monthly:
      return RouteName.budgetsMonthly;
  }

  return RouteName.budgetsYearly;
}

function handlePeriodFieldChange (period: BudgetPeriod | null) {
  if (period) {
    router.push({
      name: getRouteNameForPeriod(period),
    });
  }
}

function handleBudgetCardClick (budget: IBudgetInstanceModel) {
  // TODO: handle when definitions weren’t loaded or failed to load
  const budgetDefinition = budgetDefinitions.value.find((budgetDefinition) => {
    return budgetDefinition.EraId === budget.BudgetDefinitionId;
  });
  if (!budgetDefinition) return;

  openModal({
    name: RouteName.budgetsDefinitionsView,
    params: {
      id: budgetDefinition.EraId,
    },
  });
}
</script>

<style lang="scss" scoped>
.BudgetListView__periodField {
  padding: 16px 16px 20px;
  border-bottom: 1px solid var(--themeColorBorder);
}
</style>
