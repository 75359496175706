<template>
  <section
    class="RoutineList"
    :style="{
      pointerEvents: isClickable ? 'auto' : 'none',
    }"
  >
    <SectionHeader
      v-bind="{
        title,
        subtitle,
        iconName,
        iconColor,
      }"
    >
      <template #button>
        <CtaButton
          v-if="includeUpgrade"
          class="RoutineList__upgradeButton"
          @click="$emit('click:upgrade')"
        >
          Upgrade
        </CtaButton>
      </template>
    </SectionHeader>

    <div class="RoutineList__list">
      <slot>
        <RoutineCard
          v-for="routine in routines"
          :key="routine.RoutineId"
          :modelValue="routine"
          v-bind="{accountMap}"
          @click="$emit('click:routine', routine)"
        />
      </slot>
    </div>
  </section>
</template>

<script lang="ts" setup>
import CtaButton from "@cosine/components/CtaButton.vue";
import { IconName } from "@cosine/components/Icon.types";
import RoutineCard from "@cosine/components/RoutineCard.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import { IEnrichedAccountModel, IRoutineModel } from "@cosine/types/api-models";
import { toRefs } from "vue";

defineEmits<{
  "click:routine": [IRoutineModel],
  "click:upgrade": [],
}>();

const props = withDefaults(defineProps<{
  title: string,
  subtitle?: string,
  iconName?: keyof typeof IconName,
  iconColor?: "Grey" | "Yellow",
  routines?: Array<IRoutineModel>,
  accountMap?: Map<string, IEnrichedAccountModel>,
  includeUpgrade?: boolean,
  isClickable?: boolean,
}>(), {
  iconColor: "Grey",
  includeUpgrade: false,
  isClickable: true,
});

const {
  routines,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.RoutineList {
  & + & {
    border-top: 1px solid var(--colorSwissGrey100);
  }

  &:last-child {
    border-bottom: 1px solid var(--colorSwissGrey100);
  }
}

.RoutineList__list {
  padding: 0 var(--layoutMargin);
}
</style>
