<template>
  <section
    class="AlertBanner"
    :class="{
      [`AlertBanner--status${status}`]: !!status,
    }"
  >
    <div class="AlertBanner__iconContainer">
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <template v-if="status === 'Positive'">
          <path
            d="M14.25 0.566987C14.0953 0.477671 13.9047 0.477671 13.75 0.566987L2.49167 7.06699C2.33697 7.1563 2.24167 7.32137 2.24167 7.5V20.5C2.24167 20.6786 2.33697 20.8437 2.49167 20.933L13.75 27.433C13.9047 27.5223 14.0953 27.5223 14.25 27.433L25.5083 20.933C25.663 20.8437 25.7583 20.6786 25.7583 20.5V7.5C25.7583 7.32137 25.663 7.1563 25.5083 7.06699L14.25 0.566987Z"
            fill="var(--colorSwissTeal300)"
            stroke="var(--colorSwissBlack)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 18.4L8 14.4L9.4 13L12 15.6L18.6 9L20 10.4L12 18.4Z"
            fill="var(--colorSwissBlack)"
          />
        </template>
        <template v-else-if="status === 'Negative'">
          <path
            d="M14.3536 0.646447C14.1583 0.451184 13.8417 0.451184 13.6464 0.646447L0.646447 13.6464C0.451184 13.8417 0.451184 14.1583 0.646447 14.3536L13.6464 27.3536C13.8417 27.5488 14.1583 27.5488 14.3536 27.3536L27.3536 14.3536C27.5488 14.1583 27.5488 13.8417 27.3536 13.6464L14.3536 0.646447Z"
            fill="var(--colorSwissRed300)"
            stroke="var(--colorSwissBlack)"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.9751 19.6081C14.754 19.8145 14.4801 19.9176 14.1533 19.9176C13.8265 19.9176 13.5526 19.8145 13.3316 19.6081C13.1105 19.4018 13 19.1462 13 18.8412C13 18.5362 13.1105 18.2805 13.3316 18.0742C13.5526 17.8679 13.8265 17.7648 14.1533 17.7648C14.4801 17.7648 14.754 17.8679 14.9751 18.0742C15.1961 18.2805 15.3066 18.5362 15.3066 18.8412C15.3066 19.1462 15.1961 19.4018 14.9751 19.6081Z"
            fill="var(--colorSwissBlack)"
          />
          <path
            d="M15.3066 15.6119H13V8H15.3066L15.3066 15.6119Z"
            fill="var(--colorSwissBlack)"
          />
        </template>
      </svg>
    </div>
    <UIText
      class="AlertBanner__title"
      tag="h4"
      size="Small"
      weight="Medium"
    >
      {{ formattedTitle }}
    </UIText>
    <UIText
      class="AlertBanner__body"
      tag="p"
      size="2XSmall"
      weight="Medium"
      isMultiline
    >
      {{ formattedBody }}
    </UIText>
  </section>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import { computed, toRefs } from "vue";

const props = defineProps<{
  title?: string | undefined,
  body?: string | undefined,
  status: "Positive" | "Negative",
}>();
const {
  title,
  body,
  status,
} = toRefs(props);

const formattedTitle = computed((): string => {
  if (title.value) return title.value;

  return status.value === "Positive"
    ? "Success"
    : "Error";
});

const formattedBody = computed((): string => {
  if (body.value) return body.value;

  return status.value === "Positive"
    ? "Your changes have been saved."
    : "Something went wrong. Try again.";
});
</script>

<style lang="scss" scoped>
.AlertBanner {
  display: grid;
  grid: auto / min-content auto;
  gap: 0 16px;
  align-content: center;
  position: relative;
  z-index: 1;
  padding: 16px var(--layoutMargin);
  border: 1px solid var(--borderColor);
  background-color: var(--colorWhite);
}

.AlertBanner--statusPositive {
  --borderColor: var(--colorSwissTeal300);
}

.AlertBanner--statusNegative {
  --borderColor: var(--colorSwissRed300);
}

.AlertBanner__iconContainer {
  grid-row: span 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AlertBanner__body {
  color: var(--colorSwissAlphaBlack600);
}
</style>
