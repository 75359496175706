<template>
  <TabNavView
    v-bind="{ items }"
  />

  <!-- .AppNavView__subnav is referenced and relied upon in Layout.scss -->
  <Portal
    :id="PortalId.AppSubNav"
    class="AppNavView__subnav"
  />
</template>

<script lang="ts" setup>
import { PortalId } from "@cosine/components/Portal.types";
import Portal from "@cosine/components/Portal.vue";
import { TabNavItem } from "@cosine/components/TabNav.types";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import { RouteName } from "@cosine/routes";
import TabNavView from "@cosine/views/TabNavView.vue";
import { computed } from "vue";

const {
  flag: routinesFlag,
  isFlagReady: isFlagReady,
} = useFlag(FlagKey.HasAccessToRoutines, false);

const {
  flag: investingFlag,
} = useFlag(FlagKey.HasAccessToInvesting, false);

const items = computed((): Array<TabNavItem> => {
  if (!isFlagReady.value) return [];

  return [
    {
      label: "Timeline",
      to: {
        name: RouteName.timeline,
      },
    },
    {
      label: "Cashflow",
      to: {
        name: RouteName.cashflow,
      },
    },
    routinesFlag.value
      ? {
        label: "Routines",
        to: {
          name: RouteName.routines,
        },
      }
      : [],
    investingFlag.value
      ? {
        label: "Investing",
        to: {
          name: RouteName.investing,
        },
      }
      : [],
    {
      label: "Transfers",
      to: {
        name: RouteName.transfers,
      },
    },
    {
      label: "Settings",
      to: {
        name: RouteName.accounts,
      },
    },
  ].flatMap((item) => item);
});
</script>

<style lang="scss" scoped>
.AppNavView__subnav:not(:empty) {
  position: sticky;
  top: 0;
  background-color: var(--themeColorBackgroundSurface);

  // TODO: consider moving border to app layout slot
  &::after {
    position: absolute;
    inset: 0;

    border-block: 1px solid var(--themeColorBorder);
    pointer-events: none;
    content: "";
  }
}
</style>
