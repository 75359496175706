import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import { IClientTimelineEntry, IDynamicAttachmentPayload } from "@cosine/types/api-models";

const financialEntryTypes = Object.freeze(Object.values(FinancialEntryPayloadTypeName));

export default function (entry: IClientTimelineEntry): string {
  const payload = entry.Attachments.at(0)?.Payloads?.at(0); // TODO: eventually support multiple attachments/payloads
  const fallbackDate = entry.DateForDisplay || entry.DateCreated;

  if (payload && financialEntryTypes.includes(payload.TypeName as FinancialEntryPayloadTypeName)) {
    return (payload as IDynamicAttachmentPayload).FromDateInclusive || fallbackDate;
  }

  return fallbackDate;
}
