import { RouteName, RoutePath, UngatedRoutePaths, routes } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { Roles } from "@cosine/types/api-models";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import { storeToRefs } from "pinia";
import { createRouter as createVueRouter, createWebHistory } from "vue-router";

export function createRouter () {
  const authStore = useAuthStore();
  const {
    fetchCurrentUser,
  } = authStore;
  const {
    currentUser,
    isFetchingUser,
    fetchingUserPromise,
    session,
  } = storeToRefs(authStore);

  const router = createVueRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach((to) => {
    if (!session.value && !UngatedRoutePaths.includes(to.path as RoutePath)) {
      return {
        name: RouteName.login,
        query: {
          redirect: encodeURIComponent(router.resolve(to).fullPath),
        },
      };
    }
  });

  router.beforeResolve(async (to) => {
    if (to.meta.isEmployeeRequired) {
      if (!currentUser.value) {
        await (isFetchingUser.value ? fetchingUserPromise.value : fetchCurrentUser());
      }

      const isEmployee = currentUser.value?.Roles.includes(Roles.Employee) || (to.meta.isAllowedInDevelopment && useRuntimeEnvironment().isDevelopment);
      if (!isEmployee) {
        return false;
      }
    }
  });

  return router;
}
