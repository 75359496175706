<template>
  <Transition>
    <EmailVerificationReminderModalView
      v-if="!isCurrentUserVerified && (isInvestingConnectModalOpen || isReconnectModalOpen)"
      action="link accounts"
      @close="modalRouter.closeActiveModal"
    />
    <InvestingConnectModalView
      v-else-if="isInvestingConnectModalOpen"
    />
    <InvestingReconnectModalView
      v-else-if="isReconnectModalOpen"
      :accountId="reconnectModalRoute?.params.id.toString() || ''"
    />
  </Transition>
</template>

<script lang="ts" setup>
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import InvestingConnectModalView from "@cosine/views/InvestingConnectModalView.vue";
import InvestingReconnectModalView from "@cosine/views/InvestingReconnectModalView.vue";
import { computed } from "vue";
import EmailVerificationReminderModalView from "./EmailVerificationReminderModalView.vue";

const {
  isCurrentUserVerified,
} = useAuthStore();

const modalRouter = useModalRouter();

const {
  isModalOpen: isConnectModalOpen,
} = useModalRoute(RouteName.investingConnect);

const {
  isModalOpen: isConnectBrokerageModalOpen,
} = useModalRoute(RouteName.investingConnectBrokerage);

const {
  isModalOpen: isReconnectModalOpen,
  modalRoute: reconnectModalRoute,
} = useModalRoute(RouteName.investingReconnectAccount);

const isInvestingConnectModalOpen = computed(() => {
  return isConnectModalOpen.value || isConnectBrokerageModalOpen.value;
});
</script>
