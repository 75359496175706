import useApiClient from "@cosine/composables/useApiClient";
import getMostRecentMarketDateRange from "@cosine/lib/utils/date/getMostRecentMarketDateRange";
import { AggregateSpan, AssetType, IApiResponse, IAssetDetail, IAssetInfo, IAssetOverall, IAssetPrice, IMutualFundSummary, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { subMonths } from "date-fns";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("MarketStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const market = ref<ITimeSeriesRangeOf<IAssetOverall<IAssetDetail>, IAssetPrice>>();
  let searchController: AbortController | null;

  async function fetchMarket () {
    const response = await fetchStocks(
      [
        "SPY",
        "DIA",
        "VOX",
        "VDC",
        "VDE",
      ],
      {
        aggregateSpan: AggregateSpan.FiveMinutes,
      },
    );

    if (response) {
      market.value = response;
    }
  }

  async function fetchStocks (
    symbols: Array<string>,
    options: {
      aggregateSpan: AggregateSpan,
    },
  ) {
    const tickerParams = symbols.map((symbol) => `Tickers=${symbol}`).join("&");
    const {
      marketOpenDate,
      marketCloseDate,
    } = getMostRecentMarketDateRange(new Date());

    const response = await apiClient.value.get<IApiResponse<ITimeSeriesRangeOf<IAssetOverall<IAssetDetail>, IAssetPrice>>>(`/market/aggregates?${tickerParams}`, {
      params: {
        AggregateSpan: options.aggregateSpan,
        WithPreviousClose: true,
        FromInclusive: marketOpenDate.toISOString(),
        ToInclusive: marketCloseDate.toISOString(),
      },
    });

    return response.data.Result;
  }

  async function fetchMutualFunds (symbols: Array<string>) {
    const tickerParams = symbols.map((symbol) => `Tickers=${symbol}`).join("&");

    const response = await apiClient.value.get<IApiResponse<ITimeSeriesRangeOf<IAssetOverall<IMutualFundSummary>, IAssetPrice>>>(`/market/mutual-funds/aggregates?${tickerParams}`, {
      params: {
        FromInclusive: subMonths(new Date, 1)
          .toISOString()
          .substring(0, 10),
      },
    });

    return response.data.Result;
  }

  async function fetchSearch ({
    query,
    assetType,
  }: {
    query: string,
    assetType: AssetType,
  }) {
    searchController?.abort();
    searchController = new AbortController();

    const response = await apiClient.value.get<IApiResponse<Array<IAssetInfo>>>(
      `/market/assets?search=${encodeURIComponent(query)}&assetType=${assetType}`,
      {
        signal: searchController.signal,
      },
    );

    return response.data.Result;
  }

  return {
    market,
    fetchMarket,
    fetchStocks,
    fetchMutualFunds,
    fetchSearch,
  };
});
