<template>
  <section class="InvestingView">
    <Teleport
      :to="PortalId.AppSubNav"
      defer
    >
      <TabNavView
        :items="tabNavItems"
        isSubNav
      />
    </Teleport>

    <EmailVerificationReminderView />

    <slot />

    <Teleport :to="PortalId.Body">
      <Transition>
        <BrokerageAccountModalView
          v-if="accountModalRoute"
          :accountId="accountModalRoute.params.id.toString()"
        />
      </Transition>

      <Transition>
        <InvestmentMixModalView
          v-if="investmentMixModalRoute"
          :mixId="investmentMixModalRoute.params.id.toString()"
        />
      </Transition>

      <Transition>
        <InvestmentMixSubscribeModalView
          v-if="investmentMixSubscribeModalRoute"
          :mixId="investmentMixSubscribeModalRoute.params.id.toString()"
        />
      </Transition>
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useModalRoute from "@cosine/composables/useModalRoute";
import { RouteName } from "@cosine/routes";
import InvestmentMixSubscribeModalView from "@cosine/views/InvestmentMixSubscribeModalView.vue";
import BrokerageAccountModalView from "@cosine/views/BrokerageAccountModalView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { PortalId } from "@cosine/components/Portal.types";
import EmailVerificationReminderView from "./EmailVerificationReminderView.vue";
import InvestmentMixModalView from "@cosine/views/InvestmentMixModalView.vue";

const router = useRouter();
const route = useRoute();

const {
  flag: yourInvestmentsFlag,
  isFlagReady,
} = useFlag(FlagKey.EnableYourInvestments, false);

const {
  modalRoute: accountModalRoute,
} = useModalRoute(RouteName.investingAccount);

const {
  modalRoute: investmentMixModalRoute,
} = useModalRoute(RouteName.investingMix);

const {
  modalRoute: investmentMixSubscribeModalRoute,
} = useModalRoute(RouteName.investingMixSubscribe);

const tabNavItems = computed((): Array<TabNavItem> => {
  return [
    ...(
      isFlagReady.value && yourInvestmentsFlag.value
        ? [
          {
            label: "Your investments",
            to: {
              name: RouteName.investingInvestments,
            },
          },
        ]
        : []
    ),
    {
      label: "Explore",
      to: {
        name: RouteName.investingExplore,
      },
    },
    {
      label: "Auto-trading",
      to: {
        name: RouteName.investingTrading,
      },
    },
  ];
});

watch([yourInvestmentsFlag, isFlagReady], () => {
  if (!isFlagReady.value) return;

  if (!yourInvestmentsFlag.value && route.name === RouteName.investingInvestments) {
    router.replace({
      name: RouteName.investingExplore,
      hash: route.hash,
    });
  }
}, {
  immediate: true,
});
</script>
