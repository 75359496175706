import bigdecimal from "currency.js";

// TODO replace these with the Intl.NumberFormat options?
interface FormatPercentOptions {
  decimalPlaceCount?: number,
  minDecimalPlaceCount?: number,
  includeSign?: boolean,
}

export default function (
  value: string | number | null | undefined,
  options: FormatPercentOptions = {},
): string | null {
  if (
    value === undefined
    || value === null
    || typeof value === "string" && value.trim() === ""
  ) return null;

  const formatter = new Intl.NumberFormat("en-us", {
    style: "percent",
    minimumFractionDigits: options.minDecimalPlaceCount ?? options.decimalPlaceCount ?? 0,
    // 20 default taken from https://stackoverflow.com/a/47193121
    maximumFractionDigits: options.decimalPlaceCount ?? 20,
    signDisplay: options.includeSign ? "exceptZero" : "auto",
  });

  const decimal = bigdecimal(value, {
    precision: 10,
  });

  return formatter.format(decimal.value);
}
