import useApiClient from "@cosine/composables/useApiClient";
import { AggregateSpan, BlueprintAccessContext, IApiResponse, IBlueprintHistoricalSummary, IBlueprintHistoricalValue, IBlueprintModel, IGetBlueprintsCommand, IPaginatedList, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("InvestmentMixStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const userMixes = ref<Array<IBlueprintModel>>([]);
  const publicMixes = ref<Array<IBlueprintModel>>([]);
  let searchController: AbortController | null;

  const mixes = computed(() => {
    return [...userMixes.value, ...publicMixes.value];
  });

  // TODO: pagination
  async function fetchMixes (options: {
    accessContext: BlueprintAccessContext,
    perPage?: number,
  }) {
    const params: Partial<IGetBlueprintsCommand> = {
      AccessContext: options.accessContext,
      RecordsPerPage: options.perPage,
    };

    const response = await apiClient.value.get<IApiResponse<IPaginatedList<IBlueprintModel>>>("/investing/blueprints", {
      params,
    });

    return response.data.Result;
  }

  async function fetchUserMixes () {
    const mixes = await fetchMixes({
      accessContext: BlueprintAccessContext.UserOnly,
    });

    userMixes.value = mixes?.Items || [];
  }

  async function fetchPublicMixes () {
    const mixes = await fetchMixes({
      accessContext: BlueprintAccessContext.PublicOnly,
      perPage: 25,
    });

    publicMixes.value = mixes?.Items || [];
  }

  async function fetchInvestmentMixSearch ({
    query,
  }: {
    query: string,
  }) {
    searchController?.abort();
    searchController = new AbortController();

    const response = await apiClient.value.get<IApiResponse<IPaginatedList<IBlueprintModel>>>(
      `/investing/blueprints/search?search=${encodeURIComponent(query)}`,
      {
        signal: searchController.signal,
      },
    );

    return response.data.Result?.Items;
  }

  async function fetchInvestmentMixHistoricalData (options: {
    mixIds: Array<string>,
    from: string,
    to: string,
    aggregateSpan: AggregateSpan,
  }) {
    const params = new URLSearchParams({
      FromInclusive: options.from,
      ToInclusive: options.to,
      AggregateSpan: options.aggregateSpan,
    });
    options.mixIds.forEach((mixId) => params.append("BlueprintIds", mixId));

    const response = await apiClient.value.get<IApiResponse<ITimeSeriesRangeOf<IBlueprintHistoricalSummary, IBlueprintHistoricalValue>>>(`/investing/blueprints/aggregates?${params}`);

    return response.data.Result;
  }

  return {
    fetchUserMixes,
    fetchPublicMixes,
    fetchMixes,
    fetchInvestmentMixSearch,
    fetchInvestmentMixHistoricalData,
    userMixes,
    publicMixes,
    mixes,
  };
});
