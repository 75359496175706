import useAppStore from "@cosine/stores/useAppStore";
import { storeToRefs } from "pinia";
import { Ref, onBeforeUnmount, watch } from "vue";

// TODO: add better support for multiple usages of this,
// so they don’t cancel each other out
export default function useAppSpinner (_isLoading: Ref<boolean>) {
  const {
    isLoading,
  } = storeToRefs(useAppStore());

  onBeforeUnmount(() => {
    isLoading.value = false;
  });

  watch(_isLoading, () => {
    isLoading.value = _isLoading.value;
  }, {
    immediate: true,
  });
}
