import useAlertStore from "@cosine/stores/useAlertStore";
import { IApiResponse } from "@cosine/types/api-models";
import * as Sentry from "@sentry/vue";
import { AxiosError } from "axios";
import { ref } from "vue";

export default function useRequest () {
  const {
    showSuccess,
    showError,
    hideAllErrorAlerts,
  } = useAlertStore();

  const isRequesting = ref(false);
  const requestError = ref<AxiosError<IApiResponse<any>> | null>(null);

  async function handleRequest<T> (
    promise: Promise<T> | undefined,
    options: {
      showAlerts?: boolean,
      showOnlyErrors?: boolean,
      successMessage?: string,
    } = {},
  ): Promise<[AxiosError<IApiResponse<any>> | null, T | null]> {
    resetRequest();

    // The promise is undefined in testing when Pinia stubs methods
    if (!promise) return [new AxiosError<IApiResponse<any>>("No promise provided"), null];

    isRequesting.value = true;

    const [err, res] = await promise
      .then((data: T) => {
        const response: [AxiosError<IApiResponse<any>> | null, T | null] = [null, data];
        return response;
      })
      .catch((error: AxiosError<IApiResponse<any>>) => {
        const response: [AxiosError<IApiResponse<any>> | null, T | null] = [error, null];
        Sentry.captureException(error);
        return response;
      });

    if (err) {
      requestError.value = err;
      if (
        (
          options.showAlerts
          || options.showOnlyErrors
        ) && err.code !== AxiosError.ERR_CANCELED
      ) showError();
    } else if (options.showAlerts || options.successMessage) {
      showSuccess(options.successMessage);
    }

    isRequesting.value = false;

    return [err, res];
  }

  function resetRequest () {
    hideAllErrorAlerts();
    requestError.value = null;
  }

  return {
    handleRequest,
    resetRequest,

    isRequesting,
    requestError,
  };
}
