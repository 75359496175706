<template>
  <section
    class="InvestmentMixListItem"
  >
    <div class="InvestmentMixListItem__icon">
      <BoxIcon
        name="ArrowCycleHexagon"
        color="Green"
      />
    </div>

    <div class="InvestmentMixListItem__content">
      <UIText
        class="InvestmentMixListItem__title"
        tag="h3"
        size="Medium"
        weight="Medium"
      >
        {{ modelValue.Name }}
      </UIText>

      <UIText
        class="InvestmentMixListItem__subtitle"
        tag="p"
        size="2XSmall"
        weight="Medium"
        isMultiline
      >
        {{ modelValue.Category }} <span class="InvestmentMixListItem__bullet"> &bull; </span> {{ formattedAllocations }}
      </UIText>
    </div>
  </section>
</template>

<script lang="ts" setup>
import BoxIcon from "@cosine/components/BoxIcon.vue";
import UIText from "@cosine/components/UIText.vue";
import { IBlueprintModel } from "@cosine/types/api-models";
import { computed } from "vue";

const modelValue = defineModel<IBlueprintModel>({
  required: true,
});

const formattedAllocations = computed(() => {
  return [...modelValue.value.BlueprintSymbolAllocations]
    .sort((a, b) => b.TargetAllocation - a.TargetAllocation)
    .map((targetAllocation) => targetAllocation.Symbol)
    .join(", ");
});
</script>

<style lang="scss" scoped>
.InvestmentMixListItem {
  --borderColor: var(--colorSwissGrey100);

  display: grid;
  grid: auto / min-content auto min-content;
  gap: 16px;
  align-items: center;
  padding: 24px var(--layoutMargin);

  white-space: nowrap;
  cursor: pointer;
  border-bottom: 1px solid var(--borderColor);
}

.InvestmentMixListItem__content {
  display: grid;
  gap: 2px;
}

.InvestmentMixListItem__title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.InvestmentMixListItem__subtitle {
  display: flex;
  gap: 0.25em;
  align-items: center;
  overflow: hidden;

  color: var(--colorSwissAlphaBlack500);

  text-overflow: ellipsis;
}

.InvestmentMixListItem__bullet {
  font-size: 0.75em;
}
</style>
