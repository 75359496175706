import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import { computed } from "vue";

export default function (routeName: RouteName) {
  const {
    modalRoutes,
  } = useModalRouter();

  const modalRoute = computed(() => {
    return modalRoutes.value.find((route) => route.name === routeName) || null;
  });

  const isModalOpen = computed((): boolean => {
    return modalRoutes.value.some((route) => route.name === routeName);
  });

  const isModalActive = computed(() => {
    return modalRoutes.value.length > 0 && modalRoutes.value.at(-1)?.name === routeName;
  });

  return {
    modalRoute,
    isModalOpen,
    isModalActive,
  };
}
