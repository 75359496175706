<template>
  <SpendingCalendarPlaceholderEntry
    v-if="isPlaceholder"
    v-bind="{isSyncing}"
  />
  <FinancialTimelineEntry
    v-else
    v-bind="{
      label,
      title,
      titleCallouts,
      isSyncing,
    }"
    :lastUpdatedTimestamp="payload?.SnapshotDate"
  >
    <SpendingCalendar
      v-if="spendingSnapshot"
      v-model="spendingSnapshot"
      v-bind="{monthDate}"
    />
  </FinancialTimelineEntry>
</template>

<script lang="ts" setup>
import FinancialTimelineEntry from "@cosine/components/FinancialTimelineEntry.vue";
import SpendingCalendar from "@cosine/components/SpendingCalendar.vue";
import SpendingCalendarPlaceholderEntry from "@cosine/components/SpendingCalendarPlaceholderEntry.vue";
import useFinancialTimelineEntry from "@cosine/composables/useFinancialTimelineEntry";
import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { IAggregatedSpendingSnapshot, IClientTimelineEntry } from "@cosine/types/api-models";
import { addDays, format, parseISO, startOfMonth } from "date-fns";
import { storeToRefs } from "pinia";
import { computed } from "vue";

const modelValue = defineModel<IClientTimelineEntry>({
  required: true,
});

const {
  payload,
  snapshot,
} = useFinancialTimelineEntry(modelValue, FinancialEntryPayloadTypeName.AggregatedSpendingPayload);

const {
  accounts,
} = storeToRefs(useFinancialStore());

const isWaitingForSnapshot = computed(() => {
  return accounts.value.length > 0 && total.value === null;
});

const isSyncing = computed(() => isWaitingForSnapshot.value);

const isPlaceholder = computed(() => {
  return accounts.value.length === 0 || total.value === null;
});

const spendingSnapshot = computed(() => {
  return snapshot.value as IAggregatedSpendingSnapshot | undefined;
});

// We shift a day then grab the start of the month since the API date is in UTC but shifted
// the difference between the user’s timezone and UTC. In cases where the user isn’t in their
// timezone or something is slightly off, parsing the date directly would make the month date inaccurate.
const monthDate = computed(() => startOfMonth(addDays(parseISO(payload.value?.FromDateInclusive), 1)));
const label = computed(() => `${format(monthDate.value, DateFormat.monthName)} spending`);

const total = computed(() => spendingSnapshot.value?.Total?.Amount ?? null);
const formattedTotal = computed(() => formatAmount(total.value));

const title = computed(() => `So far, you’ve spent ${formattedTotal.value} this month`);
const titleCallouts = computed(() => formattedTotal.value ? [formattedTotal.value] : []);
</script>
