<template>
  {{ formattedInterval }}
</template>

<script lang="ts" setup>
import { formatCronExpression } from "@cosine/lib/utils/string/formatCronExpression";
import { computed, toRefs } from "vue";

const props = defineProps<{
  cronExpression: string,
}>();
const {
  cronExpression,
} = toRefs(props);

const formattedInterval = computed((): string => {
  return formatCronExpression(cronExpression.value);
});
</script>
