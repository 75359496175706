<template>
  <AppHeader
    v-bind="{
      connectionState,
      isThreadOpen,
    }"
    :isChatAnimating="!hasChattedToday"
    :isChatEnabled="isFlagReady && isChatEnabled"
    @click:logoIcon="handleLogoIconClick"
    @close="closeActiveModal"
  >
    <template
      #badge
    >
      <SubscriptionStatusBadge
        class="AppHeaderView__subscriptionStatusBadge"
        v-bind="{
          subscriptionName,
        }"
        :modelValue="activeSubscription"
        data-testid="subscriptionBadge"
        @click:badge="handleBadgeClick"
      />
    </template>
  </AppHeader>
</template>

<script lang="ts" setup>
import AppHeader from "@cosine/components/AppHeader.vue";
import SubscriptionStatusBadge from "@cosine/components/SubscriptionStatusBadge.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName, RoutePath } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import useFeatureLimitStore from "@cosine/stores/useFeatureLimitStore";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import { FeatureLimitType } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const {
  openModal,
  closeActiveModal,
} = useModalRouter();
const {
  isModalOpen: isNewThreadModalOpen,
} = useModalRoute(RouteName.threadsNew);
const {
  isModalOpen: isThreadModalOpen,
} = useModalRoute(RouteName.threadsView);
const {
  modalRoute: articleModalRoute,
} = useModalRoute(RouteName.articlesView);
const {
  isFlagReady,
  flag:isChatEnabled,
} = useFlag(FlagKey.EnableChatUI, true);

const {
  connectionState,
} = storeToRefs(useConnectionStore());
const {
  currentUser,
} = storeToRefs(useAuthStore());
const subscriptionStore = useSubscriptionStore();
const {
  canUpgrade,
  activeSubscription,
  subscriptionName,
} = storeToRefs(subscriptionStore);
const {
  startEditingSubscription,
} = subscriptionStore;
const {
  getFeatureLimit,
} = useFeatureLimitStore();

const hasChattedToday = computed((): boolean => {
  const limit = getFeatureLimit(FeatureLimitType.ChatMessageCount);

  return !limit || limit.UsageCount > 0;
});

const isThreadOpen = computed(() => {
  return !!isNewThreadModalOpen.value || !!isThreadModalOpen.value;
});

function handleLogoIconClick () {
  if (articleModalRoute.value) {
    openModal({
      name: RouteName.threadsView,
      params: {
        id: articleModalRoute.value.params.id,
      },
    });
  } else {
    openModal({
      name: RouteName.threadsNew,
    });
  }
}

function handleBadgeClick () {
  if (canUpgrade.value) {
    startEditingSubscription(currentUser.value?.Subscription || null);
  } else {
    router.push(RoutePath.settings);
  }
}
</script>

<style lang="scss" scoped>
.AppHeaderView__subscriptionStatusBadge {
  position: relative;
  top: -1px;
}
</style>
