<template>
  <section>
    <RoutineList
      v-if="transferRoutinesFlag && transferTemplates.length > 0"
      class="RoutineExploreView__routineList--transfers"
      title="Money transfers"
      subtitle="Transfer between accounts on a regular basis"
    >
      <RoutineListItem
        v-for="template in transferTemplates"
        :key="template.title"
        v-bind="template"
        @click="handleTemplateClick(template)"
      />
    </RoutineList>
    <RoutineList
      title="Coming soon"
      subtitle="Proactive monitoring, portfolio rebalancing, and more"
      :routines="comingSoonRoutines"
      :isClickable="false"
    />
  </section>
</template>

<script lang="ts" setup>
import RoutineList from "@cosine/components/RoutineList.vue";
import RoutineListItem from "@cosine/components/RoutineListItem.vue";
import RoutineStatus from "@cosine/components/RoutineStatus.vue";
import useFlag from "@cosine/composables/useFlag";
import useRoutineExploreStore from "@cosine/stores/useRoutineExploreStore";
import { RoutineTemplate } from "@cosine/stores/useRoutineStore.types";
import { IRoutineModel, MessageChannel, RoutineSource, UserFeatureFlags } from "@cosine/types/api-models";
import { addMinutes, subMinutes } from "date-fns";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const {
  flag: transferRoutinesFlag,
} = useFlag(UserFeatureFlags.FundsTransferRoutinesEnabled, false);
const {
  transferTemplates,
} = storeToRefs(useRoutineExploreStore());

const comingSoonRoutines = ref([
  createPlaceholderRoutine({
    RoutineId: "routine0",
    Description: "Auto-donate to my favorite charities",
    CronExpression: "0 12 * * 5",
  }),
  createPlaceholderRoutine({
    RoutineId: "routine1",
    Description: "Rebalance Robinhood portfolio",
    CronExpression: "0 10 * * 5",
  }),
  createPlaceholderRoutine({
    RoutineId: "routine2",
    Description: "Monitor 401k contribution",
    CronExpression: "0 12 5 * *",
  }),
]);

function createPlaceholderRoutine (overrides: Partial<IRoutineModel>): IRoutineModel {
  return {
    EraUserId: "user0",
    CronExpression: "0 0 8 ? * WED",
    Interval: {
      CronExpression: "0 0 8 ? * WED",
    },
    RoutineId: "routine123",
    ExecutionCount: 0,
    Type: "UserMessage",
    IsDisabled: false,
    NextFireTime: addMinutes(new Date(), 5).toISOString(),
    LastFireTime: subMinutes(new Date(), 5).toISOString(),
    Name: "My routine",
    Description: "A description of my routine",
    DateCreated: new Date().toISOString(),
    Source: RoutineSource.User,
    Status: RoutineStatus.Idle,
    Args: {
      IsPinned: false,
    },
    SupportedChannels: [MessageChannel.Timeline],
    IsDefault: false,
    ...overrides,
  };
}

function handleTemplateClick (template: RoutineTemplate) {
  router.push(template.route);
}
</script>
