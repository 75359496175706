<template>
  <TransitionGroup
    class="CardCarousel"
    tag="section"
  >
    <slot />
  </TransitionGroup>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.CardCarousel {
  display: flex;
  height: 184px;
  max-width: var(--layoutMaxWidth);
  overflow-x: auto;

  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  > :deep(.TimelineEntry) {
    --cardPeekWidth: 40px;
    min-width: calc(100% - var(--cardPeekWidth));
    scroll-snap-align: center;

    &:first-of-type {
      scroll-snap-align: start;
    }

    &:last-of-type {
      scroll-snap-align: end;
    }

    &:first-of-type:last-of-type {
      width: 100%;
    }
  }
}
</style>
