<template>
  <nav
    class="TabNav"
    data-sentry-unmask
  >
    <div
      ref="trackEl"
      class="TabNav__track"
    >
      <TabNavLink
        v-for="(item, i) in items"
        :key="i"
        :ref="setLinkRefs"
        v-bind="{
          ...item,
          isExact,
        }"
      />
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import TabNavLink from "@cosine/components/TabNavLink.vue";
import { ComponentPublicInstance, onMounted, ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<{
  items: Array<TabNavItem>,
  selectedItem: TabNavItem | null,
  isExact?: boolean,
}>(), {
  isExact: false,
});

const {
  items,
  selectedItem,
} = toRefs(props);

const trackEl = ref<HTMLElement>();
const itemRefs = ref<Array<ComponentPublicInstance>>([]);

onMounted(async () => {
  if (selectedItem.value) {
    await document.fonts.ready;
    handleSelectedItemChange();
  }
});

watch(selectedItem, handleSelectedItemChange);

function setLinkRefs (el: ComponentPublicInstance | Element | null) {
  if (el) {
    itemRefs.value.push(el as ComponentPublicInstance);
  }
}

function handleSelectedItemChange () {
  if (!selectedItem.value || window.innerWidth >= 600) { return; }

  const selectedIndex = items.value.findIndex((item) => item.to.name === selectedItem.value?.to.name);
  const selectedRef = itemRefs.value[selectedIndex];
  if (!selectedRef) return;

  (selectedRef.$el as HTMLElement).scrollIntoView({
    behavior: "smooth",
    inline: "nearest",
    block: "nearest",
  });
}
</script>

<style lang="scss" scoped>
.TabNav {
  position: relative;
  display: grid;
  height: var(--tabNavHeight);
}

.TabNav__track {
  display: flex;
  overflow-x: auto;
  scroll-padding-inline: 8px;
  scroll-snap-type: x mandatory;
  overscroll-behavior-x: contain;
  scrollbar-width: none;
  padding: 0 8px;
  height: inherit;

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
