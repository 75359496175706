import useAppStore from "@cosine/stores/useAppStore";
import { storeToRefs } from "pinia";
import { onBeforeUnmount, onMounted } from "vue";

export default function useAppVisibility () {
  const {
    isAppVisible,
  } = storeToRefs(useAppStore());

  onMounted(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  });

  function handleVisibilityChange (e: Event) {
    isAppVisible.value = (e.target as Document).visibilityState === "visible";
  }
}
