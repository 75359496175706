<template>
  <SheetModal
    class="InvestmentMixModalView"
    theme="Light"
    size="Fill"
    @close="closeActiveModal"
  >
    <SheetScreen class="InvestmentMixModalView__screen">
      <header class="InvestmentMixModalView__header">
        <IconTag
          iconName="Hexagon"
          label="Investment mix"
        />
      </header>
      <ScrollContainer class="InvestmentMixModalView__scrollContainer">
        <div
          v-if="mix"
          class="InvestmentMixModalView__layout"
        >
          <section class="InvestmentMixModalView__hero">
            <figure
              v-if="mix.CoverImage?.Url"
              class="InvestmentMixModalView__heroFigure"
            >
              <img
                class="InvestmentMixModalView__heroImage"
                :src="mix.CoverImage.Url"
              >
              <button
                class="InvestmentMixModalView__heroSubscribeButton"
                data-testid="subscribeButton"
                @click="handleSubscribeClick"
              >
                <Icon
                  class="InvestmentMixModalView__heroSubscribeButtonIcon"
                  name="Plus"
                  size="16px"
                />
                <UIText
                  size="XSmall"
                  weight="Medium"
                >
                  Set up…
                </UIText>
              </button>
            </figure>
            <header class="InvestmentMixModalView__heroHeader">
              <UIText
                class="InvestmentMixModalView__heroTitle"
                tag="h1"
                size="Large"
                weight="Medium"
              >
                {{ mix.Name }}
              </UIText>
              <ul class="InvestmentMixModalView__heroMetaList">
                <UIText
                  v-if="mix.Category"
                  class="InvestmentMixModalView__heroMetaItem"
                  tag="li"
                  size="2XSmall"
                >
                  {{ mix.Category }}
                </UIText>
                <UIText
                  class="InvestmentMixModalView__heroMetaItem"
                  tag="li"
                  size="2XSmall"
                >
                  {{ pluralize(mix.BlueprintSymbolAllocations.length, 'asset', 'assets') }}
                </UIText>
              </ul>
              <UIText
                class="InvestmentMixModalView__heroDescription"
                tag="p"
                size="Medium"
                isMultiline
              >
                {{ mix.Description }}
              </UIText>
            </header>
          </section>
          <InvestmentMixHistoricalChartView
            :modelValue="mix"
          />
          <section class="InvestmentMixModalView__positionSection">
            <template v-if="!isRequestingPositions">
              <SectionHeader
                title="Allocations"
              />
              <InvestmentMixPositionListItem
                v-for="mixPosition in mixPositions"
                :key="mixPosition.symbolAllocation.Symbol"
                :modelValue="mixPosition.assetDetail"
                :symbolAllocation="mixPosition.symbolAllocation"
                :marketData="mixPosition.marketData"
              />
            </template>
            <Spinner
              :isVisible="isRequestingPositions"
              isCentered
            />
          </section>
        </div>
      </ScrollContainer>
    </SheetScreen>
  </SheetModal>
</template>

<script lang="ts" setup>
import InvestmentMixPositionListItem from "@cosine/components/InvestmentMixPositionListItem.vue";
import Icon from "@cosine/components/Icon.vue";
import IconTag from "@cosine/components/IconTag.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import Spinner from "@cosine/components/Spinner.vue";
import UIText from "@cosine/components/UIText.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { RouteName } from "@cosine/routes";
import useInvestmentMixStore from "@cosine/stores/useInvestmentMixStore";
import useMarketStore from "@cosine/stores/useMarketStore";
import { AggregateSpan, IAssetDetail, IAssetOverall, IAssetPrice, IBlueprintModel, IBlueprintSymbolAllocation, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import InvestmentMixHistoricalChartView from "@cosine/views/InvestmentMixHistoricalChartView.vue";
import { storeToRefs } from "pinia";
import { computed, ref, toRefs, watch } from "vue";

const props = defineProps<{
  mixId: string,
}>();

const {
  fetchStocks,
} = useMarketStore();

const {
  openModal,
  closeActiveModal,
} = useModalRouter();

const {
  mixId,
} = toRefs(props);

const {
  mixes,
} = storeToRefs(useInvestmentMixStore());

const {
  handleRequest: handlePositionsRequest,
  isRequesting: isRequestingPositions,
} = useRequest();

// TODO: fetch mix if doesn’t exist
const mix = computed(() => mixes.value.find((mix) => mix.BlueprintId === mixId.value) || null);
const marketDataMap = ref<Map<string, ITimeSeriesRangeOf<IAssetOverall<IAssetDetail>, IAssetPrice>>>(new Map());

const sortedAllocations = computed((): Array<IBlueprintSymbolAllocation> => {
  if (!mix.value) return [];

  return [...mix.value.BlueprintSymbolAllocations].sort((a, b) => {
    return b.TargetAllocation - a.TargetAllocation;
  });
});

const mixPositions = computed(() => {
  return sortedAllocations.value.map((symbolAllocation) => {
    const marketData = marketDataMap.value.get(symbolAllocation.Symbol);

    return {
      assetDetail: marketData?.Series[0].Summary.Metadata,
      marketData,
      symbolAllocation,
    };
  });
});

// TODO: live update?
watch(mix, async () => {
  if (!mix.value) return;

  fetchInvestmentMixStocks(mix.value);
}, {
  immediate: true,
});

async function fetchInvestmentMixStocks (mix: IBlueprintModel) {
  const [_, seriesRange] = await handlePositionsRequest(fetchStocks(mix.BlueprintSymbolAllocations.map(({
    Symbol,
  }) => Symbol), {
    aggregateSpan: AggregateSpan.FiveMinutes,
  }), {
    showOnlyErrors: true,
  });

  if (seriesRange) {
    marketDataMap.value = new Map(seriesRange.Series.map((series) => {
      return [
        series.Summary.Metadata.Symbol,
        {
          ...seriesRange,
          Series: [series],
        },
      ];
    }));
  }
}

function handleSubscribeClick () {
  openModal({
    name: RouteName.investingMixSubscribe,
    params: {
      id: mixId.value,
    },
  });
}
</script>

<style lang="scss" scoped>
.InvestmentMixModalView {
  // TODO: find a more scalable way to do this
  :deep(.SheetModal__closeButton) {
    top: 5px;
    right: 0;
  }

  :deep(.SheetModal__closeButtonIcon) {
    width: 18px;
    height: 18px;
  }
}

.InvestmentMixModalView__screen {
  display: grid;
  grid: min-content auto / auto;
}

.InvestmentMixModalView__header {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 var(--layoutMargin);
}

.InvestmentMixModalView__layout {
  display: grid;
  grid: min-content min-content auto / auto;
  min-height: 100%;
}

.InvestmentMixModalView__hero {
  background-color: var(--colorSwissBlack);
  color: var(--colorSwissAlphaWhite800);
}

.InvestmentMixModalView__heroFigure {
  position: relative;

  display: grid;
  place-items: center;
  aspect-ratio: 2 / 1;
  overflow: hidden;
}

.InvestmentMixModalView__heroImage {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

// TODO: extract into reusable component when we have multiple use-cases
.InvestmentMixModalView__heroSubscribeButton {
  position: absolute;
  right: 0;
  bottom: 0;

  display: flex;
  gap: 4px;
  align-items: center;
  padding: 9px 12px 11px;

  background-color: var(--colorWhite);
  color: var(--colorSwissBlack);
}

.InvestmentMixModalView__heroSubscribeButtonIcon {
  position: relative;
  top: 1px;
}

.InvestmentMixModalView__heroHeader {
  display: grid;
  padding: 24px var(--layoutMargin);
}

.InvestmentMixModalView__heroTitle {
  color: var(--colorWhite);
}

.InvestmentMixModalView__heroMetaList {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.InvestmentMixModalView__heroMetaItem {
  & + & {
    &::before {
      margin: 0 4px;
      content: "•";
    }
  }
}

.InvestmentMixModalView__heroDescription {
  margin-top: 16px;
}

.InvestmentMixModalView__positionSection {
  position: relative;
}
</style>
