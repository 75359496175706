<template>
  <Transition>
    <SheetModal
      v-if="isRoutineModalOpen && routine"
      class="RoutineSheetModalView"
      theme="Light"
      size="Fill"
      @close="closeModal"
    >
      <div class="RoutineSheetModalView__content">
        <header class="RoutineSheetModalView__header">
          <IconTag
            iconName="Hexagon"
            label="Routine"
          />
        </header>

        <section class="RoutineSheetModalView__hero">
          <UIText
            class="RoutineSheetModalView__heroTitle"
            tag="h1"
            size="3XLarge"
            weight="Medium"
            isMultiline
          >
            {{ routine.Description }}
          </UIText>
          <UIText
            class="RoutineSheetModalView__heroInterval"
            tag="p"
            size="2XSmall"
            weight="Medium"
            isMultiline
          >
            <RoutineInterval :cronExpression="routine.CronExpression" />
          </UIText>
        </section>

        <TabNavView
          class="RoutineSheetModalView__tabNav"
          :items="[
            {label: 'Overview', to: {name: RouteName.routinesView}},
            {label: 'Settings', to: {name: RouteName.routinesSettings}},
          ]"
          isExact
        />

        <section
          class="RoutineSheetModalView__routeContainer"
        >
          <RoutineOverviewView v-if="$route.name === RouteName.routinesView" />
          <RoutineSettingsView v-else-if="$route.name === RouteName.routinesSettings" />
        </section>
      </div>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import IconTag from "@cosine/components/IconTag.vue";
import RoutineInterval from "@cosine/components/RoutineInterval.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import UIText from "@cosine/components/UIText.vue";
import { RouteName } from "@cosine/routes";
import useRoutineStore from "@cosine/stores/useRoutineStore";
import RoutineOverviewView from "@cosine/views/RoutineOverviewView.vue";
import RoutineSettingsView from "@cosine/views/RoutineSettingsView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const routineStore = useRoutineStore();
const {
  userRoutines,
} = storeToRefs(routineStore);

// TODO: fetch routine if not in store
const routine = computed(() => {
  return userRoutines.value.find((routine) => routine.RoutineId === route.params.id);
});

const isRoutineModalOpen = computed(() => {
  return [RouteName.routinesView, RouteName.routinesSettings].includes(route.name as RouteName);
});

function closeModal () {
  router.push({
    name: RouteName.routines,
  });
}
</script>

<style lang="scss" scoped>
.RoutineSheetModalView {
  // TODO: find a more scalable way to do this
  :deep(.SheetModal__closeButton) {
    top: 5px;
    right: 0;
  }

  :deep(.SheetModal__closeButtonIcon) {
    width: 18px;
    height: 18px;
  }
}

.RoutineSheetModalView__content {
  display: grid;
  grid: repeat(3, min-content) auto / auto;
}

.RoutineSheetModalView__header {
  display: flex;
  align-items: center;
  padding: 0 var(--layoutMargin);
  height: 48px;
}

.RoutineSheetModalView__hero {
  display: grid;
  gap: 16px;
  padding: 64px 32px 16px var(--layoutMargin);
  border-block: 1px solid var(--colorSwissGrey100);
  background-color: var(--colorSwissYellow300);
}

.RoutineSheetModalView__heroInterval {
  color: var(--colorSwissAlphaBlack600);
}

.RoutineSheetModalView__tabNav {
  border-bottom: 1px solid var(--colorSwissGrey100);
}
</style>
