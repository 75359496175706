<template>
  <Form
    class="EmailFormView"
    :isDisabled="status === 'Submitting' || status === 'Success'"
    @submit="handleSubmit"
  >
    <TextInput
      type="email"
      v-model="formData.email"
      data-testid="emailFormView_email_input"
      :aria-label="route.name === RouteName.login ? 'Sign in with email' : 'Sign up with email'"
      placeholder="Email address"
      required
    />

    <FormButton
      class="EmailFormView__submitButton"
      type="submit"
      data-testid="emailFormView_submit_button"
      v-bind="{status}"
    >
      <span v-if="status === 'Submitting'">
        Submitting…
      </span>
      <span v-else-if="status === 'Success'">
        Email sent! Check your inbox.
      </span>
      <span v-else-if="status === 'Error'">
        Error occurred. Try again.
      </span>
      <span v-else-if="route.name === RouteName.login">Sign in using email</span>
      <span v-else>
        Sign up using email
      </span>
    </FormButton>
  </Form>
</template>

<script lang="ts" setup>
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import TextInput from "@cosine/components/TextInput.vue";
import useAuthConfig from "@cosine/composables/useAuthConfig";
import useLoginOrSignup from "@cosine/composables/useLoginOrSignup";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useStytchStore from "@cosine/stores/useStytchStore";
import { ApiResponseStatus } from "@cosine/types/api-models";
import { ref } from "vue";
import { useRoute } from "vue-router";

const emit = defineEmits<{
  send: [{ email: string }],
}>();

const route = useRoute();

const {
  loginOrSignupWithMagicLink,
} = useStytchStore();

const {
  authenticateUrl,
} = useAuthConfig();

const {
  handleRequest,
} = useRequest();

const {
  loginOrSignupWithSession,
} = useLoginOrSignup();

type FormStatus = "Idle" | "Submitting" | "Success" | "Error";
const status = ref<FormStatus>("Idle");
const formData = ref({
  email: "",
});

async function handleSubmit () {
  status.value = "Submitting";

  const [, loginResponse] = await handleRequest(loginOrSignupWithMagicLink({
    Email: formData.value.email,
    LoginMagicLinkUrl: authenticateUrl.value,
    SignupMagicLinkUrl: authenticateUrl.value,
  }));

  if (loginResponse?.Status !== ApiResponseStatus.Success) {
    status.value = "Error";
    throw new Error("Couldn’t login or signup");
  }

  // Existing user
  if (!loginResponse.Result?.Session) {
    status.value = "Success";
    emit("send", {
      email: formData.value.email,
    });
    return;
  }

  const [error] = await handleRequest(loginOrSignupWithSession({
    sessionToken: loginResponse.Result.Session.SessionToken,
  }));

  if (error) {
    status.value = "Error";
    return;
  }

  status.value = "Success";
}
</script>

<style lang="scss" scoped>
.EmailFormView__submitButton {
  margin-top: 16px;
}
</style>
