<template>
  <UIText
    class="SubscriptionStatusBadge"
    :class="{
      [`SubscriptionStatusBadge--isSubscribed`]: !!modelValue,
    }"
    :data-urgency="subscriptionExpirationUrgency"
    size="2XSmall"
    weight="Medium"
    @click.stop="$emit('click:badge')"
  >
    <span class="SubscriptionStatusBadge__label">{{ labelWithCountdown }}</span>
    <span
      v-if="includeCountdown"
      class="SubscriptionStatusBadge__cta"
    >Upgrade</span>
  </UIText>
</template>

<script setup lang="ts">
import UIText from "@cosine/components/UIText.vue";
import useNow from "@cosine/composables/useNow";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { SubscriptionExpirationUrgency, SubscriptionPlanKey } from "@cosine/stores/useSubscriptionStore.types";
import { IUserSubscriptionModel } from "@cosine/types/api-models";
import { differenceInCalendarDays, isBefore, parseISO } from "date-fns";
import { computed, toRefs } from "vue";

const modelValue = defineModel<IUserSubscriptionModel | null>({
  required: true,
});

defineEmits<{
  "click:badge": [],
}>();

const props = defineProps<{
  subscriptionName: string,
}>();

const {
  subscriptionName,
} = toRefs(props);

const {
  now,
} = useNow();

const endDate = computed(() => {
  if (!modelValue.value) return null;
  return parseISO(modelValue.value.EndDate);
});

const isExpired = computed(() => {
  if (!endDate.value) return true;
  return isBefore(endDate.value, now.value);
});

const daysRemainingCount = computed(() => {
  if (!endDate.value) return null;
  return differenceInCalendarDays(endDate.value, now.value);
});

const includeCountdown = computed(() => {
  if (
    !isExpired.value
    && modelValue.value?.PlanKey === SubscriptionPlanKey.FreeTrial
    && daysRemainingCount.value
    && daysRemainingCount.value <= 5
  ) {
    return true;
  }

  return false;
});

const subscriptionExpirationUrgency = computed(() => {
  if (!includeCountdown.value || !daysRemainingCount.value) return null;

  if (daysRemainingCount.value <= 1) return SubscriptionExpirationUrgency.critical;
  if (daysRemainingCount.value <= 3) return SubscriptionExpirationUrgency.urgent;

  return null;
});

const labelWithCountdown = computed(() => {
  if (includeCountdown.value && daysRemainingCount.value) {
    return `${subscriptionName.value} – ${pluralize(daysRemainingCount.value, "day", "days")} left`;
  }

  return subscriptionName.value;
});
</script>

<style lang="scss" scoped>
.SubscriptionStatusBadge {
  line-height: 20px;
  cursor: pointer;
}

.SubscriptionStatusBadge > span {
  padding: 3px 6px;
}

.SubscriptionStatusBadge__label {
  background-color: var(--colorSwissAlphaBlack50);

  .SubscriptionStatusBadge--isSubscribed & {
    background-color: var(--colorSwissTeal300);
  }

  .SubscriptionStatusBadge[data-urgency="urgent"] & {
    background-color: var(--colorSwissYellow300);
  }

  .SubscriptionStatusBadge[data-urgency="critical"] & {
    background-color: var(--colorNegative);
  }
}

.SubscriptionStatusBadge__cta {
  background-color: var(--colorSwissBlack);
  color: var(--colorWhite);
}
</style>
