<template>
  <section class="InvestingPositionListView">
    <SectionHeader
      v-bind="{title}"
      :subtitle="formattedPositionCount"
    />

    <PositionListItem
      v-for="position in filteredPositions"
      :key="`${position.EraAccountId}-${position.EraSymbolId}`"
      :modelValue="position"
      :marketData="marketData[position.Symbol]"
      v-bind="{accountMap}"
    />

    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </section>
</template>

<script lang="ts" setup>
import PositionListItem from "@cosine/components/PositionListItem.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useNow from "@cosine/composables/useNow";
import useRequest from "@cosine/composables/useRequest";
import isTruthy from "@cosine/lib/utils/boolean/isTruthy";
import pluralize from "@cosine/lib/utils/string/pluralize";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useInvestingStore from "@cosine/stores/useInvestingStore";
import { stockTypes } from "@cosine/stores/useInvestingStore.types";
import useMarketStore from "@cosine/stores/useMarketStore";
import { ITimeSeriesRangeOf, IAssetOverall, IAssetDetail, IMutualFundSummary, IAssetPrice, AggregateSpan, PositionType } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, toRefs, watch } from "vue";

const props = withDefaults(defineProps<{
  title?: string,
  subtitleSuffix?: string,
  accountId?: string,
}>(), {
  title: "Positions",
});

const {
  title,
  subtitleSuffix,
  accountId,
} = toRefs(props);

const {
  accountMap,
} = storeToRefs(useFinancialStore());
const investingStore = useInvestingStore();
const {
  positions,
} = storeToRefs(investingStore);
const {
  fetchPositions,
} = investingStore;
const {
  fetchStocks,
  fetchMutualFunds,
} = useMarketStore();
const {
  now,
} = useNow();

const {
  handleRequest,
  isRequesting,
} = useRequest();

const marketData = ref<{[key: string]: ITimeSeriesRangeOf<IAssetOverall<IAssetDetail | IMutualFundSummary>, IAssetPrice> }>({});

onMounted(() => {
  handleRequest(fetchPositions());
});

const filteredPositions = computed(() => {
  if (!accountId.value) return positions.value;

  return positions.value.filter((position) => position.EraAccountId === accountId.value);
});

const formattedPositionCount = computed(() => {
  return [pluralize(filteredPositions.value.length, "position", "positions"), subtitleSuffix.value]
    .filter(isTruthy)
    .join(" ");
});

watch(filteredPositions, updateMarketData, {
  immediate: true,
});
watch(now, fetchPositions);

async function updateMarketData () {
  await Promise.all([updateStocks(), updateMutualFunds()]);
}

async function updateStocks () {
  const stockPositions = filteredPositions.value.filter(({
    Type,
  }) => stockTypes.includes(Type));
  if (stockPositions.length === 0) return;

  const stocks = await fetchStocks(
    stockPositions.map((position) => position.Symbol),
    {
      aggregateSpan: AggregateSpan.FiveMinutes,
    },
  );

  stocks?.Series.forEach((series) => {
    marketData.value[series.Summary.Metadata.Symbol] = {
      FromInclusive: stocks.FromInclusive,
      ToInclusive: stocks.ToInclusive,
      Series: [series],
    };
  });
}

async function updateMutualFunds () {
  const fundPositions = filteredPositions.value.filter(({
    Type,
  }) => Type === PositionType.OpenEndedFund); // TODO: check if other types should be included
  if (fundPositions.length === 0) return;

  const symbols = [...new Set(fundPositions.map((position) => position.Symbol))];
  const mutualFunds = await fetchMutualFunds(symbols);

  mutualFunds?.Series.forEach((series) => {
    marketData.value[series.Summary.Metadata.Symbol] = {
      FromInclusive: mutualFunds.FromInclusive,
      ToInclusive: mutualFunds.ToInclusive,
      Series: [series],
    };
  });
}
</script>

<style lang="scss" scoped>
.InvestingPositionListView {
  position: relative;
}
</style>
