import { onBeforeUnmount, onMounted } from "vue";

export default function usePreventPinchToZoom () {
  onMounted(() => {
    document.documentElement.addEventListener("touchstart", handleTouchStart, {
      passive: false,
    });
  });

  onBeforeUnmount(() => {
    document.documentElement.removeEventListener("touchstart", handleTouchStart);
  });

  function handleTouchStart (e: TouchEvent) {
    if (e.touches.length > 1) {
      e.preventDefault();
    }
  }
}
