<template>
  <section class="RoutineListView">
    <RoutineList
      v-if="activeRoutines.length > 0"
      title="Active"
      :subtitle="activeSubtitle"
      iconName="CheckSmall"
      iconColor="Yellow"
      :routines="activeRoutines"
      :includeUpgrade="canUpgrade"
      v-bind="{accountMap}"
      @click:routine="handleRoutineClick"
      @click:upgrade="startEditingSubscription(null)"
    />

    <RoutineList
      v-if="pausedRoutines.length > 0"
      title="Paused"
      :subtitle="pausedSubtitle"
      iconName="PauseSolid"
      iconColor="Grey"
      :routines="pausedRoutines"
      v-bind="{accountMap}"
      @click:routine="handleRoutineClick"
    />
  </section>
</template>

<script lang="ts" setup>
import RoutineList from "@cosine/components/RoutineList.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useFlag from "@cosine/composables/useFlag";
import useRequest from "@cosine/composables/useRequest";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useRoutineStore from "@cosine/stores/useRoutineStore";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import { IRoutineModel, UserFeatureFlags } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const subscriptionStore = useSubscriptionStore();
const {
  canUpgrade,
  subscriptionName,
} = storeToRefs(subscriptionStore);
const {
  startEditingSubscription,
} = subscriptionStore;
const {
  flag:maxActiveRoutinesFlag,
} = useFlag(UserFeatureFlags.MaxActiveRoutines, -1);

const financialStore = useFinancialStore();
const {
  fetchAccounts,
} = financialStore;
const {
  accountMap,
} = storeToRefs(financialStore);

const routineStore = useRoutineStore();
const {
  fetchRoutines,
} = routineStore;
const {
  userRoutines,
} = storeToRefs(routineStore);
const {
  isRequesting,
  handleRequest,
} = useRequest();
useAppSpinner(isRequesting);

onMounted(async () => {
  if (accountMap.value.size === 0) {
    await handleRequest(fetchAccounts());
  }

  await handleRequest(fetchRoutines());
});

const activeRoutines = computed(() => userRoutines.value.filter(({
  IsDisabled,
}) => !IsDisabled));
const pausedRoutines = computed(() => userRoutines.value.filter(({
  IsDisabled,
}) => IsDisabled));

function pluralizeRoutines (count: number) {
  return pluralize(count, "routine", "routines");
}

const activeSubtitle = computed(() => {
  if (maxActiveRoutinesFlag.value !== -1) {
    return `${activeRoutines.value.length} of ${maxActiveRoutinesFlag.value} (${subscriptionName.value})`;
  }

  return pluralizeRoutines(activeRoutines.value.length);
});

const pausedSubtitle = computed(() => {
  return pluralizeRoutines(pausedRoutines.value.length);
});

function handleRoutineClick (routine: IRoutineModel) {
  router.push({
    name: RouteName.routinesView,
    params: {
      id: routine.RoutineId,
    },
  });
}
</script>

<style lang="scss" scoped>
.RoutineListView {
  // TODO: extract
  @media (display-mode: standalone) {
    padding-bottom: calc(24px + var(--viewportPaddingBottom));
  }
}
</style>
