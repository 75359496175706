import { onMounted, provide } from "vue";
import { detectOS } from "@cosine/lib/utils/system/detectOS";
import { detectPlatform } from "@cosine/lib/utils/system/detectPlatform";
import { osInjectionKey, platformInjectionKey } from "@cosine/composables/useSystemDetection.types";

export default function () {
  // TODO: detect when mounted if we ever SSR
  const os = detectOS();
  const platform = detectPlatform();

  provide(osInjectionKey, os);
  provide(platformInjectionKey, platform);

  onMounted(() => {
    document.documentElement.dataset.jsOs = os;
    document.documentElement.dataset.jsPlatform = platform;
  });
}
