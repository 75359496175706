<template>
  <ThreadModal
    v-bind="{
      timelineEntry,
      messages,
      isAgentProcessing,
      courtesyMessage,
      chatLimitStatus,
      canFocus,
      canUpgrade,
      isLoading,
    }"
    :isChatEnabled="isFlagReady && isChatEnabled"
    :visibility="threadVisibility"
    @close="$emit('close')"
    @focus="$emit('focus')"
    @submit="handleSubmit"
    @upgrade="handleUpgrade"
  />
</template>

<script lang="ts" setup>
import ThreadModal from "@cosine/components/ThreadModal.vue";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useRequest from "@cosine/composables/useRequest";
import useSystem from "@cosine/composables/useSystem";
import useThread, { ThreadVisibility } from "@cosine/composables/useThread";
import { OS } from "@cosine/lib/utils/system/detectOS.types";
import useAuthStore from "@cosine/stores/useAuthStore";
import useFeatureLimitStore from "@cosine/stores/useFeatureLimitStore";
import useSubscriptionStore from "@cosine/stores/useSubscriptionStore";
import { ErrorType, FeatureLimitType } from "@cosine/types/api-models";
import useLogger from "@shared/composables/useLogger";
import { storeToRefs } from "pinia";
import { computed, onMounted, toRefs } from "vue";

// TODO: test

defineEmits<{
  close: [],
  focus: [],
}>();

const props = withDefaults(defineProps<{
  timelineEntryId: string | null,
  threadVisibility: ThreadVisibility,
  isTransitioning?: boolean,
}>(), {
  isTransitioning: false,
});
const {
  timelineEntryId,
  isTransitioning,
} = toRefs(props);

const {
  currentUser,
} = storeToRefs(useAuthStore());
const {
  getFeatureLimit,
} = useFeatureLimitStore();
const subscriptionStore = useSubscriptionStore();
const {
  canUpgrade,
} = storeToRefs(subscriptionStore);
const {
  startEditingSubscription,
} = subscriptionStore;

const {
  os,
} = useSystem();

const {
  isFlagReady,
  flag:isChatEnabled,
} = useFlag(FlagKey.EnableChatUI, true);

const {
  isRequesting: isLoading,
  handleRequest,
} = useRequest();

const logger = useLogger();
const chatLimitStatus = computed(() => getFeatureLimit(FeatureLimitType.ChatMessageCount));

const {
  messages,
  courtesyMessage,
  isAgentProcessing,
  timelineEntry,
  fetchMessages,
  sendMessage,
} = useThread(timelineEntryId);

const canFocus = computed(() => {
  if (os === OS.Android) return !isTransitioning.value;

  return true;
});

onMounted(() => {
  handleRequest(fetchMessages());
});

async function handleUpgrade () {
  startEditingSubscription(currentUser.value?.Subscription || null);
}

async function handleSubmit (message: string) {
  try {
    await sendMessage(message);
  } catch (err) {
    var error = err as Error;
    var userFriendlyErrorMessage = "an unexpected error occurred";
    switch (error.message) {
      case ErrorType.ChatCharacterLimitExceeded:
        userFriendlyErrorMessage = "your message was over the maximum allowed length";
        break;
      case ErrorType.DailyChatCountLimitExceeded:
        userFriendlyErrorMessage = "your daily chat limit has been reached";
        break;
    }
    // TODO: present to the user in an appropriate way
    logger.error(userFriendlyErrorMessage);
  }
}
</script>
