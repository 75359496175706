<template>
  <SelectInput
    v-bind="{
      modelValue,
      ...$attrs
    }"
    @update:modelValue="modelValue = $event"
  >
    <option
      value=""
      disabled
    >
      Select a brokerage account
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </SelectInput>
</template>

<script lang="ts" setup>
import SelectInput from "@cosine/components/SelectInput.vue";
import { IEnrichedAccountModel, IPortfolioModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const modelValue = defineModel<string | null | undefined>({
  required: true,
});

const props = defineProps<{
  accounts: Array<IEnrichedAccountModel>,
  portfolios: Array<IPortfolioModel>,
}>();
const {
  accounts,
  portfolios,
} = toRefs(props);

const accountsWithPortfolios = computed(() => {
  return portfolios.value.reduce<Array<{
    account: IEnrichedAccountModel,
    portfolio: IPortfolioModel,
  }>>((acc, portfolio) => {
    const account = accounts.value.find((account) => account.EraAccountId === portfolio.EraAccountId);

    if (account) {
      acc.push({
        account,
        portfolio,
      });
    }

    return acc;
  }, []);
});

const options = computed((): Array<{
  label: string,
  value: string,
}> => {
  return [...accountsWithPortfolios.value].sort((a, b) => {
    return a.account.DisplayName.localeCompare(b.account.DisplayName);
  }).map(({
    account,
    portfolio,
  }) => {
    return {
      label: `${account.DisplayName} - ${account.Nickname}`,
      value: portfolio.PortfolioId,
    };
  });
});
</script>
