<template>
  <section>
    <Teleport
      :to="PortalId.AppSubNav"
      defer
    >
      <TabNavView
        class="CashflowView__tabNav"
        :items="tabNavItems"
        isSubNav
      />
    </Teleport>

    <EmailVerificationReminderView />

    <slot />
  </section>
</template>

<script lang="ts" setup>
import { TabNavItem } from "@cosine/components/TabNav.types";
import { RouteName } from "@cosine/routes";
import TabNavView from "@cosine/views/TabNavView.vue";
import { PortalId } from "@cosine/components/Portal.types";
import { ref } from "vue";
import EmailVerificationReminderView from "./EmailVerificationReminderView.vue";

const tabNavItems = ref<Array<TabNavItem>>([
  {
    label: "Budgets",
    to: {
      name: RouteName.budgetsWeekly,
    },
  },
  {
    label: "Transactions",
    to: {
      name: RouteName.transactions,
    },
  },
]);
</script>
