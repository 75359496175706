<template>
  <nav class="SpendingDateCarousel">
    <ol class="SpendingDateCarousel__list">
      <SpendingDateCarouselItem
        v-for="date in dates"
        :key="date.date.toISOString()"
        :modelValue="date"
        :monthDate="selectedDate.date"
        :isActive="isSameDay(date.date, selectedDate.date)"
        @click="$emit('click:date', date.date)"
      />
    </ol>
  </nav>
</template>

<script lang="ts" setup>
import { ISpendingCalendarDate } from "@cosine/components/SpendingCalendarDate.types";
import SpendingDateCarouselItem from "@cosine/components/SpendingDateCarouselItem.vue";
import { isSameDay } from "date-fns";
import { toRefs } from "vue";

defineEmits<{
  "click:date": [Date],
}>();

const props = defineProps<{
  dates: Array<ISpendingCalendarDate>,
  selectedDate: ISpendingCalendarDate,
}>();

const {
  dates,
  selectedDate,
} = toRefs(props);
</script>

<style lang="scss" scoped>
.SpendingDateCarousel {
  min-width: 0;
}

.SpendingDateCarousel__list {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  padding: 24px 0 20px;
  overflow-x: auto;

  text-wrap: nowrap;

  scroll-snap-type: x proximity;
}
</style>
