import useApiClient from "@cosine/composables/useApiClient";
import { IApiResponse, ISnapTradeBrokerage, IAccountConnectionUrl } from "@cosine/types/api-models";
import * as Sentry from "@sentry/vue";
import { Ref, ref } from "vue";

// TODO: test

type SnaptradeMessageEvent = {
  data: string | {
    status?: string,
    authorizationId?: string,
    errorCode?: string,
    statusCode?: string,
    detail?: string,
  },
};

export default function useSnaptradeConnect (container?: Ref<HTMLElement | undefined>) {
  const {
    apiClient,
  } = useApiClient();
  const brokerages = ref<ISnapTradeBrokerage[]>([]);

  const hooks = {
    onBrokerageConnected: <Array<(authorizationId?: string) => void>>[],
    onExit: <Array<() => void>>[],
  };

  function createHook<T> (hooksArray: Array<(payload: T) => void>) {
    function addHandler (handler: (payload: T) => void) {
      hooksArray.push(handler);

      return () => {
        hooksArray.splice(hooksArray.indexOf(handler), 1);
      };
    }

    return addHandler;
  }

  async function fetchBrokerages () {
    const {
      data: _brokerages,
    } = await apiClient.value.get<IApiResponse<Array<ISnapTradeBrokerage>>>("/investing/brokerages");
    brokerages.value = _brokerages.Result ?? [];
  }

  async function mountBrokerageConnect (
    slug?: string,
    authId?: string,
  ): Promise<string | undefined> {
    if (!container?.value) { return Promise.reject(new Error("No container")); }

    const url = await fetchSnaptradePortalUrl(slug, authId);
    if (!url) { return Promise.reject(new Error("No portal url")); }

    return mountBrokerageConnectWithUrl(url);
  }

  async function mountBrokerageConnectWithUrl (url: string): Promise<string | undefined> {
    if (!container?.value) { return Promise.reject(new Error("No container")); }

    const portal = createPortalIframe(url);
    container.value.appendChild(portal);

    window.addEventListener("message", handleSnaptradeEvent);

    return Promise.resolve(url);
  }

  function unmountBrokerageConnect () {
    window.removeEventListener("message", handleSnaptradeEvent);
  }

  async function handleSnaptradeEvent (event: SnaptradeMessageEvent) {
    const message = event.data;

    if (typeof message === "string" && message === "CLOSE_MODAL") {
      hooks.onExit.forEach((handler) => handler());
    }

    if (typeof message !== "string" && message.status === "SUCCESS") {
      hooks.onBrokerageConnected.forEach((handler) => handler(message.authorizationId));
    }

    if (typeof message !== "string" && message.status === "ERROR") {
      const {
        detail,
      } = message;
      Sentry.withScope((scope) => {
        scope.setContext("Snaptrade Context", {
          details: detail,
        });
        Sentry.captureException(message);
      });
    }
  }

  function createPortalIframe (url: string) {
    const portal = document.createElement("iframe");
    portal.src = url;
    portal.id = "snaptrade-connection-portal";
    portal.title = "SnapTrade Connection Portal";
    portal.style.width = "100%";
    portal.style.height = "100%";
    return portal;
  }

  function buildSnaptradePortalRequestUrl (slug?: string, authId?: string): string {
    const params = new URLSearchParams();
    if (slug) params.append("brokerSlug", slug);
    if (authId) params.append("authorizationId", authId);
    return `/investing/login-uri?${params.toString()}`;
  }

  async function fetchSnaptradePortalUrl (slug?: string, authId?: string): Promise<string | undefined> {
    const url = buildSnaptradePortalRequestUrl(slug, authId);
    const response = await apiClient.value.get<IApiResponse<IAccountConnectionUrl>>(url);

    return response?.data?.Result?.Url;
  }

  const onBrokerageConnected = createHook<string>(hooks.onBrokerageConnected);
  const onExit = createHook(hooks.onExit);

  return {
    fetchBrokerages,
    mountBrokerageConnect,
    mountBrokerageConnectWithUrl,
    unmountBrokerageConnect,
    onBrokerageConnected,
    onExit,
    brokerages,
  };
}
