<template>
  <figure class="MonthlyCashflowChart">
    <div
      ref="chartRef"
      class="MonthlyCashflowChart__chart"
    >
      <div
        class="MonthlyCashflowChart__incomeChart"
        :class="{'MonthlyCashflowChart__incomeChart--isEmpty': snapshot.Income?.Amount === 0}"
        data-testid="incomeChart"
        :style="{
          width: `${100 * incomeChartRatio}%`,
        }"
      >
        <svg
          class="MonthlyCashflowChart__background"
          role="img"
          :aria-label="incomeLabel"
        >
          <defs>
            <pattern
              :id="`monthlyCashflowChartPattern-${date}`"
              class="MonthlyCashflowChart__backgroundPattern"
              patternUnits="userSpaceOnUse"
              width="4"
              height="4"
              patternTransform="rotate(115)"
            >
              <path d="M0,2.5 L4,2.5" />
            </pattern>
          </defs>

          <rect
            v-if="!!snapshot.Income?.Amount"
            class="MonthlyCashflowCard__bar"
            :height="barHeight"
          />
        </svg>

        <MonthlyCashflowChartLabel
          v-if="snapshot.Income?.Amount !== undefined"
          title="Income"
          :amount="snapshot.Income.Amount"
          :justify="incomeChartRatio < INCOME_LABEL_ALIGNMENT_THRESHOLD ? 'right' : 'left'"
          v-bind="{barHeight}"
        />
      </div>

      <div
        class="MonthlyCashflowChart__spendingChart"
        :class="{
          'MonthlyCashflowChart__spendingChart--isEmpty': snapshot.Expenditure?.Amount === 0,
          'MonthlyCashflowChart__spendingChart--isFull': spendRatio === 1,
          'MonthlyCashflowChart__spendingChart--isSegmented': isSegmented
        }"
        data-testid="spendingChart"
        :style="{
          width: `${100 * spendChartRatio}%`,
          '--spendingChartNeedsRatio': `${Math.round(needsRatio * 100)}%`,
          '--spendingChartUnbudgetedRatio': `${Math.round(unbudgetedRatio * 100)}%`
        }"
      >
        <svg
          class="MonthlyCashflowChart__background"
          role="img"
          :aria-label="spendingLabel"
        >
          <rect
            v-if="!!snapshot.Expenditure?.Amount"
            class="MonthlyCashflowCard__bar"
            :fill="`url(#monthlyCashflowChartPattern-${date})`"
            :height="barHeight"
          />
        </svg>

        <MonthlyCashflowChartLabel
          v-if="snapshot.Expenditure?.Amount !== undefined"
          title="Spending"
          :amount="snapshot.Expenditure.Amount"
          type="spending"
          :wantsRatio="isSegmented ? wantsRatio : 0"
          :needsRatio="isSegmented ? needsRatio : 0"
          :uncategorizedRatio="isSegmented ? unbudgetedRatio : 0"
        />
      </div>
    </div>
  </figure>
</template>

<script lang="ts" setup>
import MonthlyCashflowChartLabel from "@cosine/components/MonthlyCashflowChartLabel.vue";
import { IDynamicAttachmentPayload, IMonthlyCashflowOverviewSnapshot } from "@cosine/types/api-models";
import { computed, onBeforeUnmount, onMounted, ref, toRefs, useTemplateRef } from "vue";

const props = withDefaults(defineProps<{
  modelValue: IDynamicAttachmentPayload,
  incomeLabel?: string,
  spendingLabel?: string,
  isSegmented?: boolean,
}>(), {
  incomeLabel: "",
  spendingLabel: "",
  isSegmented: true,
});

// Align the income label right if it would obscure the bar chart
const INCOME_LABEL_ALIGNMENT_THRESHOLD = 0.3;

const {
  modelValue,
} = toRefs(props);

const barHeight = 94;

onMounted(() => {
  if (!chartRef.value) return;

  resizeObserver.observe(chartRef.value);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});

const chartRef = useTemplateRef<SVGElement>("chartRef");
const resizeObserver = new ResizeObserver(handleResize);
const width = ref(0);

const snapshot = computed(() => {
  return modelValue.value.Snapshot! as IMonthlyCashflowOverviewSnapshot;
});

const date = computed(() => {
  return modelValue.value.FromDateInclusive;
});

const spendRatio = computed((): number => {
  if (snapshot.value.Income?.Amount === 0) {
    return 1;
  }

  if (!snapshot.value.Expenditure?.Amount || !snapshot.value.Income?.Amount) {
    return 0;
  }

  return snapshot.value.Expenditure?.Amount / snapshot.value.Income?.Amount;
});

const needsRatio = computed((): number => {
  if (!snapshot.value.NeedsSpending?.Amount || !snapshot.value.Expenditure?.Amount) {
    return 0;
  }

  return snapshot.value.NeedsSpending?.Amount / snapshot.value.Expenditure?.Amount;
});

const wantsRatio = computed((): number => {
  if (!snapshot.value.WantsSpending?.Amount || !snapshot.value.Expenditure?.Amount) {
    return 0;
  }

  return snapshot.value.WantsSpending?.Amount / snapshot.value.Expenditure?.Amount;
});

const unbudgetedRatio = computed(() => {
  if (!snapshot.value.Expenditure?.Amount) return 0;
  return 1 - (parseFloat(needsRatio.value.toPrecision(2)) + parseFloat(wantsRatio.value.toPrecision(2)));
});

const incomeChartRatio = computed(() => {
  return 1 / Math.max(1, spendRatio.value);
});

const spendChartRatio = computed(() => {
  return Math.min(1, spendRatio.value || 1);
});

function handleResize (entries: Array<ResizeObserverEntry>) {
  width.value = entries[0].borderBoxSize[0].inlineSize;
}
</script>

<style lang="scss" scoped>
.MonthlyCashflowChart {
  --barColor: var(--colorSwissPurple300);
  --patternColor: var(--colorSwissAlphaBlackOpaque300);

  position: relative;
  overflow: clip;
  isolation: isolate;
}

.MonthlyCashflowChart__chart {
  overflow: hidden;
}

.MonthlyCashflowChart__incomeChart,
.MonthlyCashflowChart__spendingChart {
  height: calc(1px * v-bind(barHeight));
}

.MonthlyCashflowChart__spendingChart {
  margin-top: -1px;
}

.MonthlyCashflowChart__spendingChart--isSegmented:not(.MonthlyCashflowChart__spendingChart--isEmpty) {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    bottom: 0;

    width: 1px;

    background-color: var(--colorSwissBlack);

    content: "";
  }

  /* Divider between the other/needs portions of the bar */
  &::before {
    left: calc(var(--spendingChartUnbudgetedRatio) - 1px);
  }

  /* Divider between the needs/wants portions of the bar */
  &::after {
    left: calc(var(--spendingChartUnbudgetedRatio) + var(--spendingChartNeedsRatio) - 1px);
  }
}

.MonthlyCashflowChart__background {
  width: 100%;
  height: 100%;

  border: 1px solid var(--colorSwissBlack);

  border-left: none;

  .MonthlyCashflowChart__incomeChart--isEmpty &,
  .MonthlyCashflowChart__spendingChart--isEmpty & {
    border: none;
    border-top: 1px solid var(--colorSwissGrey100);
  }

  .MonthlyCashflowChart__spendingChart--isEmpty & {
    border-top: none;
    border-bottom: 1px solid var(--colorSwissGrey100);
  }

  .MonthlyCashflowChart__spendingChart--isFull & {
    border-right: 1px solid transparent;
  }
}

.MonthlyCashflowChart__backgroundPattern {
  stroke: var(--patternColor);
}

.MonthlyCashflowCard__bar {
  width: calc(100% + 1px);
  paint-order: stroke;
  vector-effect: non-scaling-stroke;
}

.MonthlyCashflowChart__incomeChart .MonthlyCashflowCard__bar {
  fill: var(--barColor);
}
</style>
