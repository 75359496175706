import useApiClient from "@cosine/composables/useApiClient";
import useAnalyticsStore from "@cosine/stores/useAnalyticsStore";
import useStytchStore from "@cosine/stores/useStytchStore";
import { IApiResponse, IUserModel, Roles } from "@cosine/types/api-models";
import useLogger from "@shared/composables/useLogger";
import { Session } from "@stytch/vanilla-js";
import { AxiosError, AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

export default defineStore("AuthStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const {
    stytchClient,
  } = useStytchStore();
  const {
    identify,
  } = useAnalyticsStore();

  const currentUser = ref<IUserModel | null>(null);
  const session = ref<Session | null>(null);
  const isFetchingUser = ref(false);
  const fetchingUserPromise = ref<Promise<AxiosResponse<IApiResponse<IUserModel>>> | null>(null);

  const isCurrentUserVerified = computed(() => {
    return !!currentUser.value?.Roles.includes(Roles.VerifiedUser);
  });

  async function fetchCurrentUser () {
    if (isFetchingUser.value) { return; }

    try {
      isFetchingUser.value = true;
      fetchingUserPromise.value = apiClient.value.get<IApiResponse<IUserModel>>("/users/me");
      const {
        data: {
          Result: _currentUser,
        },
      } = await fetchingUserPromise.value;

      if (_currentUser) {
        currentUser.value = _currentUser;
        identify(currentUser.value.UserId);
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response && [401, 403].includes(axiosError.response.status)) {
        stytchClient.session.revoke();
      }

      const logger = useLogger();
      logger.error(error);
    } finally {
      isFetchingUser.value = false;
    }
  }

  async function updateSignedIn () {
    return apiClient.value.get("/users/me/signed-in");
  }

  async function signOut () {
    stytchClient.session.revoke();
    return apiClient.value.get("/auth/sessions/logout");
  }

  return {
    currentUser,
    session,
    isFetchingUser,
    fetchingUserPromise,
    isCurrentUserVerified,

    fetchCurrentUser,
    updateSignedIn,
    signOut,
  };
});
