<template>
  <section
    class="InvestmentMixHistoricalChart"
    :data-trend="trend"
  >
    <header class="InvestmentMixHistoricalChart__header">
      <UIText
        class="InvestmentMixHistoricalChart__title"
        tag="h2"
        size="3XLarge"
        weight="Medium"
        data-testid="closeAmount"
      >
        {{ formattedPercentChange }}
      </UIText>
      <UIText
        size="Small"
        weight="Medium"
        data-testid="changeAmount"
      >
        for today
      </UIText>
    </header>

    <figure class="InvestmentMixHistoricalChart__figure">
      <canvas ref="chartRef" />
    </figure>
  </section>
</template>

<script lang="ts" setup>
import { InvestmentListItemChartData } from "@cosine/components/InvestmentListItemChart.types";
import UIText from "@cosine/components/UIText.vue";
import createDiagonalLinePattern from "@cosine/lib/charts/createDiagonalLinePattern";
import Colors from "@cosine/lib/Colors";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import { IBlueprintHistoricalSummary, IBlueprintHistoricalValue, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { Chart, CategoryScale, LinearScale, LineController, PointElement, LineElement, TimeSeriesScale, TimeScale, Filler } from "chart.js";
import Annotation from "chartjs-plugin-annotation";
import { computed, onBeforeUnmount, onMounted, ref, shallowRef, toRefs, useTemplateRef, watch } from "vue";

const props = defineProps<{
  modelValue: ITimeSeriesRangeOf<IBlueprintHistoricalSummary, IBlueprintHistoricalValue>,
}>();

// TODO: extract this into a higher level file
Chart.register(
  Annotation,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  TimeSeriesScale,
  TimeScale,
  Filler,
);

const {
  modelValue,
} = toRefs(props);

const chart = shallowRef<Chart<"line", Array<{
  x: string,
  y: number,
}>>>();

defineExpose({
  chart,
});

const chartRef = useTemplateRef<HTMLCanvasElement>("chartRef");
const positivePattern = ref<CanvasPattern>();
const negativePattern = ref<CanvasPattern>();

onMounted(() => {
  if (!chartRef.value) return;

  positivePattern.value = createDiagonalLinePattern(chartRef.value, {
    fill: Colors.green300,
  });
  negativePattern.value = createDiagonalLinePattern(chartRef.value, {
    fill: Colors.red300,
  });
});

const series = computed(() => modelValue.value.Series[0]);
const summary = computed(() => series.value.Summary);
const trend = computed(() => {
  if (summary.value.TotalPercentReturn > 0) return "positive";
  if (summary.value.TotalPercentReturn < 0) return "negative";

  return "neutral";
});

// TODO: re-add when including amounts in header
// const formattedCloseAmount = computed(() => formatAmount(summary.value.Close, {
//   isCents: false,
// }));

// const formattedChangeAmount = computed(() => {
//   const sign = getSign(summary.value.AbsoluteChange);
//   const amount = formatAmount(Math.abs(summary.value.AbsoluteChange), {
//     isCents: false,
//   });

//   return sign + amount;
// });

const formattedPercentChange = computed(() => formatPercent(summary.value.TotalPercentReturn, {
  includeSign: true,
}));
// TODO: re-add when adding the label to the chart
// const formattedOpenAmount = computed(() => formatAmount(summary.value.Open, {
//   isCents: false,
// }));

const chartData = computed((): InvestmentListItemChartData => {
  const points = series.value.Data.map(({
    Timestamp, Value,
  }) => {
    return {
      x: Timestamp as string,
      y: Value.Value,
    };
  });

  return {
    points,
    dateRange: {
      min: modelValue.value.FromInclusive,
      max: modelValue.value.ToInclusive,
    },
    axis: summary.value.PreviousClose,
  };
});

watch([chartRef, modelValue], () => {
  const ctx = chartRef.value?.getContext("2d");
  if (!ctx) return;

  if (chart.value) {
    chart.value.data.datasets[0].data = chartData.value.points;
    chart.value.update();
    return;
  }

  chart.value = new Chart(ctx, {
    type: "line",
    options: {
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: false,
          type: "time",
          min: chartData.value.dateRange.min,
          max: chartData.value.dateRange.max,
        },
        y: {
          display: false,
        },
      },
      layout: {
        padding: {
          top: 8,
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
        },
        annotation: {
          annotations: {
            line: chartData.value.axis
              ? {
                type: "line",
                yMin: chartData.value.axis,
                yMax: chartData.value.axis,
                borderColor: Colors.black,
                borderWidth: 0.5,
                borderDash: [2, 2],
              }
              : undefined,
          },
        },
      },
    },
    plugins: [
      // TODO: extract into reusable plugin when needed elsewhere
      {
        id: "verticalLine",
        afterDraw: function (chart) {
          const ctx = chart.ctx;

          const firstPoint = chartData.value.points.at(0);
          const lastPoint = chartData.value.points.at(-1);
          if (!firstPoint || !lastPoint) return;

          const isSpanningEntireChart = lastPoint.x === chartData.value.dateRange.max;
          const lastX = chart.scales.x.getPixelForValue(chart.scales.x.parse(lastPoint.x || 0) as number);
          const lastY = chart.scales.y.getPixelForValue(chart.scales.y.parse(lastPoint.y) as number);
          ctx.save();

          // draw starting vertical line
          ctx.strokeStyle = Colors.black;
          ctx.beginPath();
          ctx.lineWidth = 2;
          ctx.moveTo(0, chart.scales.y.getPixelForValue(chart.scales.y.parse(firstPoint.y) as number));
          ctx.lineTo(0, chart.height);
          ctx.stroke();
          ctx.closePath();

          // draw ending vertical line
          ctx.beginPath();
          ctx.lineWidth = isSpanningEntireChart ? 2 : 1;
          ctx.moveTo(lastX, lastY);
          ctx.lineTo(lastX, chart.height);
          ctx.stroke();
          ctx.closePath();

          // draw ending hexagon point
          if (!isSpanningEntireChart) {
            const sides = 6;
            const r = 4;
            ctx.fillStyle = Colors.black;
            ctx.strokeStyle = Colors.grey50;
            ctx.lineWidth = 3;
            ctx.beginPath();
            let theta = -Math.PI / sides;
            ctx.moveTo(lastX + Math.cos(theta) * r, lastY + Math.sin(theta) * r);
            for (let i = 0; i < sides; i++) {
              theta += (2 * Math.PI) / sides;
              ctx.lineTo(lastX + Math.cos(theta) * r, lastY + Math.sin(theta) * r);
            }
            ctx.stroke();
            ctx.fill();
            ctx.closePath();
          }
        },
      },
    ],
    data: {
      datasets: [
        {
          data: chartData.value.points,
          borderColor: Colors.black,
          fill: true,
          segment: {
            backgroundColor (ctx) {
              if (!chartData.value.axis) return;
              const value = (
                chartData.value.points[ctx.p0DataIndex].y
                + chartData.value.points[ctx.p1DataIndex].y
              ) / 2;

              if (value > chartData.value.axis) return positivePattern.value;
              if (value < chartData.value.axis) return negativePattern.value;
            },
          },
          tension: 0,
          pointStyle: false,
          borderWidth: -4, // HACK: draws graph to the edge
        },
      ],
    },
  });
});

onBeforeUnmount(() => {
  chart.value?.destroy();
});
</script>

<style lang="scss" scoped>
.InvestmentMixHistoricalChart__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  padding: 24px 16px;
}

.InvestmentMixHistoricalChart__title {
  position: relative;
  z-index: 0;

  &::before {
    position: absolute;
    inset: 0 -8px -2px calc(-1 * var(--layoutMargin));
    z-index: -1;

    background-color: var(--colorSwissGreen300);

    content: "";

    .InvestmentMixHistoricalChart[data-trend="negative"] & {
      background-color: var(--colorSwissRed300);
    }
  }
}

.InvestmentMixHistoricalChart__figure {
  height: 150px;
  border-bottom: 1px solid var(--colorSwissBlack);
  pointer-events: none;
}
</style>
