import useTimelineEntries from "@cosine/composables/useTimelineEntries";
import { TimelineEntryFlag, TimelineEntrySources } from "@cosine/types/api-models";
import { defineStore } from "pinia";

export default defineStore("TimelineBookmarkStore", () => {
  return useTimelineEntries({
    flags: [TimelineEntryFlag.Pinned],
    sources: [
      TimelineEntrySources.User,
      TimelineEntrySources.RoutineMessage,
      TimelineEntrySources.System_PeriodicBrief,
    ],
  });
});
