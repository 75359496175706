import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import useTimelineEntries from "@cosine/composables/useTimelineEntries";
import getAttachmentTypes from "@cosine/lib/utils/timeline/getAttachmentTypes";
import getTimestampForEntry from "@cosine/lib/utils/timeline/getTimestampForEntry";
import { timelineForYouSources, timelineFromEraSources } from "@cosine/stores/useTimelineStore.types";
import { IClientTimelineEntry } from "@cosine/types/api-models";
import { addDays, parseISO } from "date-fns";
import { defineStore } from "pinia";
import { computed } from "vue";

const attachmentDayCountMap = Object.freeze(new Map([
  [FinancialEntryPayloadTypeName.AggregatedSpendingPayload.toString(), 31],
  [FinancialEntryPayloadTypeName.MonthlyCashflowOverviewPayload.toString(), 30], // “Clever” strategy to sort monthly calendar above monthly cashflow, but can revisit if we add another monthly entry type
  [FinancialEntryPayloadTypeName.TransactionRecapPayload.toString(), 1],
]));

function getSortTimeForEntry (entry: IClientTimelineEntry): number {
  const type = getAttachmentTypes(entry).at(0);
  const date = getTimestampForEntry(entry);

  if (type) {
    const dayCount = type ? attachmentDayCountMap.get(type) || 0 : 0;
    return addDays(parseISO(date), dayCount).getTime();
  }

  return parseISO(date).getTime();
}

export default defineStore("TimelineForYouStore", () => {
  const forYouTimeline = useTimelineEntries({
    sources: [...timelineFromEraSources, ...timelineForYouSources],
  });

  const forYouEntries = computed(() => {
    return forYouTimeline.entries.value
      .filter((entry) => timelineForYouSources.includes(entry.Source))
      .sort((a, b) => {
        return getSortTimeForEntry(b) - getSortTimeForEntry(a);
      });
  });

  const fromEraEntries = computed(() => {
    return forYouTimeline.entries.value.filter((entry) => timelineFromEraSources.includes(entry.Source));
  });

  return {
    ...forYouTimeline,
    forYouEntries,
    fromEraEntries,
  };
});
