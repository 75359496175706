import useAuthStore from "@cosine/stores/useAuthStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";

export default function useCurrentUserFetch () {
  const authStore = useAuthStore();
  const {
    fetchCurrentUser,
  } = authStore;
  const {
    currentUser,
    session,
  } = storeToRefs(authStore);

  watch(session, () => {
    if (session.value && !currentUser.value) {
      fetchCurrentUser();
    }
  }, {
    immediate: true,
  });
}
