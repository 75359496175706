<template>
  <FinancialTimelineEntry
    label="Spending at-a-glance"
    title="Track your daily spending from a bird’s-eye view"
    :titleCallouts="['daily spending']"
    v-bind="{isSyncing}"
  >
    <SpendingCalendar
      v-model="snapshot"
      v-bind="{
        monthDate,
      }"
    />

    <template #cta>
      <ConnectAccountsButtonView :data-amp-track="AnalyticsTrackId['SpendingCalendarTimelineEntry.connectAccountsButton']" />
    </template>
  </FinancialTimelineEntry>
</template>

<script lang="ts" setup>
import FinancialTimelineEntry from "@cosine/components/FinancialTimelineEntry.vue";
import SpendingCalendar from "@cosine/components/SpendingCalendar.vue";
import { AnalyticsTrackId } from "@cosine/stores/useAnalyticsStore.types";
import { IAggregatedSpendingSnapshot, AggregatedSpendingSnapshotStatus, Currency } from "@cosine/types/api-models";
import ConnectAccountsButtonView from "@cosine/views/ConnectAccountsButtonView.vue";
import { eachDayOfInterval, endOfMonth, startOfMonth, subMonths } from "date-fns";
import { computed } from "vue";

defineProps<{
  isSyncing?: boolean,
}>();

const snapshot = computed((): IAggregatedSpendingSnapshot => {
  return {
    Status: AggregatedSpendingSnapshotStatus.Success,
    TypeName: "AggregatedSpendingSnapshot",
    DailySpending: eachDayOfInterval({
      start: startOfMonth(monthDate.value),
      end: endOfMonth(monthDate.value),
    }).map((date, i) => {
      return {
        Date: date.toISOString(),
        Total: {
          Amount: 150_00 + Math.sin(i * i) * 100_00,
          Currency: Currency.USD,
        },
      };
    }),
  };
});

const monthDate = computed(() => {
  return subMonths(startOfMonth(new Date), 1);
});
</script>
