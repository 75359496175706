<template>
  <section
    class="AccountSheetSummary"
    :class="{
      [`AccountSheetSummary--scope${account.Scope}`]: true,
    }"
  >
    <UIText
      class="AccountSheetSummary__caption"
      size="2XSmall"
      weight="Medium"
    >
      <template v-if="account.InstitutionName">
        <span class="AccountSheetSummary__institutionName">{{ account.InstitutionName }}</span>
        <span class="AccountSheetSummary__bullet"> &bull; </span>
      </template>
      <span class="AccountSheetSummary__last4">{{ account.Nickname }}</span>
    </UIText>

    <UIText
      class="AccountSheetSummary__title"
      tag="h3"
      size="XLarge"
      weight="Medium"
    >
      {{ account.DisplayName }}
    </UIText>

    <UIText
      class="AccountSheetSummary__amount"
      tag="h4"
      size="XLarge"
      weight="Medium"
    >
      {{ formattedAmount }}
    </UIText>

    <UIText
      class="AccountSheetSummary__status"
      tag="div"
      size="2XSmall"
      weight="Medium"
    >
      <template v-if="account.ConnectionStatus === AccountConnectionStatus.Connected">
        <span class="AccountSheetSummary__statusLabel">
          Connected
          <template v-if="account.SyncedAt">
            &nbsp;&bull;&nbsp;
            Last synced <Timestamp :datetime="account.SyncedAt" />
          </template>
        </span>
      </template>
      <template v-else>
        <Icon
          class="AccountSheetSummary__statusIcon"
          name="ExclamationDiamond"
          size="16px"
        />
        <span class="AccountSheetSummary__statusLabel">
          Disconnected
          &nbsp;&bull;&nbsp;
          <a
            class="AccountSheetSummary__statusReconnectLink"
            href="#"
            @click.prevent="$emit('click:reconnect')"
          >Re-establish connection</a>
        </span>
      </template>
    </UIText>
  </section>
</template>

<script lang="ts" setup>
import Icon from "@cosine/components/Icon.vue";
import Timestamp from "@cosine/components/Timestamp.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import obscureAmount from "@cosine/lib/utils/financial/obscureAmount";
import { AccountConnectionStatus, AccountScope, IEnrichedAccountModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

defineEmits<{
  "click:reconnect": [],
}>();

const props = defineProps<{
  account: IEnrichedAccountModel,
}>();

const {
  account,
} = toRefs(props);
const formattedAmount = computed(() => {
  const formattedBalance = formatAmount(account.value.Balance);

  if (formattedBalance && account.value.Scope === AccountScope.Limited) {
    return obscureAmount(formattedBalance);
  }

  return formattedBalance;
});
</script>

<style lang="scss" scoped>
.AccountSheetSummary {
  display: grid;
}

.AccountSheetSummary__caption {
  display: flex;
  gap: 3px;
  align-items: center;
  margin-bottom: 12px;

  color: var(--colorSwissAlphaBlack600);
}

.AccountSheetSummary__bullet {
  font-size: 8px;
}

.AccountSheetSummary__amount {
  margin-top: 8px;

  .AccountSheetSummary--scopeLimited & {
    filter: blur(8px);
  }
}

.AccountSheetSummary__status {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 16px;
}

.AccountSheetSummary__statusIcon {
  --iconColor: var(--colorSwissRed300);
}

.AccountSheetSummary__statusLabel {
  color: var(--colorSwissAlphaBlack600);
  line-height: 16px;
}
</style>
