import useApiClient from "@cosine/composables/useApiClient";
import { IApiResponse, IUserProfileModel } from "@cosine/types/api-models";

import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("UserProfileStore", () => {
  const {
    apiClient,
  } = useApiClient();

  const currentUserProfile = ref<IUserProfileModel | null>(null);

  async function fetchUserProfile () {
    const {
      data: {
        Result: _currentUserProfile,
      },
    } = await apiClient.value.get<IApiResponse<IUserProfileModel>>("/users/profile");

    if (_currentUserProfile) {
      currentUserProfile.value = _currentUserProfile;
    }

    return _currentUserProfile;
  }

  async function updateUserProfile (userProfile: IUserProfileModel) {
    const {
      data: {
        Result: _currentUserProfile,
      },
    } = await apiClient.value.put<IApiResponse<IUserProfileModel>>("/users/profile", userProfile);

    if (_currentUserProfile) {
      currentUserProfile.value = _currentUserProfile;
    }

    return _currentUserProfile;
  }

  return {
    currentUserProfile,

    fetchUserProfile,
    updateUserProfile,
  };
});
