import mixColors from "@cosine/lib/utils/color/mixColors";
import { easeInOutCubic } from "@shared/lib/animation/easing";
import { onBeforeUnmount, onMounted, ref } from "vue";

// TODO: test & add ability to reset once we no longer do a hard location change after intro

export default function () {
  const {
    documentElement,
  } = document;
  const themeMeta = document.head.querySelector("meta[name='theme-color']");
  const originalBackgroundColor = ref(documentElement.style.getPropertyValue("background-color"));
  const originalThemeMetaColor = ref(themeMeta?.getAttribute("content"));

  let rafId = 0;

  onMounted(() => {
    documentElement.style.removeProperty("background-color");
  });

  onBeforeUnmount(() => {
    documentElement.style.setProperty("background-color", originalBackgroundColor.value);

    if (themeMeta && originalThemeMetaColor.value) {
      themeMeta.setAttribute("content", originalThemeMetaColor.value);
    }
  });

  function setAppBackgroundColor (color: string) {
    documentElement.style.setProperty("--themeColorBackgroundApp", color);
    themeMeta?.setAttribute("content", color);
  }

  function fadeAppBackgroundColor (color: string, options = {
    duration: 500,
    easing: easeInOutCubic,
  }) {
    cancelAnimationFrame(rafId);

    const startColor = documentElement.style.getPropertyValue("--themeColorBackgroundApp");
    const startTime = performance.now();

    function tickAppBackgroundColor (time: number) {
      const ratio = options.easing(Math.min(1, (time - startTime) / options.duration));

      const mixedColor = mixColors(startColor, color, ratio);
      setAppBackgroundColor(mixedColor);

      if (ratio < 1) {
        rafId = requestAnimationFrame(tickAppBackgroundColor);
      }
    }

    rafId = requestAnimationFrame(tickAppBackgroundColor);
  }

  return {
    setAppBackgroundColor,
    fadeAppBackgroundColor,
  };
}
