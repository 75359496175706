<template>
  <div
    v-show="isShowingIndicator"
    :class="{
      [syncIndicatorClassName]: true,
      'SyncIndicator--includeBorder': includeBorder,
    }"
    data-testid="indicator"
  >
    <UIText
      class="SyncIndicator__label"
      tag="div"
      weight="Medium"
      size="2XSmall"
    >
      <Transition @afterLeave="handleAfterLeave">
        <span
          v-if="syncStatus === SyncIndicatorStatus.syncing"
          :data-state="SyncIndicatorStatus.syncing"
        >
          <Icon
            name="Sync"
            size="14px"
          /> Syncing {{ itemName }}…
        </span>

        <span
          v-else-if="isShowingSuccessState"
          :data-state="SyncIndicatorStatus.success"
        >
          <Icon
            name="Check"
            size="14px"
          /> Up to date
        </span>

        <span
          v-else-if="syncStatus === SyncIndicatorStatus.idle && lastUpdatedDate"
          :data-state="SyncIndicatorStatus.idle"
        >
          Last synced {{ formattedLastSyncDate }}
        </span>
      </Transition>
    </UIText>
  </div>
</template>

<script lang="ts" setup>
import { syncIndicatorClassName, syncIndicatorHeight, SyncIndicatorStatus } from "@cosine/components/SyncIndicator.types";
import { computed, inject, onBeforeUnmount, Ref, ref, watch } from "vue";
import { format } from "date-fns";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";
import { appLayoutScrollRefInjectionKey } from "@cosine/layouts/AppLayout.types";
import UIText from "@cosine/components/UIText.vue";
import Icon from "@cosine/components/Icon.vue";

const {
  isSyncing,
  itemName,
  lastUpdatedDate,
  includeBorder = true,
  scrollElementRef,
} = defineProps<{
  isSyncing: boolean,
  itemName: string,
  lastUpdatedDate?: Date,
  includeBorder?: boolean,
  scrollElementRef?: HTMLElement,
}>();

const appScrollRef = inject(appLayoutScrollRefInjectionKey) as Ref<HTMLElement | null>;

const resizeObserver = new ResizeObserver(handleResize);

const cssHeight = `${syncIndicatorHeight}px`;

const syncStatus = ref<SyncIndicatorStatus>(isSyncing ? SyncIndicatorStatus.syncing : SyncIndicatorStatus.idle);

const isShowingSuccessState = ref(false);
const successTimeoutId = ref();

const isShowingIndicator = computed(() => {
  return syncStatus.value !== SyncIndicatorStatus.idle || lastUpdatedDate;
});

const formattedLastSyncDate = computed(() => {
  if (!lastUpdatedDate) return null;

  return format(lastUpdatedDate, DateFormat.shortMonthNameWithDateAndTime);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});

watch(() => scrollElementRef, (newRef, oldRef) => {
  if (newRef && !oldRef) {
    resizeObserver.observe(newRef);
  }
}, {
  immediate: true,
});

watch(() => isSyncing, (isSyncingNow, wasSyncing) => {
  if (wasSyncing && !isSyncingNow) {
    showSuccess();
  }

  if (!wasSyncing && isSyncingNow) {
    showSyncing();
  }
}, {
  immediate: true,
});

function showSuccess () {
  clearTimeout(successTimeoutId.value);
  isShowingSuccessState.value = true;
  syncStatus.value = SyncIndicatorStatus.success;

  successTimeoutId.value = setTimeout(() => {
    isShowingSuccessState.value = false;
  }, 1000);
}

function showSyncing () {
  isShowingSuccessState.value = false;
  syncStatus.value = SyncIndicatorStatus.syncing;
}

function handleAfterLeave (element: Element) {
  if (!isSyncing && element.getAttribute("data-state") === SyncIndicatorStatus.success) {
    syncStatus.value = SyncIndicatorStatus.idle;
  }
}

function scrollToHideSyncIndicator () {
  const isDesktop = window.innerWidth >= 600;
  const _scrollRef = isDesktop ? appScrollRef.value : scrollElementRef;

  if (
    isShowingIndicator.value
    && appScrollRef.value
    && _scrollRef
    && appScrollRef.value.scrollTop <= syncIndicatorHeight
  ) {
    _scrollRef.scrollTo({
      top: syncIndicatorHeight,
      behavior: "instant",
    });
  }
}

function handleResize () {
  if (!scrollElementRef || !appScrollRef.value) return;

  const scrollHeight = scrollElementRef.scrollHeight;
  const containerHeight = appScrollRef.value.clientHeight;

  if (scrollHeight > containerHeight) {
    scrollToHideSyncIndicator();
  }
}
</script>

<style lang="scss" scoped>
.SyncIndicator {
  --height: v-bind(cssHeight);

  height: var(--height);
  border-top: 1px solid transparent;

  &:has([data-state="idle"]) {
    position: relative;
    z-index: 1;
  }

  &:not(:has([data-state="idle"])) {
    position: sticky;
    top: var(--layoutStickyTop);
    z-index: 2;
  }

  &.SyncIndicator--includeBorder:not(:has([data-state="idle"])) {
    border-color: var(--colorSwissGrey100);
  }
}

.SyncIndicator__label span {
  --iconColor: white;
  position: absolute;
  inset: -1px 0 0;

  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: var(--height);
  margin-inline: auto;
  padding: 2px 6px 4px 4px;

  background-color: var(--colorSwissBlack);
  color: var(--colorWhite);

  &[data-state="idle"] {
    --iconColor: var(--themeColorTextSubdued);

    width: 100%;

    background-color: var(--themeColorBackgroundOffset);
    color: var(--themeColorTextSubdued);

    transition: opacity 100ms ease-out;

    &.v-enter-from,
    &.v-leave-to {
      opacity: 0;
    }
  }

  &[data-state="syncing"].v-enter-active {
    animation: slideDown 200ms ease-out;
  }

  &[data-state="success"].v-leave-active {
    animation: slideUp 200ms ease-out;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
</style>
