<template>
  <div class="MetricGrid">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .MetricGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
  }
</style>
