<template>
  <section
    class="BudgetListView"
  >
    <ScrollContainer
      viewport="Mobile"
      includeViewportPadding="bottom"
    >
      <slot />

      <GetStartedView v-if="!isRequesting && budgetDefinitions.length === 0 && isAccountsReady && accounts.length === 0" />

      <FeatureCard
        v-else-if="!isRequesting"
        title="Budgeting for needs vs wants"
        body="You can track any kind of budget in Era, but we get you started with what matters most: tracking spending on necessary items. Everything else is optional, right?"
      />
    </ScrollContainer>

    <Teleport :to="PortalId.Body">
      <BudgetSheetModalView />
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import FeatureCard from "@cosine/components/FeatureCard.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import BudgetSheetModalView from "@cosine/views/BudgetSheetModalView.vue";
import GetStartedView from "@cosine/views/GetStartedView.vue";
import { storeToRefs } from "pinia";
import { PortalId } from "@cosine/components/Portal.types";
import { onMounted } from "vue";

const financialStore = useFinancialStore();
const {
  accounts,
  isAccountsReady,
} = storeToRefs(financialStore);
const budgetStore = useBudgetStore();
const {
  fetchBudgetInstances,
  fetchBudgetDefinitions,
} = budgetStore;
const {
  budgetDefinitions,
} = storeToRefs(budgetStore);
const {
  isRequesting,
  handleRequest,
} = useRequest();
useAppSpinner(isRequesting);

onMounted(async () => {
  await handleRequest(Promise.all([fetchBudgetInstances(), fetchBudgetDefinitions()]));
});
</script>

<style lang="scss" scoped>
.BudgetListView {
  position: relative;
  z-index: 0;
  display: grid;
}
</style>
