import currency from "currency.js";

// TODO: support other currencies
export default function (
  value: currency | string | number | null | undefined,
  options: {
    isCents?: boolean,
    excludeCents?: boolean,
  } = {},
): string | null {
  if (
    value === undefined
    || value === null
    || typeof value === "string" && value.trim() === ""
  ) return null;

  options.isCents ??= true;

  let amount = currency(value, options.excludeCents
    ? {
      precision: 0,
    }
    : {});
  if (options.isCents) amount = amount.divide(100);

  return amount.format();
}
