<template>
  <Transition>
    <TransactionSheetModalView
      v-if="transactionModalRoute"
      :transactionId="transactionModalRoute.params?.id.toString()"
    />
  </Transition>
</template>

<script lang="ts" setup>
import useModalRoute from "@cosine/composables/useModalRoute";
import { RouteName } from "@cosine/routes";
import TransactionSheetModalView from "@cosine/views/TransactionSheetModalView.vue";

const {
  modalRoute: transactionModalRoute,
} = useModalRoute(RouteName.transactionsView);
</script>
