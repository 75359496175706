<template>
  <figure class="HeroPicture">
    <div class="HeroPicture__buttonList">
      <slot name="buttonList" />
    </div>
  </figure>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.HeroPicture {
  position: relative;
  aspect-ratio: 2 / 1;
  background-color: var(--colorSwissPurple300);
}

.HeroPicture__buttonList {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--colorWhite);
}
</style>
