<template>
  <section class="InvestingExploreSearchView">
    <div class="InvestingExploreSearchView__searchInputContainer">
      <SearchInput
        v-model="searchQuery"
        placeholder="Search for a stock or investment mix…"
      />
    </div>

    <ScrollContainer
      v-if="searchQuery"
      class="InvestingExploreSearchView__resultsSection"
      viewport="Mobile"
      hasBottomFade
    >
      <section>
        <SectionHeader
          title="Stocks"
          :subtitle="pluralize(stocks.length || 0, 'stock', 'stocks')"
        />

        <StockListItem
          v-for="stock in stocks"
          :key="stock.Symbol"
          :modelValue="stock"
          :marketData="marketMap.get(stock.Symbol)"
        />
      </section>

      <section>
        <SectionHeader
          title="Mixes"
          :subtitle="pluralize(mixes.length || 0, 'mix', 'mixes')"
        />

        <InvestmentMixListItem
          v-for="mix in mixes"
          :key="mix.BlueprintId"
          :modelValue="mix"
          @click="openModal({
            name: RouteName.investingMix,
            params: {
              id: mix.BlueprintId,
            }
          })"
        />
      </section>
    </ScrollContainer>
  </section>
</template>

<script lang="ts" setup>
import InvestmentMixListItem from "@cosine/components/InvestmentMixListItem.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SearchInput from "@cosine/components/SearchInput.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import StockListItem from "@cosine/components/StockListItem.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import pluralize from "@cosine/lib/utils/string/pluralize";
import { RouteName } from "@cosine/routes";
import useInvestmentMixStore from "@cosine/stores/useInvestmentMixStore";
import useMarketStore from "@cosine/stores/useMarketStore";
import { AggregateSpan, AssetType, IAssetDetail, IAssetInfo, IAssetOverall, IAssetPrice, IBlueprintModel, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { computed, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const {
  fetchSearch,
  fetchStocks,
} = useMarketStore();
const {
  fetchInvestmentMixSearch,
} = useInvestmentMixStore();
const router = useRouter();
const route = useRoute();
const {
  handleRequest,
} = useRequest();
const {
  openModal,
} = useModalRouter();

const stocks = ref<Array<IAssetInfo>>([]);
const mixes = ref<Array<IBlueprintModel>>([]);
const marketMap = ref<Map<string, ITimeSeriesRangeOf<IAssetOverall<IAssetDetail>, IAssetPrice>>>(new Map());

const searchQuery = computed({
  get (): string {
    return route.query.q?.toString().trim() || "";
  },
  set (query: string) {
    router.push({
      query: query
        ? {
          q: query,
        }
        : {},
    });
  },
});

watch(searchQuery, () => {
  if (!searchQuery.value) return;

  fetchSearchInvestmentMixes(searchQuery.value);
  fetchSearchStocks(searchQuery.value);
}, {
  immediate: true,
});

watch(stocks, async () => {
  if (stocks.value.length === 0) {
    marketMap.value.clear();
    return;
  }

  const marketData = await fetchStocks(stocks.value.map((stock) => stock.Symbol), {
    aggregateSpan: AggregateSpan.FiveMinutes,
  });

  if (marketData) {
    updateMarketDataMap(marketData);
  } else {
    marketMap.value.clear();
  }
});

function updateMarketDataMap (marketData: ITimeSeriesRangeOf<IAssetOverall<IAssetDetail>, IAssetPrice>) {
  marketData.Series.forEach((series) => {
    marketMap.value.set(series.Summary.Metadata.Symbol, {
      ...marketData,
      Series: [series],
    });
  });
}

async function fetchSearchStocks (query: string) {
  const [_err, fetchedStocks] = await handleRequest(fetchSearch({
    query,
    assetType: AssetType.CommonStock,
  }), {
    showOnlyErrors: true,
  });

  stocks.value = fetchedStocks || [];
}

async function fetchSearchInvestmentMixes (query: string) {
  const [_err, fetchedMixes] = await handleRequest(fetchInvestmentMixSearch({
    query,
  }), {
    showOnlyErrors: true,
  });

  mixes.value = fetchedMixes || [];
}
</script>

<style lang="scss" scoped>
.InvestingExploreSearchView {
  display: grid;
  grid: min-content auto / auto;
  height: 100%;
}

.InvestingExploreSearchView__searchInputContainer {
  padding: 16px var(--layoutMargin);
  border-bottom: 1px solid var(--themeColorBorder);
}
</style>
