import useStytchStore from "@cosine/stores/useStytchStore";
import { FullStackConstants, IApiResponse, IUserModel, UserStatus } from "@cosine/types/api-models";
import useApiClient from "./useApiClient";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";
import useAnalyticsStore from "@cosine/stores/useAnalyticsStore";
import { currentTimezone } from "@cosine/lib/constants/timezones";
import { storeToRefs } from "pinia";
import useAuthStore from "@cosine/stores/useAuthStore";
import { RouteName } from "@cosine/routes";
import { ListName } from "@cosine/stores/useListStore.types";

export default function useLoginOrSignup () {
  const router = useRouter();
  const route = useRoute();

  const {
    stytchClient,
  } = useStytchStore();

  const {
    apiClient,
  } = useApiClient();

  const {
    currentUser,
  } = storeToRefs(useAuthStore());

  const {
    identify,
  } = useAnalyticsStore();

  function getLandingRouteForUser (user: IUserModel): RouteLocationRaw {
    if (user.Status !== UserStatus.Active) {
      return {
        name: RouteName.waitlist,
      };
    }

    const redirectRoute = route.query.redirect
      ? router.resolve(decodeURIComponent(route.query.redirect.toString()))
      : null;

    if (!user.ListSubscriptions.includes(ListName.HideIntro2024Q1)) {
      return {
        name: RouteName.intro,
        query: redirectRoute
          ? {
            ...redirectRoute.query,
            redirect: encodeURIComponent(redirectRoute.fullPath),
          }
          : undefined,
      };
    }

    if (redirectRoute) return redirectRoute;

    return {
      name: RouteName.index,
    };
  }

  async function loginOrSignupWithSession ({
    sessionToken,
  }: {
    sessionToken?: string,
  } = {}) {
    if (sessionToken) {
      stytchClient.session.updateSession({
        session_token: sessionToken,
      });

      await stytchClient.session.authenticate({
        session_duration_minutes: FullStackConstants.StytchSessionDurationMinutes,
      });
    }

    const userResponse = await apiClient.value.post<IApiResponse<IUserModel>>(
      "/users/me",
      {
        TimeZoneId: currentTimezone,
      },
    );

    const user = userResponse?.data.Result;

    if (user) {
      currentUser.value = user;

      await router.push(getLandingRouteForUser(user));

      identify(user.UserId);
    }
    else {
      throw new Error("Could not find user");
    }
  }

  return {
    loginOrSignupWithSession,
  };
}
