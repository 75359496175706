import { NowInjectionKey } from "@cosine/composables/useLiveTime";
import { Ref, inject, ref } from "vue";

export default function useNow () {
  const now = inject<Ref<Date>>(NowInjectionKey, ref(new Date));

  return {
    now,
  };
}
