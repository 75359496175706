import useApiClient from "@cosine/composables/useApiClient";
import createQueryParams from "@cosine/lib/api/createQueryParams";
import getDatetimeForTransaction from "@cosine/lib/utils/financial/getDatetimeForTransaction";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { TransactionFilter } from "@cosine/stores/useTransactionStore.types";
import { IApiResponse, IEnrichedTransactionModel, IGetEnrichedTransactionsCommand, IPaginatedList } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import useAuthStore from "./useAuthStore";

export default defineStore("TransactionStore", () => {
  const {
    apiClient,
  } = useApiClient();

  const financialStore = useFinancialStore();
  const {
    accounts,
  } = storeToRefs(financialStore);
  const {
    fetchAccounts,
  } = financialStore;

  const budgetStore = useBudgetStore();
  const {
    budgetInstances,
  } = storeToRefs(budgetStore);
  const {
    fetchBudgetInstances,
  } = budgetStore;

  const {
    isCurrentUserVerified,
  } = storeToRefs(useAuthStore());

  const transactionMap = new Map<string, IEnrichedTransactionModel>();
  const transactions = ref<Array<IEnrichedTransactionModel>>([]);
  const pagination = ref({
    pageIndex: 1,
    pageCount: 1,
  });
  const filter = ref<TransactionFilter>({});

  const filteringAccount = computed(() => {
    return accounts.value.find((account) => filter.value.accountIds?.includes(account.EraAccountId));
  });

  const filteringBudgetInstance = computed(() => {
    return budgetInstances.value.find((budgetInstance) => budgetInstance.EraId === filter.value.budgetInstanceId);
  });

  const apiFilter = computed((): IGetEnrichedTransactionsCommand => {
    return {
      RecordsPerPage: 100,
      Page: pagination.value.pageIndex,
      // @ts-expect-error TODO: fix type on backend to be string
      // TODO: support multiple account IDs by sending as query string
      AccountIds: filter.value.accountIds?.at(0),
      Categories: filteringBudgetInstance.value?.AssignedTransactionCategories,
      FromInclusiveUtc: filteringBudgetInstance.value?.StartDate,
      ToInclusiveUtc: filteringBudgetInstance.value?.EndDate,
    };
  });

  function sortTransactions (transactions: Array<IEnrichedTransactionModel>) {
    return transactions.sort((a, b) => {
      const aDatetime = getDatetimeForTransaction(a);
      const bDatetime = getDatetimeForTransaction(b);

      if (aDatetime < bDatetime) { return 1; }
      if (aDatetime > bDatetime) { return -1; }
      return 0;
    });
  }

  function clearTransactions () {
    transactionMap.clear();
    transactions.value.length = 0;
    pagination.value.pageIndex = 1;
    pagination.value.pageCount = 1;
  }

  async function setFilter (_filter: TransactionFilter) {
    // TODO: use less lazy approach for comparison
    if (JSON.stringify(filter.value) !== JSON.stringify(_filter)) {
      clearTransactions();
      filter.value = _filter;

      if (filter.value.accountIds && !filteringAccount.value) {
        // TODO: potentially fetch only this account
        await fetchAccounts();
      } else if (filter.value.budgetInstanceId && !filteringBudgetInstance.value) {
        // TODO: potentially fetch only this budget instance
        await fetchBudgetInstances();
      }
    }
  }

  async function fetchTransaction (transactionId: string) {
    if (!isCurrentUserVerified.value) return null;

    const {
      data: {
        Result: pagindatedList,
      },
    } = await apiClient.value.get<IApiResponse<IPaginatedList<IEnrichedTransactionModel>>>(`/finances/transactions?TransactionIds=${transactionId}`);

    return pagindatedList?.Items.at(0) || null;
  }

  async function fetchTransactions ({
    pageIndex,
  }: {
    pageIndex: number,
  } = {
    pageIndex: 1,
  }) {
    if (!isCurrentUserVerified.value) return null;

    const params: IGetEnrichedTransactionsCommand = {
      ...apiFilter.value,
      Page: pageIndex,
    };

    const {
      data: {
        Result: pagindatedList,
      },
    } = await apiClient.value.get<IApiResponse<IPaginatedList<IEnrichedTransactionModel>>>(`/finances/transactions?${createQueryParams(params)}`);

    if (pageIndex === 1) clearTransactions();

    if (pagindatedList) {
      pagindatedList.Items.forEach((transaction) => transactionMap.set(transaction.EraTransactionId, transaction));
      transactions.value = sortTransactions(Array.from(transactionMap.values()));

      pagination.value.pageIndex = pageIndex;
      pagination.value.pageCount = pagindatedList.Pagination.TotalPages;
    }
  }

  async function updateTransaction (params: {
    id: string,
    category: string,
  }) {
    const {
      data: {
        Result: updatedTransaction,
      },
    } = await apiClient.value.put<IApiResponse<IEnrichedTransactionModel>>("/finances/transactions/categories", {
      EraTransactionId: params.id,
      Category: params.category,
      Percentage: 100,
    });

    const existingTransaction = transactions.value.find((transaction) => {
      return transaction.EraTransactionId === params.id;
    });

    if (existingTransaction && updatedTransaction) {
      transactionMap.set(params.id, updatedTransaction);
      Object.assign(existingTransaction, updatedTransaction);
    }
  }

  return {
    transactions,
    filter,
    pagination,
    filteringAccount,
    filteringBudgetInstance,

    fetchTransaction,
    fetchTransactions,
    setFilter,
    updateTransaction,
  };
});
