export enum Language {
  "en-US" = "en-US",
  "en-GB" = "en-GB",
  "en-CA" = "en-CA",
  "en-AU" = "en-AU",
  "es-ES" = "es-ES",
  "es-MX" = "es-MX",
  "fr-FR" = "fr-FR",
  "fr-CA" = "fr-CA",
  "de-DE" = "de-DE",
  "it-IT" = "it-IT",
  "ja-JP" = "ja-JP",
}

export const Languages: {[key in Language]: string } = {
  "en-US": "English (United States)",
  "en-GB": "English (United Kingdom)",
  "en-CA": "English (Canada)",
  "en-AU": "English (Australia)",
  "es-ES": "Spanish (Spain)",
  "es-MX": "Spanish (Mexico)",
  "fr-FR": "French (France)",
  "fr-CA": "French (Canada)",
  "de-DE": "German",
  "it-IT": "Italian",
  "ja-JP": "Japanese",
};
