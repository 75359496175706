import { computed } from "vue";
import { RouteLocationRaw, useRoute, useRouter } from "vue-router";

export default function () {
  const router = useRouter();
  const route = useRoute();

  const modalRoutes = computed(() => {
    const paths = route.hash.trim() !== ""
      ? route.hash.replace("#", "").split(",")
      : [];

    return paths.map((path) => router.resolve(path));
  });

  const modalRoutePaths = computed(() => {
    return modalRoutes.value.map((route) => route.path);
  });

  function openModal (to: RouteLocationRaw) {
    const paths = [...modalRoutePaths.value];
    paths.push(router.resolve(to).path);

    return updateModalPaths(paths);
  }

  function closeActiveModal () {
    const paths = [...modalRoutePaths.value];
    paths.pop();

    return updateModalPaths(paths);
  }

  function closeAllModals () {
    return updateModalPaths([]);
  }

  function updateModalPaths (paths: Array<string>) {
    const hash = paths.length > 0
      ? "#" + paths.join(",")
      : "";

    return router.push({
      hash,
    });
  }

  return {
    openModal,
    closeActiveModal,
    closeAllModals,
    modalRoutes,
  };
}
