<template>
  <Transition>
    <SheetModal
      v-if="budgetModalRoute && budgetDefinition"
      class="BudgetSheetModalView"
      theme="Light"
      size="Fill"
      @close="closeActiveModal"
    >
      <Transition>
        <SheetScreen
          class="BudgetSheetModalView__screen"
          v-show="!viewState"
        >
          <header class="BudgetSheetModalView__header">
            <UIText
              class="BudgetSheetModalView__title"
              tag="h3"
              size="XLarge"
              weight="Medium"
            >
              {{ budgetDefinition.Name }}
            </UIText>

            <BudgetForm
              v-bind="{budgetDefinition}"
              :isDisabled="isSaving"
              @submit="handleAmountSubmit"
            />
          </header>
          <DescriptionList>
            <DescriptionListItem
              class="BudgetSheetModalView__period"
              label="Period"
              :value="budgetDefinition.Period"
            />
          </DescriptionList>
          <TransactionCategorySelectView
            :categories="budgetDefinition.AssignedTransactionCategories"
            v-model:viewState="viewState"
            v-bind="{isSaving}"
            @submit="handleCategorySelect"
          />
          <SheetButton
            iconName="Dollar"
            label="View transactions"
            color="Green"
            @click="handleViewTransactions"
          />
        </SheetScreen>
      </Transition>
    </SheetModal>
  </Transition>
</template>

<script lang="ts" setup>
import BudgetForm from "@cosine/components/BudgetForm.vue";
import DescriptionList from "@cosine/components/DescriptionList.vue";
import DescriptionListItem from "@cosine/components/DescriptionListItem.vue";
import SheetButton from "@cosine/components/SheetButton.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import UIText from "@cosine/components/UIText.vue";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useBudgetStore from "@cosine/stores/useBudgetStore";
import { TransactionCategorySelectViewForm, TransactionCategorySelectViewState } from "@cosine/views/TransactionCategorySelectView.types";
import TransactionCategorySelectView from "@cosine/views/TransactionCategorySelectView.vue";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const {
  closeActiveModal,
  closeAllModals,
} = useModalRouter();
const {
  modalRoute:budgetModalRoute,
} = useModalRoute(RouteName.budgetsDefinitionsView);

const budgetStore = useBudgetStore();
const {
  budgetDefinitions,
  budgetInstances,
} = storeToRefs(budgetStore);
const {
  updateBudgetDefinitionAmount,
  updateBudgetDefinitionCategories,
  fetchBudgetInstances,
} = budgetStore;

const {
  handleRequest,
  isRequesting: isSaving,
} = useRequest();

const budgetDefinition = computed(() => {
  return budgetDefinitions.value.find((_budgetDefinition) => _budgetDefinition.EraId === budgetModalRoute.value?.params.id);
});

const viewState = ref<TransactionCategorySelectViewState | null>(null);
defineExpose({
  viewState,
});

function handleViewTransactions () {
  // TODO: filter for most recent instance in case there are multiple
  const budgetInstance = budgetInstances.value.find((budgetInstance) => {
    return budgetInstance.BudgetDefinitionId === budgetDefinition.value?.EraId;
  });

  // TODO: handle more gracefully
  if (!budgetInstance) return;

  closeAllModals();
  router.push({
    name: RouteName.transactions,
    query: {
      budget: budgetInstance.EraId,
    },
  });
}

async function handleAmountSubmit ({
  amount,
}: { amount: number }) {
  if (!budgetDefinition.value) return;

  const [err] = await handleRequest(
    updateBudgetDefinitionAmount({
      BudgetId: budgetDefinition.value.EraId,
      DefaultAmount: amount,
    }),
    {
      showAlerts: true,
    },
  );

  if (!err) {
    fetchBudgetInstances();
  }
}

async function handleCategorySelect ({
  categories,
}: TransactionCategorySelectViewForm) {
  if (!budgetDefinition.value) return;

  const [err] = await handleRequest(
    updateBudgetDefinitionCategories({
      BudgetId: budgetDefinition.value.EraId,
      AssignedTransactionCategories: categories,
    }),
    {
      showAlerts: true,
    },
  );

  if (!err) {
    viewState.value = null;
    fetchBudgetInstances();
  }
}
</script>

<style lang="scss" scoped>
.BudgetSheetModalView__screen {
  display: grid;
  gap: 1px;
  align-content: flex-start;
}

.BudgetSheetModalView__header {
  display: grid;
  padding: 24px 0;
  gap: 8px;
}

.BudgetSheetModalView__title {
  padding: 0 var(--layoutMargin);
}
</style>
