import useConnectionStore from "@cosine/stores/useConnectionStore";
import useFeatureLimitStore from "@cosine/stores/useFeatureLimitStore";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { ClientConstants,
  Constants,
  IClientMessageRequest,
  IClientThreadEntry,
  IClientTimelineEntry,
  IAgentActivityUpdated,
  MessageSources,
  TimelineEntrySources,
  TimelineEntryVisibilities } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, onMounted, Ref, ref, watch } from "vue";

export enum ThreadVisibility {
  Hidden = "Hidden",
  Input = "Input",
  Thread = "Thread",
}

export default function (timelineEntryId: Ref<string | null>) {
  const {
    signalRClient,
  } = useConnectionStore();
  const {
    fetchFeatureLimits,
  } = useFeatureLimitStore();
  const timelineStore = useTimelineStore();
  const {
    entries,
  } = storeToRefs(timelineStore);
  const {
    fetchEntry,
  } = timelineStore;

  const timelineEntry = ref<IClientTimelineEntry | null>(null);
  const threadVisibility = ref<ThreadVisibility>(ThreadVisibility.Hidden);
  const messages = ref<Array<IClientThreadEntry>>([]);
  const isAgentProcessing = ref(false);
  const courtesyMessage = ref<string | null>(null);

  const sortedMessages = computed((): Array<IClientThreadEntry> => {
    return messages.value.sort((a, b) => {
      if (a.DateCreated > b.DateCreated) {
        return 1;
      }
      if (a.DateCreated < b.DateCreated) {
        return -1;
      }
      return 0;
    });
  });

  const userMessageCount = computed((): number => {
    return messages.value.filter((message) => message.Source === MessageSources.User).length;
  });

  watch(timelineEntryId, async () => {
    if (!timelineEntryId.value) return;

    timelineEntry.value = entries.value.find((entry) => entry.IdReference === timelineEntryId.value) || null;

    if (!timelineEntry.value) {
      timelineEntry.value = await fetchEntry(timelineEntryId.value);
    }
  }, {
    immediate: true,
  });

  watch(userMessageCount, (newCount, oldCount) => {
    if (newCount > oldCount) {
      fetchFeatureLimits();
    }
  });

  onMounted(() => {
    signalRClient.connection.on(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
    signalRClient.connection.on(ClientConstants.ReceiveMessageFromServer, handleMessageFromServer);
    signalRClient.connection.on(ClientConstants.ReceiveAgentActivity, handleAgentActivity);
  });

  onBeforeUnmount(() => {
    signalRClient.connection.off(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
    signalRClient.connection.off(ClientConstants.ReceiveMessageFromServer, handleMessageFromServer);
    signalRClient.connection.off(ClientConstants.ReceiveAgentActivity, handleAgentActivity);
  });

  async function fetchMessages () {
    messages.value = await signalRClient.invokeWithReconnect<Array<IClientThreadEntry>>(Constants.GetMessagesForUser, timelineEntryId.value);
  }

  function sendMessage (message: string) {
    threadVisibility.value = ThreadVisibility.Thread;

    if (!timelineEntry.value) {
      return sendInitialMessage(message);
    } else {
      return sendSubsequentMessage(message);
    }
  }

  async function sendInitialMessage (message: string) {
    // TODO: insert placeholder message for initial message as well

    const timelineEntryId = await signalRClient.invokeWithReconnect<string>(Constants.CreateTimelineEntry, message);

    timelineEntry.value = {
      ShareKey: "",
      IdReference: timelineEntryId,
      Title: "",
      TitleCallouts: [],
      SubtitleCallouts: [],
      Source: TimelineEntrySources.User,
      BodyAsMarkdown: "",
      Flags: [],
      Tags: [],
      ImageUrls: [],
      CreditSources: [],
      Visibility: TimelineEntryVisibilities.Visible,
      References: [],
      Attachments: [],
      DateCreated: new Date().toISOString(),
      DateUpdated: new Date().toISOString(),
    };
  }

  function sendSubsequentMessage (message: string) {
    if (!timelineEntry.value) {
      return;
    }

    const threadEntry = insertPlaceholderMessage(message);

    const request: IClientMessageRequest = {
      Text: threadEntry.Text,
      TimelineKey: threadEntry.ThreadId,
      ClientMessageGuid: threadEntry.IdReference,
    };

    return signalRClient.invokeWithReconnect(Constants.ReceiveClientMessage, request);
  }

  function insertPlaceholderMessage (message: string): IClientThreadEntry {
    const threadEntry: IClientThreadEntry = {
      Attachments: [],
      ThreadId: timelineEntry.value?.IdReference || "new",
      Reactions: [],
      Text: message,
      Source: TimelineEntrySources.User,
      DateCreated: new Date().toISOString(),
      DateUpdated: new Date().toISOString(),
      IdReference: crypto.randomUUID(),
    };

    messages.value.push(threadEntry);
    return threadEntry;
  }

  function handleTimelineEntryFromServer (entry: IClientTimelineEntry) {
    if (entry.IdReference !== timelineEntry.value?.IdReference) {
      return;
    }

    timelineEntry.value = {
      ...entry,
    };
  }

  function handleMessageFromServer (message: IClientThreadEntry) {
    if (message.ThreadId !== timelineEntry.value?.IdReference) {
      return;
    }
    if (message.Source === MessageSources.SystemEphemeral) {
      courtesyMessage.value = isAgentProcessing.value && !message.DateDeleted ? message.Text : null;
      return;
    }
    if (message.Source === MessageSources.User) {
      fetchFeatureLimits();
    }

    const existingMessage = messages.value.find((_message) => _message.IdReference === message.IdReference);

    if (existingMessage && message.DateDeleted) {
      messages.value.splice(messages.value.indexOf(existingMessage), 1);
    } else if (existingMessage) {
      if (existingMessage.Text.length < message.Text.length) Object.assign(existingMessage, message);
    } else if (!existingMessage) {
      messages.value.push(message);
    }
  }

  function handleAgentActivity (message: IAgentActivityUpdated) {
    if (message.ThreadId !== timelineEntry.value?.IdReference) {
      return;
    }

    isAgentProcessing.value = !message.ActivityCeased;

    if (!isAgentProcessing.value) {
      messages.value = messages.value.filter((message) => message.Source !== "SystemCourtesy");
      // TODO: test
      courtesyMessage.value = null;
    }
  }

  return {
    timelineEntry,
    messages: sortedMessages,
    isAgentProcessing,
    courtesyMessage,
    threadVisibility,

    fetchMessages,
    sendMessage,
  };
}
