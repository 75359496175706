import { usTradingHolidays } from "@cosine/lib/constants/holidays";
import getMarketDateRange from "@cosine/lib/utils/date/getMarketDateRange";
import { isBefore, isSameDay, isWeekend, previousFriday } from "date-fns";
import isToday from "date-fns/isToday";
import subDays from "date-fns/subDays";

export default function (date: Date) {
  const marketDateRange = getMarketDateRange(date);
  const isBeforeMarketOpen = isToday(date) && isBefore(date, marketDateRange.marketOpenDate);

  if (isBeforeMarketOpen) {
    date = subDays(date, 1);
  }

  return getMarketDateRange(getMostRecentTradingDate(date));;
}

function getMostRecentTradingDate (date: Date) {
  if (isWeekend(date)) {
    date = previousFriday(date);
  }

  if (isTradingHoliday(date)) {
    return getMostRecentTradingDate(subDays(date, 1));
  }

  return date;
}

function isTradingHoliday (date: Date) {
  return usTradingHolidays.some((holidayDate) => isSameDay(date, holidayDate));
}
