<template>
  <Form
    class="TransactionForm"
    v-bind="{isDisabled}"
  >
    <FormField label="Category">
      <SelectInput
        :modelValue="transaction.PriorityCategory"
        @update:modelValue="$emit('submit', {category: $event})"
      >
        <optgroup
          v-for="group in optionGroups"
          :key="group.label"
          :label="group.label"
        >
          <option
            v-for="option in group.options"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </option>
        </optgroup>
      </SelectInput>
    </FormField>
  </Form>
</template>

<script lang="ts" setup>
import Form from "@cosine/components/Form.vue";
import FormField from "@cosine/components/FormField.vue";
import SelectInput from "@cosine/components/SelectInput.vue";
import { TransactionCategory } from "@cosine/stores/useTransactionStore.types";
import { IEnrichedTransactionModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

defineEmits<{
  submit: [form:{ category: string }],
}>();

const props = withDefaults(defineProps<{
  transaction: IEnrichedTransactionModel,
  isDisabled?: boolean,
}>(), {
  isDisabled: false,
});

const {
  transaction,
} = toRefs(props);

const optionMap = computed(() => {
  return Object.values(TransactionCategory).reduce((acc, categoryCode) => {
    const [
      ,
      group,
      category,
    ] = categoryCode.match(/\[([^\]]+)\]-\[([^\]]+)\]/) || [];
    const existingGroup = acc[group];

    if (!existingGroup) {
      acc[group] = [category];
    } else {
      existingGroup.push(category);
    }

    return acc;
  }, {} as {[key: string]: Array<string> });
});

const optionGroups = computed(() => {
  return Object.entries(optionMap.value).map(([group, categories]) => {
    return {
      label: group,
      options: categories.map((category) => {
        return {
          label: category,
          value: `[${group}]-[${category}]`,
        };
      }),
    };
  });
});
</script>
