<template>
  <UIText
    class="TimelineCalloutTitle"
    tag="h3"
    weight="Medium"
    v-bind="{size}"
    isMultiline
  >
    <div class="TimelineCalloutTitle__container">
      <span
        ref="titleRef"
        class="TimelineCalloutTitle__title"
        v-html="nonBreakingTitle"
      />

      <span
        v-for="(rect, i) in calloutRects"
        :key="i"
        class="TimelineCalloutTitle__calloutRect"
        :style="{
          left: `${rect.x}px`,
          top: `${rect.y}px`,
          width: `${rect.width}px`,
          height: `${rect.height}px`
        }"
      />

      <span
        v-if="calloutRects.length > 0"
        class="TimelineCalloutTitle__title--callout TimelineCalloutTitle__title"
        aria-hidden="true"
        :style="`clip-path: path('${calloutClipPath}')`"
        v-html="nonBreakingTitle"
      />
    </div>
  </UIText>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import { TextSize } from "@shared/components/Text.types";
import DOMPurify from "dompurify";
import { computed, nextTick, onBeforeUnmount, onMounted, ref, toRefs, useTemplateRef, watch } from "vue";

const props = withDefaults(defineProps<{
  title: string,
  callouts: Array<string>,
  size?: keyof typeof TextSize,
}>(), {
  size: "2XLarge",
});

const {
  title,
  callouts,
} = toRefs(props);

const resizeObserver = new ResizeObserver(updateCalloutRects);
const titleRef = useTemplateRef<HTMLSpanElement>("titleRef");
const calloutRanges = ref<Array<Range>>([]);
const calloutRects = ref<Array<Pick<DOMRect, "x" | "y" | "width" | "height">>>([]);
const calloutPadding = 4;
const layoutMargin = 16;
const puncts = ".,;:!?".split("");

onMounted(() => {
  if (!titleRef.value) return;

  updateCalloutRanges();
  resizeObserver.observe(titleRef.value);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});

watch([() => title.value, () => callouts.value], () => {
  nextTick(updateCalloutRanges);
});

const nonBreakingTitle = computed(() => {
  return DOMPurify.sanitize(callouts.value.reduce((acc, callout) => {
    if (!callout.includes(" ")) return acc;

    return acc.replace(callout, callout.replace(/ /g, "&nbsp;"));
  }, title.value));
});

const calloutClipPath = computed((): string => {
  return calloutRects.value.reduce((acc, {
    x, y, width, height,
  }) => {
    return acc + ` M${x},${y} L${x + width},${y} L${x + width},${y + height} L${x},${y + height} L${x},${y}`;
  }, "");
});

function getTextNode () {
  return titleRef.value?.childNodes[0];
}

function updateCalloutRanges () {
  const textNode = getTextNode();

  calloutRanges.value = callouts.value.flatMap((callout) => {
    const startIndex = title.value.indexOf(callout);

    if (!textNode || startIndex === -1) return [];
    const endIndex = startIndex + callout.length;

    const punctOffset = puncts.includes(title.value.charAt(endIndex)) ? 1 : 0;

    const range = new Range();
    range.setStart(textNode, startIndex);
    range.setEnd(textNode, endIndex + punctOffset);

    return range;
  });

  updateCalloutRects();
}

function updateCalloutRects () {
  const textNode = getTextNode();

  if (!titleRef.value || !textNode) return;

  const parentRect = titleRef.value.getBoundingClientRect();

  calloutRects.value = calloutRanges.value.flatMap((range) => {
    let hasAttachedToEdge = false;
    if (!range.getClientRects) return []; // `getClientRects` is undefined in the test environment

    return Array.from(range.getClientRects()).reverse()
      .map(({
        x, y, width, height,
      }) => {
        const shouldAttachToEdge = !hasAttachedToEdge && x === parentRect.x;
        const leftPadding = shouldAttachToEdge ? layoutMargin : calloutPadding;
        if (shouldAttachToEdge) hasAttachedToEdge = true;

        return {
          x: x - (parentRect.x + leftPadding),
          y: y - parentRect.y + 1,
          width: width + leftPadding + calloutPadding,
          height,
        };
      });
  });
}
</script>

<style lang="scss" scoped>
.TimelineCalloutTitle {
  padding: 0 var(--layoutMargin) 1px;
  overflow: hidden;

  line-height: 1.175 !important; // Allowed only because we need a very specific line-height that’s solely to create enough spacing for the callout rects
  text-wrap: balance;
}

.TimelineCalloutTitle__container {
  position: relative;
  isolation: isolate;
}

.TimelineCalloutTitle__calloutRect {
  position: absolute;
  background-color: var(--backgroundColor, var(--colorSwissTeal300));
}

.TimelineCalloutTitle__title {
  display: block;
}

.TimelineCalloutTitle__title--callout {
  position: absolute;
  inset: 0;

  color: var(--colorWhite);

  pointer-events: none;
}
</style>
