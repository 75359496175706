import useApiClient from "@cosine/composables/useApiClient";
import useFlag from "@cosine/composables/useFlag";
import isTruthy from "@cosine/lib/utils/boolean/isTruthy";
import useAuthStore from "@cosine/stores/useAuthStore";
import useBackgroundJobStore from "@cosine/stores/useBackgroundJobStore";
import { max } from "date-fns";
import parseISO from "date-fns/parseISO";
import { AccountScope, AccountType, BackgroundJobStatus, BackgroundJobType, IAccountConnectionUrl, IApiResponse, IEnrichedAccountModel, IUpdateEnrichedAccountScopeRequestBody, MxWidgetMode, UserFeatureFlags } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

export default defineStore("FinancialStore", () => {
  const {
    currentUser,
    isCurrentUserVerified,
  } = storeToRefs(useAuthStore());
  const backgroundStore = useBackgroundJobStore();
  const {
    backgroundJobMap,
    isSyncing: isInitialSyncing,
  } = storeToRefs(backgroundStore);
  const {
    flag: maxConnectedAccountsFlag,
  } = useFlag(UserFeatureFlags.MaxConnectedAccounts, -1);
  const {
    apiClient,
  } = useApiClient();

  const isAccountsReady = ref(false);
  const isWaitingForAccounts = ref(false);

  const accounts = ref<Array<IEnrichedAccountModel>>([]);
  const accountMap = computed(() => {
    return new Map(accounts.value.map((account) => {
      return [account.EraAccountId, account];
    }));
  });
  const sortedAccounts = computed(() => {
    return [...accounts.value].sort((a, b) => {
      if (a.Scope === AccountScope.Normal && b.Scope === AccountScope.Limited) return -1;
      if (a.Scope === AccountScope.Limited && b.Scope === AccountScope.Normal) return 1;

      if (a.Type !== AccountType.Any && b.Type === AccountType.Any) return -1;
      if (a.Type === AccountType.Any && b.Type !== AccountType.Any) return 1;
      if (a.Type === b.Type) return a.DisplayName.localeCompare(b.DisplayName);

      return a.Type.localeCompare(b.Type);
    });
  });

  const activeAccounts = computed(() => {
    return sortedAccounts.value.filter((account) => account.Scope === AccountScope.Normal);
  });

  const canActivateMoreAccounts = computed((): boolean => {
    if (maxConnectedAccountsFlag.value === -1) return true;

    return activeAccounts.value.length < maxConnectedAccountsFlag.value;
  });

  const isEnrichingAccounts = computed(() => {
    return backgroundJobMap.value.get(BackgroundJobType.EnrichedAccountsSync)?.Status === BackgroundJobStatus.InProgress;
  });

  const isEnrichingTransactions = computed(() => {
    return backgroundJobMap.value.get(BackgroundJobType.EnrichedTransactionsSync)?.Status === BackgroundJobStatus.InProgress;
  });

  const isSyncingAccounts = computed(() => {
    return isWaitingForAccounts.value || isInitialSyncing.value || isEnrichingAccounts.value;
  });

  const isSyncingTransactions = computed(() => {
    return isInitialSyncing.value || isEnrichingTransactions.value;
  });

  const isSyncingFinances = computed(() => {
    return isSyncingAccounts.value || isSyncingTransactions.value;
  });

  const accountsSyncDate = computed(() => {
    const accountsDatetime = backgroundJobMap.value.get(BackgroundJobType.EnrichedAccountsSync)?.LastProcessedDate;

    return accountsDatetime ? parseISO(accountsDatetime) : undefined;
  });

  const financesSyncDate = computed(() => {
    const transactionsDatetime = backgroundJobMap.value.get(BackgroundJobType.EnrichedTransactionsSync)?.LastProcessedDate;

    const dates = [accountsSyncDate.value, transactionsDatetime ? parseISO(transactionsDatetime) : null].filter(isTruthy);
    if (dates.length === 0) return undefined;

    return max(dates);
  });

  watch(currentUser, async () => {
    if (!currentUser.value) return;

    try {
      await fetchAccounts();
    } catch {
      return;
    }

  }, {
    immediate: true,
  });

  watch(isEnrichingAccounts, async (_, wasEnrichingAccounts) => {
    if (!isEnrichingAccounts.value && wasEnrichingAccounts) {
      try {
        await fetchAccounts();
      } catch {
        return;
      }
    }
  });

  watch(accounts, (newAccounts, oldAccounts) => {
    if (newAccounts.length > oldAccounts.length) {
      isWaitingForAccounts.value = false;
    }
  });

  async function createMxAccount (memberGuid: string, connectionMode?: MxWidgetMode) {
    await apiClient.value.post("/mx/users/me/store-data", {
      MemberGuid: memberGuid,
      ConnectionMode: connectionMode,
    });

    if (connectionMode !== MxWidgetMode.Verification) {
      isWaitingForAccounts.value = true;
    }
  }

  async function fetchAccounts () {
    if (!isCurrentUserVerified.value) return null;

    try {
      const {
        data: {
          Result: _accounts,
        },
      } = await apiClient.value.get<IApiResponse<Array<IEnrichedAccountModel>>>("/finances/accounts");

      accounts.value = _accounts || [];
    } finally {
      isAccountsReady.value = true;
    }
  }

  async function fetchConnectWidgetUrl (accountId: string) {
    const response = await apiClient.value.get<IApiResponse<IAccountConnectionUrl>>(`/finances/accounts/${accountId}/reconnect`);

    return response.data.Result?.Url;
  }

  async function updateAccountScope (account: IEnrichedAccountModel, scope: AccountScope) {
    const params: IUpdateEnrichedAccountScopeRequestBody = {
      Scope: scope,
    };

    const {
      data: {
        Result: updatedAccount,
      },
    } = await apiClient.value.put<IApiResponse<IEnrichedAccountModel>>(`/finances/accounts/${account.EraAccountId}/scope`, params);

    const existingAccount = accounts.value.find(({
      EraAccountId,
    }) => {
      return EraAccountId === account.EraAccountId;
    });

    if (existingAccount && updatedAccount) {
      existingAccount.Scope = updatedAccount.Scope;
    }
  }

  return {
    accounts: sortedAccounts,
    activeAccounts,
    accountMap,
    isSyncingAccounts,
    isSyncingTransactions,
    isSyncingFinances,
    canActivateMoreAccounts,
    isAccountsReady,
    accountsSyncDate,
    financesSyncDate,

    createMxAccount,
    fetchAccounts,
    fetchConnectWidgetUrl,
    updateAccountScope,
  };
});
