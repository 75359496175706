<template>
  <SheetScreen
    class="SelectBrokerageView"
    :zIndex="1"
    leaveDirection="Left"
  >
    <div class="SelectBrokerageView__header">
      <IconTag
        class="SelectBrokerageView__headerIconTag"
        iconName="Hexagon"
        label="Select brokerage"
      />
      <SearchInput
        placeholder="Search"
        @update:modelValue="handleSearchInputChange"
        v-model="searchQuery"
      />
    </div>

    <ScrollContainer
      v-if="!isRequesting && filteredBrokerages.length"
      class="SelectBrokerageView__scrollContainer"
    >
      <div class="SelectBrokerageView__brokerageList">
        <button
          v-for="brokerage in filteredBrokerages"
          :key="brokerage.BrokerageId"
          class="BrokerageItem"
          @click="handleBrokerageClick(brokerage.Slug)"
        >
          <img
            class="BrokerageItem__logo"
            :src="brokerage.LogoUrl"
          >
          <UIText
            class="BrokerageItem__title"
            size="Small"
            weight="Medium"
          >
            {{ brokerage.Name }}
          </UIText>
        </button>
      </div>
    </ScrollContainer>

    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </SheetScreen>
</template>

<script lang="ts" setup>
import IconTag from "@cosine/components/IconTag.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SearchInput from "@cosine/components/SearchInput.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import Spinner from "@cosine/components/Spinner.vue";
import UIText from "@cosine/components/UIText.vue";
import useRequest from "@cosine/composables/useRequest";
import useSnaptradeConnect from "@cosine/composables/useSnaptradeConnect";
import { RouteName } from "@cosine/routes";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

// TODO: use SnapTrade select view?

const router = useRouter();

const {
  brokerages,
  fetchBrokerages,
} = useSnaptradeConnect();

const {
  handleRequest,
  isRequesting,
} = useRequest();

onMounted(async () => {
  await handleRequest(fetchBrokerages());
});

async function handleBrokerageClick (slug?: string) {
  // TODO: replace modal
  router.push({
    name: RouteName.investingConnectBrokerage,
    params: {
      slug,
    },
  });
}

const searchQuery = ref("");

// todo: debounce?
const handleSearchInputChange = (value: string) => {
  searchQuery.value = value;
};

const filteredBrokerages = computed(() => {
  if (searchQuery.value === "") {
    return brokerages.value;
  }

  return brokerages.value?.filter((brokerage) => {
    return brokerage.Name.toLowerCase().includes(searchQuery.value.toLowerCase());
  });
});
</script>

<style lang="scss" scoped>
.SelectBrokerageView {
  display: grid;
  grid: min-content auto / auto;
  width: 100%;
  height: 100%;
}

.SelectBrokerageView__header {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px 16px;

  border-bottom: 1px solid var(--colorSwissAlphaBlack100);
}

.SelectBrokerageView__headerIconTag {
  margin-right: auto;
}

.SelectBrokerageView__brokerageList {
  position: relative;
  padding-inline: 16px;
}

.BrokerageItem {
  display: flex;
  gap: 16px;
  align-items: center;
  width: 100%;
  padding-block: 24px;

  & + & {
    border-top: 1px solid var(--colorSwissAlphaBlack100);
  }
}

.BrokerageItem__logo {
  width: 28px;
  height: 28px;
}

.BrokerageItem__title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
