<template>
  <Form
    class="EditUserForm"
    :isDisabled="disabled"
    @submit="handleSubmit"
  >
    <FormField label="First name">
      <TextInput
        v-model="form.FirstName"
        name="FirstName"
        autocomplete="given-name"
        required
      />
    </FormField>

    <FormField label="Last name">
      <TextInput
        v-model="form.OtherNames"
        name="OtherNames"
        autocomplete="family-name"
        required
      />
    </FormField>

    <FormField label="Email">
      <TextInput
        :value="email"
        name="Email"
        placeholder="Email"
        autocomplete="email"
        disabled
        required
        readonly
      />
    </FormField>

    <FormField label="Phone Number">
      <PhoneNumberInput
        v-model="form.PhoneNumber"
        name="PhoneNumber"
        autocomplete="tel"
      />
    </FormField>

    <FormField label="Country">
      <CountrySelect
        v-model="form.CountryCode"
        name="CountryCode"
        class="EditUserForm__input"
      />
    </FormField>

    <FormField label="Language">
      <LanguageSelect
        v-model="form.DisplayLocale"
        name="DisplayLocale"
        class="EditUserForm__input"
      />
    </FormField>

    <FormField label="Timezone">
      <TimezoneSelect
        v-model="form.UserTimeZoneId"
        class="EditUserForm__input"
      />
    </FormField>

    <FormButton
      type="submit"
      class="EditUserForm__button"
    >
      Save
    </FormButton>
  </Form>
</template>

<script lang="ts" setup>
import CountrySelect from "@cosine/components/CountrySelect.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import FormField from "@cosine/components/FormField.vue";
import LanguageSelect from "@cosine/components/LanguageSelect.vue";
import PhoneNumberInput from "@cosine/components/PhoneNumberInput.vue";
import TextInput from "@cosine/components/TextInput.vue";
import TimezoneSelect from "@cosine/components/TimezoneSelect.vue";
import { IUserProfileModel } from "@cosine/types/api-models";
import { ref, toRefs, watch } from "vue";

const emit = defineEmits<{
  submit: [IUserProfileModel],
}>();
const props = withDefaults(defineProps<{
  profile: IUserProfileModel,
  email: string | undefined,
  disabled: boolean,
}>(), {
  disabled: false,
});
const {
  profile,
} = toRefs(props);

const form = ref<IUserProfileModel>({
  ...profile.value,
});

watch(profile, () => {
  form.value = {
    ...profile.value,
  };
});

const handleSubmit = () => {
  emit("submit", {
    FirstName: form.value.FirstName,
    OtherNames: form.value.OtherNames,
    PhoneNumber: form.value.PhoneNumber,
    UserTimeZoneId: form.value.UserTimeZoneId,
    CountryCode: form.value.CountryCode,
    DisplayLocale: form.value.DisplayLocale,
  });
};
</script>

<style lang="scss" scoped>
.EditUserForm {
  padding: 20px 16px 24px;

  // TODO: find a way to extract this
  :deep(fieldset) {
    display: grid;
    gap: 24px 0px;
  }
}
</style>
