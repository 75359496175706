import { RoutePath } from "@cosine/routes";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import { computed } from "vue";
import { useRoute } from "vue-router";

export default function useAuthConfig () {
  const {
    cosineBaseUrl,
  } = useRuntimeEnvironment();
  const route = useRoute();

  const authenticateUrl = computed(() => {
    const params = route.query.redirect
      ? `?redirect=${route.query.redirect}`
      : "";

    return `${cosineBaseUrl}${RoutePath.authenticate}${params}`;
  });
  const authExpirationMinuteCount = 15;

  return {
    authenticateUrl,
    authExpirationMinuteCount,
  };
}
