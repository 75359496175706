import { SpinnerAnimation } from "./SpinnerAnimation";

export class SpinnerThinkingAnimation extends SpinnerAnimation {
  spinDuration = 350;
  segmentDelay = 100;

  animate () {
    let animations: Array<Animation> = [];

    const animateSegments = async () => {
      animations = this.segments.flatMap((segment, n) => {
        return segment.spin({
          duration: this.spinDuration,
          delay: (n + 1) * this.segmentDelay,
        });
      });

      if (animations.length > 0) {
        try {
          await Promise.all(animations.map((animation) => animation.finished));
          animateSegments();
        } catch {
          // catch in case the animation gets canceled and throws an aborted promise exception
        }
      }
    };

    animateSegments();

    return () => {
      animations.forEach((animation) => animation.cancel());
    };
  }
}
