import { ModalName } from "@cosine/stores/useModalStore.types";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("ModalStore", () => {
  const modals = ref<Array<ModalName>>([]);

  function isModalOpen (id: ModalName): boolean {
    return modals.value.includes(id);
  }

  function openModal (id: ModalName) {
    modals.value.push(id);
  }

  function closeModal (id: ModalName) {
    modals.value.splice(modals.value.indexOf(id), 1);
  }

  return {
    modals,

    isModalOpen,
    openModal,
    closeModal,
  };
});
