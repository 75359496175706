<template>
  <div
    class="RoutineStatus"
    :class="{
      [`RoutineStatus--status${routine.Status}`]: true,
    }"
  >
    <UIText
      tag="p"
      size="2XSmall"
      weight="Medium"
      isMultiline
    >
      {{ label }}
    </UIText>
  </div>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import useLiveTime from "@cosine/composables/useLiveTime";
import formatInterval from "@cosine/lib/utils/interval/formatInterval";
import { IRecurrenceRoutineInterval, IRoutineModel, RoutineStatus } from "@cosine/types/api-models";
import { differenceInSeconds, formatDistanceStrict, isBefore } from "date-fns";
import { computed, toRefs } from "vue";

const props = defineProps<{
  routine: IRoutineModel,
}>();

const {
  now,
} = useLiveTime();

const {
  routine,
} = toRefs(props);

const label = computed((): string | null => {
  if (routine.value.Status === RoutineStatus.Disabled) { return "Paused"; }
  if (routine.value.NextFireTime) { return formatCron(routine.value.NextFireTime); }

  const interval = (routine.value.Interval as IRecurrenceRoutineInterval).Recurrence;
  if (interval) { return `Runs ${formatInterval(interval)}`; }

  return null;
});

function formatCron (date: string): string {
  const nextFireDate = new Date(date);
  if (isBefore(nextFireDate, now.value)) { return "Runs soon"; }

  const seconds = differenceInSeconds(nextFireDate, now.value);
  if (seconds <= 5) {
    return "Running now";
  } else if (seconds < 60) {
    return "Runs next within a minute";
  }

  return "Runs next " + formatDistanceStrict(nextFireDate, now.value, {
    addSuffix: true,
    roundingMethod: "ceil",
  });
}
</script>

<style lang="scss" scoped>
.RoutineStatus {
  display: flex;
  gap: 6px;
  align-items: center;
  color: var(--colorSwissAlphaBlack700);

  &::before {
    position: relative;
    top: 1px;
    width: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: var(--colorSwissTeal300);
    content: "";
  }
}

.RoutineStatus--statusDisabled::before {
  background-color: var(--colorRed500);
}
</style>
