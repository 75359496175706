import useApiClient from "@cosine/composables/useApiClient";
import createQueryParams from "@cosine/lib/api/createQueryParams";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import useCapabilityStore from "@cosine/stores/useCapabilityStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useRoutineStore from "@cosine/stores/useRoutineStore";
import { PrepareTransferOptions } from "@cosine/stores/useTransferStore.types";
import { AccountScope, AutomatedTransferInitiationType, Currency, FinancialAccountCapabilityType, IApiResponse, ICreateAccountRequest, IEditFundsTransferRequestBody, IEnrichedAccountModel, IFundsTransfer, IPaginatedList, IPrepareFundsTransferRequest, MessageChannel, PercentageBasedFundsTransferRoutineTarget, RoutineTypes, TransferEndpointType, UserCapabilityType } from "@cosine/types/api-models";
import { MinimumBalanceTransferRoutineOptions } from "@cosine/views/MinimumBalanceTransferRoutineFormView.types";
import { PercentageBasedTransferRoutineOptions } from "@cosine/views/PercentageBasedTransferRoutineFormView.types";
import { RoundUpSavingsRoutineOptions } from "@cosine/views/RoundUpSavingsRoutineFormView.types";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import useAuthStore from "./useAuthStore";

export default defineStore("TransferStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const capabilityStore = useCapabilityStore();
  const {
    userCapabilities,
    accountCapabilities,
  } = storeToRefs(capabilityStore);
  const {
    fetchCapabilities,
  } = capabilityStore;
  const {
    accountMap,
    activeAccounts,
  } = storeToRefs(useFinancialStore());
  const {
    createRoutine,
  } = useRoutineStore();

  const {
    isCurrentUserVerified,
  } = storeToRefs(useAuthStore());

  const transferMap = new Map<string, IFundsTransfer>();
  const transfers = ref<Array<IFundsTransfer>>([]);
  const pagination = ref({
    pageIndex: 1,
    pageCount: 1,
  });

  const hasEnabledTransfers = computed(() => {
    return !!userCapabilities.value.find((capability) => {
      return capability.CapabilityType === UserCapabilityType.FundsTransfer
        && capability.IsEnabled;
    });
  });

  const transferAccountCapabilities = computed(() => {
    return accountCapabilities.value.filter(({
      CapabilityType,
    }) => CapabilityType === FinancialAccountCapabilityType.FundsTransfer);
  });

  function getTransferAccountsForState ({
    isEnabled,
  }: { isEnabled: boolean }) {
    return transferAccountCapabilities.value.reduce<Array<IEnrichedAccountModel>>((acc, capability) => {
      if (capability.IsEnabled === isEnabled) {
        const account = accountMap.value.get(capability.EraAccountId);

        if (account && account.Scope === AccountScope.Normal) acc.push(account);
      }

      return acc;
    }, []);
  }

  const enabledTransferAccounts = computed(() => getTransferAccountsForState({
    isEnabled: true,
  }));
  const disabledTransferAccounts = computed(() => getTransferAccountsForState({
    isEnabled: false,
  }));
  const transferAccounts = computed(() => {
    return [...enabledTransferAccounts.value, ...disabledTransferAccounts.value];
  });
  const unsupportedAccountsForTransfer = computed(() => {
    const transferAccountIds = transferAccounts.value.map(({
      EraAccountId,
    }) => EraAccountId);

    return activeAccounts.value.filter(({
      EraAccountId,
    }) => {
      return !transferAccountIds.includes(EraAccountId);
    });
  });

  const hasEnoughAccountsToTransfer = computed(() => enabledTransferAccounts.value.length >= 2);

  function sortTransfers (transfers: Array<IFundsTransfer>) {
    return transfers.sort((a, b) => {
      const aDate = a.RequestedAt;
      const bDate = b.RequestedAt;

      if (aDate < bDate) { return 1; }
      if (aDate > bDate) { return -1; }
      return 0;
    });
  }

  function clearTransfers () {
    transferMap.clear();
    transfers.value.length = 0;
    pagination.value.pageIndex = 1;
    pagination.value.pageCount = 1;
  }

  function updateExistingTransfer (updatedTransfer: IFundsTransfer) {
    const existingTransfer = transfers.value.find((transfer) => {
      return transfer.EraFundsTransferId === updatedTransfer.EraFundsTransferId;
    });

    if (existingTransfer) {
      transferMap.set(existingTransfer.EraFundsTransferId, updatedTransfer);
      Object.assign(existingTransfer, updatedTransfer);
      sortTransfers(transfers.value);
    }
  }

  async function enableTransfers () {
    const {
      data: {
        Result: tosToken,
      },
    } = await apiClient.value.get<IApiResponse<string>>("/moov/tos-token");

    const params: ICreateAccountRequest = {
      TosToken: tosToken,
    };
    await apiClient.value.post("/moov/account", params);

    await fetchCapabilities();
  }

  async function fetchTransfers ({
    pageIndex,
  }: {
    pageIndex: number,
  } = {
    pageIndex: 1,
  }) {
    if (!isCurrentUserVerified.value) return null;

    const params = {
      Page: pageIndex,
    };

    const {
      data: {
        Result: pagindatedList,
      },
    } = await apiClient.value.get<IApiResponse<IPaginatedList<IFundsTransfer>>>(`/funds-transfers?${createQueryParams(params)}`);

    if (pageIndex === 1) clearTransfers();

    if (pagindatedList) {
      pagindatedList.Items.forEach((transfer) => transferMap.set(transfer.EraFundsTransferId, transfer));
      transfers.value = sortTransfers(Array.from(transferMap.values()));

      pagination.value.pageIndex = pageIndex;
      pagination.value.pageCount = pagindatedList.Pagination.TotalPages;
    }
  }

  async function prepareTransfer (options: PrepareTransferOptions) {
    const params: IPrepareFundsTransferRequest = {
      Amount: options.amount,
      SourceEraAccountId: options.sourceAccountId,
      DestinationEraAccountId: options.destinationAccountId,
      Description: options.description,
    };

    const {
      data: {
        Result: transfer,
      },
    } = await apiClient.value.post<IApiResponse<IFundsTransfer>>(
      "/funds-transfers",
      params,
    );

    if (transfer) {
      transferMap.set(transfer.EraFundsTransferId, transfer);
      transfers.value.unshift(transfer);
    }

    return transfer;
  }

  async function updateTransfer (transferId: string, options: PrepareTransferOptions) {
    const params: IEditFundsTransferRequestBody = {
      Amount: options.amount,
      SourceEraAccountId: options.sourceAccountId,
      DestinationEraAccountId: options.destinationAccountId,
      Description: options.description,
    };

    const {
      data: {
        Result: updatedTransfer,
      },
    } = await apiClient.value.put<IApiResponse<IFundsTransfer>>(
      `/funds-transfers/${transferId}`,
      params,
    );

    if (updatedTransfer) updateExistingTransfer(updatedTransfer);

    return updatedTransfer;
  }

  async function confirmTransfer (transferId: string) {
    const {
      data: {
        Result: updatedTransfer,
      },
    } = await apiClient.value.post<IApiResponse<IFundsTransfer>>(`/funds-transfers/${transferId}/initiate`);

    if (updatedTransfer) updateExistingTransfer(updatedTransfer);

    return updatedTransfer;
  }

  async function cancelTransfer (transferId: string) {
    const {
      data: {
        Result: updatedTransfer,
      },
    } = await apiClient.value.post<IApiResponse<IFundsTransfer>>(`/funds-transfers/${transferId}/cancel`);

    if (updatedTransfer) updateExistingTransfer(updatedTransfer);

    return updatedTransfer;
  }

  async function createFixedTransferRoutine (params: {
    amount: number,
    sourceAccountId: string,
    destinationAccountId: string,
    cronExpression: string,
    initiationType: AutomatedTransferInitiationType,
    channels: Array<MessageChannel>,
  }) {
    return createRoutine({
      RoutineType: RoutineTypes.UserFixedAmountFundsTransfer,
      RoutineName: "Funds transfer routine",
      Interval: {
        CronExpression: params.cronExpression,
      },
      Description: `${formatAmount(params.amount)} routine transfer`,
    }, {
      FixedAmount: {
        Amount: params.amount,
        Currency: Currency.USD,
      },
      SourceEraAccountId: params.sourceAccountId,
      DestinationEraAccountId: params.destinationAccountId,
      InitiationType: params.initiationType,
      TransferDescription: "Routine transfer",
      IsPinned: true,
      SendPushNotification: true,
      Channels: params.channels, // TODO: add defaults to settings
    });
  }

  async function createPercentageBasedTransferRoutine (params: PercentageBasedTransferRoutineOptions) {
    return createRoutine({
      RoutineType: RoutineTypes.UserPercentageBasedFundsTransfer,
      RoutineName: "Percentage-based transfer routine",
      Interval: {
        CronExpression: params.cronExpression,
      },
      Description: [
        formatPercent(params.percent),
        params.target === PercentageBasedFundsTransferRoutineTarget.AccountBalance
          ? "account balance"
          : "income",
        "routine transfer",
      ].join(" "),
    }, {
      RatioOfSourceBalance: params.percent,
      RatioAppliesTo: params.target,
      SourceEraAccountId: params.sourceAccountId,
      DestinationEraAccountId: params.destinationAccountId,
      InitiationType: params.initiationType,
      TransferDescription: "Routine transfer",
      IsPinned: true,
      SendPushNotification: true,
      Channels: params.channels, // TODO: add defaults to settings
    });
  }

  async function createMinimumBalanceRoutine (params: MinimumBalanceTransferRoutineOptions) {
    return createRoutine({
      RoutineType: RoutineTypes.UserThresholdBasedFundsTransfer,
      RoutineName: "Minimum balance routine",
      Interval: {
        CronExpression: params.cronExpression,
      },
      Description: `Maintain a ${formatAmount(params.balance)} minimum balance`,
    }, {
      Target: TransferEndpointType.Destination,
      TargetBalance: {
        Amount: params.balance,
        Currency: Currency.USD,
      },
      SourceEraAccountId: params.sourceAccountId,
      DestinationEraAccountId: params.destinationAccountId,
      InitiationType: params.initiationType,
      TransferDescription: "Minimum balance transfer",
      IsPinned: true,
      SendPushNotification: true,
      Channels: params.channels, // TODO: add defaults to settings
    });
  }

  async function createRoundUpSavingsRoutine (params: RoundUpSavingsRoutineOptions) {
    return createRoutine({
      RoutineType: RoutineTypes.UserRoundUpSavingsFundsTransfer,
      RoutineName: "Round up savings routine",
      Interval: {
        CronExpression: params.cronExpression,
      },
      Description: "Round up savings",
    }, {
      RoundUpIncrement: params.incrementAmount,
      TransactionCategories: params.transactionCategories,
      SourceEraAccountId: params.sourceAccountId,
      DestinationEraAccountId: params.destinationAccountId,
      InitiationType: params.initiationType,
      TransferDescription: "Round up savings transfer",
      IsPinned: true,
      SendPushNotification: true,
      Channels: params.channels, // TODO: add defaults to settings
    });
  }

  return {
    transfers,
    pagination,
    transferAccounts,
    enabledTransferAccounts,
    disabledTransferAccounts,
    unsupportedAccountsForTransfer,
    hasEnabledTransfers,
    hasEnoughAccountsToTransfer,

    enableTransfers,
    fetchTransfers,
    prepareTransfer,
    updateTransfer,
    confirmTransfer,
    cancelTransfer,
    createFixedTransferRoutine,
    createPercentageBasedTransferRoutine,
    createMinimumBalanceRoutine,
    createRoundUpSavingsRoutine,
  };
});
