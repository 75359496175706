<template>
  <SheetModal
    class="InvestmentMixSubscribeModalView"
    theme="Light"
    @close="closeActiveModal"
  >
    <div
      v-if="mix"
      class="InvestmentMixSubscribeModalView__content"
    >
      <header class="InvestmentMixSubscribeModalView__header">
        <BoxIcon
          class="InvestmentMixSubscribeModalView__headerIcon"
          name="HexagonChart"
          color="Green"
        />

        <UIText
          class="InvestmentMixSubscribeModalView__headerTitle"
          tag="h1"
          size="XLarge"
          weight="Medium"
          isMultiline
        >
          Choose an account to link to this Mix
        </UIText>

        <UIText
          tag="p"
          size="Small"
          isMultiline
        >
          After linking the Investment Mix, you’ll be able to manually or automatically rebalance your account.
        </UIText>
      </header>

      <Form
        class="InvestmentMixSubscribeModalView__form"
        :isDisabled="!portfolioId || isRequesting"
        @submit="handleSubmit"
      >
        <PortfolioSelect
          v-model="portfolioId"
          :accounts="activeInvestmentAccounts"
          v-bind="{
            portfolios,
          }"
        />

        <FormButton type="submit">
          Subscribe
        </FormButton>
      </Form>
    </div>

    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </SheetModal>
</template>

<script lang="ts" setup>
import BoxIcon from "@cosine/components/BoxIcon.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import PortfolioSelect from "@cosine/components/PortfolioSelect.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import Spinner from "@cosine/components/Spinner.vue";
import UIText from "@cosine/components/UIText.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useInvestingStore from "@cosine/stores/useInvestingStore";
import { storeToRefs } from "pinia";
import { computed, onMounted, ref, toRefs } from "vue";
import useInvestmentMixStore from "@cosine/stores/useInvestmentMixStore";

const props = defineProps<{
  mixId: string,
}>();

const {
  openModal,
  closeActiveModal,
  closeAllModals,
} = useModalRouter();

const {
  mixId,
} = toRefs(props);

const {
  mixes,
} = storeToRefs(useInvestmentMixStore());

const investingStore = useInvestingStore();
const {
  activeInvestmentAccounts,
  portfolios,
} = storeToRefs(investingStore);
const {
  fetchPortfolios,
  subscribePortfolioToMix,
} = investingStore;

const {
  handleRequest,
  isRequesting,
} = useRequest();

const portfolioId = ref<string | undefined>();

onMounted(async () => {
  await handleRequest(fetchPortfolios()); // TODO: make smarter so we don’t need to wait

  portfolioId.value = portfolios.value.at(0)?.PortfolioId; // TODO: eventually filter out portfolios that are already subscribed
});

// TODO: fetch mix if doesn’t exist
const mix = computed(() => mixes.value.find((mix) => mix.BlueprintId === mixId.value) || null);

async function handleSubmit () {
  if (!portfolioId.value) return;

  const [, portfolio] = await handleRequest(subscribePortfolioToMix(portfolioId.value, mixId.value), {
    showAlerts: true,
  });

  if (portfolio) {
    await closeAllModals();
    openModal({
      name: RouteName.investingAccount,
      params: {
        id: portfolio.EraAccountId,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
.InvestmentMixSubscribeModalView__content {
  display: grid;
  gap: 24px;
  padding: 24px var(--layoutMargin);
}

.InvestmentMixSubscribeModalView__header {
  display: grid;
  gap: 12px;
}

.InvestmentMixSubscribeModalView__headerIcon {
  margin-bottom: 4px;
}

.InvestmentMixSubscribeModalView__headerTitle {
  text-wrap: balance;
}

.InvestmentMixSubscribeModalView__form {
  :deep(fieldset) {
    gap: 24px;
  }
}
</style>
