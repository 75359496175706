<template>
  <InvestingView class="InvestingPage">
    <RouterView />
  </InvestingView>
</template>

<script lang="ts" setup>
import InvestingView from "@cosine/views/InvestingView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Investing",
});
</script>

<style lang="scss" scoped>
.InvestingPage {
  height: 100%;
  isolation: isolate;
}
</style>
