<template>
  <PromptPane
    v-if="isCurrentUserVerified"
    iconName="ArrowCycleHexagon"
    iconColor="Teal"
    backgroundColor="Teal"
    title="Coming soon"
    body="Automatically keep your portfolio on track with Era Investment Mixes and Routines."
  >
    <template #button>
      <FormButton
        :disabled="isRequesting || lists.includes(ListName.Trading)"
        @click="handleButtonClick"
      >
        <span v-if="!lists.includes(ListName.Trading)">
          Join the waitlist
        </span>
        <span v-else>
          You’re on the waitlist!
        </span>
      </FormButton>
    </template>
  </PromptPane>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useRequest from "@cosine/composables/useRequest";
import useAuthStore from "@cosine/stores/useAuthStore";
import useListStore from "@cosine/stores/useListStore";
import { ListName } from "@cosine/stores/useListStore.types";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";

const listStore = useListStore();
const {
  lists,
} = storeToRefs(listStore);
const {
  fetchLists,
  subscribeToList,
} = listStore;

const {
  handleRequest,
  isRequesting,
} = useRequest();
useAppSpinner(isRequesting);

const {
  isCurrentUserVerified,
} = storeToRefs(useAuthStore());

onMounted(() => {
  handleRequest(fetchLists());
});

function handleButtonClick () {
  handleRequest(subscribeToList(ListName.Trading));
}
</script>
