<template>
  <div
    ref="scrollRef"
    class="InfiniteScroll"
    :class="{
      [`InfiniteScroll--scrollViewport${scrollViewport}`]: true,
    }"
    :data-include-viewport-padding="includeViewportPadding || undefined"
  >
    <slot />
    <div
      v-if="hasMorePages"
      ref="scrollEndRef"
    />
  </div>
</template>

<script lang="ts" setup>
import { IScrollPagination, InfiniteScrollViewport } from "@cosine/components/InfiniteScroll.types";
import useTapToScrollToTop from "@cosine/composables/useTapToScrollToTop";
import { computed, onBeforeUnmount, onMounted, ref, toRefs, watch } from "vue";

const emit = defineEmits<{
  scrollEnd: [],
}>();

const props = withDefaults(defineProps<{
  pagination: IScrollPagination,
  scrollViewport?: keyof typeof InfiniteScrollViewport,
  includeViewportPadding?: "bottom" | false,
}>(), {
  pagination: () => ({
    pageIndex: 1,
    pageCount: 1,
  }),
  scrollViewport: "All",
  includeViewportPadding: undefined,
});
const {
  pagination,
} = toRefs(props);

const scrollRef = ref<HTMLElement>();
const scrollEndRef = ref<HTMLElement>();
const intersectionObserver = ref<IntersectionObserver>();
useTapToScrollToTop(scrollRef);

defineExpose({
  intersectionObserver,
  scrollRef,
});

onMounted(() => {
  intersectionObserver.value = new IntersectionObserver(handleIntersection);
});

onBeforeUnmount(() => {
  intersectionObserver.value?.disconnect();
});

const hasMorePages = computed((): boolean => {
  return pagination.value.pageIndex < pagination.value.pageCount;
});

watch(scrollEndRef, (newEl, oldEl) => {
  if (!intersectionObserver.value) { return; }

  if (oldEl) { intersectionObserver.value.unobserve(oldEl); }
  if (newEl) { intersectionObserver.value.observe(newEl); }
});

function handleIntersection (entries: Array<IntersectionObserverEntry>) {
  if (entries.some((entry) => entry.isIntersecting)) {
    emit("scrollEnd");
  }
}
</script>

<style lang="scss" scoped>
.InfiniteScroll {
  &.InfiniteScroll--scrollViewportAll {
    overflow-y: auto;
  }

  &.InfiniteScroll--scrollViewportMobile {
    @media (max-width: 599px) {
      overflow-y: auto;
    }
  }

  &[data-include-viewport-padding="bottom"] {
    @media (max-width: 599px) {
      padding-bottom: var(--safeAreaInsetBottom);
    }
  }
}
</style>
