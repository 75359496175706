import { ISpendingCalendarDate } from "@cosine/components/SpendingCalendarDate.types";
import useApiClient from "@cosine/composables/useApiClient";
import createQueryParams from "@cosine/lib/api/createQueryParams";
import getDatetimeForTransaction from "@cosine/lib/utils/financial/getDatetimeForTransaction";
import { IApiResponse, IEnrichedTransactionModel, IGetEnrichedTransactionsCommand, IPaginatedList } from "@cosine/types/api-models";
import { endOfDay, startOfDay } from "date-fns";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("SpendingCalendarStore", () => {
  const dates = ref<Array<ISpendingCalendarDate>>([]);
  const selectedDate = ref<Date | null>(null);
  const transactions = ref<Array<IEnrichedTransactionModel>>([]);
  let abortController: AbortController | null;

  const {
    apiClient,
  } = useApiClient();

  function updateDates (_dates: Array<ISpendingCalendarDate>) {
    dates.value = _dates;
  }

  function updateSelectedDate (date: Date) {
    transactions.value = [];
    selectedDate.value = date;
  }

  async function fetchTransactionsForSelectedDate () {
    abortController?.abort();
    abortController = new AbortController();

    if (!selectedDate.value) {
      transactions.value = [];
      return Promise.resolve();
    }

    // @ts-expect-error TODO: Page, Skip, Take should be optional
    const params: IGetEnrichedTransactionsCommand = {
      RecordsPerPage: 1000,
      FromInclusiveUtc: startOfDay(selectedDate.value).toISOString(),
      ToInclusiveUtc: endOfDay(selectedDate.value).toISOString(),
    };

    const {
      data: {
        Result: list,
      },
    } = await apiClient.value.get<IApiResponse<IPaginatedList<IEnrichedTransactionModel>>>(`/finances/transactions?${createQueryParams(params)}`, {
      signal: abortController.signal,
    });

    if (list) {
      transactions.value = sortTransactions(list.Items);
    }
  }

  function sortTransactions (transactions: Array<IEnrichedTransactionModel>) {
    return transactions.sort((a, b) => {
      const aDatetime = getDatetimeForTransaction(a);
      const bDatetime = getDatetimeForTransaction(b);

      if (aDatetime < bDatetime) { return 1; }
      if (aDatetime > bDatetime) { return -1; }
      return 0;
    });
  }

  return {
    selectedCalendarDate: selectedDate,
    transactionsForDate: transactions,
    spendingCalendarDates: dates,

    updateSpendingCalendarDates: updateDates,
    updateSelectedDate,
    fetchTransactionsForSelectedDate,
  };
});
