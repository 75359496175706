import createPlatformHeader from "@cosine/lib/utils/system/createPlatformHeader";
import useStytchStore from "@cosine/stores/useStytchStore";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import axios from "axios";
import { computed } from "vue";

export default function useApiClient () {
  const {
    apiBaseUrl,
    isDevelopment,
  } = useRuntimeEnvironment();

  const {
    stytchClient,
  } = useStytchStore();

  const apiClient = computed(() => {
    const defaultHeaders: {[key: string]: string } = {
      authorization: stytchClient.session.getTokens()?.session_token || "",
      ...createPlatformHeader(),
    };

    if (isDevelopment) {
      defaultHeaders["ngrok-skip-browser-warning"] = "true"; // allows API calls over ngrok with free ngrok accounts
    }

    return axios.create({
      baseURL: apiBaseUrl,
      headers: {
        common: defaultHeaders,
      },
    });
  });

  return {
    apiClient,
  };
}
