<template>
  <SheetModal
    class="BrokerageAccountModalView"
    theme="Light"
    size="Fill"
    @close="closeActiveModal"
  >
    <SheetScreen class="BrokerageAccountModalView__screen">
      <header
        v-if="account"
        class="BrokerageAccountModalView__header"
      >
        <IconTag
          iconName="Hexagon"
          :label="account.InstitutionName"
        />
      </header>
      <ScrollContainer class="BrokerageAccountModalView__scrollContainer">
        <InvestingPositionListView v-bind="{accountId}" />
      </ScrollContainer>
    </SheetScreen>
  </SheetModal>
</template>

<script lang="ts" setup>
import IconTag from "@cosine/components/IconTag.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import InvestingPositionListView from "@cosine/views/InvestingPositionListView.vue";
import { storeToRefs } from "pinia";
import { computed, toRefs } from "vue";

const props = defineProps<{
  accountId: string,
}>();

const {
  closeActiveModal,
} = useModalRouter();

const {
  accountId,
} = toRefs(props);

const {
  accounts,
} = storeToRefs(useFinancialStore());

const account = computed(() => accounts.value.find((account) => account.EraAccountId === accountId.value) || null);
</script>

<style lang="scss" scoped>
.BrokerageAccountModalView {
  --layoutStickyTop: 0px; // TODO: bake this into sheet modal?

  // TODO: find a more scalable way to do this
  :deep(.SheetModal__closeButton) {
    top: 5px;
    right: 0;
  }

  :deep(.SheetModal__closeButtonIcon) {
    width: 18px;
    height: 18px;
  }
}

.BrokerageAccountModalView__screen {
  display: grid;
  grid: min-content auto / auto;
}

.BrokerageAccountModalView__header {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 var(--layoutMargin);
}
</style>
