import useApiClient from "@cosine/composables/useApiClient";
import { IApiResponse, IAccountConnectionUrl, IMxWidgetUrlRequest, MxWidgetColorScheme, MxWidgetMode } from "@cosine/types/api-models";
import { ConnectWidget } from "@mxenabled/web-widget-sdk";
import { ConnectBackToSearchPayload, ConnectConnectedPrimaryActionPayload, ConnectCreateMemberErrorPayload, ConnectEnterCredentialsPayload, ConnectInstitutionSearchPayload, ConnectLoadedPayload, ConnectMemberConnectedPayload, ConnectMemberDeletedPayload, ConnectMemberStatusUpdatePayload, ConnectOAuthErrorPayload, ConnectOAuthRequestedPayload, ConnectSelectedInstitutionPayload, ConnectStepChangePayload, ConnectSubmitMFAPayload, ConnectUpdateCredentialsPayload, LoadPayload } from "@mxenabled/widget-post-message-definitions";
import * as Sentry from "@sentry/vue";
import { Ref, onBeforeUnmount, ref } from "vue";
import { MxConnectErrorDetails, MxMemberConnectionStatus } from "./useMxConnectWidget.types";

class MxConnectError extends Error {
  details: MxConnectErrorDetails;

  protected constructor (message: string, details: MxConnectErrorDetails) {
    super(message);
    this.details = details;
  }
}

class MxConnectCreateMemberError extends MxConnectError {
  constructor (details: ConnectCreateMemberErrorPayload) {
    super("MxConnetWidget failed to create a member", details);
  }
}

class MxConnectOAuthError extends MxConnectError {
  constructor (details: ConnectOAuthErrorPayload) {
    super("MxConnetWidget failed to connect with oauth", details);
  }
}

class MxConnectMemberConnectionStatusError extends MxConnectError {
  constructor (details: ConnectMemberStatusUpdatePayload) {
    super("MxConnetWidget failed to connect to a member", {
      ...details,
      connection_status_name: MxMemberConnectionStatus[details.connection_status] ?? "Unknown",
    });
  }

  static ErrorStatuses: readonly MxMemberConnectionStatus[] = Object.freeze([
    MxMemberConnectionStatus.Prevented,
    MxMemberConnectionStatus.Denied,
    MxMemberConnectionStatus.Rejected,
    MxMemberConnectionStatus.Locked,
    MxMemberConnectionStatus.Impeded,
    MxMemberConnectionStatus.Degraded,
    MxMemberConnectionStatus.Disconnected,
    MxMemberConnectionStatus.Discontinued,
    MxMemberConnectionStatus.Closed,
    MxMemberConnectionStatus.Delayed,
    MxMemberConnectionStatus.Failed,
    MxMemberConnectionStatus.Disabled,
    MxMemberConnectionStatus.Expired,
    MxMemberConnectionStatus.Impaired,
  ]);

  static isError (status: MxMemberConnectionStatus) {
    return MxConnectMemberConnectionStatusError.ErrorStatuses.includes(status);
  }
}

function reportErrorToSentry (error: MxConnectError) {
  Sentry.withScope((scope) => {
    scope.setContext("MX Context", error.details);
    Sentry.captureException(error);
  });
}

export default function useMxConnectWidget (container: Ref<HTMLElement | null>) {
  const {
    apiClient,
  } = useApiClient();
  const mxWidget = ref<ConnectWidget>();
  const hooks = {
    onMemberConnected: <Array<(payload: ConnectMemberConnectedPayload, widgetOptions: IMxWidgetUrlRequest) => void>>[],
  };

  onBeforeUnmount(() => {
    unmountMxWidget();
  });

  async function mountMxWidgetForAccount (accountId: string) {
    const url = await fetchWidgetUrlForAccount(accountId);
    if (!url) { return Promise.reject(new Error("No widget url")); }

    return mountMxWidgetWithUrl(url);
  }

  async function mountMxWidget (options: IMxWidgetUrlRequest = {}): Promise<ConnectWidget> {
    const url = await fetchWidgetUrl(options);
    if (!url) { return Promise.reject(new Error("No widget url")); }

    return mountMxWidgetWithUrl(url, options);
  }

  async function mountMxWidgetWithUrl (url: string, options: IMxWidgetUrlRequest = {}): Promise<ConnectWidget> {
    return new Promise((resolve, reject) => {
      if (!container.value) { return reject(new Error("No container")); }
      let isLoaded = false;

      const widget = new ConnectWidget({
        container: container.value,
        url,
        onLoad: (_payload: LoadPayload) => {
          // It is unpredictable whether onLoad or onLoaded or both will fire
          if (!isLoaded) {
            isLoaded = true;
            mxWidget.value = widget;
            resolve(widget);
          }
        },
        onLoaded: (_payload: ConnectLoadedPayload) => {
          // It is unpredictable whether onLoad or onLoaded or both will fire
          if (!isLoaded) {
            isLoaded = true;
            mxWidget.value = widget;
            resolve(widget);
          }
        },
        onEnterCredentials: (_payload: ConnectEnterCredentialsPayload) => {
          // noop
        },
        onInstitutionSearch: (_payload: ConnectInstitutionSearchPayload) => {
          // noop
        },
        onSelectedInstitution: (_payload: ConnectSelectedInstitutionPayload) => {
          // noop
        },
        onMemberConnected: (payload: ConnectMemberConnectedPayload) => {
          hooks.onMemberConnected.forEach((handler) => handler(payload, options));
        },
        onConnectedPrimaryAction: (_payload: ConnectConnectedPrimaryActionPayload) => {
          // noop
        },
        onMemberDeleted: (_payload: ConnectMemberDeletedPayload) => {
          // noop
        },
        onCreateMemberError: (payload: ConnectCreateMemberErrorPayload) => {
          reportErrorToSentry(new MxConnectCreateMemberError(payload));
        },
        onMemberStatusUpdate: (payload: ConnectMemberStatusUpdatePayload) => {
          if (MxConnectMemberConnectionStatusError.isError(payload.connection_status)) {
            reportErrorToSentry(new MxConnectMemberConnectionStatusError(payload));
          }
        },
        onOAuthError: (payload: ConnectOAuthErrorPayload) => {
          reportErrorToSentry(new MxConnectOAuthError(payload));
        },
        onOAuthRequested: (_payload: ConnectOAuthRequestedPayload) => {
          // noop
        },
        onStepChange: (_payload: ConnectStepChangePayload) => {
          // noop
        },
        onSubmitMFA: (_payload: ConnectSubmitMFAPayload) => {
          // noop
        },
        onUpdateCredentials: (_payload: ConnectUpdateCredentialsPayload) => {
          // noop
        },
        onBackToSearch: (_payload: ConnectBackToSearchPayload) => {
          // noop
        },
      });
    });
  }

  function unmountMxWidget () {
    // TODO: transition more gracefully
    mxWidget.value?.unmount();
    mxWidget.value = undefined;
    Object.values(hooks).length = 0;
  }

  // TODO: DRY this up with a `createHook` method when we add the other handlers
  function onMemberConnected (handler: (payload: ConnectMemberConnectedPayload, widgetOptions: IMxWidgetUrlRequest) => void) {
    hooks.onMemberConnected.push(handler);

    return () => {
      hooks.onMemberConnected.splice(hooks.onMemberConnected.indexOf(handler), 1);
    };
  }

  async function fetchWidgetUrl (options: IMxWidgetUrlRequest): Promise<string | undefined> {
    const request: IMxWidgetUrlRequest = {
      Mode: MxWidgetMode.Aggregation,
      ColorScheme: MxWidgetColorScheme.Light,
      ...options,
    };
    const response = await apiClient.value.post<IApiResponse<IAccountConnectionUrl>>("/mx/widget-url", request);

    return response.data.Result?.Url;
  }

  async function fetchWidgetUrlForAccount (accountId: string) {
    const response = await apiClient.value.get<IApiResponse<IAccountConnectionUrl>>(`/finances/accounts/${accountId}/reconnect`);

    return response.data.Result?.Url;
  }

  return {
    mxWidget,

    mountMxWidget,
    mountMxWidgetForAccount,
    unmountMxWidget,
    onMemberConnected,
  };
}
