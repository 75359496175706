<template>
  <Form
    class="BudgetForm"
    v-bind="{
      isDisabled,
    }"
    @submit="handleSubmit"
  >
    <CurrencyInput
      v-model="amount"
    />
    <Transition>
      <IconFormButton
        v-if="hasChanged"
        class="BudgetForm__button"
        type="submit"
        iconName="Check"
      />
    </Transition>
  </Form>
</template>

<script lang="ts" setup>
import CurrencyInput from "@cosine/components/CurrencyInput.vue";
import Form from "@cosine/components/Form.vue";
import IconFormButton from "@cosine/components/IconFormButton.vue";
import { IBudgetDefinitionModel } from "@cosine/types/api-models";
import { computed, ref, toRefs } from "vue";

const emit = defineEmits<{
  submit: [form:{ amount: number }],

}>();

const props = withDefaults(defineProps<{
  budgetDefinition: IBudgetDefinitionModel,
  isDisabled?: boolean,
}>(), {
  isDisabled: false,
});

const {
  budgetDefinition,
} = toRefs(props);
const amount = ref(budgetDefinition.value.DefaultBudgetAmount);

const hasChanged = computed(() => amount.value !== budgetDefinition.value.DefaultBudgetAmount);

function handleSubmit () {
  emit("submit", {
    amount: amount.value,
  });
}
</script>

<style lang="scss" scoped>
.BudgetForm:deep(fieldset) {
  display: flex;
  align-items: center;
}

.BudgetForm__button {
  height: 40px;
  margin-right: var(--layoutMargin);
  transition: opacity 200ms var(--easeInOutCubic);

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}
</style>
