<template>
  <TimelineListView
    class="TimelineForYouView"
    :entries="forYouEntries"
    v-bind="{
      pagination,
    }"
    @scrollEnd="fetchNextPage"
  >
    <template #carousel>
      <LabeledList
        v-if="fromEraEntries.length > 0"
        class="TimelineForYouView__fromEraList"
        title="From Era"
      >
        <template #list>
          <CardCarousel>
            <TimelineEntryView
              v-for="entry in fromEraEntries"
              :key="entry.IdReference"
              :modelValue="entry"
              isPinned
            />
          </CardCarousel>
        </template>
      </LabeledList>
    </template>
  </TimelineListView>
</template>

<script lang="ts" setup>
import CardCarousel from "@cosine/components/CardCarousel.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import useNow from "@cosine/composables/useNow";
import usePaginatedTimeline from "@cosine/composables/usePaginatedTimeline";
import useTimelineForYouStore from "@cosine/stores/useTimelineForYouStore";
import TimelineEntryView from "@cosine/views/TimelineEntryView.vue";
import TimelineListView from "@cosine/views/TimelineListView.vue";
import { subMonths } from "date-fns";
import { storeToRefs } from "pinia";
import { onBeforeMount, onBeforeUnmount } from "vue";

const forYouStore = useTimelineForYouStore();
const {
  listenForEntries,
  unlistenFromEntries,
  fetchEntries,
} = forYouStore;
const {
  fromEraEntries,
  forYouEntries,
  pagination,
} = storeToRefs(forYouStore);
const {
  now,
} = useNow();

const {
  fetchNextPage,
} = usePaginatedTimeline({
  pagination,
  fetchEntries: async (params) => {
    return fetchEntries({
      fromDate: subMonths(now.value, 1).toISOString(),
      pageIndex: params?.pageIndex || 1,
    });
  },
});

onBeforeMount(listenForEntries);
onBeforeUnmount(unlistenFromEntries);
</script>
