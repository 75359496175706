<template>
  <TransactionsFilter
    v-if="isFiltering"
    @clear="handleClear"
  >
    <FinancialAccountItem
      v-if="filteringAccount"
      :modelValue="filteringAccount"
      includeSyncStatus
    />
    <BudgetCard
      v-else-if="filteringBudgetInstance"
      :modelValue="filteringBudgetInstance"
    />
    <Spinner
      :isVisible="!filteringAccount && !filteringBudgetInstance"
      isCentered
    />
  </TransactionsFilter>
</template>

<script lang="ts" setup>
import BudgetCard from "@cosine/components/BudgetCard.vue";
import FinancialAccountItem from "@cosine/components/FinancialAccountItem.vue";
import Spinner from "@cosine/components/Spinner.vue";
import TransactionsFilter from "@cosine/components/TransactionsFilter.vue";
import useTransactionStore from "@cosine/stores/useTransactionStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const transactionStore = useTransactionStore();
const {
  filter,
  filteringAccount,
  filteringBudgetInstance,
} = storeToRefs(transactionStore);
const {
  setFilter,
} = transactionStore;

const isFiltering = computed(() => Object.values(filter.value).some((value) => value !== undefined));

async function handleClear () {
  await router.push({
    query: {},
  });
  setFilter({});
}
</script>
