<template>
  <ModalPage class="AuthenticatePage">
    <AlertBanner
      v-if="requestError"
      iconName="Exclamation"
      title="Error"
      body="Something went wrong. Try again."
      status="Negative"
    />
    <Spinner :isVisible="!requestError" />
  </ModalPage>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useLoginOrSignup from "@cosine/composables/useLoginOrSignup";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useStytchStore from "@cosine/stores/useStytchStore";
import { FullStackConstants, IStytchAuthenticatedSession } from "@cosine/types/api-models";
import ModalPage from "@shared/components/ModalPage.vue";
import { OAuthAuthenticateResponse } from "@stytch/vanilla-js";
import { computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const {
  requestError,
  handleRequest,
} = useRequest();
const {
  stytchClient,
  authenticateMagicLink,
} = useStytchStore();

const {
  loginOrSignupWithSession,
} = useLoginOrSignup();

if (!route.query.token) {
  router.push({
    name: RouteName.login,
  });
}

onMounted(async () => {
  const [, sessionData] = await handleRequest<OAuthAuthenticateResponse | IStytchAuthenticatedSession>(authClient.value.authenticate(
    String(route.query.token),
    {
      session_duration_minutes: FullStackConstants.StytchSessionDurationMinutes,
    },
  ));

  await handleRequest(loginOrSignupWithSession({
    sessionToken: (sessionData as IStytchAuthenticatedSession)?.SessionToken || (sessionData as OAuthAuthenticateResponse)?.session_token,
  }));
});

const isMagicLink = computed(() => {
  return route.query.stytch_token_type === "magic_links";
});

const authClient = computed(() => {
  return isMagicLink.value
    ? {
      authenticate: authenticateMagicLink,
    }
    : stytchClient.oauth;
});
</script>
