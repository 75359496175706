import { TimeZone } from "@cosine/lib/constants/timezones";
import { TZDate } from "@date-fns/tz";

const usTradingTimezone = TimeZone["America/New_York"];

export const usTradingHolidays = Object.freeze([
  // 2024
  new TZDate(2024, 12 - 1, 25, 0, 0, usTradingTimezone), // Christmas Day
  // 2025
  new TZDate(2025, 1 - 1, 1, 0, 0, usTradingTimezone), // New Year’s Day
  new TZDate(2025, 1 - 1, 20, 0, 0, usTradingTimezone), // Martin Luther King, Jr. Day
  new TZDate(2025, 2 - 1, 17, 0, 0, usTradingTimezone), // President’s Day
  new TZDate(2025, 4 - 1, 18, 0, 0, usTradingTimezone), // Good Friday
  new TZDate(2025, 5 - 1, 26, 0, 0, usTradingTimezone), // Memorial Day
  new TZDate(2025, 6 - 1, 19, 0, 0, usTradingTimezone), // Juneteenth
  new TZDate(2025, 7 - 1, 4, 0, 0, usTradingTimezone), // Independence Day
  new TZDate(2025, 9 - 1, 1, 0, 0, usTradingTimezone), // Labor Day
  new TZDate(2025, 11 - 1, 27, 0, 0, usTradingTimezone), // Thanksgiving Day
  new TZDate(2025, 12 - 1, 25, 0, 0, usTradingTimezone), // Christmas Day
  // 2026
  new TZDate(2026, 1 - 1, 1, 0, 0, usTradingTimezone), // New Year’s Day
  new TZDate(2026, 1 - 1, 19, 0, 0, usTradingTimezone), // Martin Luther King, Jr. Day
  new TZDate(2026, 2 - 1, 16, 0, 0, usTradingTimezone), // President’s Day
  new TZDate(2026, 4 - 1, 3, 0, 0, usTradingTimezone), // Good Friday
  new TZDate(2026, 5 - 1, 25, 0, 0, usTradingTimezone), // Memorial Day
  new TZDate(2026, 6 - 1, 19, 0, 0, usTradingTimezone), // Juneteenth
  new TZDate(2026, 7 - 1, 3, 0, 0, usTradingTimezone), // Independence Day
  new TZDate(2026, 9 - 1, 7, 0, 0, usTradingTimezone), // Labor Day
  new TZDate(2026, 11 - 1, 26, 0, 0, usTradingTimezone), // Thanksgiving Day
  new TZDate(2026, 12 - 1, 25, 0, 0, usTradingTimezone), // Christmas Day
  // TODO: 2027 when available
]);
