<template>
  <section class="RoutineView">
    <Teleport
      :to="PortalId.AppSubNav"
      defer
    >
      <TabNavView
        :items="tabNavItems"
        isExact
        isSubNav
      />
    </Teleport>

    <ScrollContainer
      viewport="Mobile"
      includeViewportPadding="bottom"
    >
      <EmailVerificationReminderView />
      <slot />
    </ScrollContainer>

    <Teleport :to="PortalId.Body">
      <RoutineSheetModalView />
      <NewRoutineModalView />
    </Teleport>
  </section>
</template>

<script lang="ts" setup>
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import { TabNavItem } from "@cosine/components/TabNav.types";
import { RouteName } from "@cosine/routes";
import NewRoutineModalView from "@cosine/views/NewRoutineModalView.vue";
import RoutineSheetModalView from "@cosine/views/RoutineSheetModalView.vue";
import TabNavView from "@cosine/views/TabNavView.vue";
import { ref } from "vue";
import { PortalId } from "@cosine/components/Portal.types";
import EmailVerificationReminderView from "./EmailVerificationReminderView.vue";

const tabNavItems = ref<Array<TabNavItem>>([
  {
    label: "Your routines",
    to: {
      name: RouteName.routinesYours,
    },
  },
  {
    label: "Explore",
    to: {
      name: RouteName.routinesExplore,
    },
  },
]);
</script>

<style lang="scss" scoped>
.RoutineView {
  display: grid;
  height: 100%;
}
</style>
