<template>
  <section class="FinancialTimelineEntry">
    <AlertBanner
      v-if="isSyncing"
      status="Positive"
      title="Sync in progress"
      body="Your financial snapshot is currently being updated"
      iconName="Sync"
    />

    <header class="FinancialTimelineEntry__header">
      <div class="FinancialTimelineEntry__iconTagContainer">
        <IconTag
          class="FinancialTimelineEntry__iconTag"
          iconName="Hexagon"
          v-bind="{label}"
        />
      </div>

      <TimelineCalloutTitle
        class="FinancialTimelineEntry__title"
        v-bind="{title}"
        size="2XLarge"
        :callouts="titleCallouts"
      />
    </header>

    <div class="FinancialTimelineEntry__body">
      <slot />

      <div
        v-if="$slots.cta"
        class="FinancialTimelineEntry__overlay"
      >
        <slot name="cta" />
      </div>
    </div>

    <footer
      v-if="lastUpdatedTimestamp"
      class="FinancialTimelineEntry__footer"
    >
      <UIText
        tag="time"
        size="2XSmall"
        weight="Medium"
        :timestamp="lastUpdatedTimestamp"
      >
        Last updated {{ formattedLastUpdatedDate }}
      </UIText>
      <UIText
        tag="time"
        size="3XSmall"
      >
        Data for linked accounts only. May be incomplete.
        <RouterLink
          :to="connectAccountModalRoute"
        >
          Link more accounts.
        </RouterLink>
      </UIText>
    </footer>
  </section>
</template>

<script lang="ts" setup>
import AlertBanner from "@cosine/components/AlertBanner.vue";
import IconTag from "@cosine/components/IconTag.vue";
import TimelineCalloutTitle from "@cosine/components/TimelineCalloutTitle.vue";
import UIText from "@cosine/components/UIText.vue";
import useModalRouteLink from "@cosine/composables/useModalRouteLink";
import useNow from "@cosine/composables/useNow";
import { RouteName } from "@cosine/routes";
import { formatDistance, min, parseISO } from "date-fns";
import { computed, toRefs } from "vue";

const props = defineProps<{
  label: string,
  title: string,
  titleCallouts: Array<string>,
  isSyncing?: boolean,
  lastUpdatedTimestamp?: string,
}>();

const {
  lastUpdatedTimestamp,
} = toRefs(props);

const {
  now,
} = useNow();

const {
  modalRoute: connectAccountModalRoute,
} = useModalRouteLink({
  name: RouteName.accountsConnect,
});

const formattedLastUpdatedDate = computed(() => {
  if (!lastUpdatedTimestamp.value) return;

  return formatDistance(min([parseISO(lastUpdatedTimestamp.value), now.value]), now.value, {
    addSuffix: true,
  });
});
</script>

<style lang="scss" scoped>
.FinancialTimelineEntry {
  isolation: isolate;

  // TODO: build this into base entry component
  &:not(:first-child) {
    border-top: 1px solid var(--themeColorBorder);
  }

  // TODO: build this into base entry component
  &:last-child {
    border-bottom: 1px solid var(--themeColorBorder);
  }
}

.FinancialTimelineEntry__header {
  display: grid;
  padding: var(--layoutMargin) 0;
}

.FinancialTimelineEntry__iconTagContainer {
  padding-inline: var(--layoutMargin);
}

.FinancialTimelineEntry__title {
  --backgroundColor: var(--colorSwissPurple300);
  margin: 32px 0 24px;
}

.FinancialTimelineEntry__body {
  position: relative;
}

.FinancialTimelineEntry__overlay {
  --ctaHeight: 78px;
  position: absolute;
  inset: 0;

  align-content: flex-end;
  padding: 16px var(--layoutMargin);

  background: linear-gradient(to top, var(--themeColorBackgroundSurface) calc(16px + var(--ctaHeight)), color-mix(in srgb, var(--themeColorBackgroundSurface), transparent) 100%);
}

.FinancialTimelineEntry__footer {
  display: grid;
  gap: 2px;
  padding: var(--layoutMargin);

  color: var(--themeColorTextSubdued);

  :deep(a) {
    font-weight: var(--fontWeightSaansMedium);
  }
}
</style>
