<template>
  <InvestmentListItem
    class="StockListItem"
    :title="modelValue.Symbol"
    :subtitle="modelValue.Name"
    :value="formattedAmount"
    :subvalue="formattedPercentChange"
    v-bind="{marketData}"
  />
</template>

<script lang="ts" setup>
import InvestmentListItem from "@cosine/components/InvestmentListItem.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import { IAssetDetail, IAssetInfo, IAssetOverall, IAssetPrice, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  modelValue: IAssetDetail | IAssetInfo,
  marketData?: ITimeSeriesRangeOf<IAssetOverall<IAssetDetail>, IAssetPrice>,
}>();

const {
  modelValue,
  marketData,
} = toRefs(props);

const series = computed(() => marketData.value?.Series.at(0));

const formattedAmount = computed(() => {
  if (!series.value?.Summary) return "$--.--";

  return String(formatAmount(series.value.Summary.Close, {
    isCents: false,
  }));
});

const formattedPercentChange = computed(() => {
  if (!series.value?.Summary) return "--%";

  return String(formatPercent(series.value.Summary.PercentChange || 0, {
    decimalPlaceCount: 2,
    includeSign: true,
  }));
});
</script>
