<template>
  <TimelineListView
    v-if="entries && entries.length > 0"
    class="TimelineChatView"
    v-bind="{
      entries,
      pagination,
    }"
    @scrollEnd="fetchNextPage"
  />
  <section
    v-else
    class="TimelineChatView__emptyState"
    data-testid="emptyState"
  >
    <UIText
      tag="h2"
      weight="Medium"
      size="Small"
    >
      No chats
    </UIText>

    <UIText
      class="TimelineChatView__emptyStateDescription"
      tag="p"
      size="XSmall"
    >
      Tap the hexagon icon in the top right to start a conversation from anywhere.
    </UIText>

    <CtaButton
      class="TimelineChatView__emptyStateCta"
      data-testid="cta"
      @click="handleCtaClick"
    >
      Start a chat
    </CtaButton>
  </section>
</template>

<script lang="ts" setup>
import usePaginatedTimeline from "@cosine/composables/usePaginatedTimeline";
import TimelineListView from "@cosine/views/TimelineListView.vue";
import { storeToRefs } from "pinia";
import useTimelineChatStore from "@cosine/stores/useTimelineChatStore";
import { onBeforeMount, onBeforeUnmount } from "vue";
import UIText from "@cosine/components/UIText.vue";
import CtaButton from "@cosine/components/CtaButton.vue";
import { RouteName } from "@cosine/routes";
import useModalRouter from "@cosine/composables/useModalRouter";

const timelineChatStore = useTimelineChatStore();
const {
  entries,
  pagination,
} = storeToRefs(timelineChatStore);
const {
  fetchEntries,
  listenForEntries,
  unlistenFromEntries,
} = timelineChatStore;

const {
  openModal,
} = useModalRouter();

const {
  fetchNextPage,
} = usePaginatedTimeline({
  pagination,
  fetchEntries: async (params) => {
    return fetchEntries({
      pageIndex: params?.pageIndex || 1,
    });
  },
});

onBeforeMount(listenForEntries);
onBeforeUnmount(unlistenFromEntries);

function handleCtaClick () {
  openModal({
    name: RouteName.threadsNew,
  });
}
</script>

<style scoped lang="scss">
.TimelineChatView__emptyState {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--themeColorTextSubdued);
  text-align: center;
}

.TimelineChatView__emptyStateDescription {
  max-width: 280px;
}

.TimelineChatView__emptyStateCta {
  margin-top: 12px;
}
</style>
