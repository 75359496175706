<template>
  <section
    class="TransactionSheetSummary"
  >
    <UIText
      class="TransactionSheetSummary__date"
      size="2XSmall"
      weight="Medium"
    >
      <Timestamp
        :datetime="transaction.TransactionPostedDate"
      />
    </UIText>

    <UIText
      class="TransactionSheetSummary__title"
      tag="h3"
      size="XLarge"
      weight="Medium"
    >
      {{ transaction.EnrichedDescription || transaction.OriginalDescription }}
    </UIText>

    <UIText
      class="TransactionSheetSummary__amount"
      tag="h4"
      size="XLarge"
      weight="Medium"
    >
      {{ formattedAmount }}
    </UIText>
  </section>
</template>

<script lang="ts" setup>
import Timestamp from "@cosine/components/Timestamp.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { IEnrichedTransactionModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  transaction: IEnrichedTransactionModel,
}>();

const {
  transaction,
} = toRefs(props);
const formattedAmount = computed(() => formatAmount(transaction.value.Amount) );
</script>

<style lang="scss" scoped>
.TransactionSheetSummary {
  display: grid;
}

.TransactionSheetSummary__date {
  margin-bottom: 12px;
  color: var(--colorSwissAlphaBlack600);
}

.TransactionSheetSummary__amount {
  margin-top: 8px;
}
</style>
