<template>
  <UIText
    class="ThreadMessage"
    :class="{
      [`ThreadMessage--source${modelValue.Source}`]: !!modelValue.Source,
    }"
    tag="section"
    :size="modelValue.Source === MessageSources.User ? 'Small' : 'Medium'"
    :weight="modelValue.Source === MessageSources.User ? 'Medium' : 'Normal'"
    isMultiline
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="ThreadMessage__body"
      v-html="formattedBody"
    />
    <!-- eslint-enable vue/no-v-html -->
  </UIText>
</template>

<script lang="ts" setup>
import UIText from "@cosine/components/UIText.vue";
import { IClientThreadEntry, MessageSources } from "@cosine/types/api-models";
import DOMPurify from "dompurify";
import { marked } from "marked";
import { computed, toRefs } from "vue";

const props = defineProps<{
  modelValue: IClientThreadEntry,
}>();
const {
  modelValue,
} = toRefs(props);

const formattedBody = computed((): string => {
  if (modelValue.value.Source === "User") {
    return `<p>${DOMPurify.sanitize(modelValue.value.Text, {
      ALLOWED_TAGS: [],
    })}</p>`;
  }

  return DOMPurify.sanitize(marked(modelValue.value.Text, {
    async: false,
  }));
});
</script>

<style lang="scss" scoped>
.ThreadMessage {
  --userBackgroundColor: var(--colorSwissTeal300);
  --userTextColor: var(--colorSwissBlack);

  user-select: text;

  .Theme--isColored & {
    --userBackgroundColor: var(--colorSwissBlack);
    --userTextColor: var(--colorWhite);
  }
}

.ThreadMessage:not(.ThreadMessage--sourceUser) {
  margin-right: 32px;
  align-self: flex-start;
  color: var(--colorSwissBlack);
  hyphens: auto;
  -webkit-hyphenate-limit-before: 4;
  -webkit-hyphenate-limit-after: 4;
}

.ThreadMessage--sourceUser {
  margin-left: 64px;
  padding: 8px 12px 10px;
  align-self: flex-end;
  background-color: var(--userBackgroundColor);
  color: var(--userTextColor);
}

.ThreadMessage__body {
  display: grid;
  gap: 12px;

  :deep(ul),
  :deep(ol) {
    display: grid;
    gap: 12px;
  }

  :deep(ul li) {
    margin-left: 24px;
    list-style-type: disc;
  }

  :deep(ol li) {
    margin-left: 40px;
    list-style-type: numeric;
  }

  :deep(a) {
    font-weight: var(--fontWeightSaansSemibold);
  }

  :deep(h3) {
    margin-top: 18px;
    font-size: 1.2em;
  }
}
</style>
