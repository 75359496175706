<template>
  <section
    class="ThreadMessageList"
    :class="{
      'ThreadMessageList--isAgentProcessing': isAgentProcessing,
      'ThreadMessageList--isScrolledToBottom': isScrolledToBottom,
    }"
  >
    <ScrollContainer
      ref="scrollContainerEl"
      class="ThreadMessageList__scrollContainer"
    >
      <div class="ThreadMessageList__scrollContent">
        <TransitionGroup
          tag="div"
          class="ThreadMessageList__messageList"
        >
          <ThreadMessage
            v-for="message in messages"
            :key="message.IdReference"
            :ref="setMessageRefs"
            class="ThreadMessageList__messageItem"
            :modelValue="message"
          />
        </TransitionGroup>

        <div class="ThreadMessageList__spinnerContainer">
          <Spinner
            type="Thinking"
            :isVisible="isAgentProcessing"
          />
          <Transition>
            <UIText
              v-if="courtesyMessage"
              :key="courtesyMessage"
              class="ThreadMessageList__courtesyMessage"
              size="XSmall"
              tag="em"
              weight="Medium"
            >
              {{ courtesyMessage }}
            </UIText>
          </Transition>
        </div>
      </div>
    </ScrollContainer>
  </section>
</template>

<script lang="ts" setup>
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import Spinner from "@cosine/components/Spinner.vue";
import ThreadMessage from "@cosine/components/ThreadMessage.vue";
import UIText from "@cosine/components/UIText.vue";
import useScrolledToBottom from "@cosine/composables/useScrolledToBottom";
import { IClientThreadEntry, IClientTimelineEntry, TimelineEntrySources } from "@cosine/types/api-models";
import { ComponentPublicInstance, computed, nextTick, onBeforeUnmount, onMounted, ref, toRefs, useTemplateRef, watch } from "vue";

const props = withDefaults(defineProps<{
  messages: Array<IClientThreadEntry>,
  timelineEntry?: IClientTimelineEntry | null,
  isAgentProcessing?: boolean,
  courtesyMessage?: string | null,
  paddingTop?: string,
  paddingBottom?: string,
}>(), {
  timelineEntry: null,
  isAgentProcessing: false,
  courtesyMessage: null,
  paddingTop: "0px",
  paddingBottom: "0px",
});

const {
  timelineEntry,
  messages,
  isAgentProcessing,
} = toRefs(props);

const scrollContainerEl = useTemplateRef("scrollContainerEl");
const messageRefs = ref<Array<ComponentPublicInstance>>([]);
const trackEl = computed((): HTMLElement | undefined => scrollContainerEl.value?.trackEl);
const innerPadding = 16;

const {
  isScrolledToBottom,
  updateScrolledToBottom,
} = useScrolledToBottom(trackEl);

onMounted(() => {
  handleViewportChange();

  window.visualViewport?.addEventListener("resize", handleViewportChange);
  window.visualViewport?.addEventListener("scroll", handleViewportChange);
});

onBeforeUnmount(() => {
  window.visualViewport?.removeEventListener("resize", handleViewportChange);
  window.visualViewport?.removeEventListener("scroll", handleViewportChange);
});

function setMessageRefs (el: ComponentPublicInstance | Element | null) {
  const messageEl = el as ComponentPublicInstance;

  if (el && !messageRefs.value.includes(messageEl)) {
    messageRefs.value.push(messageEl);
  }
}

// TODO: test
function updateScroll ({
  behavior,
}: { behavior: ScrollBehavior }) {
  if (timelineEntry.value?.Source === TimelineEntrySources.Announcement) return;

  nextTick(() => {
    if (!trackEl.value?.scrollTo) return;

    const lastMessage = messageRefs.value.at(-1);
    const top = lastMessage ? lastMessage.$el.offsetTop - innerPadding : trackEl.value.scrollHeight;

    trackEl.value.scrollTo({
      top,
      behavior,
    });
  });
}

function handleViewportChange () {
  updateScroll({
    behavior: "instant",
  });
}

function handleMessageListChange () {
  updateScroll({
    behavior: "smooth",
  });
  updateScrolledToBottom();
}

watch(isAgentProcessing, handleMessageListChange);
watch(messages, handleMessageListChange, {
  deep: true,
});
</script>

<style lang="scss" scoped>
.ThreadMessageList {
  --mobileViewportPaddingBottom: 0px;
  --transitionDuration: 350ms;

  position: relative;

  [data-js-mobile-keyboard-state="closed"] & {
    --mobileViewportPaddingBottom: var(--viewportPaddingBottom);
  }
}

.ThreadMessageList__scrollContainer {
  position: absolute;
  inset: 0;
}

.ThreadMessageList__scrollContent {
  --spinnerSize: 40px;
  --innerPadding: calc(1px * v-bind(innerPadding));
  --outerPaddingBottom: calc(v-bind(paddingBottom) + var(--mobileViewportPaddingBottom));

  position: relative;

  padding-inline: var(--innerPadding);
  padding-top: calc(v-bind(paddingTop) + var(--innerPadding));
  padding-bottom: calc(var(--innerPadding) + var(--outerPaddingBottom));

  transition: var(--transitionDuration) transform;

  .ThreadMessageList--isAgentProcessing.ThreadMessageList--isScrolledToBottom & {
    transform: translateY(calc(-1 * (var(--innerPadding) + var(--spinnerSize))));
  }
}

.ThreadMessageList__messageList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ThreadMessageList__messageItem {
  &.v-enter-active,
  &.v-leave-active {
    transition: var(--transitionDuration);
    transition-property: opacity transform;
  }

  &.v-enter-from {
    transform: translateY(16px);
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.ThreadMessageList__spinnerContainer {
  position: absolute;
  top: calc(100% - var(--outerPaddingBottom));

  display: flex;
  align-items: center;
  width: calc(100% - 2 * var(--innerPadding));
}

.ThreadMessageList__courtesyMessage {
  position: absolute;
  left: var(--spinnerSize);
  right: 0;

  padding: 0 12px;

  color: var(--colorAlphaBlack400);

  &.v-enter-active,
  &.v-leave-active {
    transition: var(--transitionDuration);
    transition-property: transform, opacity;
  }

  &.v-enter-from,
  &.v-leave-to {
    transform: translateX(8px);
    opacity: 0;
  }
}
</style>
