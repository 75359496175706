import useAuthStore from "@cosine/stores/useAuthStore";
import useBackgroundJobStore from "@cosine/stores/useBackgroundJobStore";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import useRoutineStore from "@cosine/stores/useRoutineStore";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";

export default function () {
  const {
    currentUser,
  } = storeToRefs(useAuthStore());
  const {
    signalRClient,
  } = storeToRefs(useConnectionStore());
  const {
    connectTimeline,
    disconnectTimeline,
  } = useTimelineStore();
  const {
    listenToBackgroundJobs,
    unlistenToBackgroundJobs,
  } = useBackgroundJobStore();
  const {
    connectRoutines,
    disconnectRoutines,
  } = useRoutineStore();

  watch(currentUser, async () => {
    if (currentUser.value) {
      connectTimeline();
      connectRoutines();
      listenToBackgroundJobs();
      await signalRClient.value.connect();
    } else {
      disconnectTimeline();
      disconnectRoutines();
      unlistenToBackgroundJobs();
      await signalRClient.value.disconnect();
    }
  }, {
    immediate: true,
  });
}
