<template>
  <ScrollContainer
    class="SettingsProfilePage"
    viewport="Mobile"
    includeViewportPadding="bottom"
  >
    <div class="SettingsProfilePage__content">
      <MembershipView />
      <EditUserView />

      <section class="SettingsProfilePage__support">
        <UIText
          class="SettingsProfilePage__signOutLink"
          tag="a"
          size="2XSmall"
          weight="Medium"
          @click="signOut()"
        >
          Sign out
        </UIText>

        <div class="SettingsProfilePage__supportDivider" />

        <UIText
          class="SettingsProfilePage__supportLink"
          tag="a"
          href="mailto:support@era.app"
          size="2XSmall"
          weight="Medium"
        >
          support@era.app
        </UIText>
      </section>

      <section class="SettingsProfilePage__debug">
        <UIText
          size="2XSmall"
          weight="Medium"
        >
          {{ currentUser?.UserId }}
        </UIText>
      </section>
    </div>
  </ScrollContainer>
</template>

<script lang="ts" setup>
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import UIText from "@cosine/components/UIText.vue";
import useAuthStore from "@cosine/stores/useAuthStore";
import EditUserView from "@cosine/views/EditUserView.vue";
import MembershipView from "@cosine/views/MembershipView.vue";
import { useSeoMeta } from "@unhead/vue";

useSeoMeta({
  title: "Profile",
});

const {
  signOut,
} = useAuthStore();

const {
  currentUser,
} = useAuthStore();
</script>

<style lang="scss" scoped>
.SettingsProfilePage {
  height: 100%;
}

.SettingsProfilePage__content {
  display: grid;
  grid: auto min-content min-content / auto;
  height: 100%;
}

.SettingsProfilePage__support {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  text-align: center;
}

.SettingsProfilePage__supportLink {
  text-decoration: none;
}

.SettingsProfilePage__signOutLink {
  cursor: pointer;
}

.SettingsProfilePage__supportDivider {
  width: 1px;
  height: 80%;
  background-color: var(--colorSwissAlphaBlack300);
}

.SettingsProfilePage__debug {
  padding: 8px var(--layoutMargin) var(--viewportPaddingBottom);
  color: var(--colorSwissAlphaBlack500);
  text-align: center;
}
</style>
