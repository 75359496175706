<template>
  <section class="InvestmentListItem">
    <header class="InvestmentListItem__header">
      <UIText
        class="InvestmentListItem__title"
        tag="h3"
        size="Medium"
        weight="Medium"
        v-bind="{title}"
      >
        {{ title }}
      </UIText>
      <UIText
        class="InvestmentListItem__subtitle"
        tag="h4"
        size="XSmall"
        weight="Medium"
        :title="subtitle"
      >
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </UIText>
    </header>

    <figure class="InvestmentListItem__chartFigure">
      <Transition>
        <InvestmentListItemChart
          v-if="chartData"
          class="InvestmentListItem__chartCanvas"
          :modelValue="chartData"
        />
      </Transition>
    </figure>

    <div
      class="InvestmentListItem__valueContainer"
    >
      <UIText
        class="InvestmentListItem__value"
        size="Medium"
        weight="Medium"
      >
        {{ value }}
      </UIText>
      <UIText
        class="InvestmentListItem__subvalue"
        size="XSmall"
        weight="Medium"
      >
        {{ subvalue }}
      </UIText>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { InvestmentListItemChartData } from "@cosine/components/InvestmentListItemChart.types";
import InvestmentListItemChart from "@cosine/components/InvestmentListItemChart.vue";
import UIText from "@cosine/components/UIText.vue";
import { IAssetDetail, IAssetOverall, IAssetPrice, IMutualFundSummary, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  title: string,
  subtitle?: string,
  value: string,
  subvalue: string,
  marketData?: ITimeSeriesRangeOf<IAssetOverall<IAssetDetail | IMutualFundSummary>, IAssetPrice>,
}>();

const {
  marketData,
} = toRefs(props);

const chartData = computed((): InvestmentListItemChartData | undefined => {
  if (!marketData.value) return;

  const series = marketData.value.Series[0];
  if (series.Data.length === 0) return;

  const points = series.Data.map(({
    Timestamp, Value,
  }) => {
    return {
      x: Timestamp as string,
      y: Value.Close,
    };
  });

  return {
    points,
    axis: series.Summary.PreviousClose,
    dateRange: {
      min: marketData.value.FromInclusive,
      max: marketData.value.ToInclusive,
    },
  };
});
</script>

<style lang="scss" scoped>
.InvestmentListItem {
  display: grid;
  grid: auto / minmax(88px, 25%) auto minmax(88px, 25%);
  gap: 16px;
  align-items: center;
  padding: 24px 16px;

  white-space: nowrap;

  & + & {
    border-top: 1px solid var(--themeColorBorder);
  }
}

.InvestmentListItem__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.InvestmentListItem__subtitle {
  color: var(--themeColorTextSubdued);
}

.InvestmentListItem__title,
.InvestmentListItem__subtitle {
  overflow: hidden;
  text-overflow: ellipsis;
}

.InvestmentListItem__chartFigure {
  position: relative;
  height: 100%;
}

.InvestmentListItem__chartCanvas {
  position: absolute;

  width: 100%;
  height: 100%;

  transition: 1000ms var(--easeOutQuart);

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.InvestmentListItem__valueContainer {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
}

.InvestmentListItem__subvalue {
  margin-right: -16px;
  padding: 4px 16px 4px 8px;
}
</style>
