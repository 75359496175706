<template>
  <figure
    class="BudgetChart"
  >
    <div
      ref="chartRef"
      class="BudgetChart__chart"
    >
      <svg
        class="BudgetChart__background"
      >
        <defs>
          <pattern
            id="budgetChartPattern"
            class="BudgetChart__backgroundPattern"
            patternUnits="userSpaceOnUse"
            width="4"
            height="4"
            patternTransform="rotate(115)"
          >
            <path d="M0,2.5 L4,2.5" />
          </pattern>
        </defs>

        <rect
          ref="chartRectRef"
          height="160"
          fill="url(#budgetChartPattern)"
          v-bind="{width}"
        />

        <rect
          class="BudgetCard__bar"
          v-bind="{width}"
          height="160"
          :style="{
            transform: `scaleX(${spendRatio})`
          }"
        />
      </svg>

      <BudgetChartLabel
        title="Spent"
        :amount="modelValue.AmountSpent"
        :percent="Math.round(spendRatio * 100)"
        align="Left"
      />

      <BudgetChartLabel
        v-if="modelValue.AmountRemaining >= 0"
        title="Remaining"
        :amount="modelValue.AmountRemaining"
        :percent="100 - Math.round(spendRatio * 100)"
        align="Right"
      />

      <BudgetChartLabel
        v-else
        title="Over"
        :amount="-modelValue.AmountRemaining"
        align="Right"
      />
    </div>
  </figure>
</template>

<script lang="ts" setup>
import BudgetChartLabel from "@cosine/components/BudgetChartLabel.vue";
import { IBudgetInstanceModel } from "@cosine/types/api-models";
import { computed, onBeforeUnmount, onMounted, ref, toRefs } from "vue";

const props = defineProps<{
  modelValue: IBudgetInstanceModel,
}>();
const {
  modelValue,
} = toRefs(props);

const chartRef = ref<SVGElement>();
const chartRectRef = ref<SVGRectElement>();
const spendRatio = computed((): number => modelValue.value.AmountSpent / modelValue.value.Amount);
const resizeObserver = new ResizeObserver(handleResize);
const width = ref(0);

onMounted(() => {
  if (!chartRef.value) return;

  resizeObserver.observe(chartRef.value);
});

onBeforeUnmount(() => {
  resizeObserver.disconnect();
});

function handleResize (entries: Array<ResizeObserverEntry>) {
  width.value = entries[0].borderBoxSize[0].inlineSize;
}
</script>

<style lang="scss" scoped>
.BudgetChart {
  --barColor: var(--colorSwissPurple300);
  --patternColor: var(--colorSwissAlphaBlackOpaque300);

  position: relative;
  display: grid;
  gap: 8px;

  .Theme--isColored & {
    --barColor: var(--colorSwissBlack);
    --patternColor: var(--colorSwissAlphaBlack500);
  }
}

.BudgetChart__chart {
  position: relative;
  height: 160px;
  overflow: hidden;

  &::after {
    position: absolute;
    inset: 0;
    border: 1px solid var(--colorSwissBlack);
    content: "";
  }
}

.BudgetChart__background {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 160px;
}

.BudgetChart__backgroundPattern {
  stroke: var(--patternColor);
}

.BudgetCard__bar {
  stroke: var(--colorSwissBlack);
  stroke-width: 2px;
  fill: var(--barColor);
  paint-order: stroke;
  vector-effect: non-scaling-stroke;
  transform-origin: left center;
  transition: transform 350ms var(--easeInOutCubic);
}
</style>
