import useAppStore from "@cosine/stores/useAppStore";
import { endOfMinute, startOfMinute } from "date-fns";
import { storeToRefs } from "pinia";
import { Ref, onBeforeUnmount, onMounted, provide, ref, watch } from "vue";

export const NowInjectionKey = Symbol("Now");

export default function useLiveTime () {
  const {
    isAppVisible,
  } = storeToRefs(useAppStore());
  const now = ref(startOfMinute(new Date));

  let timeoutId: NodeJS.Timeout;
  let intervalId: NodeJS.Timeout;

  provide<Ref<Date>>(NowInjectionKey, now);

  onMounted(() => {
    restartInterval();
  });

  onBeforeUnmount(() => {
    stopInterval();
  });

  watch(isAppVisible, () => {
    if (isAppVisible.value) {
      updateTime();
      restartInterval();
    } else {
      stopInterval();
    }
  });

  function updateTime () {
    now.value = startOfMinute(new Date);
  }

  function restartInterval () {
    const millisecondsUntilNextMinute = endOfMinute(new Date()).getTime() - new Date().getTime() + 1;

    stopInterval();
    timeoutId = setTimeout(() => {
      updateTime();
      intervalId = setInterval(updateTime, 1000 * 60);
    }, millisecondsUntilNextMinute);
  }

  function stopInterval () {
    clearTimeout(timeoutId);
    clearInterval(intervalId);
  }

  return {
    now,
  };
}
