import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import useRequest from "@cosine/composables/useRequest";
import useBackgroundJobStore from "@cosine/stores/useBackgroundJobStore";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { IClientTimelineEntry, IDynamicAttachmentPayload } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, ref, Ref, watch } from "vue";

export default function (
  entry: Ref<IClientTimelineEntry>,
  typeName: FinancialEntryPayloadTypeName,
) {
  const {
    fetchDynamicEntryPayloads,
  } = useTimelineStore();

  const {
    isSyncing: isInitialSyncing,
  } = storeToRefs(useBackgroundJobStore());

  const {
    isSyncingAccounts,
    isSyncingTransactions,
    isSyncingFinances,
    isAccountsReady,
  } = storeToRefs(useFinancialStore());

  const {
    handleRequest,
  } = useRequest();

  const updatedPayload = ref<IDynamicAttachmentPayload>();

  watch(isAccountsReady, () => {
    if (isAccountsReady.value) {
      fetchLatestPayload();
    }
  }, {
    immediate: true,
  });

  watch(isSyncingAccounts, (_, wasSyncingAccounts) => {
    if (wasSyncingAccounts && !isSyncingAccounts.value) fetchLatestPayload();
  });

  watch(isSyncingTransactions, (_, wasSyncingTransactions) => {
    if (wasSyncingTransactions && !isSyncingTransactions.value) fetchLatestPayload();
  });

  const isSyncing = computed(() => {
    return isInitialSyncing.value || isSyncingFinances.value;
  });

  const initialPayload = computed(() => {
    return entry.value.Attachments
      .flatMap((attachment) => attachment.Payloads)
      .find((payload) => payload?.TypeName === typeName) as IDynamicAttachmentPayload | undefined;
  });

  const payload = computed(() => {
    return updatedPayload.value || initialPayload.value;
  });

  const snapshot = computed(() => {
    return payload.value?.Snapshot;
  });

  async function fetchLatestPayload () {
    const [_err, payloads] = await handleRequest(fetchDynamicEntryPayloads(entry.value));

    // TODO: show requesting status and any errors on the card itself
    const payload = payloads?.find((payload) => payload.TypeName === typeName);

    if (payload) {
      updatedPayload.value = payload;
    }
  }

  return {
    payload,
    snapshot,
    isSyncing,
  };
}
