<template>
  <li
    class="SpendingDateCarouselItem"
    :class="{
      'SpendingDateCarouselItem--isCurrentMonth': isDateCurrentMonth,
      'SpendingDateCarouselItem--isPastOrPresent': isDatePastOrPresent,
      'SpendingDateCarouselItem--isFuture': isDateFuture,
      'SpendingDateCarouselItem--isActive': isActive,
    }"
    ref="item"
  >
    <div
      v-if="isToday"
      class="SpendingDateCarouselItem__todayIndicator"
    />
    <button
      class="SpendingDateCarouselItem__button"
      @click="!isActive ? $emit('click') : undefined"
    >
      <UIText
        class="SpendingDateCarouselItem__date"
        tag="time"
        size="2XSmall"
        :datetime="modelValue.date.toISOString()"
      >
        {{ label }}
      </UIText>
      <UIText
        class="SpendingDateCarouselItem__amount"
        tag="strong"
        size="Medium"
        weight="Medium"
      >
        {{ formattedAmount }}
      </UIText>
    </button>
  </li>
</template>

<script lang="ts" setup>
import { ISpendingCalendarDate } from "@cosine/components/SpendingCalendarDate.types";
import UIText from "@cosine/components/UIText.vue";
import useSpendingCalendarDate from "@cosine/composables/useSpendingCalendarDate";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import format from "date-fns/format";
import { computed, onMounted, toRefs, useTemplateRef, watch } from "vue";

const modelValue = defineModel<ISpendingCalendarDate>({
  required: true,
});

defineEmits<{
  click: [],
}>();

const props = defineProps<{
  monthDate: Date,
  isActive?: boolean,
}>();

const {
  monthDate,
  isActive,
} = toRefs(props);

const itemRef = useTemplateRef("item");

const date = computed(() => modelValue.value.date);

const label = computed(() => format(modelValue.value.date, DateFormat.shortWeekdayNameWithOrdinal));
const formattedAmount = computed(() => formatAmount(modelValue.value.amount, {
  excludeCents: true,
}));

const {
  isDateCurrentMonth,
  isDatePastOrPresent,
  isDateFuture,
  isToday,
} = useSpendingCalendarDate(date, monthDate);

onMounted(() => {
  if (isActive.value) {
    itemRef.value?.scrollIntoView({
      inline: "center",
      block: "nearest",
    });
  }
});

watch(isActive, () => {
  if (isActive.value) {
    itemRef.value?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }
});
</script>

<style lang="scss" scoped>
.SpendingDateCarouselItem {
  position: relative;
  z-index: 0;

  display: flex;

  background-color: var(--themeColorBackgroundApp);
  box-shadow: inset 0 0 0 1px var(--colorSwissAlphaBlack100);

  transition: transform 0.15s;

  scroll-snap-align: center;

  & + & {
    margin-left: -1px;
  }

  &:not(.SpendingDateCarouselItem--isCurrentMonth) {
    color: var(--colorSwissAlphaBlack400);
  }

  &.SpendingDateCarouselItem--isCurrentMonth.SpendingDateCarouselItem--isPastOrPresent::before {
    position: absolute;
    inset: 0;
    z-index: -1;

    background-color: var(--colorSwissPurple300);
    opacity: calc(0.1 + v-bind('modelValue.spendingRatio') * 0.9);

    content: "";
  }

  &.SpendingDateCarouselItem--isActive {
    z-index: 1;
    transform: scale(1.1);
    outline: 1px solid var(--colorSwissBlack);
  }
}

.SpendingDateCarouselItem__button {
  display: grid;
  place-content: center;
  place-items: center;
  padding: 20px 32px;

  text-align: center;

  row-gap: 16px;
}

.SpendingDateCarouselItem__date {
  align-content: center;
  text-align: center;
  text-wrap: nowrap;

  .SpendingDateCarouselItem--isFuture & {
    color: var(--colorSwissAlphaBlack500);
  }
}

.SpendingDateCarouselItem__amount {
  text-align: center;

  .SpendingDateCarouselItem:is(:not(.SpendingDateCarouselItem--isCurrentMonth), .SpendingDateCarouselItem--isFuture) & {
    display: none;
  }
}

.SpendingDateCarouselItem__todayIndicator {
  --size: 6px;
  position: absolute;
  top: calc(-1 * var(--size) - 10px);
  left: calc(50% - var(--size) / 2);
  z-index: -1;

  width: var(--size);
  height: var(--size);

  border-radius: 50%;
  background-color: var(--colorSwissPurple300);

  .SpendingDateCarouselItem--isActive & {
    background-color: var(--colorSwissBlack);
  }
}
</style>
