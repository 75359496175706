<template>
  <section class="LoginEmailSentScreen">
    <LoginSlide>
      <template #title>
        Email sent!<br>
        <strong>Check your inbox</strong>
      </template>
      <template #body>
        We sent a magic link to {{ email }}. Click the link in that email to sign in to Era securely. No password required.
      </template>
    </LoginSlide>
  </section>
</template>

<script lang="ts" setup>
import LoginSlide from "@cosine/components/LoginSlide.vue";
import useAppBackgroundColor from "@cosine/composables/useAppBackgroundColor";

defineProps<{
  email: string,
}>();

const {
  fadeAppBackgroundColor,
} = useAppBackgroundColor();

fadeAppBackgroundColor("#2BE9A4");
</script>

<style lang="scss" scoped>
.LoginEmailSentScreen {
  display: grid;
  place-items: end center;
  background-color: var(--themeColorBackgroundApp);

  @media (min-width: 900px) {
    align-items: start;
  }
}
</style>
