import useAppSpinner from "@cosine/composables/useAppSpinner";
import useAppVisibilityCallback from "@cosine/composables/useAppVisibilityCallback";
import useRequest from "@cosine/composables/useRequest";
import { IClientTimelineEntry, IPaginatedList, IPagination } from "@cosine/types/api-models";
import { Ref } from "vue";

export default function ({
  pagination,
  fetchEntries,
}: {
  pagination: Ref<IPagination>,
  fetchEntries: (params?: {
    pageIndex: number,
  }) => Promise<IPaginatedList<IClientTimelineEntry>>,
}) {
  const {
    isRequesting,
    handleRequest,
  } = useRequest();

  useAppSpinner(isRequesting);
  useAppVisibilityCallback(fetchRequest);
  fetchRequest();

  function fetchRequest (params?: {
    pageIndex: number,
  }) {
    return handleRequest(fetchEntries(params));
  }

  function fetchNextPage () {
    if (isRequesting.value) return;

    return fetchRequest({
      pageIndex: pagination.value.Page + 1,
    });
  }

  return {
    pagination,
    fetchNextPage,
  };
}
