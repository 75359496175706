<template>
  <Transition>
    <SheetScreen
      v-show="!viewState"
      class="PercentageBasedTransferRoutineFormView__screen"
    >
      <header class="PercentageBasedTransferRoutineFormView__header">
        <IconTag
          iconName="Hexagon"
          label="Routine"
        />
        <UIText
          class="PercentageBasedTransferRoutineFormView__title"
          tag="h1"
          size="XLarge"
          weight="Medium"
        >
          Percentage-based transfer
        </UIText>
      </header>

      <Form
        class="PercentageBasedTransferRoutineFormView__form"
        :isDisabled="isRequesting"
        @submit="handleSubmit"
      >
        <PercentInput
          class="PercentageBasedTransferRoutineFormView__percentInput"
          v-model="form.percent"
          autofocus
        />

        <ScrollContainer
          class="PercentageBasedTransferRoutineFormView__scrollContainer"
          hasBottomFade
        >
          <div class="PercentageBasedTransferRoutineFormView__scrollContainerFieldset">
            <TransferAccountSelectView
              v-model:sourceAccountId="form.sourceAccountId"
              v-model:destinationAccountId="form.destinationAccountId"
              v-model:viewState="viewState"
            />

            <FormFieldset>
              <PercentageBasedTransferRoutineTargetField
                v-model="form.target"
              />
            </FormFieldset>

            <RoutineScheduleFieldset
              v-model="form.cronExpression"
            />

            <FormFieldset>
              <TransferInitiationTypeField
                v-model="form.initiationType"
              />
            </FormFieldset>

            <ChannelFieldset
              v-model="form.channels"
              :supportedChannels="[
                MessageChannel.Timeline,
                MessageChannel.Email,
                MessageChannel.Sms
              ]"
              :email="currentUser?.Email"
            />
          </div>
        </ScrollContainer>

        <FormButtonContainer class="PercentageBasedTransferRoutineFormView__formButtonContainer">
          <FormButton
            variant="Primary"
            type="submit"
            :disabled="!validatedForm"
          >
            <span v-if="!isRequesting">Schedule routine</span>
            <span v-else>Scheduling routine…</span>
          </FormButton>
        </FormButtonContainer>
      </Form>
    </SheetScreen>
  </Transition>
</template>

<script lang="ts" setup>
import ChannelFieldset from "@cosine/components/ChannelFieldset.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import FormButtonContainer from "@cosine/components/FormButtonContainer.vue";
import FormFieldset from "@cosine/components/FormFieldset.vue";
import IconTag from "@cosine/components/IconTag.vue";
import PercentageBasedTransferRoutineTargetField from "@cosine/components/PercentageBasedTransferRoutineTargetField.vue";
import PercentInput from "@cosine/components/PercentInput.vue";
import RoutineScheduleFieldset from "@cosine/components/RoutineScheduleFieldset.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import TransferInitiationTypeField from "@cosine/components/TransferInitiationTypeField.vue";
import UIText from "@cosine/components/UIText.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { RoutineTemplate } from "@cosine/stores/useRoutineStore.types";
import useTransferStore from "@cosine/stores/useTransferStore";
import { NullableValues } from "@cosine/types";
import { AutomatedTransferInitiationType, MessageChannel, PercentageBasedFundsTransferRoutineTarget } from "@cosine/types/api-models";
import { PercentageBasedTransferRoutineOptions } from "@cosine/views/PercentageBasedTransferRoutineFormView.types";
import { TransferAccountSelectViewState } from "@cosine/views/TransferAccountSelectView.types";
import TransferAccountSelectView from "@cosine/views/TransferAccountSelectView.vue";
import { storeToRefs } from "pinia";
import { computed, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
  routineTemplate: RoutineTemplate,
}>();
const router = useRouter();
const {
  currentUser,
} = storeToRefs(useAuthStore());
const {
  createPercentageBasedTransferRoutine,
} = useTransferStore();
const {
  handleRequest,
  isRequesting,
} = useRequest();

const {
  routineTemplate,
} = toRefs(props);

const viewState = ref<TransferAccountSelectViewState | null>(null);

// TODO: extract into useRoutineForm composable along with portal and view state?
const form = ref(getInitialForm());
defineExpose({
  form,
});

function getInitialForm ():
  NullableValues<PercentageBasedTransferRoutineOptions> &
  Required<
    Pick<PercentageBasedTransferRoutineOptions,
    "target" |
    "cronExpression" |
    "initiationType" |
    "channels"
    >>
{
  const query = routineTemplate.value?.route.query;

  return {
    sourceAccountId: query?.from?.toString() || null,
    destinationAccountId: query?.to?.toString() || null,
    percent: query?.percent ? parseFloat(String(query?.percent)) : null,
    target: query?.target?.toString() === PercentageBasedFundsTransferRoutineTarget.AccountBalance
      ? PercentageBasedFundsTransferRoutineTarget.AccountBalance
      : PercentageBasedFundsTransferRoutineTarget.Income,
    cronExpression: query?.cron ? String(query.cron) : "0 0 8 ? * MON",
    initiationType: AutomatedTransferInitiationType.Manual,
    channels: [MessageChannel.Timeline, MessageChannel.Email],
  };
}

const validatedForm = computed((): PercentageBasedTransferRoutineOptions | void => {
  if (
    !form.value.sourceAccountId
    || !form.value.destinationAccountId
    || !form.value.percent
    || !form.value.target
  ) return;

  return {
    sourceAccountId: form.value.sourceAccountId,
    destinationAccountId: form.value.destinationAccountId,
    percent: form.value.percent,
    target: form.value.target,
    cronExpression: form.value.cronExpression,
    initiationType: form.value.initiationType,
    channels: form.value.channels,
  };
});

async function handleSubmit () {
  if (!validatedForm.value) return;

  const [err] = await handleRequest(createPercentageBasedTransferRoutine(validatedForm.value), {
    showOnlyErrors: true,
    successMessage: "Your routine has been created.",
  });

  if (!err) {
    router.push({
      name: RouteName.routinesYours,
    });
  }
}
</script>

<style lang="scss" scoped>
.PercentageBasedTransferRoutineFormView__screen {
  display: grid;
  grid: min-content auto / auto;
}

.PercentageBasedTransferRoutineFormView__form {
  // TODO: figure out a better way to do this
  > :deep(fieldset) {
    display: grid;
    grid: min-content auto min-content / auto;
    height: 100%;
  }
}

.PercentageBasedTransferRoutineFormView__scrollContainer {
  border-top: 1px solid var(--colorSwissGrey100);
}

.PercentageBasedTransferRoutineFormView__scrollContainerFieldset {
  display: grid;
  gap: 1px;
}

.PercentageBasedTransferRoutineFormView__header {
  display: grid;
  gap: 20px;
  padding: 24px var(--layoutMargin) 8px;
}

.PercentageBasedTransferRoutineFormView__title {
  text-wrap: balance;
}

.PercentageBasedTransferRoutineFormView__percentInput {
  margin-bottom: 16px;
}

.PercentageBasedTransferRoutineFormView__formButtonContainer {
  // TODO: extract
  @media (display-mode: standalone) {
    [data-js-mobile-keyboard-state="closed"] & {
      padding-bottom: calc(24px + var(--viewportPaddingBottom));
    }
  }
}
</style>

