import useApiClient from "@cosine/composables/useApiClient";
import { AccountScope, AccountType, BackgroundJobStatus, BackgroundJobType, IApiResponse, IEnrichedAssetPositionModel, IPortfolioModel, IUpdateBlueprintSubscriptionAllocationsCommand, PositionType, ReferenceTypes } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

import useFinancialStore from "@cosine/stores/useFinancialStore";
import useBackgroundJobStore from "@cosine/stores/useBackgroundJobStore";
import { max } from "date-fns";
import parseISO from "date-fns/parseISO";
import isTruthy from "@cosine/lib/utils/boolean/isTruthy";

const positionOrder = [
  // sorted by likelihood of owning with assets at the bottom ¯\_(ツ)_/¯
  PositionType.CommonStock,
  PositionType.ETF,
  PositionType.OpenEndedFund,
  PositionType.CloseEndedFund,
  PositionType.Bond,
  PositionType.PreferredStock,
  PositionType.Cryptocurrency,
  PositionType.RealEstate,
  PositionType.Automobile,
  // unsure about these…
  PositionType.AmericanDepositaryReceipt,
  PositionType.GlobalDepositaryReceipt,
  PositionType.Right,
  PositionType.StructuredProduct,
  PositionType.Temporary,
  PositionType.Unit,
  PositionType.Warrant,
  PositionType.New,
  PositionType.Undefined,
  PositionType.DelistedDefunct,
];

export default defineStore("InvestingStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const financialStore = useFinancialStore();
  const {
    backgroundJobMap,
  } = storeToRefs(useBackgroundJobStore());
  const {
    accounts,
    isSyncingAccounts,
    accountsSyncDate,
  } = storeToRefs(financialStore);

  const isWaitingForInvestmentAccounts = ref(false);
  const portfolios = ref<Array<IPortfolioModel>>([]);
  const positions = ref<Array<IEnrichedAssetPositionModel>>([]);

  const investmentAccounts = computed(() => {
    return accounts.value.filter((account) => {
      return account.Type === AccountType.Investment
        && account.SourceReferences.some((source) => source.ReferenceType === ReferenceTypes.SnapTradeAccountProjection);
    });
  });

  const activeInvestmentAccounts = computed(() => {
    return investmentAccounts.value.filter((account) => {
      return account.Scope === AccountScope.Normal;
    });
  });

  const investmentMixSubscriptions = computed(() => {
    return portfolios.value.flatMap((portfolio) => {
      return portfolio.Subscriptions;
    });
  });

  const sortedPositions = computed(() => {
    return [...positions.value].sort((a, b) => {
      const aIndex = 1 + positionOrder.indexOf(a.Type);
      const bIndex = 1 + positionOrder.indexOf(b.Type);

      const compare = aIndex - bIndex;
      if (compare === 0) return a.Symbol.localeCompare(b.Symbol);

      return compare;
    });
  });

  const isSyncingInvestmentAccounts = computed(() => {
    return isWaitingForInvestmentAccounts.value || isSyncingAccounts.value;
  });

  const isSyncingPositions = computed(() => {
    return backgroundJobMap.value.get(BackgroundJobType.EnrichedPositionsSync)?.Status === BackgroundJobStatus.InProgress;
  });

  const isSyncingInvestments = computed(() => {
    return isSyncingInvestmentAccounts.value || isSyncingPositions.value;
  });

  const investmentsSyncDate = computed(() => {
    const positionsDatetime = backgroundJobMap.value.get(BackgroundJobType.EnrichedPositionsSync)?.LastProcessedDate;

    const dates = [accountsSyncDate.value, positionsDatetime ? parseISO(positionsDatetime) : null].filter(isTruthy);
    if (dates.length === 0) return undefined;

    return max(dates);
  });

  watch(isSyncingPositions, () => {
    if (!isSyncingPositions.value) {
      try {
        fetchPositions();
      } catch {
        return;
      }
    }
  });

  function waitForInvestmentAccounts () {
    isWaitingForInvestmentAccounts.value = true;

    watch(investmentAccounts, (newAccounts, oldAccounts) => {
      if (newAccounts.length > oldAccounts.length) {
        isWaitingForInvestmentAccounts.value = false;
      }
    }, {
      once: true,
    });
  }

  async function fetchPortfolios () {
    const {
      data: {
        Result: _portfolios,
      },
    } = await apiClient.value.get<IApiResponse<Array<IPortfolioModel>>>("/investing/portfolios");

    portfolios.value = _portfolios || [];
  }

  async function fetchPositions () {
    const {
      data: {
        Result: _positions,
      },
    } = await apiClient.value.get<IApiResponse<Array<IEnrichedAssetPositionModel>>>("/finances/positions");

    positions.value = _positions || [];
  }

  async function subscribePortfolioToMix (portfolioId: string, mixId: string) {
    const params: IUpdateBlueprintSubscriptionAllocationsCommand = {
      Subscriptions: [
        {
          BlueprintId: mixId,
          TargetAllocation: 1, // TODO: support multiple Mixes
        },
      ],
    };

    const {
      data: {
        Result: updatedPortfolio,
      },
    } = await apiClient.value.put<IApiResponse<IPortfolioModel>>(`/investing/portfolios/${portfolioId}/allocations`, params);

    const existingPortfolio = portfolios.value.find((portfolio) => {
      return portfolioId === portfolio.PortfolioId;
    });

    if (existingPortfolio && updatedPortfolio) {
      return Object.assign(existingPortfolio, updatedPortfolio);
    }

    return updatedPortfolio;
  }

  return {
    investmentAccounts,
    activeInvestmentAccounts,
    portfolios,
    investmentMixSubscriptions,
    positions: sortedPositions,
    isSyncingInvestmentAccounts,
    isSyncingPositions,
    isSyncingInvestments,
    isWaitingForInvestmentAccounts,
    investmentsSyncDate,

    waitForInvestmentAccounts,
    fetchPortfolios,
    fetchPositions,
    subscribePortfolioToMix,
  };
});
