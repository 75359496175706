<template>
  <Transition
    @beforeEnter="isTransitioning = true"
    @afterEnter="isTransitioning = false"
    @beforeLeave="isTransitioning = true"
    @afterLeave="isTransitioning = false"
  >
    <ThreadModalView
      v-if="isNewThreadModalOpen || timelineEntryId"
      :key="timelineEntryId || 'new'"
      v-bind="{
        timelineEntryId,
        threadVisibility,
        isTransitioning,
      }"
      @focus="handleFocus"
      @close="closeActiveModal"
    />
  </Transition>
</template>

<script lang="ts" setup>
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import { ThreadVisibility } from "@cosine/composables/useThread";
import { RouteName } from "@cosine/routes";
import ThreadModalView from "@cosine/views/ThreadModalView.vue";
import { computed, ref } from "vue";

const {
  openModal,
  closeActiveModal,
} = useModalRouter();
const {
  isModalOpen: isNewThreadModalOpen,
} = useModalRoute(RouteName.threadsNew);
const {
  modalRoute: threadModalRoute,
  isModalOpen: isThreadModalOpen,
} = useModalRoute(RouteName.threadsView);
const {
  modalRoute: articleModalRoute,
} = useModalRoute(RouteName.articlesView);

const isTransitioning = ref(false);

const timelineEntryId = computed(() => {
  if (threadModalRoute.value) return threadModalRoute.value.params.id.toString();
  if (articleModalRoute.value) return articleModalRoute.value.params.id.toString();

  return null;
});

const threadVisibility = computed(() => {
  if (isNewThreadModalOpen.value || isThreadModalOpen.value) return ThreadVisibility.Thread;

  return ThreadVisibility.Input;
});

function handleFocus () {
  if (!isThreadModalOpen.value && timelineEntryId.value) {
    openModal({
      name: RouteName.threadsView,
      params: {
        id: timelineEntryId.value,
      },
    });
  }
}
</script>
