<template>
  <article
    class="TimelineEntry"
    :class="{
      [`TimelineEntry--source${sourceSlug}`]: true,
      'TimelineEntry--isPinned': isPinned,
      'TimelineEntry--isRoutine': isRoutine,
      'TimelineEntry--hasBackground': hasBackground,
    }"
    data-sentry-unmask
    @click="$emit('click', modelValue)"
  >
    <div class="TimelineEntry__tagContainer">
      <TimelineEntryIconTag
        class="TimelineEntry__tag"
        :entry="modelValue"
      />
    </div>

    <TimelineEntryImage
      v-if="!isPinned && modelValue.ImageUrls.length > 0"
      class="TimelineEntry__image"
      :src="modelValue.ImageUrls[0]"
    />

    <TimelineCalloutTitle
      v-if="includeTitleCallouts"
      class="TimelineEntry__title"
      :size="titleSize"
      :title="modelValue.Title"
      :callouts="modelValue.TitleCallouts"
    />
    <UIText
      v-else
      class="TimelineEntry__title TimelineEntry__title--withoutCallouts"
      tag="h3"
      :size="titleSize"
      weight="Medium"
      isMultiline
    >
      {{ modelValue.Title }}
    </UIText>

    <UIText
      v-if="!isPinned"
      class="TimelineEntry__body"
      tag="p"
      isMultiline
    >
      {{ modelValue.Subtitle }}
    </UIText>

    <footer class="TimelineEntry__footer">
      <UIText
        class="TimelineEntry__timestamp"
        size="2XSmall"
        weight="Medium"
      >
        <Timestamp
          :datetime="modelValue.DateForDisplay"
        />
      </UIText>

      <div class="TimelineEntry__footerButtonList">
        <slot name="buttonList" />
      </div>
    </footer>
  </article>
</template>

<script lang="ts" setup>
import TimelineCalloutTitle from "@cosine/components/TimelineCalloutTitle.vue";
import TimelineEntryIconTag from "@cosine/components/TimelineEntryIconTag.vue";
import TimelineEntryImage from "@cosine/components/TimelineEntryImage.vue";
import Timestamp from "@cosine/components/Timestamp.vue";
import UIText from "@cosine/components/UIText.vue";
import { IClientTimelineEntry, TimelineEntryFlag, TimelineEntrySources } from "@cosine/types/api-models";
import { TextSize } from "@shared/components/Text.types";
import { computed, toRefs } from "vue";

defineEmits<{
  menu: [IClientTimelineEntry],
  click: [IClientTimelineEntry],
}>();

const props = withDefaults(defineProps<{
  modelValue: IClientTimelineEntry,
  isPinned?: boolean,
}>(), {
  isPinned: false,
});

const {
  modelValue,
  isPinned,
} = toRefs(props);

const isRoutine = computed(() => modelValue.value.Flags.includes(TimelineEntryFlag.Routine));
const hasBackground = computed((): boolean => {
  switch (modelValue.value.Source) {
    case TimelineEntrySources.Announcement:
    case TimelineEntrySources.User:
    case TimelineEntrySources.AccountConnectionStatus:
      return true;
  }

  return false;
});

const sourceSlug = computed((): string => modelValue.value.Source.replace(":", ""));
const titleSize = computed((): keyof typeof TextSize => {
  if (isPinned.value) return "Large";

  return modelValue.value.Source === TimelineEntrySources.System_PeriodicBrief
    ? "XLarge"
    : "2XLarge";
});
const includeTitleCallouts = computed(() => {
  return [TimelineEntrySources.User, TimelineEntrySources.RoutineMessage].includes(modelValue.value.Source);
});
</script>

<style lang="scss" scoped>
.TimelineEntry {
  --tagIconColor: var(--colorSwissTeal300);

  position: relative;

  display: grid;
  grid: min-content auto min-content / auto;
  align-items: center;
  padding: 20px 0 16px;
  overflow: hidden;

  background: var(--colorSwissGrey50);
  color: var(--colorSwissBlack);

  cursor: pointer;
}

.TimelineEntry:not(.TimelineEntry--isPinned) {
  &.TimelineEntry--hasBackground {
    & + & {
      border-top: 1px solid var(--colorSwissAlphaBlack100);
    }
  }

  &:not(.TimelineEntry--hasBackground) {
    & + & {
      border-top: 1px solid var(--colorSwissGrey100);
    }
  }
}

.TimelineEntry--isPinned {
  padding-block: 12px;

  &:not(.TimelineEntry--hasBackground) {
    border-bottom: 1px solid var(--colorSwissGrey100);

    & + & {
      border-left: 1px solid var(--colorSwissGrey100);
    }
  }
}

.TimelineEntry--sourceAnnouncement {
  --tagIconColor: var(--colorSwissGrey100);

  background: var(--colorSwissBlack);
  color: var(--colorSwissGrey50);
}

.TimelineEntry--sourceUser {
  --tagIconColor: var(--colorSwissTeal300);

  background: var(--colorSwissGrey100);
  color: var(--colorSwissBlack);
}

.TimelineEntry--sourceAccountConnectionStatus {
  --tagIconColor: var(--colorSwissBlack);

  background: var(--colorSwissYellow300);
  color: var(--colorSwissBlack);
}

.TimelineEntry__tagContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin: 0 var(--layoutMargin) 16px;
}

.TimelineEntry__tag.TimelineEntryIconTag {
  --iconColor: var(--tagIconColor);
}

.TimelineEntry__image {
  width: calc(100% - var(--layoutMargin));
  margin: 0 var(--layoutMargin) 16px;
}

.TimelineEntry__title--withoutCallouts {
  margin: 0 var(--layoutMargin);
}

.TimelineEntry__body {
  margin: 12px var(--layoutMargin) 0;
  color: var(--colorSwissAlphaBlack600);
}

.TimelineEntry__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin: 10px var(--layoutMargin) 0;
}

.TimelineEntry__footerButtonList {
  display: flex;
  margin: -8px;
}
</style>
