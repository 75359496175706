<template>
  <SheetScreen
    class="ConnectBrokerageView"
    :zIndex="1"
    leaveDirection="Left"
  >
    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
    <div
      ref="container"
      class="ConnectBrokerageView__portal"
    />
  </SheetScreen>
</template>

<script lang="ts" setup>
import SheetScreen from "@cosine/components/SheetScreen.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import useSnaptradeConnect from "@cosine/composables/useSnaptradeConnect";
import { RouteName } from "@cosine/routes";
import useInvestingStore from "@cosine/stores/useInvestingStore";
import { onBeforeUnmount, onMounted, ref } from "vue";

// TODO: test

const investingStore = useInvestingStore();
const container = ref<HTMLElement | undefined>();
const {
  waitForInvestmentAccounts,
} = investingStore;

const {
  closeActiveModal,
} = useModalRouter();
const {
  modalRoute,
} = useModalRoute(RouteName.investingConnectBrokerage);

const {
  mountBrokerageConnect,
  unmountBrokerageConnect,
  onBrokerageConnected,
  onExit,
} = useSnaptradeConnect(container);

const {
  handleRequest,
  isRequesting,
} = useRequest();

onMounted( () => {
  if (!modalRoute.value) return;

  handleRequest(mountBrokerageConnect(modalRoute.value.params.slug as string));
});

onBeforeUnmount(() => {
  unmountBrokerageConnect();
});

onBrokerageConnected(() => {
  waitForInvestmentAccounts();
  closeActiveModal();
});

onExit(closeActiveModal);
</script>

<style lang="scss" scoped>
.ConnectBrokerageView {
  --backgroundColor: var(--colorWhite);

  width: 100%;
  height: 100%;
}

.ConnectBrokerageView:deep(iframe) {
  max-width: 400px;
  margin: auto;

  border: none;
  background-color: transparent;
}

.ConnectBrokerageView__portal {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
}
</style>
