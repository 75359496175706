<template>
  <SheetScreen
    class="TransactionCategorySelectScreenView"
    title="Categories"
    :zIndex="1"
    @click:back="$emit('click:back')"
  >
    <Form
      class="TransactionCategorySelectScreenView__form"
      :isDisabled="isSaving"
      @submit="$emit('submit', form)"
    >
      <ScrollContainer
        class="TransactionCategorySelectScreenView__scrollContainer"
        hasBottomFade
      >
        <CheckboxListFieldset
          v-model="form.categories"
          v-bind="{
            items,
          }"
        />
      </ScrollContainer>

      <div class="TransactionCategorySelectScreenView__formButtonContainer">
        <FormButton
          variant="Primary"
          type="submit"
        >
          <span v-if="isSaving">Saving…</span>
          <span v-else>Save</span>
        </FormButton>
      </div>
    </Form>
  </SheetScreen>
</template>

<script lang="ts" setup>
import { CheckboxListFieldsetItem } from "@cosine/components/CheckboxListFieldset.types";
import CheckboxListFieldset from "@cosine/components/CheckboxListFieldset.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import { TransactionCategory } from "@cosine/stores/useTransactionStore.types";
import { TransactionCategorySelectViewForm } from "@cosine/views/TransactionCategorySelectView.types";
import { computed, ref, toRefs, watch } from "vue";

defineEmits<{
  submit: [TransactionCategorySelectViewForm],
  "click:back": [],
}>();

const props = withDefaults(defineProps<{
  categories: Array<string>,
  isSaving?: boolean,
}>(), {
  isSaving: false,
});
const {
  categories,
} = toRefs(props);

const form = ref(getInitialForm());

watch(categories, () => {
  form.value = getInitialForm();
});

const itemMap = computed(() => {
  return Object.values(TransactionCategory).reduce((acc, subcategoryCode) => {
    const [
      _,
      category,
      subcategory,
    ] = subcategoryCode.match(/\[([^\]]+)\]-\[([^\]]+)\]/) || [];
    const existingCategory = acc[category];

    if (category === subcategory) return acc;

    if (!existingCategory) {
      acc[category] = {
        label: category,
        value: `[${category}]-[${category}]`,
        subitems: [
          {
            label: subcategory,
            value: subcategoryCode,
          },
        ],
      };
    } else {
      existingCategory.subitems?.push({
        label: subcategory,
        value: subcategoryCode,
      });
    }

    return acc;
  }, {} as {[key: string]: CheckboxListFieldsetItem });
});

const items = computed((): Array<CheckboxListFieldsetItem> => {
  return Object.values(itemMap.value);
});

function getInitialForm (): TransactionCategorySelectViewForm {
  return {
    categories: [...categories.value],
  };
}
</script>

<style lang="scss" scoped>
.TransactionCategorySelectScreenView {
  display: grid;
  grid: min-content auto / auto;
}

.TransactionCategorySelectScreenView__form :deep(fieldset) {
  display: grid;
  grid: auto min-content / auto;
  height: 100%;
}

.TransactionCategorySelectScreenView__formButtonContainer {
  padding: 24px var(--layoutMargin);
}
</style>
