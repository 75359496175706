export enum ModalName {
  ConnectAccounts = "ConnectAccounts",
  ConnectBrokerage = "ConnectBrokerage",
  EditAccount = "EditAccount",
  EditBudgetDefinition = "EditBudgetDefinition",
  EditRoutine = "EditRoutine",
  EditTimelineEntry = "EditTimelineEntry",
  EditTransaction = "EditTransaction",
  InitiateTransfer = "InitiateTransfer",
  UpgradeSubscription = "UpgradeSubscription",
  ViewArticle = "ViewArticle",
}
