<template>
  <li
    class="FinancialTransactionItem"
    :class="{
      'FinancialTransactionItem--isPending': isPending
    }"
  >
    <Icon
      v-if="iconName"
      :name="iconName"
    />

    <div class="FinancialTransactionItem__header">
      <UIText
        class="FinancialTransactionItem__description"
        tag="h3"
        size="Small"
        weight="Medium"
        :title="coalescedDescription"
      >
        {{ coalescedDescription }}
      </UIText>
      <UIText
        class="FinancialTransactionItem__amount"
        tag="strong"
        size="Medium"
        weight="Medium"
      >
        {{ formattedAmount }}
      </UIText>
    </div>

    <div class="FinancialTransactionItem__footer">
      <UIText
        class="FinancialTransactionItem__footerLeading"
        size="2XSmall"
        weight="Medium"
      >
        <span
          v-if="isPending"
          data-testid="status"
        >
          (Pending)
        </span>

        <AccountLabel
          :modelValue="account"
        />
      </UIText>

      <UIText
        class="FinancialTransactionItem__category"
        size="2XSmall"
        weight="Medium"
        :title="formattedCategory"
      >
        {{ formattedCategory }}
      </UIText>
    </div>
  </li>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import { IconName } from "@cosine/components/Icon.types";
import Icon from "@cosine/components/Icon.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import formatCategory from "@cosine/lib/utils/financial/formatCategory";
import { TransactionCategory } from "@cosine/stores/useTransactionStore.types";
import { EnrichedTransactionStatus, IEnrichedAccountModel, IEnrichedTransactionModel, TransactionType } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  modelValue: IEnrichedTransactionModel,
  accountMap: Map<string, IEnrichedAccountModel>,
}>();
const {
  modelValue,
  accountMap,
} = toRefs(props);

const iconName = computed(() => {
  if (modelValue.value.PriorityCategory.includes("Transfer")) {
    return IconName.ArrowTransfer;
  }

  switch (modelValue.value.Type) {
    case TransactionType.Credit:
      return IconName.ArrowInward;
    case TransactionType.Debit:
      return IconName.ArrowOutward;
    default:
      return null;
  }
});

const coalescedDescription = computed(() => modelValue.value.EnrichedDescription || modelValue.value.OriginalDescription);
const formattedCategory = computed(() => formatCategory(modelValue.value.PriorityCategory as TransactionCategory));
const formattedAmount = computed(() => formatAmount(modelValue.value.Amount));
const account = computed(() => accountMap.value.get(modelValue.value.EraAccountId) || null);
const isPending = computed(() => [EnrichedTransactionStatus.Pending, EnrichedTransactionStatus.ReversePending].includes(modelValue.value.Status));
</script>

<style lang="scss" scoped>
.FinancialTransactionItem {
  display: grid;
  grid: auto / min-content auto;
  gap: 2px 16px;
  align-items: center;
  padding: 12px 16px 16px;

  background-color: var(--themeColorBackgroundSurface);

  border-bottom: solid 1px var(--themeColorBorder);
  white-space: nowrap;
  cursor: pointer;
}

.FinancialTransactionItem__header {
  display: grid;
  grid: auto / auto min-content;
  gap: 16px;
}

.FinancialTransactionItem__amount {
  text-align: right;
}

.FinancialTransactionItem__footer {
  display: grid;
  grid: auto / auto min-content;
  grid-column-start: 2;

  color: var(--themeColorTextSubdued);
}

.FinancialTransactionItem__footerLeading {
  display: flex;
  gap: 6px;
}

.FinancialTransactionItem__category {
  grid-column: -1;
  text-align: right;
}

.FinancialTransactionItem__description,
.FinancialTransactionItem__category {
  overflow: hidden;
  text-overflow: ellipsis;
}

.FinancialTransactionItem--isPending {
  opacity: 0.5;
}
</style>
