<template>
  <SheetModal
    class="TransactionSheetModalView"
    theme="Light"
    @close="closeActiveModal"
  >
    <template
      v-if="transaction"
      #summary
    >
      <TransactionSheetSummary
        v-bind="{transaction}"
      />
    </template>
    <template
      v-if="transaction"
      #form
    >
      <TransactionForm
        :isDisabled="isSavingTransaction"
        v-bind="{transaction}"
        @submit="handleSubmit"
      />
    </template>
  </SheetModal>
</template>

<script lang="ts" setup>
import SheetModal from "@cosine/components/SheetModal.vue";
import TransactionForm from "@cosine/components/TransactionForm.vue";
import TransactionSheetSummary from "@cosine/components/TransactionSheetSummary.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import useTransactionStore from "@cosine/stores/useTransactionStore";
import { IEnrichedTransactionModel } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

const {
  transactionId,
} = defineProps<{
  transactionId: string,
}>();

const {
  closeActiveModal,
} = useModalRouter();

const {
  handleRequest,
  isRequesting: isSavingTransaction,
} = useRequest();

const transactionStore = useTransactionStore();
const {
  fetchTransaction,
  updateTransaction,
} = transactionStore;
const {
  transactions,
} = storeToRefs(transactionStore);

const fetchedTransaction = ref<IEnrichedTransactionModel | null>(null);

const transaction = computed(() => {
  return fetchedTransaction.value || transactions.value.find((transaction) => transaction.EraTransactionId === transactionId);
});

watch(() => transactionId, async () => {
  fetchedTransaction.value = null;

  if (!transaction.value) {
    const [, _transaction] = await handleRequest(fetchTransaction(transactionId), {
      showOnlyErrors: true,
    });

    fetchedTransaction.value = _transaction;
  }
}, {
  immediate: true,
});

async function handleSubmit (form: { category: string }) {
  // TODO: confirm when closing the modal while saving
  if (!transaction.value) return;

  await handleRequest(updateTransaction({
    id: transaction.value.EraTransactionId,
    category: form.category,
  }), {
    showAlerts: true,
  });
}
</script>

<style lang="scss" scoped>
.TransactionSheetModalView {
  z-index: var(--zIndexPriorityModal);
}
</style>
