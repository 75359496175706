<template>
  <section class="TransactionsListView">
    <InfiniteScroll
      class="TransactionsListView__list"
      scrollViewport="Mobile"
      v-bind="{ pagination }"
      includeViewportPadding="bottom"
      ref="scrollRef"
      @scrollEnd="handleScrollEnd"
    >
      <template v-if="transactions.length > 0">
        <SyncIndicator
          v-if="!isFiltering"
          :isSyncing="isSyncingFinances"
          itemName="transactions"
          :lastUpdatedDate="financesSyncDate"
          v-bind="{scrollElementRef}"
        />

        <GroupedList>
          <LabeledList
            v-for="list in lists"
            :key="list.title"
            :title="list.title"
          >
            <FinancialTransactionItem
              v-for="transaction in list.items"
              :key="transaction.EraTransactionId"
              :modelValue="transaction"
              v-bind="{ accountMap }"
              @click="handleTransactionClick(transaction)"
            />
          </LabeledList>
        </GroupedList>
      </template>

      <GetStartedView v-else-if="!isRequesting && accountMap.size === 0" />
    </InfiniteScroll>
  </section>
</template>

<script lang="ts" setup>
import FinancialTransactionItem from "@cosine/components/FinancialTransactionItem.vue";
import GroupedList from "@cosine/components/GroupedList.vue";
import InfiniteScroll from "@cosine/components/InfiniteScroll.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import SyncIndicator from "@cosine/components/SyncIndicator.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import groupByDate from "@cosine/lib/utils/collection/groupByDate";
import getDateForTransaction from "@cosine/lib/utils/financial/getDateForTransaction";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useTransactionStore from "@cosine/stores/useTransactionStore";
import { IEnrichedTransactionModel } from "@cosine/types/api-models";
import GetStartedView from "@cosine/views/GetStartedView.vue";
import { storeToRefs } from "pinia";
import { computed, onMounted, useTemplateRef, watch } from "vue";
import { useRoute } from "vue-router";

const scrollContainerRef = useTemplateRef("scrollRef");

const route = useRoute();
const {
  openModal,
} = useModalRouter();

const financialStore = useFinancialStore();
const {
  fetchAccounts,
} = financialStore;
const {
  accountMap,
  isSyncingFinances,
  financesSyncDate,
} = storeToRefs(financialStore);

const transactionStore = useTransactionStore();
const {
  setFilter,
  fetchTransactions,
} = transactionStore;
const {
  transactions,
  filter,
  pagination,
} = storeToRefs(transactionStore);

const {
  isRequesting,
  handleRequest,
} = useRequest();

useAppSpinner(isRequesting);

const isFiltering = computed(() => Object.values(filter.value).some((value) => value !== undefined));

const scrollElementRef = computed(() => scrollContainerRef.value?.scrollRef);

watch(filter, () => {
  if (isRequesting.value) return;

  handleRequest(fetchTransactions());
});

watch(isSyncingFinances, () => {
  if (!isSyncingFinances.value) {
    handleRequest(fetchTransactions());
  }
});

onMounted(async () => {
  // TODO: consider moving this to top level view since it applies to the filter view as well
  await setFilter({
    accountIds: route.query.account && !Array.isArray(route.query.account)
      ? [route.query.account]
      : undefined,
    budgetInstanceId: route.query.budget && !Array.isArray(route.query.budget)
      ? route.query.budget
      : undefined,
  });

  if (accountMap.value.size === 0) {
    await handleRequest(fetchAccounts());
  }

  await handleRequest(fetchTransactions());
});

const lists = computed(() => {
  return groupByDate(transactions.value, (transaction) => getDateForTransaction(transaction));
});

async function handleScrollEnd () {
  if (isRequesting.value) return;

  await handleRequest(fetchTransactions({
    pageIndex: pagination.value.pageIndex + 1,
  }));
}

function handleTransactionClick (transaction: IEnrichedTransactionModel) {
  openModal({
    name: RouteName.transactionsView,
    params: {
      id: transaction.EraTransactionId,
    },
  });
}
</script>

<style lang="scss" scoped>
.TransactionsListView {
  position: relative;
  display: grid;
  align-content: flex-start;
}

.TransactionsListView__list {
  @media (max-width: 599px) {
    position: absolute;
    inset: 0;
  }
}

.TransactionsListView__connectAccountsCard {
  align-self: flex-start;
}
</style>
