<template>
  <SelectInput
    v-bind="{ modelValue, ...$attrs }"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <option
      value=""
      disabled
    >
      Select a country
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </SelectInput>
</template>

<script lang="ts" setup>
import SelectInput from "@cosine/components/SelectInput.vue";
import { Countries } from "@cosine/lib/constants/countries";
import { computed } from "vue";

defineEmits<{
  "update:modelValue": [string],
}>();

defineProps<{
  modelValue?: string,
}>();

const options = computed((): Array<{
  label: string,
  value: string,
}> => {
  return Object.entries(Countries).sort(([, countryA], [, countryB]) => {
    return countryA.localeCompare(countryB);
  })
    .map(([countryKey, countryName]) => {
      return {
        label: countryName,
        value: countryKey,
      };
    });
});
</script>
