<template>
  <section class="AlertView">
    <TransitionGroup
      class="AlertView__list"
      tag="div"
    >
      <ToastAlert
        v-for="alert in alerts"
        :key="alert.id"
        class="AlertView__toast"
        v-bind="alert"
        @click="hideAlert(alert)"
      />
    </TransitionGroup>
  </section>
</template>

<script lang="ts" setup>
import ToastAlert from "@cosine/components/ToastAlert.vue";
import useAlertStore from "@cosine/stores/useAlertStore";
import { storeToRefs } from "pinia";

const alertStore = useAlertStore();
const {
  hideAlert,
} = alertStore;
const {
  alerts,
} = storeToRefs(alertStore);
</script>

<style lang="scss" scoped>
.AlertView {
  --paddingTop: calc(8px + var(--viewportPaddingTop));

  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--zIndexPopover);

  width: var(--viewportWidth, 100%);

  pointer-events: none;
}

.AlertView__list {
  position: relative;

  width: 100%;
  height: var(--appHeaderHeight);
  max-width: var(--layoutMaxWidth);
  margin-inline: auto;

  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black var(--paddingTop),
    black 100%,
  );
}

.AlertView__toast {
  position: absolute;
  top: var(--paddingTop);
  left: 16px;
  right: 16px;

  transition: 250ms transform;
  pointer-events: auto;

  &.v-enter-active {
    transition-timing-function: var(--easeOutCubic);
  }

  &.v-leave-active {
    transition-timing-function: var(--easeInCubic);
  }

  &.v-enter-from,
  &.v-leave-to {
    transform: translateY(calc(-100% - 24px));
  }
}
</style>
