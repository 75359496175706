<template>
  <dl class="DescriptionList">
    <slot />
  </dl>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.DescriptionList {
  display: grid;
  gap: 1px;
}
</style>
