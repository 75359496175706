<template>
  <Transition>
    <div
      v-if="isVisible"
      class="LineSpinner"
    >
      <div class="LineSpinner__line" />
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";

const props = defineProps<{
  isVisible: boolean,
}>();

const {
  isVisible,
} = toRefs(props);
</script>

<style lang="scss" scoped>
@keyframes loading {
  0% {
    transform: translateX(-100%);
  }

  10% {
    transform: translateX(-100%);
  }

  90% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.LineSpinner {
  transition: opacity 400ms;

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}

.LineSpinner__line {
  position: relative;
  width: 100%;
  height: 2px;
  margin: 0 auto;
  max-width: var(--layoutMaxWidth);
  overflow: hidden;

  &::before {
    position: absolute;
    inset: 0;
    background-color: var(--colorSwissBlack);
    animation: 1200ms loading infinite alternate ease-in-out 400ms;
    transform: translateX(-100%);
    content: "";
  }
}
</style>
