<template>
  <section
    class="FinancialAccountItem"
    :class="{
      [`FinancialAccountItem--scope${modelValue.Scope}`]: true,
    }"
  >
    <div class="FinancialAccountItem__icon">
      <svg
        class="FinancialAccountItem__iconBackground"
      >
        <defs>
          <pattern
            id="boxIconPattern"
            class="BoxIcon__backgroundPattern"
            patternUnits="userSpaceOnUse"
            width="2"
            height="2"
            patternTransform="rotate(115)"
          >
            <path
              d="M0,1 L2,1"
              stroke="var(--colorSwissAlphaBlack700)"
            />
          </pattern>
        </defs>

        <rect
          width="40"
          height="40"
          fill="url(#boxIconPattern)"
        />
      </svg>

      <Icon
        :name="iconName"
      />
    </div>
    <header class="FinancialAccountItem__header">
      <UIText
        class="FinancialAccountItem__name"
        tag="h3"
        size="Small"
        weight="Medium"
        :title="modelValue.DisplayName"
      >
        {{ modelValue.DisplayName }}
      </UIText>
      <UIText
        v-if=" !includeEnableButton"
        class="FinancialAccountItem__balance"
        tag="strong"
        size="Medium"
        weight="Medium"
      >
        {{ balance }}
      </UIText>
    </header>

    <div class="FinancialAccountItem__footer">
      <UIText
        class="FinancialAccountItem__institution"
        size="2XSmall"
        weight="Medium"
      >
        {{ modelValue.InstitutionName }} <span class="FinancialAccountItem__bullet">&bull;</span> {{ modelValue.Nickname }}
      </UIText>

      <UIText
        v-if="includeSyncStatus && syncDate && !includeEnableButton"
        class="FinancialAccountItem__syncStatus"
        size="2XSmall"
        weight="Medium"
      >
        <template v-if="modelValue.ConnectionStatus === AccountConnectionStatus.Connected">
          <Icon
            class="FinancialAccountItem__syncStatusIcon--sync FinancialAccountItem__syncStatusIcon"
            name="Sync"
          /><Timestamp :datetime="syncDate" />
        </template>
        <template v-else>
          <Icon
            class="FinancialAccountItem__syncStatusIcon--disconnected FinancialAccountItem__syncStatusIcon"
            name="ExclamationDiamond"
          />Disconnected
        </template>
      </UIText>
    </div>

    <CtaButton
      v-if="includeEnableButton"
      class="FinancialAccountItem__enableButton"
      @click="$emit('click:enable', modelValue)"
    >
      Enable
    </CtaButton>
  </section>
</template>

<script lang="ts" setup>
import CtaButton from "@cosine/components/CtaButton.vue";
import { IconName } from "@cosine/components/Icon.types";
import Icon from "@cosine/components/Icon.vue";
import Timestamp from "@cosine/components/Timestamp.vue";
import UIText from "@cosine/components/UIText.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import obscureAmount from "@cosine/lib/utils/financial/obscureAmount";
import { AccountConnectionStatus, AccountScope, IEnrichedAccountModel } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

// TODO: extract pattern box

defineEmits<{
  "click:enable": [account:IEnrichedAccountModel],
}>();

const props = withDefaults(defineProps<{
  modelValue: IEnrichedAccountModel,
  includeSyncStatus?: boolean,
  includeEnableButton?: boolean,
}>(), {
  includeSyncStatus: false,
  includeEnableButton: false,
});
const {
  modelValue,
} = toRefs(props);

const iconName = computed((): IconName => {
  switch (modelValue.value.Type) {
    case "CreditCard":
      return IconName.CreditCard;
    case "Investment":
      return IconName.GraphLine;
    case "Loan":
      return IconName.MoneyMemo;
    case "Mortgage":
      return IconName.House;
    case "Checking":
      return IconName.Checkbook;
    case "Savings":
      return IconName.PiggyBank;
  }

  return IconName.Dollar;
});

const balance = computed(() => {
  const formattedBalance = formatAmount(modelValue.value.Balance);

  if (formattedBalance && modelValue.value.Scope === AccountScope.Limited) {
    return obscureAmount(formattedBalance);
  }

  return formattedBalance;
});

const syncDate = computed((): string | null => {
  return Object.values(modelValue.value.SyncedAtWithSources).reduce((maxDate, date) => {
    if (!maxDate || date > maxDate) return date;
  }, null) || modelValue.value.SyncedAt;
});
</script>

<style lang="scss" scoped>
.FinancialAccountItem {
  --dividerColor: var(--colorSwissGrey100);
  --borderColor: var(--colorSwissGrey100);
  --iconColor: var(--colorSwissBlack);
  --iconBackgroundColor: var(--colorWhite);

  position: relative;

  display: grid;
  grid: auto / min-content auto;
  gap: 2px 16px;
  align-items: center;
  padding: 16px var(--layoutMargin);

  white-space: nowrap;
  cursor: pointer;

  & + & {
    border-top: 1px solid var(--dividerColor);
  }

  .Theme--isColored & {
    --dividerColor: var(--colorSwissAlphaBlack200);
    --borderColor: var(--colorSwissBlack);
    --iconColor: var(--colorSwissBlack);
    --iconBackgroundColor: transparent;
  }
}

.FinancialAccountItem__icon {
  position: relative;

  display: flex;
  grid-row: span 2;
  justify-content: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;

  border: 1px solid var(--borderColor);
  background-color: var(--iconBackgroundColor);
}

.FinancialAccountItem__iconBackground {
  position: absolute;
  top: 0;
  left: 0;

  display: none;
  width: 100%;
  height: 100%;

  opacity: 0.5;

  .Theme--isColored & {
    display: block;
  }
}

.FinancialAccountItem__institution {
  display: flex;
  gap: 3px;
  align-items: center;
}

.FinancialAccountItem__name,
.FinancialAccountItem__institution {
  overflow: hidden;
  text-overflow: ellipsis;
}

.FinancialAccountItem__bullet {
  font-size: 8px;
}

.FinancialAccountItem__header {
  display: grid;
  grid: auto / auto min-content;
  gap: 16px;
}

.FinancialAccountItem__balance {
  text-align: right;

  .FinancialAccountItem--scopeLimited & {
    filter: blur(4px);
  }
}

.FinancialAccountItem__footer {
  --footerHeight: 17px;
  display: grid;
  grid: auto / auto min-content;
  grid-column-start: 2;
  gap: 16px;
  height: var(--footerHeight);

  color: var(--colorSwissAlphaBlack600);
}

.FinancialAccountItem__syncStatus {
  display: flex;
  gap: 3px;
  align-items: center;

  .FinancialAccountItem--scopeLimited & {
    filter: blur(4px);
  }
}

.FinancialAccountItem__syncStatusIcon {
  --iconSize: var(--footerHeight);

  width: var(--iconSize);
  height: var(--iconSize);
}

.FinancialAccountItem__syncStatusIcon--sync {
  --iconColor: var(--colorSwissAlphaBlack600);
}

.FinancialAccountItem__syncStatusIcon--disconnected {
  --iconColor: var(--colorSwissRed300);
}

.FinancialAccountItem__enableButton {
  grid-area: 1 / -1 / span 2 / -1;
  width: 80px;
  justify-self: flex-end;
}
</style>
