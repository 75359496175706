<template>
  <section class="FormButtonContainer">
    <slot />
  </section>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>
.FormButtonContainer {
  display: grid;
  grid-auto-flow: column;
  padding: 24px var(--layoutMargin) max(24px, 24px + var(--safeAreaInsetBottom));

  [data-js-mobile-keyboard-state="open"] & {
    padding: 24px var(--layoutMargin);
  }
}
</style>
