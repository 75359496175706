<template>
  <IconTag
    class="TimelineEntryIconTag"
    :iconName="shapeName"
    :label="formattedSource"
  />
</template>

<script lang="ts" setup>
import IconTag from "@cosine/components/IconTag.vue";
import { ShapeName } from "@cosine/components/ShapeIcon.types";
import { IClientTimelineEntry, TimelineEntrySources } from "@cosine/types/api-models";
import { computed, toRefs } from "vue";

const props = defineProps<{
  entry: IClientTimelineEntry,
}>();

const {
  entry,
} = toRefs(props);

const formattedSource = computed((): string => {
  switch (entry.value.Source) {
    case TimelineEntrySources.User:
      return "Chat";
    case TimelineEntrySources.AccountConnectionStatus:
      return "Connection";
    case TimelineEntrySources.Announcement:
      return "Announcement";
    case TimelineEntrySources.System:
      return "System";
    case TimelineEntrySources.RoutineMessage:
      return "Routine";
    case TimelineEntrySources.System_NewsItem:
    case TimelineEntrySources.System_PeriodicBrief:
      return entry.value.Tags[0] || "News";
    case TimelineEntrySources.FundsTransfer:
      return "Transfer";
    default:
      return entry.value.Source;
  }
});
const shapeName = computed((): ShapeName => {
  switch (entry.value.Source) {
    case TimelineEntrySources.User:
    case TimelineEntrySources.RoutineMessage:
    case TimelineEntrySources.Announcement:
      return ShapeName.Hexagon;
    case TimelineEntrySources.AccountConnectionStatus:
    case TimelineEntrySources.System:
      return ShapeName.Circle;
    case TimelineEntrySources.System_NewsItem:
    case TimelineEntrySources.System_PeriodicBrief:
      return ShapeName.Square;
    default:
      return ShapeName.Square;
  }
});
</script>

<style lang="scss" scoped>
.TimelineEntryIconTag {
  --iconColor: var(--colorSwissTeal300);
}
</style>
