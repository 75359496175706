<template>
  <SheetModal
    size="Fill"
    theme="White"
    @close="emit('close')"
  >
    <div class="EmailVerificationReminderModalView__layout">
      <div class="EmailVerificationReminderModalView__content">
        <UIText
          class="EmailVerificationReminderModalView__title"
          tag="h2"
          size="3XLarge"
          weight="Medium"
        >
          First, <strong>verify</strong> your <strong>email address</strong>
        </UIText>

        <UIText
          class="EmailVerificationReminderModalView__description"
          tag="p"
        >
          Before you can {{ action }}, we have to make sure you’re you.
        </UIText>

        <UIText
          class="EmailVerificationReminderModalView__instructions"
          tag="p"
        >
          Click the login button we sent to {{ userEmail }} and then come back here to pick up where you left off.
        </UIText>
      </div>

      <UIText
        class="EmailVerificationReminderModalView__footer"
        tag="footer"
        weight="Medium"
      >
        <p>Didn’t get the email?</p>
        <p>
          <button
            class="EmailVerificationReminderModalView__link"
            @click="handleResendMagicLinkEmail"
          >
            Resend
          </button>
        </p>
      </UIText>
    </div>
  </SheetModal>
</template>

<script lang="ts" setup>
import useAuthStore from "@cosine/stores/useAuthStore";
import useStytchStore from "@cosine/stores/useStytchStore";
import useRequest from "@cosine/composables/useRequest";
import useAuthConfig from "@cosine/composables/useAuthConfig";
import { computed } from "vue";
import UIText from "@cosine/components/UIText.vue";
import SheetModal from "@cosine/components/SheetModal.vue";

const emit = defineEmits<{
  close: [],
}>();

defineProps<{
  action: string,
}>();

const authStore = useAuthStore();
const {
  sendMagicLink,
} = useStytchStore();

const {
  handleRequest,
} = useRequest();

const {
  authenticateUrl,
} = useAuthConfig();

const userEmail = computed(() => authStore.currentUser?.Email);

async function handleResendMagicLinkEmail () {
  if (!authStore.currentUser || !userEmail.value) return;

  handleRequest(sendMagicLink(userEmail.value, authenticateUrl.value), {
    showAlerts: true,
    successMessage: "Verification email sent! Be sure to check your spam folder.",
  });
}
</script>

<style lang="scss" scoped>
.EmailVerificationReminderModalView__layout {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 72px 32px 32px;
  color: var(--colorSwissBlack);

  @media (width < 600px) {
    justify-content: space-between;
  }
}

.EmailVerificationReminderModalView__title {
  text-wrap: pretty;

  @media (width >= 600px) {
    font: var(--fontSaans4XLarge);
    font-weight: var(--fontWeightSaansMedium);
  }

  strong {
    position: relative;

    display: inline-block;
    margin: 0 4px;

    color: var(--themeColorBackgroundApp);

    font-weight: inherit;

    isolation: isolate;

    &::before {
      position: absolute;
      inset: 2px -4px -4px;
      z-index: -1;

      background-color: var(--colorSwissBlack);

      content: "";
    }
  }
}

.EmailVerificationReminderModalView__content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.EmailVerificationReminderModalView__footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.EmailVerificationReminderModalView__link {
  font: inherit;
  text-decoration: underline;
}
</style>
