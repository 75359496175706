<template>
  <SheetModal
    class="AccountSheetModalView"
    theme="Light"
    @close="closeActiveModal()"
  >
    <template
      v-if="account"
      #summary
    >
      <AccountSheetSummary
        v-bind="{account}"
        @click:reconnect="handleReconnect(account)"
      />
    </template>
    <template
      v-if="account"
      #buttonList
    >
      <SheetButton
        class="AccountSheetModalView__transactionsButton"
        iconName="Dollar"
        label="View transactions"
        :disabled="isRequesting"
        @click="handleViewTransactions"
      />
      <SheetButton
        class="AccountSheetModalView__scopeButton"
        :iconName="account.Scope === 'Normal' ? 'EyeCrossed' : 'Eye'"
        :label="account.Scope === 'Normal' ? 'Deactivate account' : 'Activate account'"
        :disabled="isRequesting || account.Scope === 'Limited' && !canActivateMoreAccounts"
        @click="handleToggleAccount"
      />
    </template>
  </SheetModal>
</template>

<script lang="ts" setup>
import AccountSheetSummary from "@cosine/components/AccountSheetSummary.vue";
import SheetButton from "@cosine/components/SheetButton.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { AccountScope, IEnrichedAccountModel, ReferenceTypes } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
  accountId: string,
}>();

const {
  accountId,
} = toRefs(props);

const router = useRouter();
const {
  openModal,
  closeActiveModal,
} = useModalRouter();

const financialStore = useFinancialStore();
const {
  accounts,
  canActivateMoreAccounts,
} = storeToRefs(financialStore);
const {
  updateAccountScope,
  fetchConnectWidgetUrl,
} = financialStore;

const {
  isRequesting,
  handleRequest,
} = useRequest();

// TODO: show spinner when fetching account if directly linking to account
const account = computed(() => {
  return accounts.value.find((account) => account.EraAccountId === accountId.value);
});

function getRouteNameForReferenceType (referenceType: ReferenceTypes): RouteName | null {
  switch (referenceType) {
    case ReferenceTypes.SnapTradeAccountProjection:
      return RouteName.investingReconnectAccount;
    case ReferenceTypes.MxAccountProjection:
      return RouteName.accountsReconnect;
    default:
      return null;
  }
}

function getReferenceTypeFromWidgetUrl (widgetUrl: string): ReferenceTypes | null {
  const url = new URL(widgetUrl);

  if (url.host.endsWith("moneydesktop.com")) return ReferenceTypes.MxAccountProjection;
  if (url.host.endsWith("snaptrade.com")) return ReferenceTypes.SnapTradeAccountProjection;

  return null;
}

async function getReferenceTypeForAccount (account: IEnrichedAccountModel): Promise<ReferenceTypes | null> {
  const singleReferenceType = account.SourceReferences.length === 1 && account.SourceReferences[0].ReferenceType || null;
  if (singleReferenceType) return singleReferenceType;

  const [, url] = await handleRequest(fetchConnectWidgetUrl(account.EraAccountId), {
    showOnlyErrors: true,
  });

  if (!url) return null;
  return getReferenceTypeFromWidgetUrl(url);
}

async function handleReconnect (account: IEnrichedAccountModel) {
  const referenceType = await getReferenceTypeForAccount(account);
  if (!referenceType) return;

  const routeName = getRouteNameForReferenceType(referenceType);
  if (!routeName) return;

  openModal({
    name: routeName,
    params: {
      id: account.EraAccountId,
    },
  });
}

function handleViewTransactions () {
  if (!account.value) return;

  router.push({
    name: RouteName.transactions,
    query: {
      account: account.value.EraAccountId,
    },
  });
}

async function handleToggleAccount () {
  if (!account.value) return;

  const scope = account.value.Scope === AccountScope.Normal
    ? AccountScope.Limited
    : AccountScope.Normal;

  await handleRequest(
    updateAccountScope(account.value, scope),
    {
      showAlerts: true,
    },
  );
}
</script>
