import { osInjectionKey, platformInjectionKey } from "@cosine/composables/useSystemDetection.types";
import { OS } from "@cosine/lib/utils/system/detectOS.types";
import { Platform } from "@cosine/lib/utils/system/detectPlatform.types";
import { inject } from "vue";

export default function () {
  const os = inject<OS>(osInjectionKey);
  const platform = inject<Platform>(platformInjectionKey);

  return {
    os,
    platform,
  };
}
