import { App as CapacitorApp, URLOpenListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";

export default function () {
  const router = useRouter();

  const handleDeepLink = (urlOpenEvent: URLOpenListenerEvent) => {
    const url = new URL(urlOpenEvent.url);
    router.push(url.pathname + url.search);
  };

  onMounted(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    CapacitorApp.addListener("appUrlOpen", handleDeepLink);
  });

  onUnmounted(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    CapacitorApp.removeAllListeners();
  });
}
