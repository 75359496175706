<template>
  <section class="TransferOnboardingView">
    <PromptPane
      v-if="!hasEnabledTransfers || !hasEnoughAccountsToTransfer"
      iconName="ArrowUpRightHexagon"
      iconColor="Grey"
      title="Make money moves"
      body="Easy, fee-free transfers between linked financial accounts. Coming soon: automated transfers using Routines."
    >
      <template #button>
        <FormButton
          v-if="!hasEnabledTransfers"
          variant="Primary"
          :disabled="isRequesting"
          @click="handleEnableTransfersClick"
        >
          <span v-if="isRequesting">
            Enabling transfers…
          </span>
          <span v-else>
            Enable transfers
          </span>
        </FormButton>

        <FormButton
          v-else
          variant="Primary"
          @click="modalRouter.openModal({ name: RouteName.accountsConnect })"
        >
          <template v-if="transferAccounts.length === 0">
            Link accounts
          </template>
          <template v-else>
            Link another account
          </template>
        </FormButton>
      </template>

      <template
        v-if="!hasEnabledTransfers"
        #disclaimer
      >
        By enabling transfers, you agree to our <a
          href="https://era.app/legal/privacy-policy"
          target="_blank"
        >privacy policy</a> and <a
          href="https://era.app/legal/terms-of-service"
          target="_blank"
        >terms of service</a>.
      </template>
    </PromptPane>

    <template v-if="hasEnabledTransfers">
      <SectionHeader
        v-if="transferAccounts.length > 0"
        title="Linked accounts"
      />
      <FinancialAccountList
        v-if="enabledTransferAccounts.length > 0"
        title="Transfer-ready"
        :subtitle="!hasEnoughAccountsToTransfer ? '1 more needed to initiate a transfer' : undefined"
        iconName="CheckSmall"
        iconColor="Teal"
        :accounts="enabledTransferAccounts"
      />
      <FinancialAccountList
        v-if="disabledTransferAccounts.length > 0"
        title="Needs instant verification"
        subtitle="Confirm these accounts to enable them for transfers"
        iconName="CheckSmall"
        :accounts="disabledTransferAccounts"
        includeEnableButton
        @click:enable="handleDisableAccountClick"
      />
      <FinancialAccountList
        v-if="unsupportedAccountsForTransfer.length > 0"
        title="Unsupported"
        subtitle="These accounts don’t support money transfers yet"
        iconName="X"
        :accounts="unsupportedAccountsForTransfer"
      />
    </template>
  </section>

  <Teleport :to="PortalId.Body">
    <Transition>
      <EmailVerificationReminderModalView
        v-if="isEmailVerificationModalOpen"
        action="enable transfers"
        @close="handleModalClose"
      />
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import FinancialAccountList from "@cosine/components/FinancialAccountList.vue";
import FormButton from "@cosine/components/FormButton.vue";
import PromptPane from "@cosine/components/PromptPane.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useTransferStore from "@cosine/stores/useTransferStore";
import { storeToRefs } from "pinia";
import EmailVerificationReminderModalView from "./EmailVerificationReminderModalView.vue";
import useAuthStore from "@cosine/stores/useAuthStore";
import { ref } from "vue";
import { PortalId } from "@cosine/components/Portal.types";

const modalRouter = useModalRouter();

const transferStore = useTransferStore();
const {
  transferAccounts,
  enabledTransferAccounts,
  disabledTransferAccounts,
  unsupportedAccountsForTransfer,
  hasEnabledTransfers,
  hasEnoughAccountsToTransfer,
} = storeToRefs(transferStore);
const {
  enableTransfers,
} = transferStore;

const {
  isRequesting,
  handleRequest,
} = useRequest();

const {
  isCurrentUserVerified,
} = storeToRefs(useAuthStore());

const isEmailVerificationModalOpen = ref(false);

function handleEnableTransfersClick () {
  if (!isCurrentUserVerified.value) {
    isEmailVerificationModalOpen.value = true;
    return;
  }

  handleRequest(enableTransfers(), {
    showOnlyErrors: true,
  });
}

function handleModalClose () {
  isEmailVerificationModalOpen.value = false;
}

function handleDisableAccountClick () {
  modalRouter.openModal({
    name: RouteName.accountsVerify,
  });
}
</script>

