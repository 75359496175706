import { CapacitorAppConfig, CapacitorPlatformConfig, CapacitorPlatform } from "./capacitor-app.types";
import config from "./app.json";

export function getTeamId (): string {
  return config.team_id;
}

export function getAppId (
  environment: string,
  platform: keyof typeof CapacitorPlatform,
): string
{
  const envConfig = config[environment] as CapacitorAppConfig;
  if (!envConfig) return config.development[platform].app_id;

  return (envConfig[platform] as CapacitorPlatformConfig)?.app_id;
}

export function getServer (environment: string): string | undefined {
  return (config[environment] as CapacitorAppConfig)?.server;
}

export function getiOSScheme (environment: string): string {
  return ((config[environment] as CapacitorAppConfig)?.ios?.scheme || config.development.ios.scheme as string);
}

export function getAndroidFlavor (environment: string): string {
  return ((config[environment] as CapacitorAppConfig)?.android?.flavor || config.development.android.flavor as string);
}

export function getAndroidSha256CertFingerprint (environment: string): string {
  return ((config[environment] as CapacitorAppConfig)?.android?.sha256_cert_fingerprint || config.development.android.sha256_cert_fingerprint as string);
}
