<template>
  <GlobalBannerView />
  <RouterView />
</template>

<script lang="ts" setup>
import useAppVisibility from "@cosine/composables/useAppVisibility";
import useDeepLinks from "@cosine/composables/useDeepLinks";
import useEscapeKeyToCloseModal from "@cosine/composables/useEscapeKeyToCloseModal";
import useLiveTime from "@cosine/composables/useLiveTime";
import useSystemDetection from "@cosine/composables/useSystemDetection";
import usePreventPinchToZoom from "@cosine/composables/usePreventPinchToZoom";
import useSessionSync from "@cosine/composables/useSessionSync";
import useSignalRConnection from "@cosine/composables/useSignalRConnection";
import useAnalyticsStore from "@cosine/stores/useAnalyticsStore";
import GlobalBannerView from "@cosine/views/GlobalBannerView.vue";
import { useSeoMeta } from "@unhead/vue";
import { SafeArea } from "@capacitor-community/safe-area";
import { Capacitor } from "@capacitor/core";
import { CapacitorPlatform } from "./lib/config/capacitor-app.types";

useSeoMeta({
  titleTemplate: (title) => {
    return title ? `${title} | Era` : "Era";
  },
});

if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === CapacitorPlatform.android) {
  SafeArea.enable({
    config: {
      statusBarContent: "dark",
      // 8 is a magic number chosen to make Android match iOS visually because
      // iOS has some extra spacing baked in by default
      offset: 8,
    },
  });
}

useSessionSync();
useAnalyticsStore();
useSignalRConnection();

useLiveTime();
useDeepLinks();
useSystemDetection();
useAppVisibility();
usePreventPinchToZoom();
useEscapeKeyToCloseModal();
</script>
