<template>
  <div :id="idWithoutHash">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { PortalId } from "@cosine/components/Portal.types";
import { computed } from "vue";

const {
  id,
} = defineProps<{ id: PortalId }>();

const idWithoutHash = computed(() => id.replace("#", ""));
</script>
