<template>
  <Transition>
    <EmailVerificationReminderModalView
      v-if="!isCurrentUserVerified && isVisible"
      action="link accounts"
      @close="modalRouter.closeActiveModal"
    />
    <ConnectAccountsModalView
      v-else-if="isCurrentUserVerified && isVisible"
    />
  </Transition>
</template>

<script lang="ts" setup>
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { MxWidgetMode } from "@cosine/types/api-models";
import ConnectAccountsModalView from "@cosine/views/ConnectAccountsModalView.vue";
import EmailVerificationReminderModalView from "@cosine/views/EmailVerificationReminderModalView.vue";
import { computed, watch } from "vue";
import { RouteLocationAsRelativeGeneric, RouteLocationNormalizedLoadedGeneric, useRoute } from "vue-router";

const route = useRoute();
const modalRouter = useModalRouter();

const {
  isCurrentUserVerified,
} = useAuthStore();

const {
  isModalOpen: isConnectModalOpen,
} = useModalRoute(RouteName.accountsConnect);

// TODO: potentially support multiple route names for `useModalRoute`
const {
  isModalOpen: isReconnectModalOpen,
} = useModalRoute(RouteName.accountsReconnect);

const {
  isModalOpen: isVerifyModalOpen,
} = useModalRoute(RouteName.accountsVerify);

const {
  isModalOpen: isReconnectMemberModalOpen,
} = useModalRoute(RouteName.accountsMemberReconnect);

// TODO: test
watch(route, () => {
  const modalRoute = getModalRouteFromRoute(route);

  if (modalRoute) {
    modalRouter.openModal(modalRoute);
  }
}, {
  immediate: true,
});

const isVisible = computed(() => {
  return isConnectModalOpen.value || isReconnectModalOpen.value || isVerifyModalOpen.value || isReconnectMemberModalOpen.value;
});

function getModalRouteFromRoute (route: RouteLocationNormalizedLoadedGeneric): RouteLocationAsRelativeGeneric | null {
  if (route.name !== RouteName.accounts || route.query.connect === undefined) return null;

  if (route.query.mode === MxWidgetMode.Verification) {
    return {
      name: RouteName.accountsVerify,
    };
  }

  if (route.query.connect) {
    return {
      name: RouteName.accountsMemberReconnect,
      params: {
        memberGuid: String(route.query.connect),
      },
    };
  }

  return {
    name: RouteName.accountsConnect,
  };
}
</script>
