import useModalRouter from "@cosine/composables/useModalRouter";
import { onBeforeUnmount, onMounted } from "vue";

export default function () {
  const {
    closeActiveModal,
  } = useModalRouter();

  onMounted(() => {
    window.addEventListener("keydown", handleEscape);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("keydown", handleEscape);
  });

  function handleEscape (event: KeyboardEvent) {
    if (event.key === "Escape") {
      closeActiveModal();
    }
  }
}
