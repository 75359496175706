<template>
  <TimelineListView
    class="TimelineNewsView"
    v-bind="{
      entries,
      pagination,
    }"
    @scrollEnd="fetchNextPage"
  />
</template>

<script lang="ts" setup>
import usePaginatedTimeline from "@cosine/composables/usePaginatedTimeline";
import TimelineListView from "@cosine/views/TimelineListView.vue";
import useTimelineNewsStore from "@cosine/stores/useTimelineNewsStore";
import { storeToRefs } from "pinia";
import { onBeforeMount, onBeforeUnmount } from "vue";

const timelineNewsStore = useTimelineNewsStore();
const {
  entries,
  pagination,
} = storeToRefs(timelineNewsStore);
const {
  fetchEntries,
  listenForEntries,
  unlistenFromEntries,
} = timelineNewsStore;

const {
  fetchNextPage,
} = usePaginatedTimeline({
  pagination,
  fetchEntries: async (params) => {
    return fetchEntries({
      pageIndex: params?.pageIndex || 1,
    });
  },
});

onBeforeMount(listenForEntries);
onBeforeUnmount(unlistenFromEntries);
</script>
