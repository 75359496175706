<template>
  <MxWidgetModal
    class="ConnectAccountsModalView"
    @close="closeModal"
  >
    <VendorSecurityScreen
      v-if="viewState === 'Security'"
      tagTitle="Connect accounts"
      title="Era uses MX to securely connect your accounts"
      :features="[
        {
          iconName: 'Lock',
          title: 'Top notch security',
          body: 'MX is a leader in financial industry security, with 256-bit encryption.',
        },
        {
          iconName: 'VisibilityOff',
          title: 'Your data belongs to you',
          body: 'MX doesn’t sell your personal info.',
        },
      ]"
      @click:continue="handleSecurityContinue"
    >
      <template #logo>
        <path
          d="M205.998 56L230.596 70V98L205.998 112L181.4 98V70L205.998 56Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M206.795 89.4596L208.314 87.5176C208.326 87.5048 208.326 87.4794 208.326 87.4668L206.987 78.2771C206.987 78.239 206.948 78.2136 206.91 78.2136H205.162C205.137 78.2136 205.111 78.2264 205.098 78.2518L200.926 86.7051C200.901 86.756 200.824 86.756 200.798 86.7051L196.588 78.2518C196.575 78.2264 196.55 78.2136 196.524 78.2136H194.763C194.725 78.2136 194.699 78.239 194.687 78.2771L193.002 89.815C192.99 89.8531 193.028 89.8911 193.079 89.8911H195.121C195.159 89.8911 195.184 89.8657 195.197 89.8276L196.218 82.4277C196.231 82.3515 196.32 82.3388 196.358 82.4023L200.071 89.8531C200.084 89.8785 200.109 89.8911 200.135 89.8911H201.577C201.602 89.8911 201.628 89.8785 201.64 89.8531L205.303 82.5038C205.341 82.4405 205.43 82.4531 205.443 82.5292L206.438 89.7007C206.451 89.7642 206.527 89.7894 206.566 89.7389L206.795 89.4596Z"
          fill="white"
        />
        <mask
          id="mask0_153_4"
          style="mask-type: alpha;"
          maskUnits="userSpaceOnUse"
          x="207"
          y="78"
          width="13"
          height="12"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M207.609 89.9038H219.309V78.2136H207.609V89.9038Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_153_4)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M216.658 78.2136C216.632 78.2136 216.619 78.2264 216.594 78.239L213.519 82.2501C213.493 82.2881 213.43 82.2881 213.404 82.2501L210.316 78.239C210.304 78.2264 210.278 78.2136 210.253 78.2136H207.688C207.624 78.2136 207.599 78.2897 207.624 78.3279L212.039 84.0143C212.064 84.0397 212.064 84.0778 212.039 84.1032L207.624 89.7896C207.586 89.8403 207.624 89.9039 207.688 89.9039H210.253C210.278 89.9039 210.291 89.8911 210.316 89.8785L213.404 85.8674C213.43 85.8295 213.493 85.8295 213.519 85.8674L216.607 89.8785C216.619 89.8911 216.645 89.9039 216.671 89.9039H219.235C219.299 89.9039 219.324 89.8278 219.299 89.7896L214.884 84.1032C214.859 84.0778 214.859 84.0397 214.884 84.0143L219.299 78.3279C219.312 78.2771 219.286 78.2136 219.222 78.2136H216.658Z"
            fill="white"
          />
        </g>
      </template>
    </VendorSecurityScreen>

    <div
      v-show="viewState === 'Connect'"
      ref="mxWidgetEl"
      class="ConnectAccountsButtonView__widget"
      :class="{
        'ConnectAccountsButtonView__widget--isLoading': isRequesting,
      }"
    />
    <Spinner
      :isVisible="viewState === 'Connect' && isRequesting"
      isCentered
    />
  </MxWidgetModal>
</template>

<script lang="ts" setup>
import MxWidgetModal from "@cosine/components/MxWidgetModal.vue";
import Spinner from "@cosine/components/Spinner.vue";
import VendorSecurityScreen from "@cosine/components/VendorSecurityScreen.vue";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import useMxConnectWidget from "@cosine/composables/useMxConnectWidget";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { IMxWidgetUrlRequest, MxWidgetMode } from "@cosine/types/api-models";
import { onBeforeUnmount, onMounted, ref, useTemplateRef } from "vue";

const modalRouter = useModalRouter();
const {
  isModalOpen: isConnecting,
} = useModalRoute(RouteName.accountsConnect);
const {
  modalRoute: reconnectModalRoute,
} = useModalRoute(RouteName.accountsReconnect);
const {
  modalRoute: reconnectMemberModalRoute,
} = useModalRoute(RouteName.accountsMemberReconnect);
const {
  isModalOpen: isVerifying,
} = useModalRoute(RouteName.accountsVerify);

const {
  createMxAccount,
} = useFinancialStore();
const mxWidgetEl = useTemplateRef<HTMLElement>("mxWidgetEl");

const {
  handleRequest,
  isRequesting,
} = useRequest();
const {
  mountMxWidget,
  mountMxWidgetForAccount,
  unmountMxWidget,
  onMemberConnected,
} = useMxConnectWidget(mxWidgetEl);

const viewState = ref<"Security" | "Connect">(isConnecting.value ? "Security" : "Connect");

onMounted(async () => {
  if (reconnectModalRoute.value) {
    await handleRequest(mountMxWidgetForAccount(String(reconnectModalRoute.value.params.id)));
  } else {
    const options: IMxWidgetUrlRequest = {};

    const memberGuid = reconnectMemberModalRoute.value?.params.memberGuid;
    if (memberGuid) {
      options.MemberGuid = memberGuid.toString();
    }

    if (isVerifying.value) {
      options.Mode = MxWidgetMode.Verification;
    }

    await handleRequest(mountMxWidget(options));
  }
});

onMemberConnected(async (payload, widgetOptions) => {
  await handleRequest(createMxAccount(payload.member_guid, widgetOptions.Mode));

  closeModal();
});

onBeforeUnmount(() => {
  unmountMxWidget();
});

function handleSecurityContinue () {
  viewState.value = "Connect";
}

function closeModal () {
  modalRouter.closeActiveModal();
}
</script>

<style lang="scss" scoped>
.ConnectAccountsModalView {
  z-index: var(--zIndexPriorityModal);
}

.ConnectAccountsButtonView__widget {
  // MX takes ~2s to finish animating (or rather jittering) in, so this at least smooths that out.
  transition: opacity 2000ms var(--easeInOutCubic);

  &.ConnectAccountsButtonView__widget--isLoading {
    opacity: 0;
  }
}
</style>
