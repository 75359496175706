<template>
  <SheetModal
    class="InvestingReconnectModalView"
    size="Fill"
    theme="White"
    :canClose="!isWidgetMounted"
    @close="closeActiveModal()"
  >
    <div
      ref="container"
      class="InvestingReconnectModalView__portal"
    />
    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </SheetModal>
</template>

<script lang="ts" setup>
import SheetModal from "@cosine/components/SheetModal.vue";
import Spinner from "@cosine/components/Spinner.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import useRequest from "@cosine/composables/useRequest";
import useSnaptradeConnect from "@cosine/composables/useSnaptradeConnect";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import { onBeforeUnmount, onMounted, ref } from "vue";

const {
  accountId,
} = defineProps<{
  accountId: string,
}>();

const {
  fetchConnectWidgetUrl,
} = useFinancialStore();

const {
  closeActiveModal,
} = useModalRouter();

const container = ref<HTMLElement | undefined>();
const {
  mountBrokerageConnectWithUrl,
  unmountBrokerageConnect,
  onBrokerageConnected,
  onExit,
} = useSnaptradeConnect(container);

const {
  handleRequest,
  isRequesting,
} = useRequest();

const isWidgetMounted = ref(false);

onMounted(async () => {
  const [, url] = await handleRequest(fetchConnectWidgetUrl(accountId), {
    showOnlyErrors: true,
  });

  if (url) {
    await handleRequest(mountBrokerageConnectWithUrl(url), {
      showOnlyErrors: true,
    });
    isWidgetMounted.value = true;
  }
});

onBeforeUnmount(unmountBrokerageConnect);
onBrokerageConnected(closeActiveModal);
onExit(closeActiveModal);
</script>

<style lang="scss" scoped>
.InvestingReconnectModalView {
  --backgroundColor: var(--colorWhite);
  z-index: var(--zIndexPriorityModal);
  width: 100%;
  height: 100%;
}

.InvestingReconnectModalView:deep(iframe) {
  max-width: 400px;
  margin: auto;

  border: none;
  background-color: transparent;
}

.InvestingReconnectModalView__portal {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
}
</style>
