<template>
  <CheckboxFieldset
    label="Notification method"
  >
    <CheckboxField
      v-if="supportedChannels.includes(MessageChannel.Timeline)"
      title="Timeline"
      :modelValue="modelValue.includes(MessageChannel.Timeline)"
      @update:modelValue="handleCheck(MessageChannel.Timeline, $event)"
    />
    <CheckboxField
      v-if="supportedChannels.includes(MessageChannel.Email)"
      title="Email"
      :subtitle="email"
      :modelValue="modelValue.includes(MessageChannel.Email)"
      @update:modelValue="handleCheck(MessageChannel.Email, $event)"
    />
    <CheckboxField
      v-if="supportedChannels.includes(MessageChannel.Sms)"
      title="SMS"
      :subtitle="phoneNumber"
      :modelValue="modelValue.includes(MessageChannel.Sms)"
      @update:modelValue="handleCheck(MessageChannel.Sms, $event)"
    />
  </CheckboxFieldset>
</template>

<script lang="ts" setup>
import CheckboxFieldset from "@cosine/components/CheckboxFieldset.vue";
import { MessageChannel } from "@cosine/types/api-models";
import CheckboxField from "@cosine/views/CheckboxField.vue";
import { toRefs } from "vue";

const emit = defineEmits<{
  "update:modelValue": [Array<MessageChannel>],
}>();

const props = defineProps<{
  modelValue: Array<MessageChannel>,
  supportedChannels: Array<MessageChannel>,
  email?: string,
  phoneNumber?: string,
}>();

const {
  modelValue,
} = toRefs(props);

function handleCheck (channel: MessageChannel, isChecked: boolean) {
  const updatedChannels = [...modelValue.value];

  if (isChecked && !updatedChannels.includes(channel)) {
    updatedChannels.push(channel);
  } else if (!isChecked && updatedChannels.includes(channel)) {
    updatedChannels.splice(updatedChannels.indexOf(channel), 1);
  }

  emit("update:modelValue", updatedChannels);
}
</script>
