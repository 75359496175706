import { Capacitor } from "@capacitor/core";
import { syncIndicatorClassName, syncIndicatorHeight } from "@cosine/components/SyncIndicator.types";
import { appLayoutScrollRefInjectionKey } from "@cosine/layouts/AppLayout.types";
import { Ref, inject, onBeforeUnmount, onMounted } from "vue";

export default function useTapToScrollToTop (scrollRef?: Ref<HTMLElement | undefined>) {
  const scrollToTopEventName = "TapToScrollToTop";
  const iOSStatusBarTapEventName = "statusTap";
  const appScrollRef = inject(appLayoutScrollRefInjectionKey) as Ref<HTMLElement | null>;

  onMounted(() => {
    document.documentElement.addEventListener(scrollToTopEventName, handleScrollToTop);

    if (Capacitor.isNativePlatform()) {
      window.addEventListener(iOSStatusBarTapEventName, handleScrollToTop);
    }
  });

  onBeforeUnmount(() => {
    document.documentElement.removeEventListener(scrollToTopEventName, handleScrollToTop);

    if (Capacitor.isNativePlatform()) {
      window.removeEventListener(iOSStatusBarTapEventName, handleScrollToTop);
    }
  });

  function dispatchScrollToTop () {
    document.documentElement.dispatchEvent(new CustomEvent(scrollToTopEventName));
  }

  function handleScrollToTop () {
    const hasSyncIndicator = document.querySelector(`.${syncIndicatorClassName}`);
    const top = hasSyncIndicator ? syncIndicatorHeight : 0;
    const _scrollRef = window.innerWidth >= 600 ? appScrollRef : scrollRef;

    _scrollRef?.value?.scrollTo({
      top,
      behavior: "smooth",
    });

  }

  return {
    scrollToTop: dispatchScrollToTop,
  };
}
