import useApiClient from "@cosine/composables/useApiClient";
import useAuthStore from "@cosine/stores/useAuthStore";
import useConnectionStore from "@cosine/stores/useConnectionStore";
import { INotifyBackgroundJobStatusChanged } from "@cosine/stores/useFinancialStore.types";
import { BackgroundJobStatus, ClientConstants, IApiResponse, IBackgroundStatusJobRecord, IInitialMxDataSynced } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

export default defineStore("BackgroundJobStore", () => {
  const {
    signalRClient,
  } = useConnectionStore();
  const {
    apiClient,
  } = useApiClient();
  const {
    currentUser,
  } = storeToRefs(useAuthStore());
  const initialSyncJobStatus = ref(BackgroundJobStatus.NotStarted);
  const backgroundJobs = ref<Array<IBackgroundStatusJobRecord>>([]);
  const backgroundJobMap = computed(() => {
    return new Map(backgroundJobs.value.map((job) => {
      return [job.Type, job];
    }));
  });

  const isSyncing = computed(() => initialSyncJobStatus.value === BackgroundJobStatus.InProgress);

  watch(currentUser, (newCurrentUser, oldCurrentUser) => {
    if (newCurrentUser && newCurrentUser.UserId !== oldCurrentUser?.UserId) {
      fetchBackgroundJobs();
    }
  }, {
    immediate: true,
  });

  function listenToBackgroundJobs () {
    signalRClient.connection.on(ClientConstants.ReceiveInitialMxDataSynchedFromServer, handleInitialSyncFromServer);
    signalRClient.connection.on(ClientConstants.ReceiveEnrichedSynchedFromServer, handleEnrichedSyncFromServer);
  }

  function unlistenToBackgroundJobs () {
    signalRClient.connection.off(ClientConstants.ReceiveInitialMxDataSynchedFromServer, handleInitialSyncFromServer);
    signalRClient.connection.off(ClientConstants.ReceiveEnrichedSynchedFromServer, handleEnrichedSyncFromServer);
  }

  async function fetchBackgroundJobs () {
    const response = await apiClient.value.get<IApiResponse<Array<IBackgroundStatusJobRecord>>>("/users/me/background-job-statuses");

    backgroundJobs.value = response?.data?.Result || [];
  }

  function handleInitialSyncFromServer (e: IInitialMxDataSynced) {
    initialSyncJobStatus.value = e.BackgroundJobStatus;
  }

  function handleEnrichedSyncFromServer (e: INotifyBackgroundJobStatusChanged) {
    const existingBackgroundJob = backgroundJobs.value.find((job) => job.Type === e.Type);

    if (existingBackgroundJob) {
      existingBackgroundJob.Status = e.BackgroundJobStatus;
    } else {
      backgroundJobs.value.push({
        Type: e.Type,
        Status: e.BackgroundJobStatus,
      });
    }
  }

  return {
    listenToBackgroundJobs,
    unlistenToBackgroundJobs,
    fetchBackgroundJobs,

    backgroundJobs,
    backgroundJobMap,
    isSyncing,
  };
});
