import useConnectionStore from "@cosine/stores/useConnectionStore";
import useTimelineStore from "@cosine/stores/useTimelineStore";
import { ClientConstants, IClientTimelineEntry, IPagination, TimelineEntryFlag, TimelineEntrySources, TimelineEntryVisibilities } from "@cosine/types/api-models";
import { storeToRefs } from "pinia";
import { ref, computed } from "vue";

export default function ({
  sources,
  flags,
}: {
  sources?: Array<TimelineEntrySources>,
  flags?: Array<TimelineEntryFlag>,
}) {
  const {
    signalRClient,
  } = useConnectionStore();
  const timelineStore = useTimelineStore();
  const {
    fetchEntries,
  } = timelineStore;
  const {
    entryMap,
  } = storeToRefs(timelineStore);

  const entryIds = ref<Set<string>>(new Set([]));
  const pagination = ref<IPagination>({
    Page: 1,
    RecordsPerPage: 0,
    TotalPages: 1,
    TotalRecords: 0,
  });

  function listenForEntries () {
    signalRClient.connection.on(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
  }

  function unlistenFromEntries () {
    signalRClient.connection.off(ClientConstants.ReceiveTimelineEntryFromServer, handleTimelineEntryFromServer);
  }

  const entries = computed(() => {
    return Array.from(entryIds.value.values())
      .map((entryId) => entryMap.value.get(entryId)!)
      .filter((entry) => {
        return Boolean(entry) && (!flags?.length || flags.every((flag) => entry.Flags.includes(String(flag))));
      });
  });

  const sortedEntries = computed(() => {
    return entries.value.sort((a, b) => {
      const aDate = a.DateForDisplay || a.DateCreated;
      const bDate = b.DateForDisplay || b.DateCreated;

      if (aDate < bDate) { return 1; }
      if (aDate > bDate) { return -1; }
      return 0;
    });
  });

  async function fetchFilteredEntries (options: {
    pageIndex?: number,
    fromDate?: string,
  } = {}) {
    const response = await fetchEntries({
      Page: options.pageIndex || 1,
      PerPage: 50,
      SourcesFilter: sources?.map(String),
      FlagsFilter: flags?.map(String),
      FromDateInUtc: options.fromDate,
    });

    if (response.Pagination.Page === 1) {
      entryIds.value.clear();
    }

    response.Items.forEach((entry) => {
      if (entry.IdReference) entryIds.value.add(entry.IdReference);
    });
    pagination.value = response.Pagination;

    return response;
  }

  function isFilterMatch (entry: IClientTimelineEntry) {
    return (!sources?.length || sources.includes(entry.Source))
      && (!flags?.length || flags.every((flag) => entry.Flags.includes(String(flag))));
  }

  function upsertEntry (entry: IClientTimelineEntry) {
    if (!entry.IdReference || !isFilterMatch(entry)) return;

    const hasEntry = entryIds.value.has(entry.IdReference);

    if (hasEntry && (entry.DateDeleted || entry.Visibility !== TimelineEntryVisibilities.Visible)) {
      entryIds.value.delete(entry.IdReference);
    } else if (!hasEntry) {
      entryIds.value.add(entry.IdReference);
    }
  }

  function handleTimelineEntryFromServer (entry: IClientTimelineEntry) {
    upsertEntry(entry);
  }

  return {
    listenForEntries,
    unlistenFromEntries,
    entryIds,
    entries: sortedEntries,
    pagination,
    fetchEntries: fetchFilteredEntries,
  };
}
