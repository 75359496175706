<template>
  <section
    class="InvestingListView"
  >
    <template v-if="isAccountsReady">
      <InvestingPrompt
        v-if="!isWaitingForInvestmentAccounts && activeInvestmentAccounts.length === 0"
        @click:prompt="handleConnectClick"
      />

      <div
        v-else
        class="InvestingListView__listContainer"
      >
        <ScrollContainer
          class="InvestingListView__listScrollContainer"
          viewport="Mobile"
          ref="scrollContainerRef"
        >
          <SyncIndicator
            :isSyncing="isSyncingInvestments"
            :lastUpdatedDate="investmentsSyncDate"
            itemName="positions"
            :includeBorder="false"
            v-bind="{scrollElementRef}"
          />
          <InvestingAccountListView />
          <InvestingPositionListView
            title="Overall positions"
            subtitleSuffix="across all brokerages"
          />
          <FormButtonContainer>
            <FormButton
              variant="Primary"
              @click="handleConnectClick"
            >
              Link brokerages
            </FormButton>
          </FormButtonContainer>
        </ScrollContainer>
      </div>
    </template>
    <Spinner
      :isVisible="!isAccountsReady"
      isCentered
    />
  </section>
</template>

<script lang="ts" setup>
import FormButton from "@cosine/components/FormButton.vue";
import FormButtonContainer from "@cosine/components/FormButtonContainer.vue";
import InvestingPrompt from "@cosine/components/InvestingPrompt.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import Spinner from "@cosine/components/Spinner.vue";
import SyncIndicator from "@cosine/components/SyncIndicator.vue";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useInvestingStore from "@cosine/stores/useInvestingStore";
import InvestingAccountListView from "@cosine/views/InvestingAccountListView.vue";
import InvestingPositionListView from "@cosine/views/InvestingPositionListView.vue";
import { storeToRefs } from "pinia";
import { computed, useTemplateRef } from "vue";

const scrollContainerRef = useTemplateRef("scrollContainerRef");

const {
  isAccountsReady,
} = storeToRefs(useFinancialStore());
const {
  activeInvestmentAccounts,
  isWaitingForInvestmentAccounts,
  isSyncingInvestments,
  investmentsSyncDate,
} = storeToRefs(useInvestingStore());

const {
  openModal,
} = useModalRouter();

const scrollElementRef = computed(() => scrollContainerRef.value?.trackEl);

function handleConnectClick () {
  openModal({
    name: RouteName.investingConnect,
  });
}
</script>

<style lang="scss" scoped>
.InvestingListView {
  display: grid;
  height: 100%;
}

.InvestingListView__listContainer {
  display: grid;
  grid: auto min-content / auto;
}

.InvestingListView__listScrollContainer {
  height: 100%;
}
</style>
