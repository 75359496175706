<template>
  <section
    class="SheetModal"
    :class="{
      [`SheetModal--size${size}`]: !!size,
      [`SheetModal--theme${theme}`]: !!theme,
      ['SheetModal--canClose']: canClose,
    }"
  >
    <div
      class="SheetModal__background"
      @click="$emit('close')"
    />
    <div class="SheetModal__container">
      <div
        class="SheetModal__window"
        aria-modal="true"
      >
        <div class="SheetModal__content">
          <div
            v-if="$slots.summary"
            class="SheetModal__summary"
          >
            <slot name="summary" />
          </div>
          <slot />
          <section
            v-if="$slots.buttonList"
            class="SheetModal__buttonList"
          >
            <slot name="buttonList" />
          </section>
          <section
            v-if="$slots.form"
            class="SheetModal__form"
          >
            <slot name="form" />
          </section>
          <section
            v-if="$slots.footer"
            class="SheetModal__footer"
          >
            <slot name="footer" />
          </section>
        </div>
        <button
          class="SheetModal__closeButton"
          @click="$emit('close')"
        >
          <Icon
            class="SheetModal__closeButtonIcon"
            name="X"
          />
        </button>
        <div
          :id="portalId"
          class="SheetModal__portal"
          ref="portalRef"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import Icon from "@cosine/components/Icon.vue";
import { v4 as uuid } from "uuid";
import { provide, ref } from "vue";

defineEmits<{
  close: [],
}>();

withDefaults(defineProps<{
  size?: "Hug" | "Fill",
  theme?: "Dark" | "Light" | "White",
  canClose?: boolean,
}>(), {
  size: "Hug",
  theme: "White",
  canClose: true,
});

const portalId = `SheetModal--${uuid()}`;
const portalRef = ref<HTMLElement>();

provide("portalRef", portalRef);
</script>

<style lang="scss" scoped>
.SheetModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--zIndexModal);

  width: 100%;
  height: var(--visualViewportHeight);
  padding-right: var(--scrollbarWidth, 0);

  transition: none var(--windowEnterTransition);
  pointer-events: none;
}

.SheetModal--sizeFill {
  --windowHeight: calc(100% - (var(--appLogoHeaderHeight) + var(--safeAreaInsetTop)) / 2);
  --windowEnterTransition: 355ms var(--easeOutCubic);
  --windowLeaveTransition: 355ms var(--easeInCubic);
}

.SheetModal--sizeHug {
  --windowEnterTransition: 400ms cubic-bezier(0.17, 1.28, 0.64, 1); // bounce
  --windowLeaveTransition: 200ms cubic-bezier(0.16, 0, 0.5, 0); // drop
}

.SheetModal--themeWhite {
  --windowBackground: var(--colorWhite);
  --windowButtonColor: var(--colorSwissGrey100);
}

.SheetModal--themeLight {
  --windowBackground: var(--themeColorBackgroundSurface);
  --windowButtonColor: var(--colorWhite);
}

.SheetModal__container {
  position: relative;

  display: flex;
  align-items: flex-end;
  height: 100%;
  max-width: var(--layoutMaxWidth);
  margin: 0 auto;

  &::after {
    position: absolute;
    inset: 0;

    outline: 1px solid var(--themeColorBorder);

    transition: opacity var(--mobileThemeTransition);
    content: "";

    .SheetModal.v-enter-from &,
    .SheetModal.v-leave-to & {
      opacity: 0;
    }
  }
}

.SheetModal__background {
  position: absolute;
  inset: 0;
  z-index: -1;

  background: var(--themeColorBackgroundBackdrop);
  backdrop-filter: blur(var(--backgroundBlur));

  transition: opacity var(--mobileThemeTransition);
  pointer-events: auto;
  cursor: pointer;

  .SheetModal.v-enter-from &,
  .SheetModal.v-leave-to & {
    opacity: 0;
  }
}

.SheetModal__window {
  position: relative;
  z-index: 0;

  display: grid;
  width: 100%;
  height: var(--windowHeight);
  overflow: hidden;

  pointer-events: auto;

  &::before {
    position: absolute;
    inset: 0;
    bottom: -24px;
    z-index: -1;

    background: var(--windowBackground);

    content: "";
  }

  &::after {
    position: absolute;
    inset-inline: 0;
    top: 0;
    z-index: 1;

    border-top: 1px solid var(--themeColorBorder);
    content: "";
  }

  .SheetModal.v-enter-active & {
    transition: transform var(--windowEnterTransition);
  }

  .SheetModal.v-leave-active & {
    transition: transform var(--windowLeaveTransition);
  }

  .SheetModal.v-enter-from &,
  .SheetModal.v-leave-to & {
    transform: translateY(100%);
  }
}

.SheetModal__content {
  display: grid;

  @media (max-width: 599px) {
    padding-bottom: var(--safeAreaInsetBottom);
  }
}

.SheetModal__portal {
  position: absolute;
  inset: 0;
  pointer-events: none;

  > :deep(*) {
    pointer-events: auto;
  }
}

.SheetModal__summary {
  padding: 24px 52px 24px 16px;
}

.SheetModal__buttonList {
  display: grid;
  grid-auto-flow: column;

  grid-auto-columns: 1fr;
  border-block: 1px solid var(--themeColorBorder);
}

.SheetModal__form,
.SheetModal__footer {
  position: relative;
  padding: 24px 16px;

  .SheetModal__summary + & {
    border-top: 1px solid var(--themeColorBorder);
  }
}

.SheetModal__closeButton {
  position: absolute;
  top: 12px;
  right: 6px;
  z-index: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  aspect-ratio: 1;

  .SheetModal:not(.SheetModal--canClose) & {
    display: none;
  }
}

.SheetModal__closeButtonIcon {
  --iconColor: var(--themeColorTextSubdued);
}
</style>
