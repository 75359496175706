<template>
  <MetricGrid>
    <MetricGridItem
      v-if="dayOfWeekMetric"
      v-bind="dayOfWeekMetric"
    />
    <MetricGridItem
      v-if="monthMetric"
      v-bind="monthMetric"
    />
  </MetricGrid>
</template>

<script lang="ts" setup>
import MetricGrid from "@cosine/components/MetricGrid.vue";
import MetricGridItem from "@cosine/components/MetricGridItem.vue";
import { IDynamicAttachmentPayload, ITransactionRecapSnapshot } from "@cosine/types/api-models";
import { computed } from "vue";
import { parseISO, subDays, format } from "date-fns";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import { Metric, MetricSentiment } from "@cosine/components/MetricGridItem.types";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";

const modelValue = defineModel<Pick<IDynamicAttachmentPayload, "Snapshot" | "SnapshotDate">>({
  required: true,
});

function getSentimentFromRatio (ratio: number) {
  if (ratio === 0) return MetricSentiment.neutral;

  return ratio < 0 ? MetricSentiment.positive : MetricSentiment.negative;
};

const transactionSnapshot = computed(() => {
  return modelValue.value.Snapshot! as ITransactionRecapSnapshot;
});

const date = computed(() => subDays(parseISO(modelValue.value.SnapshotDate), 1));

const dayOfWeekMetric = computed((): Metric | null => {
  const {
    DayOfWeekAverage,
    DayOfWeekAverageChange,
  } = transactionSnapshot.value;

  if (
    DayOfWeekAverage.Amount === undefined
    || DayOfWeekAverageChange === undefined
  ) {
    return null;
  }

  return {
    // TODO: Use <abbr> tag for "average"
    label: `${format(date.value, DateFormat.weekdayName)} avg.`,
    trend: {
      value: String(formatPercent(DayOfWeekAverageChange, {
        decimalPlaceCount: 2,
        includeSign: true,
      })),
      sentiment: getSentimentFromRatio(DayOfWeekAverageChange),
    },
    value: String(formatAmount(DayOfWeekAverage.Amount)),
  };
});

const monthMetric = computed((): Metric => {
  const {
    MonthDailyAverage,
    MonthDailyAverageChange,
  } = transactionSnapshot.value;

  return {
    // TODO: Use <abbr> tag for "average"
    label: "Past 30 days, daily avg.",
    trend: {
      value:  String(formatPercent(MonthDailyAverageChange, {
        decimalPlaceCount: 2,
        includeSign: true,
      })),
      sentiment: getSentimentFromRatio(MonthDailyAverageChange),
    },
    value: String(formatAmount(MonthDailyAverage.Amount)),
  };
});
</script>
