import { Ref, onBeforeUnmount, onMounted, ref } from "vue";

export default function useScrolledToBottom (elRef: Ref<HTMLElement | undefined>) {
  const isScrolledToBottom = ref(false);

  onMounted(() => {
    elRef.value?.addEventListener("scroll", handleScroll);
    handleScroll();
  });

  onBeforeUnmount(() => {
    elRef.value?.removeEventListener("scroll", handleScroll);
  });

  function updateScrolledToBottom () {
    isScrolledToBottom.value = !!elRef.value
    && elRef.value.scrollTop > 0
    && Math.abs(elRef.value.scrollHeight - (elRef.value.scrollTop + elRef.value.offsetHeight)) < 1;
  }

  function handleScroll () {
    updateScrolledToBottom();
  }

  return {
    isScrolledToBottom,
    updateScrolledToBottom,
  };
}
