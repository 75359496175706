import useApiClient from "@cosine/composables/useApiClient";
import useAuthStore from "@cosine/stores/useAuthStore";
import { IApiResponse, IEraCapabilities, IFinancialAccountCapabilityStatus, IUserCapabilityStatus } from "@cosine/types/api-models";
import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";

export default defineStore("CapabilityStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const {
    currentUser,
  } = storeToRefs(useAuthStore());

  const userCapabilities = ref<Array<IUserCapabilityStatus>>([]);
  const accountCapabilities = ref<Array<IFinancialAccountCapabilityStatus>>([]);

  watch(currentUser, () => {
    if (currentUser.value) {
      fetchCapabilities();
    }
  }, {
    immediate: true,
  });

  async function fetchCapabilities () {
    const {
      data: {
        Result,
      },
    } = await apiClient.value.get<IApiResponse<IEraCapabilities>>("/users/me/capabilities");

    userCapabilities.value = Result?.UserCapabilities || [];
    accountCapabilities.value = Result?.AccountCapabilities || [];
  }

  return {
    fetchCapabilities,

    userCapabilities,
    accountCapabilities,
  };
});
