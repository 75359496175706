import SignalRClient from "@cosine/lib/api/SignalRClient";
import { SignalRClientEvent, SignalRClientEventType } from "@cosine/lib/api/SignalRClient.types";
import useStytchStore from "@cosine/stores/useStytchStore";
import { Constants } from "@cosine/types/api-models";
import { HubConnectionState, LogLevel } from "@microsoft/signalr";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

export default defineStore("ConnectionStore", () => {
  const {
    apiBaseUrl, isProduction,
  } = useRuntimeEnvironment();
  const {
    stytchClient,
  } = useStytchStore();

  const signalRClient = ref(new SignalRClient({
    hubUrl: `${apiBaseUrl}${Constants.HubRoute}`,
    logLevel: isProduction ? LogLevel.Error : LogLevel.Debug,
    logMessageContent: !isProduction,
    accessTokenFactory: () => stytchClient.session.getTokens()?.session_token ?? "",
    retryDelays: [
      250,
      250,
      500,
      500,
      750,
      750,
      1000,
      1000,
      2000,
      2000,
      5000,
    ],
  }));
  const connectionState = ref<HubConnectionState>(HubConnectionState.Disconnected);

  watch(signalRClient, (newClient, oldClient) => {
    oldClient?.removeEventListener(SignalRClientEventType.connectionStateChange, handleConnectionStateChange);

    connectionState.value = newClient.connectionState;
    newClient.addEventListener(SignalRClientEventType.connectionStateChange, handleConnectionStateChange);
  }, {
    immediate: true,
  });

  function handleConnectionStateChange (e: Event) {
    connectionState.value = (e as SignalRClientEvent).detail.toState;
  }

  return {
    signalRClient,
    connectionState,
  };
});
