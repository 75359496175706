<template>
  <fieldset
    class="CheckboxListFieldset"
  >
    <ol class="CheckboxListFieldset__itemList">
      <li
        v-for="item in items"
        :key="item.value"
        class="CheckboxListFieldset__item"
      >
        <label
          class="CheckboxListFieldset__itemContainer"
        >
          <input
            class="CheckboxListFieldset__itemInput"
            type="checkbox"
            :value="item.value"
            :title="item.title"
            v-model="localModelValue"
            @input="handleParentCheck(item, ($event.target as HTMLInputElement).checked)"
          >
          <Icon
            class="CheckboxListFieldset__itemIcon"
            name="CheckSmall"
          />
          <UIText
            class="CheckboxListFieldset__itemLabel"
            size="Small"
            weight="Medium"
          >{{ item.label }}</UIText>
        </label>

        <ol
          v-if="item.subitems && item.subitems.length > 0"
          class="CheckboxListFieldset__itemList"
        >
          <li
            v-for="subitem in item.subitems"
            :key="subitem.value"
            class="CheckboxListFieldset__item"
          >
            <label
              class="CheckboxListFieldset__itemContainer"
            >
              <input
                class="CheckboxListFieldset__itemInput"
                type="checkbox"
                :value="subitem.value"
                :title="subitem.title"
                v-model="localModelValue"
              >
              <Icon
                class="CheckboxListFieldset__itemIcon"
                name="CheckSmall"
              />
              <UIText
                class="CheckboxListFieldset__itemLabel"
                size="Small"
                weight="Medium"
              >{{ subitem.label }}</UIText>
            </label>
          </li>
        </ol>
      </li>
    </ol>
  </fieldset>
</template>

<script lang="ts" setup>
import { CheckboxListFieldsetItem } from "@cosine/components/CheckboxListFieldset.types";
import Icon from "@cosine/components/Icon.vue";
import UIText from "@cosine/components/UIText.vue";
import { computed, toRefs } from "vue";

const emit = defineEmits<{
  "update:modelValue": [Array<string | number>],
}>();

const props = defineProps<{
  modelValue: Array<string | number>,
  items: Array<CheckboxListFieldsetItem>,
}>();

const {
  modelValue,
  items,
} = toRefs(props);

const localModelValue = computed({
  get () {
    return modelValue.value;
  },
  set (values: Array<string | number>) {
    emit("update:modelValue", toggleParentFromValues(values));
  },
});

function toggleParentFromValues (values: Array<string | number>): Array<string | number> {
  return items.value.reduce((acc, item) => {
    const isAllChecked = item.subitems?.every((subitem) => values.includes(subitem.value)) || false;

    if (isAllChecked && !values.includes(item.value)) {
      acc.push(item.value);
    } else if (!isAllChecked && values.includes(item.value)) {
      acc.splice(values.indexOf(item.value), 1);
    }

    return acc;
  }, values);
}

function addAllValuesFromItem (item: CheckboxListFieldsetItem): Array<string | number> {
  return Array.from(new Set([
    ...modelValue.value,
    item.value,
    ...(item.subitems?.map((subitem) => subitem.value) || []),
  ]));
}

function removeAllItemValues (item: CheckboxListFieldsetItem): Array<string | number> {
  const valueSet = new Set(modelValue.value);

  valueSet.delete(item.value);
  item.subitems?.forEach((subitem) => {
    valueSet.delete(subitem.value);
  });

  return Array.from(valueSet);
}

function handleParentCheck (item: CheckboxListFieldsetItem, isChecked: boolean) {
  const values = isChecked
    ? addAllValuesFromItem(item)
    : removeAllItemValues(item);

  emit("update:modelValue", values);
}
</script>

<style lang="scss" scoped>
.CheckboxListFieldset__itemList {
  position: relative;
  z-index: 0;
  display: grid;
  gap: 1px;
}

.CheckboxListFieldset__item {
  --indentation: 0px;

  display: grid;
  gap: 1px;

  & & {
    --indentation: 16px;
  }
}

.CheckboxListFieldset__itemContainer {
  --gap: 4px;
  --iconSize: 24px;

  display: grid;
  grid: auto / min-content auto;
  gap: 2px var(--gap);
  align-items: center;
  padding: 16px var(--layoutMargin) 16px calc(var(--layoutMargin) + var(--indentation));
  outline: 1px solid var(--colorSwissGrey100);
  white-space: nowrap;
  cursor: pointer;
}

.CheckboxListFieldset__itemInput {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.CheckboxListFieldset__itemIcon {
  transition: 200ms var(--easeOutBack);
  transition-property: opacity, transform;

  .CheckboxListFieldset__itemInput:not(:checked) ~ & {
    transform: scale(0.5);
    opacity: 0;
  }
}

.CheckboxListFieldset__itemLabel {
  user-select: none;
  transition: transform 200ms var(--easeOutBack);

  .CheckboxListFieldset__itemInput:not(:checked) ~ & {
    transform: translateX(calc(-1 * (var(--iconSize) + var(--gap))));
  }
}
</style>
