<template>
  <InlineInput
    class="InlinePercentInput"
    :modelValue="formattedValue"
    placeholder="0%"
    v-bind="{autofocus}"
    @format="format"
    @update:modelValue="handleValueUpdate"
  />
</template>

<script lang="ts" setup>
import InlineInput from "@cosine/components/InlineInput.vue";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import unformatPercent from "@cosine/lib/utils/financial/unformatPercent";
import { ref, toRefs, watch } from "vue";

// TODO: variant for normal input style?

const emit = defineEmits<{
  "update:modelValue": [number | null],
}>();

const props = withDefaults(defineProps<{
  modelValue: number | null,
  autofocus?: boolean,
}>(), {
  autofocus: false,
});

const {
  modelValue,
} = toRefs(props);

// TODO: extract into composable?
const formattedValue = ref<string | null>(null);

watch(modelValue, format);

function format () {
  formattedValue.value = formatPercent(modelValue.value);
}

function handleValueUpdate (value: string | null) {
  const newValue = unformatPercent(value);

  if (newValue !== modelValue.value) {
    emit("update:modelValue", newValue);
  }
}
</script>
