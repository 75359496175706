<template>
  <Transition>
    <SheetScreen
      v-show="!viewState"
      class="RoundUpSavingsRoutineFormView__screen"
    >
      <header class="RoundUpSavingsRoutineFormView__header">
        <IconTag
          iconName="Hexagon"
          label="Routine"
        />
        <UIText
          class="RoundUpSavingsRoutineFormView__title"
          tag="h1"
          size="XLarge"
          weight="Medium"
        >
          Round up savings
        </UIText>
      </header>

      <Form
        class="RoundUpSavingsRoutineFormView__form"
        :isDisabled="isRequesting"
        @submit="handleSubmit"
      >
        <ScrollContainer
          class="RoundUpSavingsRoutineFormView__scrollContainer"
          hasBottomFade
        >
          <div class="RoundUpSavingsRoutineFormView__scrollContainerFieldset">
            <TransferAccountSelectView
              v-model:sourceAccountId="form.sourceAccountId"
              v-model:destinationAccountId="form.destinationAccountId"
              v-model:viewState="viewState"
            />

            <TransactionCategorySelectView
              :categories="form.transactionCategories"
              v-model:viewState="viewState"
              @submit="handleCategorySelectSubmit"
            />

            <FormFieldset>
              <RadioGroupField
                label="Round up to the nearest…"
                :items="[
                  { label: '$10', value: 10_00 },
                  { label: '$25', value: 25_00 },
                  { label: '$50', value: 50_00 },
                  { label: '$100', value: 100_00 },
                ]"
                v-model="form.incrementAmount"
              />
            </FormFieldset>

            <RoutineScheduleFieldset
              v-model="form.cronExpression"
            />

            <FormFieldset>
              <TransferInitiationTypeField
                v-model="form.initiationType"
              />
            </FormFieldset>

            <ChannelFieldset
              v-model="form.channels"
              :supportedChannels="[
                MessageChannel.Timeline,
                MessageChannel.Email,
                MessageChannel.Sms
              ]"
              :email="currentUser?.Email"
            />
          </div>
        </ScrollContainer>

        <FormButtonContainer class="RoundUpSavingsRoutineFormView__formButtonContainer">
          <FormButton
            variant="Primary"
            type="submit"
            :disabled="!validatedForm"
          >
            <span v-if="!isRequesting">Schedule routine</span>
            <span v-else>Scheduling routine…</span>
          </FormButton>
        </FormButtonContainer>
      </Form>
    </SheetScreen>
  </Transition>
</template>

<script lang="ts" setup>
import ChannelFieldset from "@cosine/components/ChannelFieldset.vue";
import Form from "@cosine/components/Form.vue";
import FormButton from "@cosine/components/FormButton.vue";
import FormButtonContainer from "@cosine/components/FormButtonContainer.vue";
import FormFieldset from "@cosine/components/FormFieldset.vue";
import IconTag from "@cosine/components/IconTag.vue";
import RadioGroupField from "@cosine/components/RadioGroupField.vue";
import RoutineScheduleFieldset from "@cosine/components/RoutineScheduleFieldset.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import TransferInitiationTypeField from "@cosine/components/TransferInitiationTypeField.vue";
import UIText from "@cosine/components/UIText.vue";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useAuthStore from "@cosine/stores/useAuthStore";
import { RoutineTemplate } from "@cosine/stores/useRoutineStore.types";
import { TransactionCategory } from "@cosine/stores/useTransactionStore.types";
import useTransferStore from "@cosine/stores/useTransferStore";
import { NullableValues } from "@cosine/types";
import { AutomatedTransferInitiationType, MessageChannel } from "@cosine/types/api-models";
import { RoundUpSavingsRoutineOptions } from "@cosine/views/RoundUpSavingsRoutineFormView.types";
import { TransactionCategorySelectViewState } from "@cosine/views/TransactionCategorySelectView.types";
import TransactionCategorySelectView from "@cosine/views/TransactionCategorySelectView.vue";
import TransferAccountSelectView from "@cosine/views/TransferAccountSelectView.vue";
import { storeToRefs } from "pinia";
import { computed, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

const props = defineProps<{
  routineTemplate: RoutineTemplate,
}>();
const router = useRouter();
const {
  currentUser,
} = storeToRefs(useAuthStore());
const {
  createRoundUpSavingsRoutine,
} = useTransferStore();
const {
  handleRequest,
  isRequesting,
} = useRequest();

const {
  routineTemplate,
} = toRefs(props);

const viewState = ref<TransactionCategorySelectViewState | null>(null);

const form = ref(getInitialForm());
defineExpose({
  form,
});

function getInitialForm ():
  NullableValues<RoundUpSavingsRoutineOptions> &
  Required<Pick<RoundUpSavingsRoutineOptions,
  "transactionCategories" |
  "incrementAmount" |
  "cronExpression" |
  "initiationType" |
  "channels"
  >>
{
  const query = routineTemplate.value?.route.query;

  return {
    sourceAccountId: query?.from?.toString() || null,
    destinationAccountId: query?.to?.toString() || null,
    transactionCategories: query?.categories && Array.isArray(query.categories) ? query.categories.map((category) => category as TransactionCategory) : [],
    incrementAmount: query?.increment ? parseInt(String(query.increment)) : 10_00,
    cronExpression: query?.cron ? String(query.cron) : "0 0 8 ? * MON",
    initiationType: AutomatedTransferInitiationType.Manual,
    channels: [MessageChannel.Timeline, MessageChannel.Email],
  };
}

const validatedForm = computed((): RoundUpSavingsRoutineOptions | void => {
  if (
    !form.value.sourceAccountId
    || !form.value.destinationAccountId
    || form.value.transactionCategories.length === 0
  ) return;

  return {
    sourceAccountId: form.value.sourceAccountId,
    destinationAccountId: form.value.destinationAccountId,
    transactionCategories: form.value.transactionCategories,
    incrementAmount: form.value.incrementAmount,
    cronExpression: form.value.cronExpression,
    initiationType: form.value.initiationType,
    channels: form.value.channels,
  };
});

function handleCategorySelectSubmit ({
  categories,
}: { categories: Array<string> }) {
  form.value.transactionCategories = categories;
  viewState.value = null;
}

async function handleSubmit () {
  if (!validatedForm.value) return;

  const [err] = await handleRequest(createRoundUpSavingsRoutine(validatedForm.value), {
    showOnlyErrors: true,
  });

  if (!err) {
    router.push({
      name: RouteName.routinesYours,
    });
  }
}
</script>

<style lang="scss" scoped>
.RoundUpSavingsRoutineFormView__screen {
  display: grid;
  grid: min-content auto / auto;
}

.RoundUpSavingsRoutineFormView__form {
  // TODO: figure out a better way to do this
  > :deep(fieldset) {
    display: grid;
    grid: auto min-content / auto;
    height: 100%;
  }
}

.RoundUpSavingsRoutineFormView__scrollContainer {
  border-top: 1px solid var(--colorSwissGrey100);
}

.RoundUpSavingsRoutineFormView__scrollContainerFieldset {
  display: grid;
  gap: 1px;
}

.RoundUpSavingsRoutineFormView__header {
  display: grid;
  gap: 20px;
  padding: 24px var(--layoutMargin) 20px;
}

.RoundUpSavingsRoutineFormView__title {
  text-wrap: balance;
}

.RoundUpSavingsRoutineFormView__amountInput {
  margin-bottom: 16px;
}

.RoundUpSavingsRoutineFormView__formButtonContainer {
  // TODO: extract
  @media (display-mode: standalone) {
    padding-bottom: calc(24px + var(--viewportPaddingBottom));
  }
}
</style>
