<template>
  <SelectInput
    v-bind="{ modelValue }"
    @update:modelValue="$emit('update:modelValue', $event)"
  >
    <option
      value=""
      disabled
    >
      Select a timezone
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </SelectInput>
</template>

<script lang="ts" setup>
import SelectInput from "@cosine/components/SelectInput.vue";
import { TimeZone, TimeZones } from "@cosine/lib/constants/timezones";
import { computed } from "vue";

defineEmits<{
  "update:modelValue": [string],
}>();

defineProps<{
  modelValue?: string,
}>();

const options = computed((): Array<{
  label: string,
  value: string,
}> => {
  function getDateTimeForTimeZone (timeZone: string): string {
    // Use sv-SE to output date in YYYY-MM-DD format for sorting
    return new Intl.DateTimeFormat("sv-SE", {
      timeZone,
      timeStyle: "short",
      dateStyle: "short",
    }).format();
  }

  return Object.keys(TimeZones).sort((a, b) => {
    return getDateTimeForTimeZone(a).localeCompare(getDateTimeForTimeZone(b));
  })
    .map((timeZone) => {
      const offset = new Intl.DateTimeFormat("en-US", {
        timeZone,
        timeZoneName: "longOffset",
      }).formatToParts()
        .at(-1)?.value;
      const offsetWithZeros = !String(offset).includes(":") ? "GMT+00:00" : offset;

      return {
        label: `(${offsetWithZeros}) ${TimeZones[timeZone as TimeZone]}`,
        value: timeZone,
      };
    });
});
</script>
