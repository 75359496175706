<template>
  <SheetModal
    v-if="day"
    class="SpendingCalendarModalView"
    theme="Light"
    size="Fill"
    @close="closeActiveModal"
  >
    <SheetScreen
      class="SpendingCalendarModalView__screen"
    >
      <div>
        <header class="SpendingCalendarModalView__header">
          <UIText
            class="SpendingCalendarModalView__label"
            tag="h2"
            size="Small"
            weight="Medium"
          >
            {{ formattedDate }}
          </UIText>

          <UIText
            class="SpendingCalendarModalView__title"
            tag="h3"
            size="3XLarge"
            weight="Medium"
          >
            {{ title }}
          </UIText>
        </header>

        <SpendingDateCarousel
          :dates="datesInMonth"
          :selectedDate="day"
          @click:date="handleDateClick"
          class="SpendingCalendarModalView__dateCarousel"
        />
      </div>

      <ScrollContainer v-if="transactionsForDate && transactionsForDate.length > 0">
        <GroupedList>
          <LabeledList
            v-if="spendingTransactions && spendingTransactions.length > 0"
            title="Spending"
          >
            <FinancialTransactionItem
              v-for="transaction in spendingTransactions"
              :key="transaction.EraTransactionId"
              :modelValue="transaction"
              v-bind="{ accountMap }"
              @click="handleTransactionClick(transaction)"
            />
          </LabeledList>

          <LabeledList
            v-if="incomeTransactions && incomeTransactions.length > 0"
            title="Income"
          >
            <FinancialTransactionItem
              v-for="transaction in incomeTransactions"
              :key="transaction.EraTransactionId"
              :modelValue="transaction"
              v-bind="{ accountMap }"
              @click="handleTransactionClick(transaction)"
            />
          </LabeledList>
        </GroupedList>
      </ScrollContainer>

      <UIText
        v-if="requestError || (!isRequesting && transactionsForDate.length === 0)"
        class="SpendingCalendarModalView__emptyState"
        size="Small"
        weight="Medium"
        tag="div"
      >
        <template v-if="requestError">
          There was an error fetching transactions. Please try again.
        </template>
        <template v-else-if="selectedCalendarDate && isFuture(selectedCalendarDate)">
          Return to this date in the future to see its transactions
        </template>
        <template v-else>
          No transactions for this date
        </template>
      </UIText>

      <UIText
        v-if="isRecent"
        class="SpendingCalendarModalView__recentTransactionDisclaimer"
        size="2XSmall"
        tag="div"
      >
        Banks can take 12 to 36 hours to report transactions to Era
      </UIText>
    </SheetScreen>

    <Spinner
      :isVisible="isRequesting"
      isCentered
    />
  </SheetModal>
</template>

<script lang="ts" setup>
import Spinner from "@cosine/components/Spinner.vue";
import FinancialTransactionItem from "@cosine/components/FinancialTransactionItem.vue";
import GroupedList from "@cosine/components/GroupedList.vue";
import LabeledList from "@cosine/components/LabeledList.vue";
import SheetModal from "@cosine/components/SheetModal.vue";
import SheetScreen from "@cosine/components/SheetScreen.vue";
import SpendingDateCarousel from "@cosine/components/SpendingDateCarousel.vue";
import UIText from "@cosine/components/UIText.vue";
import useModalRoute from "@cosine/composables/useModalRoute";
import useModalRouter from "@cosine/composables/useModalRouter";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import { RouteName } from "@cosine/routes";
import useFinancialStore from "@cosine/stores/useFinancialStore";
import useSpendingCalendarStore from "@cosine/stores/useSpendingCalendarStore";
import { IEnrichedTransactionModel, TransactionType } from "@cosine/types/api-models";
import { differenceInCalendarDays, isSameDay, isSameMonth, parse } from "date-fns";
import format from "date-fns/format";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount, watch } from "vue";
import { useRouter } from "vue-router";
import isFuture from "date-fns/isFuture";
import useRequest from "@cosine/composables/useRequest";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import useNow from "@cosine/composables/useNow";
import { DateFormat } from "@cosine/lib/utils/date/dateFormat.types";

const {
  handleRequest, isRequesting, requestError,
} = useRequest();

const spendingCalendarStore = useSpendingCalendarStore();

const {
  updateSpendingCalendarDates,
  updateSelectedDate,
  fetchTransactionsForSelectedDate,
} = spendingCalendarStore;

const {
  spendingCalendarDates,
  transactionsForDate,
  selectedCalendarDate,
} = storeToRefs(spendingCalendarStore);

const financialStore = useFinancialStore();

const {
  accountMap,
} = storeToRefs(financialStore);

const {
  modalRoute: spendingDateModalRoute,
} = useModalRoute(RouteName.spendingCalendarDate);

const {
  openModal,
  closeActiveModal,
} = useModalRouter();

const router = useRouter();

const {
  now,
} = useNow();

onBeforeUnmount(() => {
  updateSpendingCalendarDates([]);
});

const datesInMonth = computed(() => {
  if (selectedCalendarDate.value === null) return [];

  return spendingCalendarDates.value.filter(({
    date,
  }) => selectedCalendarDate.value && isSameMonth(selectedCalendarDate.value, date));
});

const day = computed(() => {
  if (!spendingDateModalRoute.value || !spendingDateModalRoute.value.params.day) return null;

  const {
    day, month, year,
  } = spendingDateModalRoute.value.params;

  const activeDate = parse(`${year}-${month}-${day}`, "yyyy-MM-dd", new Date());

  return spendingCalendarDates.value.find(({
    date,
  }) => {
    return isSameDay(activeDate, date);
  });
});

const spendingTransactions = computed(() => {
  return transactionsForDate.value.filter(transaction => transaction.Type === TransactionType.Debit);
});

const incomeTransactions = computed(() => {
  return transactionsForDate.value.filter(transaction => transaction.Type === TransactionType.Credit);
});

const formattedDate = computed(() => day.value ? format(day.value.date, DateFormat.monthNameWithYear) : null);
const title = computed(() => day.value ? formatAmount(day.value.amount) : null);
const isRecent = computed(() => {
  if (!selectedCalendarDate.value) return null;
  const delta = differenceInCalendarDays(now.value, selectedCalendarDate.value);
  return delta >= 0 && delta <= 2;
});

function handleDateClick (date: Date) {
  updateSelectedDate(date);

  router.push({
    name: RouteName.spendingCalendarDate,
    params: {
      year: date.getFullYear(),
      month: format(date, "MM"),
      day: format(date, "dd"),
    },
  });
}

function handleTransactionClick (transaction: IEnrichedTransactionModel) {
  openModal({
    name: RouteName.transactionsView,
    params: {
      id: transaction.EraTransactionId,
    },
  });
}

watch(selectedCalendarDate, async () => {
  if (!selectedCalendarDate.value) return;

  return handleRequest(fetchTransactionsForSelectedDate());
}, {
  immediate: true,
});
</script>

<style lang="scss" scoped>
.SpendingCalendarModalView {
  --labeledListStickyTop: 0px;
}

.SpendingCalendarModalView__screen {
  display: grid;
  grid: min-content auto min-content / minmax(0, auto);
}

.SpendingCalendarModalView__header {
  display: grid;
  gap: 8px;
  padding: 24px 0;
}

.SpendingCalendarModalView__title,
.SpendingCalendarModalView__label {
  padding: 0 var(--layoutMargin);
}

.SpendingCalendarModalView__emptyState,
.SpendingCalendarModalView__recentTransactionDisclaimer {
  color: var(--colorSwissAlphaBlack400);
  text-align: center;
}

.SpendingCalendarModalView__emptyState {
  align-content: center;
  padding: 40px 16px;
}

.SpendingCalendarModalView__recentTransactionDisclaimer {
  padding: 24px 16px;
  align-self: end;
}

.SpendingCalendarModalView__dateCarousel {
  margin-top: -4px;
}
</style>
