<template>
  <section
    class="LoginCarousel"
    :data-is-timer-active="isTimerActive"
  >
    <div
      v-show="isFontLoaded"
      class="LoginCarousel__container"
    >
      <Transition>
        <LoginSlide v-if="selectedIndex === 0">
          <template #title>
            Build your <strong>financial</strong> <strong>command center</strong>
          </template>
          <template #body>
            Connect your accounts once, and Era will start to optimize your money automatically. No more manual transfers and multiple apps.
          </template>
        </LoginSlide>

        <LoginSlide v-else-if="selectedIndex === 1">
          <template #title>
            Get <strong>personalized</strong> money <strong>answers</strong>
          </template>
          <template #body>
            Chat with the AI that actually knows your finances. Get real insights, not generic advice.
          </template>
        </LoginSlide>

        <LoginSlide v-else-if="selectedIndex === 2">
          <template #title>
            Feel <strong>confident</strong> and in <strong>control</strong>
          </template>
          <template #body>
            Decide what matters, and let Era handle the routine. Your money moves smarter, not just faster.
          </template>
        </LoginSlide>
      </Transition>
    </div>
  </section>
</template>

<script lang="ts" setup>
import LoginSlide from "@cosine/components/LoginSlide.vue";
import useAppBackgroundColor from "@cosine/composables/useAppBackgroundColor";
import useFontLoaded from "@cosine/composables/useFontLoaded";
import sleep from "@cosine/lib/utils/async/sleep";
import { easeOutCubic } from "@shared/lib/animation/easing";
import { onBeforeUnmount, onMounted, ref, watch } from "vue";

const {
  isFontLoaded,
} = useFontLoaded();

const {
  setAppBackgroundColor,
  fadeAppBackgroundColor,
} = useAppBackgroundColor();

const selectedIndex = ref(0);
const colors = [
  "#9382FF",
  "#EAFC78",
  "#41D2D2",
];

const isTimerActive = ref(false);
const startTime = ref(0);
const timerDuration = 5000;
const fadeDuration = 500;
const timerProgress = ref(0);
const rafId = ref(0);

onMounted(() => {
  setAppBackgroundColor(colors[selectedIndex.value]);

  startTimer();
});

onBeforeUnmount(() => {
  stopTimer();
});

watch(selectedIndex, () => {
  fadeAppBackgroundColor(colors[selectedIndex.value], {
    duration: fadeDuration,
    easing: easeOutCubic,
  });
});

function startTimer () {
  stopTimer();

  isTimerActive.value = true;
  startTime.value = performance.now();
  rafId.value = requestAnimationFrame(timerTick);
}

function stopTimer () {
  isTimerActive.value = false;
  cancelAnimationFrame(rafId.value);
}

async function timerTick (now: number) {
  timerProgress.value = Math.min(1, (now - startTime.value) / timerDuration);

  if (timerProgress.value >= 1) {
    stopTimer();
    selectedIndex.value = (selectedIndex.value + 1) % colors.length;

    await sleep(fadeDuration);

    startTimer();
  } else {
    rafId.value = requestAnimationFrame(timerTick);
  }
}
</script>

<style lang="scss" scoped>
.LoginCarousel {
  --transitionDuration: calc(1ms * v-bind(fadeDuration));

  position: relative;

  display: grid;
  width: 100%;
  height: 230px;

  background-color: var(--themeColorBackgroundApp);
}

.LoginCarousel__container {
  position: relative;
  overflow: hidden;

  &:deep(.LoginSlide) {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    transition: var(--transitionDuration) var(--easeOutCubic);
    transition-property: opacity, transform;

    &.v-enter-from {
      transform: translateX(-24px);
      opacity: 0;
    }

    &.v-leave-to {
      transform: translateX(24px);
      opacity: 0;
    }
  }
}
</style>
