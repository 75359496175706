<template>
  <Teleport
    :to="PortalId.AppSubNav"
    defer
  >
    <TabNavView
      :items="tabNavItems"
      isSubNav
      isExact
    />
  </Teleport>

  <RouterView />
</template>

<script lang="ts" setup>
import { PortalId } from "@cosine/components/Portal.types";
import { TabNavItem } from "@cosine/components/TabNav.types";
import { RouteName } from "@cosine/routes";
import TabNavView from "@cosine/views/TabNavView.vue";
import { ref } from "vue";

const tabNavItems = ref<Array<TabNavItem>>([
  {
    label: "Financial accounts",
    to: {
      name: RouteName.accounts,
    },
  },
  {
    label: "Era profile",
    to: {
      name: RouteName.settingsProfile,
    },
  },
]);
</script>
