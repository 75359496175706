import Colors from "@cosine/lib/Colors";

export default function (canvas: HTMLCanvasElement, options: { fill: string }) {
  const ctx = canvas.getContext("2d");
  if (!ctx) return;

  const patternCanvas = document.createElement("canvas");
  const size = 6;
  patternCanvas.width = size;
  patternCanvas.height = size;
  const patternContext = patternCanvas.getContext("2d");
  if (!patternContext) return;

  patternContext.fillStyle = options.fill;
  patternContext.fillRect(0, 0, size, size);
  patternContext.beginPath();
  patternContext.lineWidth = 2;
  patternContext.strokeStyle = Colors.black;
  patternContext.moveTo(0, 0);
  patternContext.lineTo(0, size);
  patternContext.stroke();

  const pattern = ctx.createPattern(patternCanvas, "repeat");
  if (!pattern) return;

  pattern.setTransform(new DOMMatrix().rotate(25));

  return pattern;
}
