<template>
  <FormButton
    class="OAuthButtonView"
    :data-analytics-id="`${slug}ButtonView_auth_button`"
    variant="Secondary"
    :status="isRedirecting ? 'Submitting' : 'Idle'"
    @click="handleClick"
  >
    <span
      v-if="!isRedirecting"
      class="OAuthButtonView__label"
    >
      <svg
        v-if="provider === 'Apple'"
        width="18"
        height="18"
      >
        <path
          d="M16.1858 6.1362C16.0814 6.2172 14.2382 7.2558 14.2382 9.5652C14.2382 12.2364 16.5836 13.1814 16.6538 13.2048C16.643 13.2624 16.2812 14.499 15.4172 15.759C14.6468 16.8678 13.8422 17.9748 12.6182 17.9748C11.3942 17.9748 11.0792 17.2638 9.6662 17.2638C8.2892 17.2638 7.7996 17.9982 6.68 17.9982C5.5604 17.9982 4.7792 16.9722 3.881 15.7122C2.8406 14.2326 2 11.934 2 9.7524C2 6.2532 4.2752 4.3974 6.5144 4.3974C7.7042 4.3974 8.696 5.1786 9.443 5.1786C10.154 5.1786 11.2628 4.3506 12.6164 4.3506C13.1294 4.3506 14.9726 4.3974 16.1858 6.1362ZM11.9738 2.8692C12.5336 2.205 12.9296 1.2834 12.9296 0.3618C12.9296 0.234 12.9188 0.1044 12.8954 0C11.9846 0.0342 10.901 0.6066 10.2476 1.3644C9.7346 1.9476 9.2558 2.8692 9.2558 3.8034C9.2558 3.9438 9.2792 4.0842 9.29 4.1292C9.3476 4.14 9.4412 4.1526 9.5348 4.1526C10.352 4.1526 11.3798 3.6054 11.9738 2.8692Z"
          fill="black"
        />
      </svg>
      <svg
        v-else-if="provider === 'Google'"
        width="18"
        height="18"
      >
        <path
          d="M16.92 9.1875C16.92 8.6025 16.8675 8.04 16.77 7.5H9V10.6913H13.44C13.2488 11.7225 12.6675 12.5963 11.7938 13.1813V15.2512H14.46C16.02 13.815 16.92 11.7 16.92 9.1875Z"
          fill="#4285F4"
        />
        <path
          d="M9 17.2499C11.2275 17.2499 13.095 16.5111 14.46 15.2511L11.7938 13.1811C11.055 13.6761 10.11 13.9686 9 13.9686C6.85125 13.9686 5.0325 12.5174 4.38375 10.5674H1.6275V12.7049C2.985 15.4011 5.775 17.2499 9 17.2499Z"
          fill="#34A853"
        />
        <path
          d="M4.38375 10.5674C4.21875 10.0724 4.125 9.54367 4.125 8.99992C4.125 8.45617 4.21875 7.92742 4.38375 7.43242V5.29492H1.6275C1.05 6.44457 0.749491 7.71338 0.750001 8.99992C0.750001 10.3312 1.06875 11.5912 1.6275 12.7049L4.38375 10.5674Z"
          fill="#FBBC05"
        />
        <path
          d="M9 4.03125C10.2113 4.03125 11.2988 4.4475 12.1538 5.265L14.52 2.89875C13.0913 1.5675 11.2238 0.75 9 0.75C5.775 0.75 2.985 2.59875 1.6275 5.295L4.38375 7.4325C5.0325 5.4825 6.85125 4.03125 9 4.03125Z"
          fill="#EA4335"
        />
      </svg>
      <svg
        v-else-if="provider === 'Facebook'"
        width="18"
        height="18"
      >
        <path
          d="M18 9C18 4.02944 13.9706 -1.42524e-06 9 -1.42524e-06C4.02944 -1.42524e-06 0 4.02944 0 9C0 13.2208 2.90574 16.7624 6.82581 17.735V11.7503H4.96994V9H6.82581V7.81491C6.82581 4.7516 8.2121 3.33176 11.2195 3.33176C11.7897 3.33176 12.7736 3.44354 13.176 3.55536V6.0485C12.9636 6.02615 12.5947 6.01495 12.1363 6.01495C10.6605 6.01495 10.0903 6.57396 10.0903 8.02735V9H13.0301L12.525 11.7503H10.0903V17.9341C14.5466 17.3958 18 13.6014 18 9Z"
          fill="#0866FF"
        />
        <path
          d="M12.5248 11.7503L13.0299 9H10.0902V8.02732C10.0902 6.57392 10.6603 6.01495 12.1361 6.01495C12.5945 6.01495 12.9634 6.02611 13.1758 6.04847V3.55536C12.7733 3.44354 11.7895 3.33173 11.2193 3.33173C8.21192 3.33173 6.8256 4.7516 6.8256 7.81492V9H4.96973V11.7503H6.8256V17.735C7.52195 17.9077 8.25005 18 8.99978 18C9.36893 18 9.73267 17.9772 10.0902 17.9341V11.7503H12.5248Z"
          fill="white"
        />
      </svg>

      <template v-if="route.name === RouteName.login">Sign in</template><template v-else>Sign up</template> using {{ provider }}
    </span>
    <span v-else>
      Redirecting…
    </span>
  </FormButton>
</template>

<script lang="ts" setup>
import { Capacitor } from "@capacitor/core";
import FormButton from "@cosine/components/FormButton.vue";
import useAuthConfig from "@cosine/composables/useAuthConfig";
import { RouteName } from "@cosine/routes";
import useStytchStore from "@cosine/stores/useStytchStore";
import { OAuthProviders } from "@stytch/vanilla-js";
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { SignInWithApple,
  SignInWithAppleOptions } from "@capacitor-community/apple-sign-in";
import useRequest from "@cosine/composables/useRequest";
import useLoginOrSignup from "@cosine/composables/useLoginOrSignup";
import { getAppId } from "@cosine/lib/config/capacitor-app";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import sha256 from "@cosine/lib/utils/auth/sha256";
import generateNonce from "@cosine/lib/utils/auth/generateNonce";
import { CapacitorPlatform } from "@cosine/lib/config/capacitor-app.types";

const {
  provider,
} = defineProps<{
  provider: keyof typeof OAuthProviders,
}>();

const route = useRoute();

const {
  currentEnvironment,
} = useRuntimeEnvironment();

const {
  stytchClient,
  authenticateNativeAppleLogin,
} = useStytchStore();
const {
  authenticateUrl,
} = useAuthConfig();

const {
  handleRequest,
} = useRequest();

const {
  loginOrSignupWithSession,
} = useLoginOrSignup();

const isRedirecting = ref(false);

const slug = computed(() => provider.toLowerCase());

const oauthClient = computed(() => {
  switch (provider) {
    case "Apple":
      return stytchClient.oauth.apple;
    case "Facebook":
      return stytchClient.oauth.facebook;
    case "Google":
      return stytchClient.oauth.google;
    default:
      return null;
  }
});

async function handleClick () {
  if (!oauthClient.value) return;

  isRedirecting.value = true;

  if (Capacitor.getPlatform() === CapacitorPlatform.ios && provider === "Apple") {
    await startNativeAppleLogin();
  }
  else {
    await oauthClient.value.start({
      login_redirect_url: authenticateUrl.value,
      signup_redirect_url: authenticateUrl.value,
    });
  }
}

async function startNativeAppleLogin () {
  const rawNonce = generateNonce();
  const nonce = await sha256(rawNonce);

  const options: SignInWithAppleOptions = {
    clientId: getAppId(currentEnvironment, CapacitorPlatform.ios),
    redirectURI: "", // not required for native login
    scopes: "email name",
    nonce,
  };

  const result = await SignInWithApple.authorize(options);

  const [, sessionData] = await handleRequest(authenticateNativeAppleLogin(result?.response.identityToken, rawNonce));

  await handleRequest(loginOrSignupWithSession({
    sessionToken: sessionData?.session_token,
  }));
}
</script>

<style lang="scss" scoped>
// TODO: eventually fix with @layer
span.OAuthButtonView__label {
  display: grid;
  grid: auto / 1fr auto 1fr;
  gap: 8px;
  justify-content: stretch;
  align-items: center;
  padding-inline: 16px;
}
</style>
