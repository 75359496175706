import useAuthStore from "@cosine/stores/useAuthStore";
import { storeToRefs } from "pinia";
import { watch } from "vue";

export default function () {
  const authStore = useAuthStore();
  const {
    session,
  } = storeToRefs(authStore);
  const {
    updateSignedIn,
  } = authStore;

  watch(session, () => {
    if (session.value) {
      updateSignedIn();
    }
  }, {
    immediate: true,
  });
}
