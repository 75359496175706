<template>
  <FormField
    class="RadioGroupField"
    v-bind="{label}"
    tag="fieldset"
  >
    <div class="RadioGroupField__itemList">
      <label
        v-for="item in items"
        :key="item.value"
        class="RadioGroupField__item"
      >
        <input
          class="RadioGroupField__itemInput"
          type="radio"
          :value="item.value"
          :title="item.title"
          v-model="localModelValue"
        >
        <UIText
          class="RadioGroupField__itemLabel"
          size="XSmall"
          weight="Medium"
        >{{ item.label }}</UIText>
      </label>
    </div>
  </FormField>
</template>

<script lang="ts" setup>
import FormField from "@cosine/components/FormField.vue";
import UIText from "@cosine/components/UIText.vue";
import { computed, toRefs } from "vue";

const emit = defineEmits<{
  "update:modelValue": [string | number | null],
}>();

const props = defineProps<{
  label: string,
  modelValue: string | number | null,
  items: Array<{
    label: string,
    value: string | number,
    title?: string,
  }>,
}>();

const {
  modelValue,
} = toRefs(props);

const localModelValue = computed({
  get () {
    return modelValue.value;
  },
  set (value: string | number | null) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss" scoped>
.RadioGroupField__itemList {
  position: relative;
  z-index: 0;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  border: 1px solid var(--colorSwissGrey100);
  background-color: var(--colorWhite);
  color: var(--colorSwissAlphaBlack600);
  user-select: none;
  transition: var(--defaultMobileTransition);
  transition-property: background-color, color;

  &:has(:disabled) {
    background-color: var(--colorSwissGrey50);
    color: var(--colorSwissAlphaBlack300);
  }
}

.RadioGroupField__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: var(--defaultMobileTransition);
  transition-property: color;

  & + & {
    border-left: inherit;
  }

  &::after {
    position: absolute;
    inset: -1px;
    border: 1px solid var(--colorSwissBlack);
    transition: var(--defaultMobileTransition);
    transition-property: opacity;
    opacity: 0;
    content: "";
  }

  &:has(:checked) {
    z-index: 1;
    color: var(--colorSwissBlack);

    &::after {
      opacity: 1;
    }
  }

  &:has(:disabled) {
    color: inherit;

    &::after {
      opacity: 0;
    }
  }
}

.RadioGroupField__itemInput {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>
