import useAppStore from "@cosine/stores/useAppStore";
import { differenceInMinutes } from "date-fns";
import { storeToRefs } from "pinia";
import { watch } from "vue";

export default function useAppVisibilityCallback<T> (
  callback: () => Promise<T> | T,
  {
    thresholdMinutes,
  }: { thresholdMinutes: number } = {
    thresholdMinutes: 0,
  },
) {
  const {
    isAppVisible,
  } = storeToRefs(useAppStore());
  let lastCallDate = new Date();

  watch(isAppVisible, async () => {
    if (!isAppVisible.value) { return; }
    const now = new Date();

    if (differenceInMinutes(now, lastCallDate) >= thresholdMinutes) {
      await callback();
      lastCallDate = now;
    }
  });
}
