<template>
  <form
    class="Form"
    :disabled="isDisabled ? '' : undefined"
    @submit.prevent="handleSubmit"
  >
    <fieldset
      class="Form__fieldset"
      :disabled="isDisabled ? true : undefined"
    >
      <slot />
    </fieldset>
  </form>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";

const emit = defineEmits<{
  submit: [],
}>();

const props = withDefaults(defineProps<{
  isDisabled?: boolean,
}>(), {
  isDisabled: false,
});
const {
  isDisabled,
} = toRefs(props);

function handleSubmit () {
  if (isDisabled.value) return;

  emit("submit");
}
</script>

<style lang="scss" scoped>
.Form__fieldset {
  display: grid;
}
</style>
