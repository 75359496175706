import { getAppId } from "@cosine/lib/config/capacitor-app";
import { CapacitorPlatform } from "@cosine/lib/config/capacitor-app.types";
import createPlatformHeader from "@cosine/lib/utils/system/createPlatformHeader";
import { FullStackConstants, IApiResponse, ILoginRequestBody, ILoginResponse, IStytchAuthenticatedSession } from "@cosine/types/api-models";
import useRuntimeEnvironment from "@shared/composables/config/useRuntimeEnvironment";
import useLogger from "@shared/composables/useLogger";
import { OAuthAuthenticateResponse, StytchUIClient } from "@stytch/vanilla-js";
import { defineStore } from "pinia";

export default defineStore("StytchStore", () => {
  const {
    isTest,
    apiBaseUrl,
    currentEnvironment,
  } = useRuntimeEnvironment();
  const logger = useLogger();
  const stytchToken = import.meta.env.VITE_STYTCH_PUBLIC_TOKEN;

  if (!stytchToken && !isTest) {
    logger.error("Missing STYTCH_PUBLIC_TOKEN. Login will not work.");
  }

  const stytchClient = new StytchUIClient(stytchToken);

  async function loginOrSignupWithMagicLink (body: ILoginRequestBody): Promise<IApiResponse<ILoginResponse>> {
    const response = await fetch(`${apiBaseUrl}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!data) {
      throw new Error("Could not login");
    }

    return data;
  }

  async function sendMagicLink (email: string, loginMagicLinkUrl: string): Promise<IApiResponse<null>> {
    const response = await fetch(`${apiBaseUrl}/auth/magic-link/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        loginMagicLinkUrl,
      }),
    });

    const data = await response.json();

    if (!data) {
      throw new Error("Could not send magic link");
    }

    return data;
  }

  async function authenticateMagicLink (token: string): Promise<IStytchAuthenticatedSession> {
    const response = await fetch(`${apiBaseUrl}/auth/magic-link/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...createPlatformHeader(),
      },
      body: JSON.stringify({
        token,
      }),
    });

    const data = await response.json();

    if (!data) {
      throw new Error("Could not authenticate magic link with Stytch");
    }

    return data.Result;
  }

  async function authenticateNativeAppleLogin (token: string, nonce: string): Promise<OAuthAuthenticateResponse> {
    const stytchMobileIdentifier = btoa(JSON.stringify({
      app: {
        identifier: getAppId(currentEnvironment, CapacitorPlatform.ios),
      },
      sdk: {
        identifier: "stytch-ios",
        version: "0.62.0",
      },
    }));

    const stytchBaseURL = stytchToken.startsWith("public-token-test") ? "https://test.stytch.com" : "https://api.stytch.com";

    const response = await fetch(`${stytchBaseURL}/sdk/v1/oauth/apple/id_token/authenticate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(`${stytchToken}:${stytchToken}`),
        "X-SDK-Client": stytchMobileIdentifier,
      },
      body: JSON.stringify({
        id_token: token,
        session_duration_minutes: FullStackConstants.StytchSessionDurationMinutes,
        nonce,
      }),
    });

    const results = await response.json();

    if (!results) {
      throw new Error("Could not authenticate with Stytch");
    }

    return results.data;
  }

  return {
    stytchClient,
    sendMagicLink,
    authenticateMagicLink,
    loginOrSignupWithMagicLink,
    authenticateNativeAppleLogin,
  };
});
