import { SignalRClientEventType } from "@cosine/lib/api/SignalRClient.types";
import sleep from "@cosine/lib/utils/async/sleep";
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, IRetryPolicy, LogLevel } from "@microsoft/signalr";
import useLogger from "@shared/composables/useLogger";
import * as Sentry from "@sentry/vue";

export type AccessTokenFactory = () => string | Promise<string>;

export type SignalRClientOptions = {
  hubUrl: string,
  accessTokenFactory?: AccessTokenFactory,
  logLevel?: LogLevel,
  logMessageContent?: boolean,
  retryDelays?: Array<number>,
  retryPolicy?: IRetryPolicy,
  builder?: HubConnectionBuilder,
};

export default class SignalRClient extends EventTarget {
  readonly connection: HubConnection;
  private _connectionState: HubConnectionState = HubConnectionState.Disconnected;
  private shouldBeConnected = false;
  private reconnectCount = 0;
  private maxReconnectCount = 10;

  constructor (options: SignalRClientOptions) {
    super();

    const {
      hubUrl,
      accessTokenFactory,
      logLevel = LogLevel.Error,
      logMessageContent = false,
      retryDelays,
      retryPolicy,
    } = options;
    // Allow passing a custom builder solely for testing purposes
    const builder = options.builder || new HubConnectionBuilder();

    builder.withUrl(hubUrl, {
      transport: HttpTransportType.WebSockets,
      skipNegotiation: true, // needed for websockets
      logger: logLevel,
      logMessageContent,
      accessTokenFactory,
    });
    builder.configureLogging(logLevel);

    if (retryDelays) {
      builder.withAutomaticReconnect(retryDelays);
    } else if (retryPolicy) {
      builder.withAutomaticReconnect(retryPolicy);
    } else {
      builder.withAutomaticReconnect();
    }

    this.connection = builder.build();
    this.connection.onreconnecting(this.handleReconnecting);
    this.connection.onreconnected(this.handleReconnected);
    this.connection.onclose(this.handleClose);
  }

  get connectionState (): HubConnectionState {
    return this._connectionState;
  }

  async connect () {
    this.shouldBeConnected = true;
    await this.connectIfShouldBeConnected();
  }

  async connectIfShouldBeConnected () {
    if (!this.shouldBeConnected || this.connection.state !== HubConnectionState.Disconnected) { return; }

    try {
      await this.wrapWithConnectionCheck(() => this.connection.start());
      this.reconnectCount = 0;

      this.dispatchEvent(new CustomEvent(SignalRClientEventType.connectionConnected));
    } catch (err) {
      useLogger().error(err);
      Sentry.captureException(err);

      if (++this.reconnectCount > this.maxReconnectCount) return;

      await sleep(1000);
      await this.connectIfShouldBeConnected();
    }
  }

  async disconnect () {
    this.shouldBeConnected = false;
    await this.wrapWithConnectionCheck(() => this.connection.stop());
  }

  async invokeWithReconnect<T = any>(methodName: string, ...args: any[]): Promise<T> {
    switch (this.connection.state) {
      case HubConnectionState.Disconnected:
        await this.connectIfShouldBeConnected();
        break;
      case HubConnectionState.Connecting:
        await this.onceConnected();
        break;
      case HubConnectionState.Reconnecting:
        await this.onceReconnected();
        break;
    }

    try {
      return await this.connection.invoke<T>(methodName, ...args);
    } catch (err) {
      const error = err as Error;

      if (error.message.includes("timeout")) {
        return await this.invokeWithReconnect(methodName, ...args);
      } else {
        const matches = / MessageThreadHubException: (.*)$/.exec(error.message);
        if (matches) {
          return Promise.reject(new Error(matches[1], {
            cause: error,
          }));
        }
        return Promise.reject(error);
      }
    }
  }

  private async wrapWithConnectionCheck (callback: () => Promise<void>) {
    const promise = callback();
    this.checkConnectionStateChange();
    await promise;
    this.checkConnectionStateChange();
  }

  private checkConnectionStateChange () {
    if (this.connection.state !== this._connectionState) {
      const fromState = this._connectionState;
      const toState = this.connection.state;

      useLogger().info(`SignalR connection state change: ${fromState} -> ${toState}`);
      this._connectionState = this.connection.state;
      this.dispatchEvent(new CustomEvent(SignalRClientEventType.connectionStateChange, {
        detail: {
          fromState,
          toState,
        },
      }));
    }
  }

  private async waitOnceForConnection (
    addListener: (handler: () => void) => void,
    removeListener: (handler: () => void) => void,
  ) {
    await new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(new Error("Timed out on connecting/reconnecting"));
      }, 15000);

      const handleConnected = () => {
        useLogger().info("Waited for connecting/reconnecting");
        clearTimeout(timeoutId);
        removeListener(handleConnected);
        resolve(undefined);
      };

      addListener(handleConnected);
    });
  }

  private async onceConnected () {
    await this.waitOnceForConnection(
      (handleConnected) => this.addEventListener(SignalRClientEventType.connectionConnected, handleConnected),
      (handleConnected) => this.removeEventListener(SignalRClientEventType.connectionConnected, handleConnected),
    );
  }

  private async onceReconnected () {
    await this.waitOnceForConnection(
      (handleReconnected) => this.connection.onreconnected(handleReconnected),
      (handleReconnected) => this.connection.off("reconnected", handleReconnected),
    );
  }

  private handleReconnecting = () => {
    this.checkConnectionStateChange();
  };

  private handleReconnected = () => {
    this.checkConnectionStateChange();
  };

  private handleClose = async () => {
    this.checkConnectionStateChange();
    await this.connectIfShouldBeConnected();
  };
}
