<template>
  <SpendingCalendarEntryView
    v-if="isSpendingCalendar"
    v-bind="{ modelValue }"
  />
  <MonthlyCashflowEntryView
    v-else-if="isMonthlyCashflow"
    v-bind="{modelValue}"
  />
  <TransactionRecapEntryView
    v-else-if="isTransactionRecap"
    v-bind="{ modelValue }"
  />

  <TimelineEntry
    v-else
    v-bind="{
      modelValue,
      isPinned,
    }"
    @click="handleEntryClick"
  >
    <template #buttonList>
      <TimelineBookmarkButtonView :entry="modelValue" />
    </template>
  </TimelineEntry>
</template>

<script lang="ts" setup>
import TimelineEntry from "@cosine/components/TimelineEntry.vue";
import { FinancialEntryPayloadTypeName } from "@cosine/composables/useFinancialTimelineEntry.types";
import useModalRouter from "@cosine/composables/useModalRouter";
import { RouteName } from "@cosine/routes";
import { IClientTimelineEntry, TimelineEntrySources } from "@cosine/types/api-models";
import MonthlyCashflowEntryView from "@cosine/views/MonthlyCashflowEntryView.vue";
import SpendingCalendarEntryView from "@cosine/views/SpendingCalendarEntryView.vue";
import TimelineBookmarkButtonView from "@cosine/views/TimelineBookmarkButtonView.vue";
import TransactionRecapEntryView from "@cosine/views/TransactionRecapEntryView.vue";
import { computed, toRefs } from "vue";

const props = withDefaults(defineProps<{
  modelValue: IClientTimelineEntry,
  isPinned?: boolean,
}>(), {
  isPinned: false,
});

const {
  openModal,
} = useModalRouter();

const {
  modelValue,
} = toRefs(props);

const isTransactionRecap = computed(() => {
  return hasAttachmentType(FinancialEntryPayloadTypeName.TransactionRecapPayload);
});

// TODO: when we have more than two entry types, use a switch with <Component :is>
const isSpendingCalendar = computed(() => {
  return hasAttachmentType(FinancialEntryPayloadTypeName.AggregatedSpendingPayload);
});

const isMonthlyCashflow = computed(() => {
  return hasAttachmentType(FinancialEntryPayloadTypeName.MonthlyCashflowOverviewPayload);
});

function hasAttachmentType (type: string) {
  return !!modelValue.value.Attachments.find((attachment) => {
    return attachment.Payloads?.find((payload) => payload?.TypeName === type);
  });
}

function handleEntryClick () {
  if (!modelValue.value.IdReference) { return; }

  openModal({
    name: modelValue.value.Source === TimelineEntrySources.System_PeriodicBrief
      ? RouteName.articlesView
      : RouteName.threadsView,
    params: {
      id: modelValue.value.IdReference,
    },
  });
}
</script>
