export const easeInCubicString = "cubic-bezier(0.32, 0, 0.67, 0)";
export const easeOutCubicString = "cubic-bezier(0.33, 1, 0.68, 1)";
export const easeInOutCubicString = "cubic-bezier(0.65, 0, 0.35, 1)";

export function easeInQuad (x: number): number {
  return x * x;
}

export function easeOutQuad (x: number): number {
  return 1 - (1 - x) * (1 - x);
}

export function easeOutCubic (x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

export function easeInOutCubic (x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
