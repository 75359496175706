import useApiClient from "@cosine/composables/useApiClient";
import useEditModal from "@cosine/composables/useEditModal";
import useFlag from "@cosine/composables/useFlag";
import { FlagKey } from "@cosine/composables/useFlag.types";
import useAuthStore from "@cosine/stores/useAuthStore";
import { ModalName } from "@cosine/stores/useModalStore.types";
import { SubscriptionPlanKey, SubscriptionPriceKey } from "@cosine/stores/useSubscriptionStore.types";
import { IApiResponse, IPaymentSessionLink, IUserSubscriptionModel, SubscriptionStatus } from "@cosine/types/api-models";
import { isBefore, parseISO } from "date-fns";

import { defineStore, storeToRefs } from "pinia";
import { computed } from "vue";

export default defineStore("SubscriptionStore", () => {
  const {
    apiClient,
  } = useApiClient();
  const {
    currentUser,
  } = storeToRefs(useAuthStore());
  const {
    flag: upgradeFlag,
  } = useFlag(FlagKey.EnableUpgradeCTAs, false);

  const {
    editingItem: editingSubscription,
    startEditingItem: startEditingSubscription,
    finishEditingItem: finishEditingSubscription,
  } = useEditModal<IUserSubscriptionModel | null>(ModalName.UpgradeSubscription);

  const activeSubscription = computed((): IUserSubscriptionModel | null => {
    return currentUser.value?.Subscription ?? null;
  });

  const isSubscribed = computed(() => {
    return !!activeSubscription.value;
  });

  const isFreeTrialSubscription = computed(() => {
    return isSubscribed.value && currentUser.value?.Subscription?.PlanKey === SubscriptionPlanKey.FreeTrial;
  });

  const isEssentialsSubscription = computed(() => {
    return isSubscribed.value
      && currentUser.value?.Subscription?.PlanKey === SubscriptionPlanKey.EssentialsSubscription
      && currentUser.value.Subscription.Status === SubscriptionStatus.Active;
  });

  const subscriptionName = computed(() => {
    if (!activeSubscription.value) return "Free";

    if (isEssentialsSubscription.value) return activeSubscription.value.DisplayName;

    const endDate = parseISO(activeSubscription.value.EndDate);
    // TODO: Replace new Date() with `useNow`, which can't currently
    // be used in a store due to provide/inject
    if (isBefore(endDate, new Date())) return "Free";

    return activeSubscription.value.DisplayName;
  });

  const canUpgrade = computed(() => {
    return upgradeFlag.value && (!isSubscribed.value || isFreeTrialSubscription.value);
  });

  async function redirectToCheckout (options: {
    successUrl?: string,
    cancelUrl?: string,
    couponId?: string,
  } = {}) {
    const response = await apiClient.value.get<IApiResponse<IPaymentSessionLink>>("/subscriptions/payment-link", {
      params: {
        PriceKey: SubscriptionPriceKey.PlusMonthlyQ1Jan,
        SuccessUrl: options.successUrl || window.location.href,
        CancelUrl: options.cancelUrl || window.location.href,
        ...(options.couponId
          ? {
            CouponCodeId: options.couponId,
          }
          : {}
        ),
      },
    });

    if (response.data.Result?.Url) {
      window.location.href = response.data.Result.Url;
    }
  }

  async function redirectToCustomerPortal () {
    const url = window.location.href;

    const response = await apiClient.value.get<IApiResponse<IPaymentSessionLink>>(`/subscriptions/portal-link?ReturnUrl=${url}`);

    if (response.data.Result?.Url) {
      window.location.href = response.data.Result.Url;
    }
  }

  return {
    editingSubscription,
    isSubscribed,
    canUpgrade,
    activeSubscription,
    subscriptionName,

    redirectToCheckout,
    redirectToCustomerPortal,
    startEditingSubscription,
    finishEditingSubscription,
  };
});
