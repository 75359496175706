<template>
  <canvas ref="chartRef" />
</template>

<script lang="ts" setup>
import { InvestmentListItemChartData } from "@cosine/components/InvestmentListItemChart.types";
import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, TimeScale, TimeSeriesScale } from "chart.js";
import "chartjs-adapter-date-fns";
import Annotation from "chartjs-plugin-annotation";
import { onBeforeUnmount, ref, shallowRef, toRefs, watch } from "vue";

const props = defineProps<{
  modelValue: InvestmentListItemChartData,
}>();

// TODO: extract this into a higher level file
Chart.register(
  Annotation,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  LineElement,
  TimeSeriesScale,
  TimeScale,
);

const {
  modelValue,
} = toRefs(props);

const chartRef = ref<HTMLCanvasElement>();
const chart = shallowRef<Chart<"line", Array<{ x: string,
  y: number, }>>>();
defineExpose({
  chart,
});

watch([chartRef, modelValue], () => {
  const ctx = chartRef.value?.getContext("2d");
  if (!ctx || !modelValue.value) return;

  if (chart.value) {
    chart.value.data.datasets[0].data = modelValue.value.points;
    chart.value.update();
    return;
  }

  chart.value = new Chart(ctx, {
    type: "line",
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          display: false,
          type: "time",
          min: modelValue.value.dateRange.min,
          max: modelValue.value.dateRange.max,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
        },
        annotation: {
          annotations: {
            line: modelValue.value.axis
              ? {
                type: "line",
                yMin: modelValue.value.axis,
                yMax: modelValue.value.axis,
                borderColor: "rgb(25 26 23 / 0.3)", // alpha black 300
                borderWidth: 0.5,
                borderDash: [2, 2],
              }
              : undefined,
          },
        },
      },
    },
    data: {
      datasets: [
        {
          data: modelValue.value.points,
          fill: false,
          borderColor: "#191a17", // TODO: black via JS
          segment: {
            borderColor (ctx) {
              if (!modelValue.value.axis) return;
              const value = (
                modelValue.value.points[ctx.p0DataIndex].y
                + modelValue.value.points[ctx.p1DataIndex].y
              ) / 2;

              if (value > modelValue.value.axis) return "#2BE9A4";
              if (value < modelValue.value.axis) return "#FF4E78";
            },
          },
          tension: 0.2,
          pointStyle: false,
          borderWidth: 2,
        },
      ],
    },
  });
});

onBeforeUnmount(() => {
  chart.value?.destroy();
});
</script>
