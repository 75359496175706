<template>
  <InvestmentListItem
    class="PositionListItem"
    :title="modelValue.Symbol"
    :value="formattedAmount"
    :subvalue="formattedPercentChange"
    v-bind="{
      marketData,
      accountMap,
    }"
  >
    <template #subtitle>
      <AccountLabel
        :modelValue="account"
      />
    </template>
  </InvestmentListItem>
</template>

<script lang="ts" setup>
import AccountLabel from "@cosine/components/AccountLabel.vue";
import InvestmentListItem from "@cosine/components/InvestmentListItem.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import formatPercent from "@cosine/lib/utils/financial/formatPercent";
import { IAssetDetail, IAssetOverall, IAssetPrice, IEnrichedAccountModel, IEnrichedAssetPositionModel, IMutualFundSummary, ITimeSeriesRangeOf } from "@cosine/types/api-models";
import currency from "currency.js";
import { computed, toRefs } from "vue";

const props = defineProps<{
  modelValue: IEnrichedAssetPositionModel,
  marketData?: ITimeSeriesRangeOf<IAssetOverall<IAssetDetail | IMutualFundSummary>, IAssetPrice>,
  accountMap?: Map<string, IEnrichedAccountModel>,
}>();

const {
  modelValue,
  marketData,
  accountMap,
} = toRefs(props);

const account = computed(() => accountMap.value?.get(modelValue.value.EraAccountId) || null);

const formattedAmount = computed((): string => {
  if (!modelValue.value.LastPrice) return "$--.--";

  return String(formatAmount(currency(modelValue.value.LastPrice?.Amount || 0).multiply(modelValue.value.Units)));
});

const formattedPercentChange = computed(() => {
  if (!modelValue.value.PriceChange && modelValue.value.PriceChange !== 0) return "--%";

  return String(formatPercent(modelValue.value.PriceChange, {
    decimalPlaceCount: 2,
    includeSign: true,
  }));
});
</script>
