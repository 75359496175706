<template>
  <InlineInput
    class="InlineCurrencyInput"
    :modelValue="formattedValue"
    :placeholder="formattedPlaceholder"
    v-bind="{autofocus}"
    @format="format"
    @update:modelValue="handleValueUpdate"
  />
</template>

<script lang="ts" setup>
import InlineInput from "@cosine/components/InlineInput.vue";
import formatAmount from "@cosine/lib/utils/financial/formatAmount";
import unformatAmount from "@cosine/lib/utils/financial/unformatAmount";
import { ref, toRefs, watch } from "vue";

// TODO: variant for normal input style?

const emit = defineEmits<{
  "update:modelValue": [number | null],
}>();

const props = withDefaults(defineProps<{
  modelValue: number | null,
  autofocus?: boolean,
}>(), {
  autofocus: false,
});

const {
  modelValue,
} = toRefs(props);

// TODO: extract into composable?
const formattedValue = ref<string | null>(null);
const formattedPlaceholder = ref(formatAmount(0)!);

watch(modelValue, format);

function format () {
  formattedValue.value = formatAmount(modelValue.value);
}

function handleValueUpdate (value: string | null) {
  const newValue = unformatAmount(value);

  if (newValue !== modelValue.value) {
    emit("update:modelValue", newValue);
  }
}
</script>
