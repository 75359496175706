<template>
  <FinancialTimelineEntry
    label="Daily transaction summary"
    title="Get a daily summary of transactions plus trends"
    :titleCallouts="['transactions']"
    v-bind="{isSyncing}"
  >
    <div class="TransactionRecapPlaceholderEntry__metricGridContainer">
      <TransactionRecapMetricGrid
        :modelValue="payload"
      />
    </div>

    <template #cta>
      <ConnectAccountsButtonView :data-amp-track="AnalyticsTrackId['TransactionRecapTimelineEntry.connectAccountsButton']" />
    </template>
  </FinancialTimelineEntry>
</template>

<script lang="ts" setup>
import FinancialTimelineEntry from "@cosine/components/FinancialTimelineEntry.vue";
import TransactionRecapMetricGrid from "@cosine/components/TransactionRecapMetricGrid.vue";
import { AnalyticsTrackId } from "@cosine/stores/useAnalyticsStore.types";
import { Currency, IAttachmentSnapshot } from "@cosine/types/api-models";
import ConnectAccountsButtonView from "@cosine/views/ConnectAccountsButtonView.vue";
import { ref } from "vue";

defineProps<{
  isSyncing?: boolean,
}>();

const payload = ref({
  Snapshot: {
    TotalTransactions: 0,
    TotalSpent: {
      Amount: 0,
      Currency: Currency.USD,
    },
    DayOfWeekAverage: {
      Amount: 130_04,
      Currency: Currency.USD,
    },
    DayOfWeekAverageChange: -0.015,
    MonthDailyAverage: {
      Amount: 110_19,
      Currency: Currency.USD,
    },
    MonthDailyAverageChange: 0.1624,
  } as unknown as IAttachmentSnapshot,
  SnapshotDate: "2024-11-07T18:58:07.9092024Z",
});
</script>

<style lang="scss" scoped>
.TransactionRecapPlaceholderEntry__metricGridContainer {
  padding-bottom: 88px;
}
</style>
