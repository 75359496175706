<template>
  <section
    class="InvestingExploreView"
    :class="{
      [`InvestingExploreView--isSearching`]: isSearching,
    }"
  >
    <Transition>
      <div
        v-if="marketStocks.length > 0 || !isRequesting"
        class="InvestingExploreView__container"
      >
        <InvestingExploreSearchView />

        <ScrollContainer
          v-if="!isSearching"
          class="InvestingExploreView__scrollContainer"
          viewport="Mobile"
          hasBottomFade
        >
          <section
            v-if="publicMixes.length > 0"
            class="InvestingExploreView__section InvestingExploreView__section--mixes"
          >
            <SectionHeader
              class="InvestingExploreView__sectionHeader"
              title="Mixes"
            />

            <InvestmentMixListItem
              v-for="mix in publicMixes"
              :key="mix.BlueprintId"
              :modelValue="mix"
              @click="openModal({
                name: RouteName.investingMix,
                params: {
                  id: mix.BlueprintId,
                }
              })"
            />
          </section>

          <section class="InvestingExploreView__section InvestingExploreView__section--stocks">
            <SectionHeader
              class="InvestingExploreView__sectionHeader"
              title="Stocks"
            />

            <StockListItem
              v-for="stock in marketStocks"
              :key="stock.Series[0].Summary.Metadata.Symbol"
              :modelValue="stock.Series[0].Summary.Metadata"
              :marketData="stock"
            />
          </section>
        </ScrollContainer>
      </div>
    </Transition>
  </section>
</template>

<script lang="ts" setup>
import InvestmentMixListItem from "@cosine/components/InvestmentMixListItem.vue";
import ScrollContainer from "@cosine/components/ScrollContainer.vue";
import SectionHeader from "@cosine/components/SectionHeader.vue";
import StockListItem from "@cosine/components/StockListItem.vue";
import useAppSpinner from "@cosine/composables/useAppSpinner";
import useModalRouter from "@cosine/composables/useModalRouter";
import useNow from "@cosine/composables/useNow";
import useRequest from "@cosine/composables/useRequest";
import { RouteName } from "@cosine/routes";
import useInvestmentMixStore from "@cosine/stores/useInvestmentMixStore";
import useMarketStore from "@cosine/stores/useMarketStore";
import InvestingExploreSearchView from "@cosine/views/InvestingExploreSearchView.vue";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { useRoute } from "vue-router";

const {
  now,
} = useNow();
const investmentMixStore = useInvestmentMixStore();
const {
  fetchPublicMixes,
} = investmentMixStore;
const {
  publicMixes,
} = storeToRefs(investmentMixStore);
const marketStore = useMarketStore();
const {
  fetchMarket,
} = marketStore;
const {
  market,
} = storeToRefs(marketStore);
const {
  handleRequest, isRequesting,
} = useRequest();
const route = useRoute();
const {
  openModal,
} = useModalRouter();
useAppSpinner(isRequesting);

const isSearching = computed(() => !!route.query.q);

const marketStocks = computed(() => {
  if (!market.value) return [];

  return market.value.Series.map((series) => {
    return {
      ...market.value!,
      Series: [series],
    };
  });
});

watch(now, () => {
  handleRequest(Promise.all([
    publicMixes.value.length === 0
      ? fetchPublicMixes()
      : null,
    fetchMarket(),
  ]));
}, {
  immediate: true,
});
</script>

<style lang="scss" scoped>
.InvestingExploreView {
  height: 100%;
}

.InvestingExploreView__container {
  display: grid;
  grid: min-content auto / auto;
  height: 100%;

  transition: opacity 500ms;

  .InvestingExploreView--isSearching & {
    grid: auto / auto;
  }

  &.v-enter-from,
  &.v-leave-to {
    opacity: 0;
  }
}
</style>
