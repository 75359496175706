export enum IconName {
  Airplane = "Airplane",
  Avatar = "Avatar",
  ArrowCycle = "ArrowCycle",
  ArrowCurvedLeft = "ArrowCurvedLeft",
  ArrowInward = "ArrowInward",
  ArrowOutward = "ArrowOutward",
  ArrowLeft = "ArrowLeft",
  ArrowRefresh = "ArrowRefresh",
  ArrowRight = "ArrowRight",
  ArrowTransfer = "ArrowTransfer",
  ArrowUp = "ArrowUp",
  Bag = "Bag",
  Bank = "Bank",
  Banknote = "Banknote",
  BookmarkAdd = "BookmarkAdd",
  BookmarkRemove = "BookmarkRemove",
  Burger = "Burger",
  Carrot = "Carrot",
  Check = "Check",
  CheckSmall = "CheckSmall",
  Checkbook = "Checkbook",
  ChevronDown = "ChevronDown",
  Coffee = "Coffee",
  CreditCard = "CreditCard",
  Dollar = "Dollar",
  Ellipsis = "Ellipsis",
  ExclamationDiamond = "ExclamationDiamond",
  Eye = "Eye",
  EyeCrossed = "EyeCrossed",
  Exclamation = "Exclamation",
  Football = "Football",
  GasPump = "GasPump",
  GraphLine = "GraphLine",
  House = "House",
  Info = "Info",
  Lightbulb = "Lightbulb",
  Lock = "Lock",
  MagnifyingGlass = "MagnifyingGlass",
  Menu = "Menu",
  MobilePhone = "MobilePhone",
  MoneyMemo = "MoneyMemo",
  MoneyHouse = "MoneyHouse",
  Newspaper = "Newspaper",
  Octagon = "Octagon",
  Pause = "Pause",
  PauseSolid = "PauseSolid",
  Pencil = "Pencil",
  PiggyBank = "PiggyBank",
  Pin = "Pin",
  Play = "Play",
  Plus = "Plus",
  Scissors = "Scissors",
  Shirt = "Shirt",
  Stethoscope = "Stethoscope",
  Sync = "Sync",
  Ticket = "Ticket",
  Trash = "Trash",
  VisibilityOff = "VisibilityOff",
  X = "X",
  XOctagon = "XOctagon",
  Wine = "Wine",
}
